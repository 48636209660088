//
//  Idea:
//      - Having a left box for a config card with fixed width value
//      - Having a right box that takes all the remaining space and left aligns the content
//
//  Responsiveness:
//      - on small screens -> hide the config card. (hide-sm on the div)
//
//

.sb-config-card-box-left {
    display: block;
    position: absolute;
    top:60px;
    bottom:0;
    left:0;
    overflow-y: scroll;
    md-card {
        margin: 12px 0;
        width: @filter-card-width - 10px; // remove some space to not overlap with the scrollbar
        max-width: @filter-card-width - 10px; // remove some space to not overlap with the scrollbar

    }

}

.sb-config-card-box-right {
    display: block;
    position: absolute;
    top:0;
    bottom:0;
    left:250px;
    right:0;
    margin: @margin-big  0 0;
    // IE fix
    overflow-x: hidden;

    .sb-background-info .sb-trascluded-content{
        padding-right: 250px;
        box-sizing: border-box;
        @media (max-width: @media-xlg) {
            padding-right: 0;
        }
    }
}

.sb-config-card-box {
    display: block;
    position: absolute;
    top:0;
    bottom:0;
    left:0;
    right:0;
    overflow: hidden;
    background-color: @gray-background;
}

@media (max-width: @media-sm) {
    .sb-config-card-box-right{
        left: 0;
    }
}

