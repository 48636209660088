@sb-team-select--number-of-visible-options: 8;
@sb-team-select-max-expanded-height: (@sb-team-select--number-of-visible-options * 40px);

// component class for team selection in dropdown list
//

.sb-team-select {
    width: 100%;
    min-width: 220px;
    text-indent: 8px;
    margin-left: @margin-normal;

    margin: 1em 0;
    padding: 0;
}

// show team color on active selection
//
.sb-team-select__input {
    border-left: 5px solid;
    padding-right: 5px;
    height: auto;
}

.sb-team-select__input--colorless{
    border-color: transparent;
}

// custom styling for md-select-container
//
.sb-team-select__options {

    // overwrite maximum height to show more items in team selection
    //
    md-select-menu, md-content{
        max-height: @sb-team-select-max-expanded-height;
    }
}

// show team color as left border on select options
//
.sb-team-select__option {
    border-left: 5px solid;
    margin: 2px 0;
}

// do not show colored border on action items
//
.sb-team-select__option--colorless {
    margin: 2px 0;
    padding: 0 21px;
}

// multiline on many values instead of overflowing
//
.md-select-value {
    span:first-child {
        white-space: normal;
    }
}

.sb-team-select__select--invalid {
    margin-top: @margin-small;
    font-size: @font-size-smaller;
    white-space: nowrap;
    color: @riemi-red-A700;
}
