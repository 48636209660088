@close-button-size: 50px;


.sb-images-overlay {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    // needed for IE
    max-width: 100%;
    max-height: 100%;
    background-color: @sablono-grey-900;
    .flex-row;
}

.sb-images-overlay__gallery {
    width: 100%;
    display: flex;
}

.sb-images-overlay__close-btn, .sb-gallery__download-photo {
    position: absolute;
    top: 0;
    right: 0;
    height: @close-button-size;
    width: @close-button-size;
    font-size: 30px;
    background-color: @sablono-grey-800;
    color: @light-text-color;
    cursor: pointer;
    outline-width: 0;
    z-index: 1;
    .flex-row-center;

    &:hover {
        background-color: @sablono-grey-700;
    }
}

.sb-gallery__download-photo {
    right: 50px;
}

.sb-gallery__image-info {
    .flex-column();
    .large-thin-font();
    position: absolute;
    background-color: @sablono-grey-800;
    color: @light-text-color;
    bottom: 0;
    right: 0;
    padding: @padding-small;
    span:not(:first-child) {
        padding-top: @padding-small;
    }
    .mdi {
        margin-right: @margin-small;
    }
}

.sb-gallery__image-info-title {
    .larger-bold-font();
}
