@import "../../../../../styles/global";
@import "../../../../../styles/colorpalette";

@sb-look-ahead-definition-date-picker-height: 32px;
@sb-look-ahead-definition-dialog-width: 470px;
@sb-look-ahead-definition-dialog-left-column-size: 190px;
@sb-look-ahead-definition-unset-text-width: 120px;
@sb-look-ahead-definition-checkbox-width: 30px;

.sb-look-ahead-definition__dialog {
    display: block;
    max-width: @sb-look-ahead-definition-dialog-width;
}

.sb-look-ahead-definition__row {
    .flex-row-center-start();
}

.sb-look-ahead-definition__content {
    color: @primary-font-color;
    .small-font();
    .flex-column();
}
md-dialog-content.sb-look-ahead-definition__content {
    padding: @padding-small @padding-normal;
    margin: @margin-none;
}

.sb-look-ahead-definition__key, .sb-look-ahead-definition__value {
    .sb-readability-fix();
    display: block;
    margin: @margin-tiny;
    align-self: center;
}

.sb-look-ahead-definition__key {
    flex: 0 0 @sb-look-ahead-definition-dialog-left-column-size;
}

.sb-look-ahead-definition__value {
    margin-left: @margin-normal;
    flex: 1 1 0;
}

.sb-look-ahead-definition__last-planned-duration-input-container {
    margin: 0 0 @margin-small 0;
    padding: 0;
    width: @sb-look-ahead-definition-unset-text-width;

    .md-errors-spacer {
        display: none;
    }
}

.sb-look-ahead-definition__checkbox-container {
    margin: @margin-small 0 @margin-small 0;
    .flex-row-center-start();
}

.sb-look-ahead-definition__date-picker-form {
    flex: 0 0 0;
    position: relative;
}

.sb-look-ahead-definition__checkbox {
    margin: 0;
    flex: 0 0 @sb-look-ahead-definition-checkbox-width;
}

.sb-look-ahead-definition__select {
    margin: @margin-small 0 @margin-small 0;
}

.sb-look-ahead-definition__date-picker {
    position: relative;

    .md-datepicker-input {
        color: @primary-font-color;
        .small-font();
    }

    .sb-datepicker--undefined-value .sb-datepicker__not-set-text {
        color: @sablono-grey-500;
    }

    .sb-datepicker-container {
        margin: @margin-small 0 @margin-small @margin-smaller;
        padding: @padding-none;
    }

    .md-datepicker-button, label, .md-errors-spacer {
        display: none;
    }

    .md-input {
        height: @sb-look-ahead-definition-date-picker-height;
    }

    .md-datepicker-triangle-button {
        transform: translate(@margin-small, @margin-tiny);
    }

    .md-datepicker-input-container {
        margin-left: 0;
    }
}

.sb-look-ahead-definition__error {
    font-size: @font-size-smaller;
    color: @riemi-red-500;
}
