@import "../../../../styles/global";
@import "../../../../styles/colorpalette";

.sb-create-new-issue-dialog{
    width: 600px;
    max-width: 80%;
}
.sb-create-new-issue-dialog-content{
    min-height:300px;
    position: relative;
    .sb-saving{
        font-size: @font-size-normal;
        position: absolute;
        top:8px;
        right: 16px;
    }
}

.sb-create-new-issue-dialog-content__text {
    width: 100%;
}

.sb-create-new-issue-dialog-content__team-select {

    .sb-team-select {
        width: 100%;
    }

}

.sb-create-new-issue-dialog__note-preview {
    display: flex;
    flex-direction: row;
    align-items: center;
}

.sb-create-new-issue-dialog__note-icon {
    font-size: 32px;
    margin-right: 8px;
}

.sb-create-new-issue-dialog__note-icon .sb-claim {
    color: @note-color-quality-issue;
}

.sb-create-new-issue-dialog__note-icon .sb-obstruction {
    color: @note-color-obstruction;
}

.sb-create-new-issue-dialog__note-icon .sb-info {
    color: @note-color-info;
}

.sb-create-new-issue-dialog__note-description {
    font-size: 16px;
}
