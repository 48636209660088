sb-breadcrumbs {
    overflow: hidden;
    display: flex;
    flex-shrink: 1;
    flex-direction: row;
    flex-wrap: nowrap;
    align-items: center;
    align-content: flex-start;
    justify-content: flex-start;
    color: @sablono-grey-800;
    .breadcrumb-item-container {
        display: flex;
        align-items: center;
        flex-grow: 0;
        flex-shrink: 1;
        align-self: flex-start;
        overflow: hidden;
        //don't shrink the last item
        &:last-of-type {
            flex-shrink: 0;
        }
    }
    .breadcrumb-delimiter {
        flex-shrink: 0;
        font-size: 15pt;
        color: @sablono-grey-600 !important;
        position: relative;
    }
    .breadcrumb-item,
    .breadcrumb-menu-button {
        flex-shrink: 1;
        white-space: nowrap;
        text-overflow: ellipsis;
        min-width: 0;
        text-transform: none;
        color: @sablono-grey-800;
    }
    .breadcrumb-menu-button {
        display: flex;
        align-items: center;
        .select-icon {
            margin-left: 8px;
            color: @sablono-grey-800;
            font-size:@font-size-larger;
        }
    }
    .breadcrumb-menu {
        margin: 0px;
        padding: 0px;
    }
}

.breadcrumb-menu-icon {
    font-size: 20px;
    text-align: center;
}
