

.template-editor-no-data-cards{
    min-height: 125px;
}
sb-group-activity-rows{

    .section {
        h2.title{
            color: @primary-font-color;
            font-weight: @font-weight-normal;
        }
        .containers {
            margin: 2px 8px 20px 8px;
            .row {
                background-color: white;
                min-height: 50px;
                color: @primary-font-color;
                display: flex;
                flex-direction: row;
                align-items: center;
                outline: none;
                text-decoration: none;
                border-bottom: 1px solid @border-color;
                .sb-icon-activity, .sb-icon-group{
                    color: @primary-font-color;
                    font-size: 18pt;
                }
                .delete-row i {
                    font-size: 24px;
                    opacity: 0;
                    transition: opacity 200ms;
                    color: @riemi-red-500;
                }
                .delete-row:focus i {
                    opacity: 1;
                }
                &:hover {
                    background-color: @sablono-grey-100;
                    cursor: pointer;
                    .delete-row i {
                        opacity: 1;
                    }
                }
                .image-column {
                    display: flex;
                    flex-basis: 50px;
                    justify-content: center;

                }
                .code-column {
                    display: flex;
                    flex: 10;
                    justify-content: center;
                    text-align: center;
                }
                .activity-name-column {
                    flex: 30;
                    md-input-container {
                        padding: 0;
                        margin: 0;
                        input {
                            padding: 0;
                            margin: 0;
                        }
                    }
                }
                .name-column {
                    flex: 60;
                    md-input-container {
                        padding-bottom: 0px;
                    }
                }
                .add-new-autocomplete {
                    background-color: transparent;
                    md-autocomplete-wrap {
                        box-shadow: none;
                        input {
                            margin: 0;
                            padding: 0;
                            border-bottom: 1px solid @sablono-grey-300;
                            font-size: 16px;
                        }
                        button {
                            display: none;
                        }
                    }
                }
            }
            &.not-editable{
                box-shadow: none;
                .row:hover{
                    background: @white-background;
                    cursor: auto;
                }
            }

            .activity-edit-name-column {
                flex: 30;
                md-input-container {
                    padding: 0;
                    margin: 0;
                    input {
                        padding: 0;
                        margin: 0;
                    }
                }
            }
            .activity-edit-state-column{
                flex: 30;
                md-input-container {
                    padding: 0;
                    margin: 0;
                    input {
                        padding: 0;
                        margin: 0;
                    }
                }
            }
        }
    }
}

.group-item-template-activity{
    font-weight: @font-weight-bold;
    display: flex;
    align-items: center;
    .activity-delimiter{
        font-size: 1.5em;
        padding: 0 0.2em;
    }
}

.group-item{
    .flex-row;
}

.group-item-template {
    &-title {
        .group-code {
            padding-right: @padding-small;
            .highlight{
                color: @riemi-red-500;
            }
        }
        .group-name {
            flex: 1;
            font-weight: @font-weight-bold;
            .highlight{
                color: @riemi-red-500;
            }
        }
        .activity-count{
            flex-shrink: 0;
            font-weight: @font-weight-normal;
        }
    }
    &-info {
        .flex-column;
    }
    &-desc{

    }
    &-author{
        font-weight: @font-weight-normal;
        color: rgba(0, 0, 0, 0.53);
    }
}
.group-item-template-new {
    display: flex;
    flex-direction: column;
    span {
        font-weight: @font-weight-bold;
    }
}

