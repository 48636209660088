@import "sbCalendarDay/calendar_day";
@calendar-exceptions-container-width: 470px;
@calendar-exceptions-header-margin-left: 5px;
@calendar-exceptions-header-font-size: 14px;

.sb-calendar-days-list {
    .flex-row-center;
    overflow: hidden;
}

.sb-calendar-days-list__list {
    width: @calendar-exceptions-container-width;
}

.sb-calendar-days-list__header {
    margin-left: @calendar-exceptions-header-margin-left;
    font-size: @calendar-exceptions-header-font-size;
    color: @sablono-grey-600;
}

.sb-calendar-days-list-dates__error {
    .flex-row-center;
    padding-left: @padding-normal;
    font-size: @font-size-small;
    color: @riemi-red;
    font-weight: @font-weight-bold;
    i {
        margin-right: @margin-smaller;
    }
}

.sb-calendar-days-list-add-more {
    .flex-column-center-start
}

.sb-calendar-days-list-dates__day-item {
    width: 100%;
}

md-list-item.sb-calendar-days-list-dates__list-item {
    padding: 0;
}

