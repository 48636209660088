@import "../../../../../../styles/global";
@import "../../../../../../styles/colorpalette";

@sb-activity-details-panel-width: 500px;
@sb-activity-details-panel-min-height: 240px;
@sb-activity-details-panel-header-min-height: 30px;
@sb-activity-details-date-picker-width: 200px;
@sb-activity-details-loading-height: 245px;
@has-changed-session-left-position: 140px;
@sb-activity-details-date-picker-height: 21px;
@sb-activity-details-tooltip-width: 300px;
@sb-activity-details-left-side-flex: 1.3;
@sb-activity-details-right-side-flex: 1.5;
@sb-activity-details-last-planned-duration-input-width: 48%;

@sb-activity-details-accept-forecast-button-height: 18px;

.sb-activity-details-panel {
    width: @sb-activity-details-panel-width;
    min-height: @sb-activity-details-panel-min-height;

    md-checkbox.md-checked .md-icon:after {
        top: 0;
        left: 5px;
    }
}

.sb-activity-details-panel__header {
    min-height: @sb-activity-details-panel-header-min-height;
    padding: @padding-normal @padding-small;
    background-color: @sablono-grey-50;
    align-items: center;
}

.sb-activity-details-panel__header-text {
    font-size: @font-size-larger;
    font-weight: @font-weight-bold;
    margin-left: @margin-small;
    color: @sablono-grey-800;
}

.sb-activity-details-panel__call-to-actions {
    .flex-row;
    justify-content: space-between;
}

.sb-activity-details-panel__call-to-actions--only-one {
    margin-left: auto !important;
}

.sb-activity-details-panel__row {
    .flex-row;
}

.sb-activity-details-panel__content {
    .flex-column;
    padding: @padding-small @padding-normal;
}

.sb-activity-details-panel__key, .sb-activity-details-panel__value {
    font-size: @font-size-small;
    display: block;
    margin: @margin-tiny;
}

.sb-activity-details-panel__key {
    color: @sablono-grey-600;
    .sb-readability-fix;
    flex: @sb-activity-details-left-side-flex;
    align-self: flex-start;
}

.sb-activity-details-panel__value {
    word-break: break-all;
    .sb-readability-fix;
    flex: @sb-activity-details-right-side-flex;
}

.sb-activity-details-panel__last-planned-duration-input-container {
    margin: -@margin-smaller 0 @margin-small 0;
    padding: 0;
    padding-bottom: @padding-smaller;
    height: @sb-activity-details-date-picker-height;
    width: @sb-activity-details-last-planned-duration-input-width;

    .md-errors-spacer {
        display: none;
    }

    input {
        padding-top: 0;
    }
}

.sb-activity-details-panel__checkbox-container {
    .flex-row();
    flex: @sb-activity-details-right-side-flex;
    margin-bottom: @margin-smaller;
}

.sb-activity-details-panel__date-picker-form {
    flex: @sb-activity-details-right-side-flex;
    position: relative;

    .sb-datepicker__not-set-text{
        left: 0;
    }
}

.sb-activity-details-panel__has-changed-this-session {
    position: absolute;
    top: 0;
    left: @has-changed-session-left-position;
    font-size: @font-size-larger;
    color: @sablono-green-main;
}

.sb-activity-details-panel__checkbox {
    margin-top: @margin-normal;
}

.sb-activity-details-panel__checkbox-text {
    .smaller-font();
    position: relative;
    top: 10px;
}

.sb-activity-details-panel__tooltip {
    word-wrap: break-word;
    max-width: @sb-activity-details-tooltip-width;
    white-space: initial;
    height: initial;
    line-height: 1.6;
}

.sb-activity-details-panel__tags {
    .flex-column;
}

.sb-activity-details-panel__tag-cloud {
    width: 100%;
    border-right: none;
    padding-right: @padding-none;
}

.sb-activity-details-panel__change-date {
    cursor: pointer;
    margin-left: @margin-small;

    &:hover {
        color: @sablono-grey-900;
    }
}

.sb-activity-details-panel__loading {
    min-height: @sb-activity-details-loading-height;
}

.sb-activity-details-panel__date-picker {
    height: @sb-activity-details-date-picker-height;
    position: relative;
    top: -@margin-tiny;

    .md-datepicker-input {
        font-weight: @font-weight-light;
        color: @sablono-grey-800;
    }

    .sb-datepicker--undefined-value .sb-datepicker__not-set-text {
        color: @sablono-grey-500;
        .small-font();
    }

    .sb-datepicker-container {
        margin: @margin-smaller @margin-none;
        padding: @padding-none;
    }

    .md-datepicker-button, label, .md-errors-spacer {
        display: none;
    }

    .md-input {
        height: @sb-activity-details-date-picker-height;
    }

    .md-datepicker-triangle-button {
        transform: translate(@margin-small, @margin-tiny);
    }

    .md-datepicker-input-container {
        margin-left: 0;
    }
}

.sb-activity-details-panel__error {
    font-size: @font-size-smaller;
    color: @riemi-red-500;
}

.sb-activity-details-panel__forecasted-date {
    position: relative;
    top: @margin-tiny;
}

.sb-activity-details-panel__accept-forecasted-date-button {
    font-size: @font-size-smaller;
    background-color: @sablono-green-400;
    color: @white-background !important;
    min-height: @sb-activity-details-accept-forecast-button-height;
    height: @sb-activity-details-accept-forecast-button-height;
    line-height: @sb-activity-details-accept-forecast-button-height;
    font-size: @font-size-smallest;
    margin: 0 0 0 @margin-small;
    text-transform: none;

    &:hover {
        background-color: @sablono-green-600 !important;
    }
}

.sb-activity-details-panel {
    .sb-loading {
        position: inherit;
        top: inherit;
        width: inherit;
        text-align: inherit;

    }
}

.sb-activity-details-panel__further-details {
    outline: none;
    padding: @padding-smaller @padding-normal;
    font-size: @font-size-small;
    color: @sablono-green-400;
    cursor: pointer;
    font-weight: @font-weight-normal;
}

.sb-activity-details-panel__responsible-team {
    padding-top: 0;
}

.sb-workflow__activity-details-panel-cell--multiple-entries {
    // Prevents layout break caused by white-space: no wrap in the .ellipsed-text();
    min-width: 0;

    ul {
        margin: @margin-none;
        padding: @padding-none;
        overflow: auto;
        list-style-type: none;
        // indicates scrollable content
        // see  https://ux.stackexchange.com/questions/26214/how-to-indicate-container-is-scrollable-without-scroll-bars
        max-height: 58px;
    }

    ul li {
        .ellipsed-text();
    }
}

.sb-activity-details-panel__session-loader {
    display: flex;
    align-items: center;
}

.sb-activity-details-panel__plan-button-wrapper {
    display: flex;
}

.sb-activity-details-panel__edit-row {
    min-height: 33px;
}
