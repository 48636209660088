@sb-toolbar-search-max-width: 800px;

.sb-toolbar-search-bar {
    display: flex;
    flex-direction: row;
    align-items: center;
    max-width: @sb-toolbar-search-max-width;
    margin: auto;
}

.sb-toolbar-search-bar__input {

    width: 100%;
    margin: auto;
    height: 40px; // same as toolbar icon height.

    background-color: @white-background;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: row;
    border-radius: 5px;

    input {
        .normal-thin-font();
        border-bottom: none;
        width: 100%;
        min-width: 100%;
    }
}

.sb-toolbar-search-bar__icon {
    color: @sablono-grey-500 !important;
}

.sb-toolbar-search-bar__cancel {
    color: @sablono-grey-500 !important;
    outline: none;
    &:focus {
        outline: none;
    }
    &.mdi-close {
        cursor: pointer;
    }
}
