.sb-import-upload__background-info--custom-dates-detected .sb-trascluded-content {
    align-items: center;
    text-align: start;
    h1,h2,h3,h4,h5,h6,p {
        margin-left: 0;
        margin-right: 0;
    }
    .sb-import-upload__custom-dates-detected-container {
        max-width: 560px;
    }
    .sb-import-upload__custom-dates-detected--select-heading {
        margin-top: 0.2em;
        margin-bottom: 0.2em;
        font-weight: bold;
    }
    .sb-import-upload__custom-dates-detected--select-description {
        margin-top: 0.2em;
        margin-bottom: 0.2em;
    }
    .sb-import-upload__custom-dates-detected--actions {
        display: flex;
        justify-content: flex-end;
    }
}

