.sb-profile md-card {
    max-width: 600px;
    margin-left: auto;
    margin-right: auto;
}
.sb-profile {
    background-color: inherit;
    padding: 0 8px;
}
.sb-profile-lowercase {
    text-transform: none;
}

.sb-profile__password-input {
    flex: 1 1 200px;
}
