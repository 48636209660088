@sb-bulk-checklist-pdf-export-max-width: 600px;

@sb-bulk-checklist-pdf-container-min-height: 350px;
@sb-bulk-checklist-pdf-empty-state-container-width: 120px;
@sb-bulk-checklist-pdf-empty-state-container-height: 130px;
@sb-bulk-checklist-pdf-empty-state-icon-size: 80px;

.sb-bulk-checklist-pdf-export-dialog {
    max-width: @sb-bulk-checklist-pdf-export-max-width;
    min-width: @sb-bulk-checklist-pdf-export-max-width;
}

.sb-bulk-checklist-pdf-export-dialog__toolbar {
    flex: 0 0 @sb-expanded-toolbar-height;
}

.sb-bulk-checklist-pdf-export-dialog__actions {
    flex: 0 0 @sb-toolbar-height;
}

.sb-dialog md-toolbar .sb-bulk-checklist-pdf-export-dialog__header {
    margin: @margin-normal;
}

.sb-bulk-checklist-pdf-export-dialog__success {
    .flex-row-center();
}

.sb-bulk-checklist-pdf-export-dialog__success-text {
    .normal-font();
    color: @sablono-grey-700;
}

.sb-dialog .sb-bulk-checklist-pdf-export__empty-state {
    .flex-row();
    min-height: @sb-bulk-checklist-pdf-empty-state-container-height;
    padding: @padding-none @padding-normal;
}

.sb-bulk-checklist-pdf-export__icon-container{
    .flex-column-center();
    flex: 0 0 auto;
    width: @sb-bulk-checklist-pdf-empty-state-container-width;
}

.sb-bulk-checklist-pdf-export__text-container {
    .flex-row-center-start();
    flex: 1;

    .sb-bulk-checklist-pdf-export__empty-state-title, .sb-bulk-checklist-pdf-export__empty-state-text {
        margin: @margin-smaller;
        font-weight: @font-weight-light;
        color: @sablono-grey-600;
    }

    .sb-bulk-checklist-pdf-export__empty-state-title{
        color: @sablono-grey-700;
    }
}

.mdi-file-remove {
    font-size: @sb-bulk-checklist-pdf-empty-state-icon-size;
    width: @sb-bulk-checklist-pdf-empty-state-icon-size;
    height: @sb-bulk-checklist-pdf-empty-state-icon-size;
}

.sb-bulk-checklist-pdf-export-dialog__add-email-btn-icon {
    display: flex;
    justify-content: center;
    border-radius: 50%;
    margin-left: @margin-small;
    height: 20px;
    width: 20px;
    outline-width: 0;
    font-size: 18px;
    color: @sablono-green-main;
}



.sb-bulk-checklist-pdf-export-dialog__add-email-btn-text {
    margin-left: 8px;
}

.sb-bulk-checklist-pdf-export-dialog__add-email-wrapper {
    display: flex;
    flex-direction: row;
    align-items: center;
    border: 0;
    background-color: transparent;
    position: absolute;
    left: 12px;
}

.sb-bulk-checklist-pdf-export-dialog__add-email-wrapper:disabled .sb-bulk-checklist-pdf-export-dialog__add-email-btn-icon {
    color: @sablono-grey-400
}
