@import '../../../styles/layout';
@import 'components/sbSingleActionCard/single_action_card';
@import 'components/sbLegend/legend';
@import 'components/sbSearchableSelect/searchable_select';
@import 'components/sbSvgConfigCard/svg_config_card';
@import 'components/sbDeliverablePanelTooltipPanel/sb_deliverable_panel_tooltip';
@import 'states/sketchVisualization/sketch_visualization';
@import 'states/visualizationConfig/visualization_config';
@import 'components/sbFullscreenCard/fullscreen_card';


.sb-visualization-list {
    background-color: @sablono-grey-50;
    height: 100%;
    .sb-visualization-list-container {
        .sb-centered-content-area-of(@media-md);
        display: flex;
        flex-wrap: wrap;
        padding: @padding-small;
        .sb-visualization-card {
            box-sizing: border-box;
            flex: 0 0 33.33%;
            max-width: 33.33%; //IE
            display: flex;
            flex-direction: column;
            overflow: hidden;
            & > * {
                flex: 1 0 auto;
            }
            @media (max-width: @media-chrome-xlg) {
                flex: 0 0 33.33%;
                max-width: 33.33%; //IE
            }
            @media (max-width: @media-chrome-lg) {
                flex: 0 0 33.33%;
                max-width: 33.33%; //IE
            }
            @media (max-width: @media-chrome-md) {
                flex: 0 0 50%;
                max-width: 50%; //IE
            }
            @media (max-width: @media-chrome-sm) {
                flex: 0 0 100%;
                max-width: 100%; //IE
            }
            // customize some styles from sb-image-card
            .sb-image-card-image {
                background-size: cover;
                padding: @padding-small;
            }
            .sb-image-card-title {
                position: relative;
                top: 0;
            }
        }
    }
    .sb-add-new-card .sb-add-new-card-content {
        font-size: @font-size-larger;
    }
}

.sb-visualization-list-empty {
    .flex-row-center();
    height: 100%;
}

.sb-visualizations-page__details-view {
    .flex-row();
    flex: 1 0 100%;
    overflow: auto;
}

.sb-for-print {
    display: none;
}

@media only print {
    #sb-subbar,
    .sb-toolbar,
    .sb-content-zoom,
    .sb-svg-legend__header__button,
    .sb-toolbar-state__intercom,
    #user-image-top-card,
    .sb-toolbar-state-info-right-item,
        // last one, solves problem of showing tooltip in the printing
    .md-panel-outer-wrapper {
        display: none;
    }

    // this makes sure the content is not cut off all overflows must be full visible
    //  no scrollbars are allowed
    * {
        overflow: visible !important;
    }

    html {
        overflow: auto;
        body {
            display: contents;
        }
    }

    .ng-sablono {
        height: auto;
        min-height: auto;
    }

    .sb-for-print {
        display: block;
    }

    .sb-for-print-title {
        width: 100%;
        border-bottom: 1px solid @sablono-grey-200;
        padding-bottom: @padding-small;
        color: @sablono-grey-500;
        .flex-row-center-start;
    }

    .sb-print-logo {
        width: 50px;
        margin-right: 10px;
    }

    .sb-visualization-page {
        padding: 0 1cm;
    }
    .sb-visualization-page.sb-visualization-page--with-searchable-select {
        margin: unset;
    }

    .sb-visualization-page__controls {
        padding-top: 1cm;
        break-before: page;
        break-inside: avoid;
        position: relative;
        max-height: initial;
        margin: 0;
        width: auto;
    }
    .sb-visualization-page__controls--left {
        display: none;
    }

    .sb-visualizations-page__details-view {
        position: absolute;
        left: 0;
        right: 0;
    }

    .sb-title {
        span {
            display: inline;
        }
    }

    .sb-svg-legend {
        background: transparent;
        box-shadow: none;
        .sb-svg-legend__entry,
        .sb-svg-legend__header span {
            background: transparent;
        }
    }

    .sb-svg-legend__entries {
        max-height: none;
        overflow: hidden;
    }

    .sb-svg-legend__entry {
        max-width: none;
    }

    // print selection and list as well
    // overwrite of material design css
    .sb-searchable-select md-content {
        overflow: hidden !important;
    }

    .sb-visualization-page .sb-svg-container {
        height: 100% !important; // revert the current zoom
        width: 100% !important; // revert the current zoom
        svg {
            max-width: 100%;
            padding-bottom: 0;
        }
    }

    .sb-svg-container.sb-print-prefer-landscape {
        svg {
            max-height: 170mm; // adjusted to work with a two line title
        }
    }

    .sb-svg-container.sb-print-prefer-portrait {
        svg {
            max-height: 255mm; // adjusted to work with a two line title
        }
    }

    .sb-content-zoom {
        height: auto;
    }



    // Remove margins to remove automatic footer and header in Chrome.
    // In other browsers, header and footer need to be removed by user input
    @page {
        margin: 0;
    }

}

.sb-action-card {
    &:focus {
        outline: none;
    }
}

.sb-visualization-card--primary {
    .sb-single-action-card {
        color: @primary-font-color;
    }
}

.sb-visualization-card--secondary {
    .sb-single-action-card {
        color: @primary-font-color;
    }
}

.sb-visualization-tooltip {
    background-color: @white-background;
    border-radius: 3px;
    padding:10px;
    box-shadow: 0px 0px 10px 0px rgba(0,0,0,0.62);
    border:1px solid @sablono-grey-100;
    pointer-events: none;
}
