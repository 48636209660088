.sb-file-card {
    @main-color: @sablono-grey-500;
    box-sizing: border-box;
    height: 100%;
    // ie fix
    margin:2px;
}

.sb-file-card__new-card {
    height: 95%;
    padding: 0 @padding-normal;
    .flex-column-center;
    box-sizing: border-box;
    cursor: pointer;
    border: 2px dashed @sablono-grey-300;
    color: @sablono-grey-500;
    text-shadow: 1px 2px 2px @sablono-grey-50;
    font-weight: @font-weight-light;
    text-align: center;
    transition: all 0.2s ease;

    &:hover {
        outline: none;
        border: 2px solid @sablono-grey-300;
        background: @sablono-grey-100;
    }
}

.sb-file-card__new-card__icon {
    font-size: 32px;
    display: block;
}
