@sb-user-badge-image-size: 40px;
.sb-user-badge{

    .sb-user-badge-container{
        .flex-row-center-start;
        padding: @padding-small;
        &:hover{
            .sb-user-badge-hover{
                display: block;
            }
        }
    }
    .sb-user-badge-user{
        .flex-column;
        font-size: @font-size-small;
        justify-content: space-between;
        width:calc(100% ~"-" @sb-user-badge-image-size + @padding-smaller );
        &-name,
        &-display-name{
            .ellipsed-text;
        }
        &-name{
            font-size: @font-size-smaller;
            color: @sablono-grey-600;
        }
        &-display-name{
            font-size:@font-size-small;
        }
        &-role{
            text-transform: uppercase;
            font-size: 10px;
            font-weight: @font-weight-bold;
            margin-bottom:2px;
            .ellipsed-text;
        }
    }
    .sb-user-badge-icon{
        position: relative;
        .sb-user-image{
            margin-right:@margin-smaller;
        }
        .sb-user-badge-hover{
            outline-width: 0;
            cursor: pointer;
            display: none;
            position: absolute;
            top:0;
            width: @sb-user-badge-image-size;
            height: @sb-user-badge-image-size;
            border-radius: 50%;
            &.mdi{
                font-size:24px;
                text-align: center;
                line-height:@sb-user-badge-image-size;
            }
        }
    }
    sb-user-image{
        outline-width: 0;
    }
}
// role container type dependent coloring
.sb-user-badge-color(@color) {
    .sb-user-badge-container {
        background: fade(@color, 15%);
    }
    .sb-user-image{
        background-color: @color;
    }
    .sb-user-badge-hover{
        color: @light-text-color;
        background: @color;
    }
    .sb-user-badge-user-role{
        color: darken(@color, 10%);
    }

}
.sb-user-badge{
    .sb-user-badge-color(@sablono-green-main);
    &.admin{
        .sb-user-badge-color(@role_admin);
    }
    &.manager{
        .sb-user-badge-color(@role_manager);
    }
    &.commercial_manage{
        .sb-user-badge-color(@role_manager);
    }
    &.controller{
        .sb-user-badge-color(@role_controller);
    }
    &.inspector{
        .sb-user-badge-color(@role_inspector);
    }
    &.reviewer{
        .sb-user-badge-color(@role_reviewer);
    }
    &.commercial_read{
        .sb-user-badge-color(@role_reviewer);
    }
}
