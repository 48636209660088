@import "../../../styles/global";
@import "directives/sbDeliverableRow/deliverable_row";
@import "dialogs/pickProjectMember/pick_project_member_dialog";
@import "dialogs/bulkStateChange/bulk_state_change";
@import "dialogs/bulkChecklistPdfExport/bulk_checklist_pdf_export";
@import "dialogs/deliverableChecklistsPdfExport/deliverable_checklists_pdf_export";
@import "dialogs/bulkCreateNote/bulk_create_note";
@import "dialogs/activitySetConfiguration/activity_set_configuration";
@import "dialogs/deliverableSetConfiguration/deliverable_set_configuration";

@sb-deliverables-list-header-height: 54px;
@sb-deliverables-page-header-height: 120px;
@sb-deliverables-list-offset: 20px;
@sb-deliverables-list-width: calc(100% - @sb-deliverables-list-offset);
@sb-deliverables-list-max-width: 1400px;
@filter-menu-width: 200px;

.sb-deliverables-list-header {
    // 100% minus scroll width. So we can center the header properly
    width: calc(~"100% - 8px");
    margin-top: @margin-small;
    flex: 0 0 auto;
    .sb-workflow {
        align-items: center;
    }
    .sb-has-active-selections{
        .sb-checkbox-remove-selection-state();
    }
    .sb-deliverables-list-header-checkbox{
        position: absolute;
        z-index: 1;
        left: 18px;
        top:12px;
        &.md-checked .md-icon {
            background: darken(@sablono-green-main,5%);
        }
    }
}
.sb-deliverables-list-header-row {
    width: @sb-deliverables-list-width;
    max-width: @sb-deliverables-list-max-width;
    border-width: 0;
    margin: 0 0 2px 10px;
    padding: 0 @padding-small;
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    background: @sablono-grey-50;
    position: relative;
    .sb-deliverable-cell {
        font-weight: @font-weight-light;
        color: @sablono-grey-800;
        overflow: visible;
    }
    .sb-whiteframe-glow-z1;
}
.sb-deliverables-list-container {
    position: relative;
    margin: 0 auto;
    background: @gray-background;
    overflow: hidden;
    .flex-column;
    width: 100%;
    height: 100%;
    max-width: @sb-deliverables-list-max-width;

    .sb-odata-list-content {
        min-height: calc(100% ~"-" @sb-deliverables-page-header-height);
        height:  calc(100% ~"-" @sb-deliverables-page-header-height);

        .sb-odata-row-container {
            width: @sb-deliverables-list-width;
            max-width: @sb-deliverables-list-max-width;
            margin: 0 0 0 @sb-deliverables-list-offset;
            background: @white-background;
        }
    }
}
.sb-deliverables-list-container__filter-container {
    flex: 0 0 auto;
}
@media screen and(max-width: @media-md) {
    .sb-odata-row-container,
    .sb-deliverables-list-header,
    .sb-deliverables-list-header-row {
        width: 100%;
    }
    .sb-odata-list-repeater {
        height: 100%;
        padding-bottom: 0;
        &.sb-deliverables-list-with-filters {
            // 108px = @sb-deliverables-list-header-height (filters) + margin small
            height: calc(~"100% - 62px");
            min-height: calc(~"100% - 62px");
            padding-bottom: @sb-deliverables-list-header-height;
        }
    }
}
.sb-toolbar-icon{
    .sb-icon-template,
    .sb-icon-wbs_structure{
        font-size: 20px;
    }
}


.sb-request-for-progress-panel {
    width: 600px;
}

.sb-deliverables-list {
    height: 100%;
    justify-content: center;
    width: @sb-deliverables-list-width;
    .flex-row();
}

.sb-deliverables-list__filter-menu {
    margin-top: 70px;
    position: absolute;
    left: 0;
    top: 0;
}

.sb-deliverables-list-container .sb-odata-list-repeater {
    position: relative;
    left: -5px;
}

.sb-deliverables-empty-state {
    margin: auto;
}

@media screen and(max-width: @media-xxlg) {
    .sb-deliverables-list-container.is-menu-showing {
        margin: 0 auto 0 @filter-menu-width;
    }
}
