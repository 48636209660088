@sb-deliverables-list-width: 90%;
@sb-deliverables-list-max-width: 1400px;

.sb-odata-row-container {
    position: relative;
    .sb-sides-shadow;
    &:nth-last-child(1) {
        margin-bottom: @margin-small;
        .sb-sides-and-bottom-shadow;
    }
    .sb-checkbox{
        position: absolute;
        height: 100%;
        z-index: 1;
        color:@sablono-grey-500;
        cursor: pointer;
    }
}

.sb-odata-list-content {
    flex: 1;
    margin: 0 auto;
    background: @gray-background;
    overflow: hidden;
    display: flex;
    flex-direction: column;
    width: 100%;
    height: 100%;
    min-height: 100%;
    .sb-info-large {
        margin: auto;
        max-width: 100%;
    }
}
.sb-odata-list-repeater {
    flex: 1;
    height: 100%;
}

@media screen and(max-width: @media-md) {
    .sb-odata-row-container,
    .sb-odata-list-repeater {
        height: 100%;
        padding-bottom: 0;
    }
    .sb-odata-row-container {
        &:nth-last-child(1) {
            margin-bottom: 0;
        }
    }
}
