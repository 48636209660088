.sb-error{

    animation: 1200ms sb-error ease-in-out 400ms;

    &.ng-enter,
    &.ng-leave {
        transition: 400ms all;
        overflow: hidden;
    }
    &.ng-enter {
        max-height: 0;
    }
    &.ng-enter.ng-enter-active {
        max-height: 60px;
        opacity:1;
    }
    &.ng-leave {
        max-height: 60px;
        opacity:1;
    }
    &.ng-leave.ng-leave-active {
        max-height: 0;
        opacity:1;
    }
}

.sb-error-message{
    font-size: 11pt;
    margin: 0;
    padding: 2px 2px;
}

@keyframes sb-error {
    0% {
        color: inherit;
    }
    22% {
        color: @riemi-red;
    }
    45% {
        color: @riemi-red;
    }
    100% {
        color: inherit;
    }
}
