@import "./components/sbProjectCard/project_card";
@import "./components/sbPendingInviteCard/pending_invite_card";


@sb-project-card-height: 360px;
@sb-project-selection-cards-container: 1200px;
@sb-project-selection-cards-container-md: 800px;
@sb-project-selection-cards-container-s: 400px;
@sb-project-card-width: 380px;
@main-color: @primary-font-color;

.sb-project-selection-layout {
    background-color: @gray-background;
    .flex-column-center-start;
    min-width: 0;
    min-height: 0;
    padding-bottom: @padding-normal;

}
.sb-project-selection-cards-container{
    .flex-row-center;
    flex-wrap: wrap;
    padding-bottom: @padding-normal;
    margin: 0 auto;
}
// media queries for responsiveness. No more than 3 projects per row
.sb-project-selection-cards-container,
.sb-project-selection-sort-toolbar,
.sb-project-selection-pending-invites {
    max-width: @sb-project-selection-cards-container;
    @media (max-width: @media-lg - 1) {
        max-width: @sb-project-selection-cards-container-md;
    }
    @media(max-width: @sb-project-selection-cards-container-md){
        max-width: @sb-project-selection-cards-container-s;
    }
    @media(max-width: @sb-project-card-width){
        width: 100%;
    }
}
.sb-project-selection-sort-toolbar {
    width: 100%;
    min-height: @sb-toolbar-height;
    .flex-row;
    justify-content: flex-end;
    margin: @margin-small 0 0;
    &-button {
        text-transform: capitalize;
        font-size: @font-size-large;
        font-weight: @font-weight-normal;
        color: @sablono-grey-600;
        @media (max-width: @media-sm) {
            font-size: @font-size-normal;
            min-width: auto;
        }
        @media (max-width: @media-s) {
            font-size: @font-size-small;
        }
    }
}

.sb-project-selection-pending-invites {
    width: 100%;
    margin: @margin-small 0;

    .sb-project-selection-pending-invites-cards-container {
        width: 100%;
        display: flex;
        flex-wrap: wrap;
    }
}

.sb-project-card.sb-project-card-welcome {
    cursor: default;
    height: @sb-project-card-height;
    background-color: @white-background;
    padding: 0;
    .sb-project-card-header{
        background: @main-color;
        font-size: @font-size-larger;
        min-height: 72px;
        .md-toolbar-tools {
            justify-content: space-between;
            align-content: center;
            padding-right: @padding-big;
            .md-icon-button {
                display: flex;
                align-items: center;
                justify-content: center;
            }
        }
    }
    .md-toolbar-tools {
        justify-content: space-between;
    }
    .sb-project-card-header-label {
        font-size: @font-size-title-large;
    }
    .sb-project-card-header-remove {
        color: @main-color !important;
    }
    .sb-project-card-content {
        height: 250px;
        background: @white-background;
        display: flex;
        flex-direction: column;
        justify-content: space-around;

    }
    .sb-project-card-welcome-section {
        display: flex;
        flex-direction: column;
        justify-content: flex-end;
        font-size: @font-size-small;
        &-text {
            display: flex;
            flex-direction: row;
            align-items: center;
            justify-content: space-around;
            span{
                width: 280px;
            }

        }
        .mdi {
            width: 50px;
            padding: 0;
            font-size: 50px;
            align-self: center;
            flex: 0 0 auto;
            color: @main-color;
        }
        .question-image {

            display: block;
            width: 50px;
            min-width: 50px;
            height: 50px;
            padding: 0 @padding-small;
            align-self: center;
            padding: 0;
        }
        .md-primary {
            color: @main-color;
            align-self: flex-end;
        }
    }
    &:hover{
        box-shadow: 0px 1px 3px 0px rgba(0, 0, 0, 0.2), 0px 1px 1px 0px rgba(0, 0, 0, 0.14), 0px 2px 1px -1px rgba(0, 0, 0, 0.12);
    }
}

.project-selection__new-project-card {
    display: flex;
    flex-direction: column;
    gap: 12px;
    height: @sb-project-card-height;
    margin: 0 8px 0 8px;
    width: @sb-project-card-width;
}

.project-selection__new-project-card__project:hover {
    border-color: @main-color;
}

.project-selection__new-project-card .mdi {
    color: @main-color;
    font-size: 50px;
}

.project-selection__new-project-card__demo {
    flex: 1;
}

.project-selection__new-project-card__project {
    align-items: center;
    border: solid 1px @light-text-color;
    cursor: pointer;
    display: flex;
    flex: 1;
    flex-direction: column;
    justify-content: center;
    margin: 0;
}

.project-selection__new-project-card__title {
    color: @main-color;
    font-size: 22px;
    font-weight: 400;
}
