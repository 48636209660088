@import "../../../../../styles/global";
@import "../../../../../styles/layout";
@import "../../../../../styles/fonts";
@import "../../../../../styles/sb-common/number-outlined";

@sb-svg-legend-width: 300px;
@toolbar-height: 64px;
@subbar-height: 48px;
@legend-header-height: 48px;
@legend-entry-key-move-right: 20px;


// made to fit the legend correctly in the 2D visualization page (see sketch_visualization.less)
//
@vertical-whitespace: 116px;

@sb-hatched-legend-size: 11px;

.sb-svg-legend {
    .flex-column();
    padding: @padding-none;
    margin: @margin-none;
    min-width: @sb-svg-legend-width;

    background: @sablono-grey-50;
}

.sb-svg-legend__header {
    .flex-row2(@align-horizontal: space-between; @align-vertical: center);
    padding: 0 @margin-normal;

    .font(smaller, bold);
    .uppercase();

    color: @primary-font-color;
    cursor: pointer;
    outline-width: 0;
}

.sb-svg-legend__header__button {
    border-radius: 50%;
    padding: @padding-smaller;
    margin: @margin-tiny;

    background: @sablono-grey-200;

    &:hover {
        background: @sablono-grey-300;
    }
}

.sb-svg-legend__entries {
    .flex-column();
    min-width: @sb-svg-legend-width - @margin-big;
    max-height: calc(
        100vh - @toolbar-height - @subbar-height - @legend-header-height - @vertical-whitespace
    );

    overflow-x: hidden;
    overflow-y: auto;
}

.sb-svg-legend__entry {
    .flex-column();
    flex-shrink: 0;
    position: relative;
    max-width: @sb-svg-legend-width - @legend-entry-key-move-right - @padding-big;

    .font(smaller);

    // puts margin on all except the first element
    ~ .sb-svg-legend__entry {
        margin-top: @margin-small;
    }
}

.sb-svg-legend__entry__key,
.sb-svg-legend__entry__icon {
    &::first-letter {
        .uppercase();
    }
}

.sb-svg-legend__entry__icon--rotated {
    .font(large);
    position: relative;
    transform: rotate(-50deg);
    top: -@margin-smaller;
    left: -@margin-tiny;
}

.sb-svg-legend__entry__icon--rotated-right {
    .font(large);
    position: relative;
    transform: rotate(50deg) scale(0.75) translate(-3px, 1px);
    top: -@margin-smaller;
    left: -@margin-tiny;
}

.sb-svg-legend__entry-icon--hatched {
    position: absolute;
    width: @sb-hatched-legend-size;
    height: @sb-hatched-legend-size;
    border-radius: 50%;
    overflow: hidden;
    margin-right: @margin-small;
    top: @margin-tiny;
    left: 0;
    transform: scale(0.9);
}

.sb-svg-legend__entry__icon {
    position: absolute;
}

.sb-svg-legend__entry__key {
    display: flex;
    position: relative;
    right: -@legend-entry-key-move-right;
}

.sb-svg-legend__entry__key__percentage{
    margin-left: 8px;
}

.sb-svg-legend__topological-index {
    .sb-number-outlined--small(@sablono-grey-500, @border-solid-thin, @margin-none, @margin-smaller, auto);
}

.sb-svg-legend__entry__header-wrapper {
    width: 100%;
}

.sb-svg-legend__entry__header {
    .font(smaller, bold);
    .uppercase();

    color: @primary-font-color;
    width: 100%;
    text-align: center;
}

.sb-svg-legend__line-icon-wrapper {
    display: flex;
    flex-direction: row;
    padding: 2px 2px 2px 5px;
}

.sb-svg-legend__line-icon {
    width: 2px;
    height: 15px;
    margin-right: 14px;
}

.sb-svg-legend__line-icon--strong {
background-color: @planed-completed-today;
}
.sb-svg-legend__line-icon--medium {
background-color: @planed-completed-end-of-this-week;
}
.sb-svg-legend__line-icon--weak {
 background-color: @planed-completed-end-of-next-week;
}
