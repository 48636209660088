sb-multiline-edit{
    .flex-row-center;
    width: 100%;
    height: 100%;
    user-select: none;
    outline: none;
    .edit-text{
        background: transparent;
        resize: none;
        border: none;
        overflow: hidden;
        color: @sablono-grey-800;
        border-bottom: 1px dotted @sablono-grey;
    }
    .edit-text, .plain-text{
        text-align: inherit;
        line-height: 20px;
        display: block;
        width: 100%;
        outline: none;
    }
}
