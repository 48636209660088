@sb-filter-text-row-height: 40px;
.sb-filter-user{
    display: flex;
    flex-direction: row;
    align-items: flex-end;
    height: @sb-filter-text-row-height;
    .sb-filter-user-input{
        width: 100%;
        position: relative;
        input{
            height: 100%;
            font-size: @font-size-small;
            border-width: 0;
            border:1px solid @sablono-grey-300;
            width:100%;
            box-sizing: border-box;
            padding: @padding-small @padding-normal @padding-small 2px;
            outline-width: 0px;
            text-indent:4px;
            &:disabled{
                background: @sablono-grey-50;
                border-bottom: 1px dashed @sablono-grey-400;
            }
            &:focus{
                border-bottom-color: @sablono-green-main;
            }
        }
        md-autocomplete-wrap {
            box-shadow: none;
        }

    }
}
