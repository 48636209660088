.sb-project-detail-card.sb-project-detail-card {
    padding: 0;
}

.sb-project-edit {
    padding-top: 320px;
}

.sb-project-edit-container {
    background: rgba(255, 255, 255, 0.9);
    position: relative;
    padding: 0 @padding-normal;
    .flex-row;

    &:first-child {
        padding-top: @padding-small;
    }

    md-input-container {
        flex: 1;
    }
}


.sb-project-edit-container-wrapper {
    .sb-project-edit-container {
        padding: @padding-none;
    }
}

.sb-dialog {
    .sb-project-edit {
        padding: 0;

        .sb-project-edit-container {
            .flex-column;
            min-height: 36px;
        }
    }
}

.sb-project-edit-cover {
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-size: cover;
    background-position: 50% 50%;
    position: absolute;
}

.sb-project-edit-cover--not-editable {
    height: 491px;
}

.sb-project-edit-cover__file-input {
    width: 100%;
    height: 100%;
    position: absolute;
    top: -95px;

    .md-button {
        position: absolute;
        right: 0;
        top: 240px;
        color: @primary-font-color;
        background: rgba(255, 255, 255, 0.8);

        &:hover, &:focus, &:active {
            background: rgba(255, 255, 255, 0.8);
        }

    }
}

.sb-project-edit-cover__saving {
    position: absolute;
    top: 8px;
    right: 8px;
}

.sb-project-edit-cover__suggestion {
    position: absolute;
    top: 195px;
    right: 8px;
    padding: 2px @padding-small;
    border-radius: 2px;
    background: rgba(255, 255, 255, 0.8);
}

.sb-project-edit--edit-mode {
    padding-top: 226px;

    .sb-project-edit__dates {
        order: 3;
        flex-basis: 100%;
    }

    .sb-project-edit-container {
        flex-wrap: wrap;
    }
}

.sb-project-edit__project-type__input {
    width: 100%;
    margin-bottom: 0;
}

.sb-project-edit__project-type__select {
    margin-bottom: 12px;
}

.sb-project-edit__dates {
    display: flex;
    margin-left: -4px;
}

.sb-project-edit__dates__start-date {
    margin-left: -12px;
}

.sb-project-edit__language-timezone {
    display: flex;
}

.sb-project-edit__language-timezone__language {
    flex: none;
}

.sb-project-edit__language-timezone__timezone {
    flex: 2;
}
