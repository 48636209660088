@sb-calendar-width: 95%;
@day-of-month-size: 38px;
@calendar-border-radius: 4px;
@calendar-nav-button-line-height: 36px;

.sb-calendar {
    width: @sb-calendar-width;
    margin: @margin-small auto;
}

.sb-calendar-body {
    .flex-row();
}

.sb-calendar-body__day {
    outline: none;
    flex-grow: 1;
    flex-basis: 100%;
}

.sb-calendar-body__day-inner {
    .flex-row();
    padding: (@padding-small + @padding-smaller) @padding-normal;
    cursor: pointer;
    border-radius: @calendar-border-radius;

    &:hover {
        background-color: @sablono-grey-300;
    }
}

.sb-calendar-body__date {
    .flex-column;
    padding-right: @padding-small;
    border-right: 1px solid @sablono-grey-500;
}

.sb-calendar-body__day-of-week {
    color: @sablono-grey-600;
    font-size: @font-size-smaller;
    font-weight: @font-weight-bold;
    text-transform: uppercase;
    padding-bottom: @padding-smaller;
    text-align: center;
}

.sb-calendar-body__day-of-month {
    color: @sablono-grey-900;
    font-size: @font-size-title-large;
    font-weight: @font-weight-bold;
    display: inline-block;
    text-align: center;
    line-height: @day-of-month-size;
    width: @day-of-month-size;
    height: @day-of-month-size;
    border-radius: @day-of-month-size;
}

sb-diary-entry {
    flex-grow: 1;
}

.sb-calendar-body-day(@day-of-week-color; @day-of-month-color; @day-of-month-background: transparent) {
    .sb-calendar-body__day-of-week {
        color: @day-of-week-color;
    }

    .sb-calendar-body__day-of-month {
        color: @day-of-month-color;
        background-color: @day-of-month-background;
    }
}

.sb-calendar-body__day--non-working {
    .sb-calendar-body-day(@sablono-grey-500, @sablono-grey-500);
}

.sb-calendar-body__day--today {
    .sb-calendar-body-day(@sablono-grey-600, @white-background, @sablono-grey-500);
}

.sb-calendar-body__day--current {
    .sb-calendar-body-day(@sablono-green-400, @white-background, @sablono-green-400);
}
