// FONTS

// Most common font sizes used in the app
@font-size-smallest: 10px;
@font-size-smaller: 12px;
@font-size-small: 14px;
@font-size-normal: 16px;
@font-size-large: 18px;
@font-size-larger: 20px;
@font-size-title-large: 24px;
@font-size-icon-normal: 24px;
@font-size-icon-large: 34px;
@font-size-icon-huge: 90px;

.font-sizes() {
    @smallest: 10px;
    @smaller: 12px;
    @small: 14px;
    @normal: 16px;
    @large: 18px;
    @larger: 20px;
    @largest: 24px;
}

.font-weights() {
    @thin: 100;
    @light: 300;
    @normal: 400;
    @bold: 500;
}

.font(@size: normal; @weight: normal) {
    font-family: 'Work Sans', 'Helvetica Neue', sans-serif;
    font-size: .font-sizes[@@size];
    font-weight: .font-weights[@@weight];
}

// common line-height sizes used in the app
@font-small-line-height: 12px;
@font-large-line-height: 20px;

// Font weights used in the app
@font-weight-thin: 100;
@font-weight-light: 300;
@font-weight-normal: 400;
@font-weight-bold: 500;
@font-weight-bold-max: 900;

.thin-font {
    .font(smaller, light);
}

.small-font {
    .font(small, normal);
}

.smaller-font {
    .font(smaller, normal);
}

.normal-font {
    .font(normal, normal);
}

.large-thin-font {
    .font(large, light);
}

.normal-thin-font {
    .font(normal, light);
}

.normal-bold-font {
    .font(normal, bold);
}

.small-bold-font {
    .font(small, bold);
}

.smaller-bold-font {
    .font(smaller, bold);
}

.small-thin-font {
    .font(small, light);
}

.larger-thin-font {
    .font(largest, light);
}

.larger-bold-font {
    .font(larger, normal);
}

.uppercase() {
    text-transform: uppercase;
}

.sb-lowercase {
    text-transform: lowercase;
}

.sb-uppercase-first-letter {
    display:inline-block;

    &::first-letter {
        text-transform: uppercase;
    }
}

/////////////////////////////////////////////////////////////////////
//
// Load our fonts using font-face definitions
//
//
// !!important!!
//  Here we have all the fonts that we can use (all the sources are in the fonts folder)
//  -> If you need to add a font-weight: add a variable and uncomment the proper font-face
//  -> If you need to use an italic font-style: uncomment the proper font-face
//
/////////

/**
 * Mixin to easily create @font-face rules by font, weight and style.
 */
.font-face_work-sans(@font, @font-weight, @font-style: normal) {
    @filepath: %('../fonts/%s/WorkSans-%s', @font, @font);

    @font-face {
        font-family: "Work Sans";
        src: url('@{filepath}.woff2') format('woff2'),  /* Super Modern Browsers */
             url('@{filepath}.woff') format('woff'),    /* Pretty Modern Browsers */
             url('@{filepath}.ttf') format('truetype'); /* Safari, Android, iOS */
        font-weight: @font-weight;
        font-style: @font-style;
    }
}

.font-face_poppins(@font, @font-weight, @font-style: normal) {
    @filepath: %('../fonts/%s/Poppins-%s', @font, @font);

    @font-face {
        font-family: "Poppins";
        src: url('@{filepath}.woff2') format('woff2'),  /* Super Modern Browsers */
             url('@{filepath}.woff') format('woff'),    /* Pretty Modern Browsers */
             url('@{filepath}.ttf') format('truetype'); /* Safari, Android, iOS */
        font-weight: @font-weight;
        font-style: @font-style;
    }
}

// Thin
//
.font-face_work-sans("Thin", 100);

// Thin Italic
//
//.font-face_work-sans("ThinItalic", 100, italic);

// Light
//
.font-face_work-sans("Light", 300);

// Light Italic
//
//.font-face_work-sans("LightItalic", 300, italic);

// Regular
//
.font-face_work-sans("Regular", 400);
.font-face_work-sans("Regular", normal);

// Italic
//
//.font-face_work-sans("Italic", 400, italic);
//.font-face_work-sans("Italic", normal, italic);

// Medium
//
.font-face_work-sans("Medium", 500);
.font-face_work-sans("Medium", bold);

// Medium Italic
//
//.font-face_work-sans("MediumItalic", 500, italic);

// Bold
//
//.font-face_work-sans("Bold", 700);
//.font-face_work-sans("Bold", bold);

// Bold Italic
//
//.font-face_work-sans("BoldItalic", 700, italic);
//.font-face_work-sans("BoldItalic", bold, italic);

// Black
//
//.font-face_work-sans("Black", 900);

// Black Italic
//
//.font-face_work-sans("BlackItalic", 900, italic);



