/* Adding some styles for the maintenance message */
.maintenance-message{
    padding: @padding-normal;
    display: flex;
    flex-direction: column;
    text-align: center;
    p{
        color: @sablono-green-text-color;
        font-size: @font-size-large;
        margin-top:@margin-normal;
    }
    h1 {
        color: @sablono-green-text-color;
    }
    h2{
        color: @sablono-green-text-color;
        font-weight: @font-weight-normal;
    }
    .md-button{
        margin-top:@margin-normal;
    }
}
