@import "./../../../../styles/fonts";

@import "components/sbSidenav/sbSidenav";
@import "directives/sbSubbarActions/sbSubbarActions";
@import "directives/sbSubbarMode/sbSubbarMode";
@import "directives/sbToolbarStateInfo/sbToolbarStateInfo";
@import "directives/sbToolbarSearchBar/sbToolbarSearchBar";
@import "directives/sbProfilePanel/sb_profile_panel";
@import "directives/sbSchedulingPanel/sb_scheduling_panel";

@import "states/project";
body,
html {
    height: 100%;
    overflow: hidden;
    font-weight: @font-weight-light;
    font-family: "Work Sans", "Helvetica Neue", sans-serif;
}
.sb-main {
    background: @gray-background;
}
.ng-sablono {
    font-size: @font-size-normal;
    font-family: "Work Sans", "Helvetica Neue", sans-serif;
    height: calc(100%~"-"@main-toolbar-height);
    min-height:calc(100%~"-"@main-toolbar-height);
    overflow: hidden;
    background-color: @gray-background;
    display: flex;
    flex-direction: column;
    md-toolbar {
        flex: 0 0 auto;
        z-index: 1;
    }
    .sablono-project {
        display: flex;
        flex-direction: column;
        flex: 1;
        height: calc(100%~"-"@sb-subbar-height);
        min-height:  calc(100%~"-"@sb-subbar-height);
        position: relative;
        &.sb-subbar-hidden{
            height: 100%;
            min-height: 100%;
        }
        z-index: 0;
    }
}
.sb-toolbar {
    background-color: @dark-background !important;
    z-index: 1;
    .md-toolbar-tools {
        display: grid;
        grid-template-columns: 1fr 300px auto;
        align-items: center;
        height: @main-toolbar-height;
        max-height: @main-toolbar-height;
        padding: 0 0 0 16px;
    }
}

.sb-toolbar__middle-part {
    flex-grow: 1;
}

@state-info-icon-spacing: @padding-small;
.sb-toolbar__right-part {
    margin-left: @state-info-icon-spacing;
    margin-right: @state-info-icon-spacing;
    .flex-row-center();
    > .md-button {
        margin-left: @state-info-icon-spacing;
        margin-right: @state-info-icon-spacing;
    }
    > .sb-toolbar-state__user {
        margin-left: @state-info-icon-spacing;
        margin-right: @state-info-icon-spacing;
    }
}

.ng-sablono--fullscreen {
    height: 100%;
}
