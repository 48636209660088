.sb-search-bar{
    width: 100%;
    .flex-row;
    .sb-search-bar-input,
    .sb-search-input{
        margin: 0;
        width: 100%;
        font-weight: @font-weight-light;
        font-size: @font-size-large;
        &.md-input-focused{
            .sb-search-bar-input{
                padding: @padding-small @padding-normal;
                background: @sablono-grey-200;
                border-bottom-color: @sablono-grey-200;
            }
        }
        .md-errors-spacer{
            min-height: 0;
        }
        // Remove clear from input field on IE
        ::-ms-clear {
            display: none;
        }
    }
    .sb-search-icon{
        font-size: 24px;
        color: @sablono-grey-500;
        line-height: 36px;
        padding-right: 10px;
        cursor: pointer;
        outline-width: 0;
    }
    .sb-search-bar-input{
        background: @sablono-grey-50;
        box-sizing: content-box;
        padding: @padding-small @padding-normal;
        border-radius: 4px;
        border:1px solid @sablono-grey-200;

    }
}
