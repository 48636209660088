@sb-wizard-stepper-height: 46px;

.sb-wizard{
    width: 100%;
    height: calc(100% ~"-"@sb-subbar-height);
    position: absolute;
    top:0;
    left: 0;
    width: 100%;
    .sb-wizard-header{
        background: @sablono-grey-100;
        border-bottom: 1px solid @light-text-color;
        width: 100%;
        position: fixed;
        height: @sb-wizard-stepper-height;
        z-index: @z-indexes[background];
    }
    .sb-wizard-content{
        position: absolute;
        top:@sb-wizard-stepper-height + 1;
        height: 100%;
        @media(max-width: @media-sm){
            // if it's displayed as column, we have to move it
            // for the number of steps
            top:@sb-wizard-stepper-height * 4 + @padding-small;
            // 136 = @sb-wizard-stepper-height * 4 + @padding-small;
            height: calc(~"100% - 136px");
        }
        width: 100%;
        overflow: auto;
        display: flex;
        align-items: flex-start;
        justify-content: center;
        border-top: 1px solid @sablono-grey-300;
        background: @sablono-grey-50;
    }
    md-progress-circular{
        margin: 0 auto;
    }
}
