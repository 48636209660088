@import "../../../../../styles/buttons";
.sb-step{
    text-align: center;
    padding: @padding-normal 0;
    box-sizing: border-box;
    width:100%;
    min-width: 100%;
    height: 100%;
    .sb-loading{
        margin-top:@margin-normal;
    }
    .flex-row-center;
    .sb-step-content{
        min-width: @sb-list-checkboxes-width;
        max-width: @sb-list-checkboxes-width;
        margin:0 auto;
        padding: @padding-big 0;
        h2{
            margin: @margin-normal 0;
        }
        @media(max-width: @sb-list-checkboxes-width){
            min-width: 100%;
            max-width: 100%;
        }
    }
    p{
        margin: @margin-normal;
    }
    .sb-step-action {
        float: right;
    }
    .sb-tools-select-file,
    .sb-step-icon-large{
        font-size: 140px;
        margin:@margin-big auto;
        color: @sablono-grey-500;
        &:hover{
            cursor: pointer;
            color: @sablono-grey-400;
        }
    }
    .sb-side-note{
        font-size: @font-size-small;
        text-align: right;
    }


    // Overwrite success buttons and other stuff from the upload success.
    a.md-button.md-primary.md-raised{
        color: @sablono-green-text-color;
        background: @sablono-green-main;
        &:active, &.active,
        &:not([disabled]):hover {
            background: lighten(@sablono-green-main, 15%) !important;
        }
        &:not([disabled]):focus {
            color: @sablono-green-text-color;
            background: @sablono-green-main;
        }
        box-shadow: none;
    }
    .md-button.md-primary{
        color: @sablono-green-main;
        background: @white-background;
        &:active, &.active,
        &:not([disabled]):hover {
            color: @sablono-green-text-color;
            background-color: lighten(@sablono-green-main, 15%) !important;
        }
        &:not([disabled]):focus {
            color: @sablono-green-text-color;
            background: @sablono-green-main;
        }
        background: transparent;
    }
    h2, h3{
        font-weight: @font-weight-light;
    }

}
