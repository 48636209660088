@import 'colorpalette';

/* Role based color classes */
.sb-role-admin, .sb-role-admin-color,
.md-fab.sb-role-admin-color {
    color: @role_admin;
}

.sb-role-controller, .sb-role-controller-color,
.md-fab.sb-role-controller-color {
    color: @role_controller;
}

.sb-role-manager, .sb-role-manager-color,
.md-fab.sb-role-manager-color {
    color: @role_manager;
}

.sb-role-inspector, .sb-role-inspector-color,
.md-fab.sb-role-inspector-color {
    color: @role_inspector;
}

.sb-role-reviewer, .sb-role-reviewer-color,
.md-fab.sb-role-reviewer-color {
    color: @role_reviewer;
}

.sb-role-default, .sb-role-default-color,
.md-fab.sb-role-default-color {
    color: @primary-font-color;
}

.sb-role-admin-bg,
.sb-role-admin.selected {
    background-color: @role_admin;
}

.sb-role-controller-bg,
.sb-role-controller.selected {
    background-color: @role_controller;
}

.sb-role-manager-bg,
.sb-role-manager.selected {
    background-color: @role_manager;
}

.sb-role-inspector-bg,
.sb-role-inspector.selected {
    background-color: @role_inspector;
}

.sb-role-reviewer-bg,
.sb-role-reviewer.selected {
    background-color: @role_reviewer;
}

.sb-role-default-bg,
.sb-role-default.selected {
    background-color: @primary-font-color;
}
