@import "../../../../../styles/layout";
@import "../../../../../styles/global";

@left-side-space: 22px;
@options-height: 32px;

// the select input to open the custom date selection
.sb-date-select {
    margin: @margin-none;

    // overwrite text color of placeholder text
    .sb-date-select__placeholder {
        color: rgba(0, 0, 0, 0.87);
    }
}

// everything is scoped under the select container
.md-select-menu-container {

    // style the select header content which displays the selected range
    .sb-selected-date-range {
        padding: @padding-small @padding-small 0 @left-side-space;
    }

    .sb-selected-date-range-label {
        padding-bottom: @padding-smaller;
        .smaller-bold-font();
        color: @sablono-grey-500;
        text-transform: uppercase;
    }

    .sb-selected-date-range-value {
        padding-bottom: @padding-small;
        .small-bold-font();
        color: @sablono-grey-700;
        line-height: @font-size-large;
    }

    // styling and layout of a select option
    .sb-date-selector-option {
        color: @sablono-grey-600;
        height: @options-height;
        margin: @margin-none;
        padding: @padding-none @left-side-space;
        .small-font();

        .md-text {
            display: flex;
            width: 100%;
            .small-bold-font();
        }
    }

    // overwrite md-select color for selected and focus state
    md-select-menu md-content md-option[selected="true"]:focus,
    md-select-menu md-content md-option[selected]:focus{
        color: @sablono-grey-600;
    }

    // styling the select options text and icon
    .sb-option-label {
        line-height: @font-size-title-large;
        margin-right: auto;
    }

    .sb-option-icon {
        display: block;
        .larger-bold-font();
        line-height: @font-size-title-large;
        margin: @margin-none;
    }
}

// the calendar date picker inside the panel
//
.sb-date-picker {
    position: relative;
}


.sb-date-picker__actions {
    .flex-row();
    justify-content: flex-end;
}

.sb-date-picker__calendar {
    padding: @padding-none;
}

.mdi-calendar-alert {
    color: @florange;
}
