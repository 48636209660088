@import "../../../../../styles/global";
@import "../../../../../styles/fonts";
@import "../../../../../styles/layout";
@import "../../../../../styles/colorpalette";

@sb-cost-group-template-min-width: 1100px;
@sb-cost-group-editor-calculation-details-min-height: 200px;
@sb-cost-group-editor-activity-weight-input-width: 60px;
@sb-data-table-body-row-border-thickness: 1px;
@sb-data-table-header-row-height: 32px;
@sb-data-table-flex-basis: 60%;

@sb-data-table-flex-large: 3;
@sb-data-table-flex-medium: 2;
@sb-data-table-flex-small: 1.5;
@sb-data-table-flex-smaller: 1;

// local mixins
//
.align-label-with-input {
    padding-bottom: @padding-normal;
}

.align-text-right {
    text-align: right;
}

.sb-cost-group {
    .flex-row();
    box-sizing: border-box;
    position: relative;
    min-height: @sb-cost-group-editor-calculation-details-min-height;

    // On small screens, we change the layout
    @media(max-width: @media-md){
        flex-wrap: wrap;
    }
}

.sb-cost-group__cost-group-template {
    flex: 1;
    padding: @padding-normal;

    min-width: @sb-cost-group-template-min-width;

    // On small screens, we change the layout
    @media(max-width: @media-md){
        flex-basis: @sb-data-table-flex-basis;
    }
}

.sb-cost-group__description {
    display: flex;
    justify-content: space-between;
    .align-label-with-input();
    color: @primary-font-color;
    font-size: @font-size-large;
    margin-top: 0;
}

.sb-cost-group-stage__weight-input-container {
    margin: 0;
    margin-left: @margin-small;
    min-width: @sb-cost-group-editor-activity-weight-input-width;
    max-width: @sb-cost-group-editor-activity-weight-input-width;

    .mdi-percent {
        text-align: left;
    }
}

.sb-cost-group-stage__process-template-name {
    .small-bold-font();
    height: 34px;
    line-height: 34px;
    margin-right: auto;
    margin-left: auto;
}

.sb-data-table__body {
    .md-input-message-animation {
        font-size: @font-size-smallest;
    }

    .md-errors-spacer {
        display: none;
    }
}


// table
//
.sb-data-table {
    height: 100%;
    width: 100%;
    box-sizing: border-box;
}


// table header
//
.sb-data-table__header {
    .flex-row();

    box-sizing: border-box;
    min-height: @sb-data-table-header-row-height;
    padding: @padding-none;

    .smaller-font();
    line-height: @font-small-line-height;
    letter-spacing: 0;
}


// table body
//
.sb-data-table__body {
    .flex-column();
}

.sb-data-table__body-row {
    .flex-row-center-start();
    .small-thin-font();
    box-sizing: border-box;
    padding: 0;
}

.sb-data-table__body-row--dark {
    background-color: @sablono-grey-100;
}

.sb-data-table__body-row--process-template {
    background-color: @sablono-green-main;
    color: white;
}

// column layout for table body & row
//
.sb-data-table__activity {
    flex: @sb-data-table-flex-large;
    .smaller-font();
    display: flex;
    align-items: center;
    justify-content: space-between;
}

.sb-data-table__weight {
    flex: @sb-data-table-flex-small;
}

.sb-data-table__budget-allocated {
    flex: @sb-data-table-flex-small;
    .align-text-right();
}

.sb-data-table__progress {
    flex: @sb-data-table-flex-medium;
    .align-text-right();
}

.sb-data-table__link {
    text-decoration: underline;
    color: @sablono-green-700;
    background-color: inherit;
    border: none;
    padding-left: 0;
    padding-right: 0;
    margin-left: -1px;
    margin-right: -1px;
    outline: none;
    &:disabled {
        color: @sablono-grey-600;
        text-decoration: none;
    }
    &:active {
        font-weight: bold;
    }
}

.sb-data-table__total-spent {
    margin-left: @margin-normal;
    flex: @sb-data-table-flex-smaller;
    .align-text-right();
}

.sb-data-table__body-row--small {
    font-size: @font-size-smallest;
}

.sb-data-table__ungroup-icon {
    font-size: @font-size-large;
    cursor: pointer;
    border-radius: 50%;
    padding: @padding-tiny;
    outline-width: 0;
    &:hover {
        background: @sablono-grey-200;
    }
    &:active {
        background: @sablono-grey-500;
    }
}

.sb-line-breaker {
    white-space: pre-line;
}
