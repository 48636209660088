@sb-area-manager-user-card: 190px;
@sb-area-manager-toggle: 50px;
@sb-team-menu-user-height: 48px;
@sb-area-manager-user-badge-height: 63px;

.sb-area-manager-card {
    border: 1px solid @border-color;

    .sb-user-badge-container {
        border: none !important;
    }
}

.sb-area-manager-card__active-switch {
    display: block;
    margin: @margin-small 0 0;
    width: @sb-area-manager-toggle;

    .md-container {
        margin-left: @margin-small;
    }
}

.sb-area-manager-card__menu {
    display: inline-block;
}

.sb-area-manager-card__active-text {
    display: inline-block;
    vertical-align: top;
    font-size: @font-size-smallest;
    max-width: @sb-area-manager-toggle;
    color: @sablono-grey-500;
}

.sb-area-manager-card__existing-replacement {
    position: relative;

    .md-button {
        padding: 0;
        margin: 0;
    }

    .sb-area-manager-card__menu {
        vertical-align: top;
        overflow-x: hidden;
    }
}

.sb-area-manager-card__menu-item .sb-user-badge-container {
    background: @white-background;
    height: @sb-team-menu-user-height;

    .sb-user-badge-icon {
        cursor: pointer;
    }

    .sb-user-badge-user-role {
        display: none;
    }
}

.sb-area-manager-card__menu-item .sb-user-badge.replacement {
    outline: none;
    padding: 0;
    width: 100%;
}

.breadcrumb-menu-content .sb-add-new-card .sb-add-new-card-content,
.sb-area-manager-card__menu .sb-add-new-card .sb-add-new-card-content {
    padding-left: @padding-small;
    border: none;
    background: @white-background;
    color: @sablono-grey-500;
    text-transform: none;
}

.sb-add-new-card--change-forbidden {
    pointer-events: none;
}

.sb-area-manager-card__activate {
    display: inline-block;
    position: relative;
    padding: 0;
    text-align: center;
}

.sb-team-user-list sb-team-area-manager-card .sb-add-new-card-content {
    height: @sb-area-manager-user-badge-height !important;
}

.sb-user-badge.replacement {
    width: @sb-area-manager-user-card;

    .sb-user-badge-container {
        box-sizing: border-box;
        box-shadow: none;
        height: 100%;
    }

    .sb-user-badge-user {
        line-height: initial;
        text-align: left;
        text-transform: initial;
        font-weight: @font-weight-light;
        cursor: pointer !important;
    }
}
