.sb-icon-msproject {
    color: @sablono-green-500;
    font-size: 35px;
    margin: auto;
}

.sb-icon-excel {
    color: @sablono-green-700;
}

.sb-import-selection {
    margin: @margin-big 0 0;
    .flex-column-center;
}
.sb-import-selection-text {
    padding: @padding-small;
    margin: @margin-big @margin-small;
    text-align: center;
    color: @sablono-grey-600;
    font-size: @font-size-large;
    white-space: pre-line;
    font-weight: @font-weight-light;
    background: transparent;
    @media (min-width: @media-md) {
        font-size: @font-size-larger;
    }
    @media (min-width: @media-lg) {
        font-size: @font-size-title-large;
    }
}
.sb-import-tiles-container {
    background: inherit;
    overflow: unset;
}

.sb-integrate-tile {
    text-decoration: none;
}

.sb-integrate-icon-container {
    display: flex;
    flex: 1;
    flex-wrap: wrap;
    justify-content: center;
}

.sb-integrate-icon {
    width: 50%;
    object-fit: contain;
}
