.sb-warning-box {
    display: flex;
    flex-direction: row;
    margin: @margin-normal auto @margin-normal auto;
    width: 100%;
    max-width: 500px;
    border: 1px solid @border-color;
}

.sb-warning-box-image{
    background-color: @florange-200;
    display: flex;
    width: 60px;
    max-width: 60px;
    min-width: 60px;
    .mdi {
        color: @light-text-color;
    }
}

.sb-warning-box-text{
    padding: @padding-normal @padding-small;
    display: flex;
    flex-direction: column;
    width: 100%;
    span {
        text-align: left;
    }
    small {
        text-align: left;
        padding: @padding-normal 0;
    }
}
