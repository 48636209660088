@import "../../../../../styles/global.less";

@sb-dialog-bulk-create-note-max-width: 600px;

.sb-bulk-create-note-dialog {
    max-width: @sb-dialog-bulk-create-note-max-width;
    min-width: @sb-dialog-bulk-create-note-max-width;
}

.sb-bulk-create-note-dialog__toolbar {
	flex: 0 0 @sb-expanded-toolbar-height;
}

.sb-bulk-create-note-dialog__actions {
	flex: 0 0 @sb-toolbar-height;
}
