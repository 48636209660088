@import "layout";
@import "colorpalette";
@import "buttons";
@import "roles";


.hide-visibility {
    visibility: hidden;
}

// helper classes
.ellipsed-text {
    text-overflow: ellipsis;
    white-space: nowrap;
    overflow: hidden;
    min-width: 0;
    min-height: 0;
}

.wrapped-text {
    word-wrap: break-word;
    overflow-wrap: break-word;
    hyphens: auto;
    white-space: normal;
}

.not-selectable {
    -moz-user-select: none;
    -webkit-user-select: none;
    -ms-user-select: none;
    cursor: default;
}

.sb-clickable {
    cursor: pointer;
    button {
        cursor: pointer;
    }
}

.sb-readability-fix {
    // bug 605
    text-rendering: auto;
    font-smoothing: antialiased;
    -moz-font-smoothing: antialiased;
    -webkit-font-smoothing: antialiased;
}

.sb-sticky-header {
    z-index: 1;
    position: sticky;
    top: 0;
}

// animations
.sb-hover-rotate-90 {
    transition: all 0.2s ease-in-out;
    transform: rotate(0deg);
    &:hover {
        transform: rotate(90deg);
    }
}

// usually they set the padding to 50 px -> that leads to wrapped error messages on small input fields (like CODE)
.sb-error-message-on-input-global {
    padding-right: 10px !important;
}
// Progress icon colors
.sb-todo {
    color: @activity-state-icon-not-started;

    &.selected {
        background-color: @sablono-grey-400;
        color: contrast(@sablono-grey-400, @primary-font-color, @light-text-color);
        opacity: 1;
    }
}

.sb-done {
    color: @activity-state-icon-done;

    &.selected {
        background-color: @activity-state-done;
        color: contrast(@activity-state-done, @primary-font-color, @light-text-color);
        opacity: 1;
    }
}

.sb-in-progress {
    color: @activity-state-icon-started;

    &.selected {
        background-color: @activity-state-started;
        color: contrast(@activity-state-started, @primary-font-color, @light-text-color);
        opacity: 1;
    }
}


.sb-wfc {
    color: @activity-state-icon-wfc;
}

.sb-rejected {
    color: @activity-state-icon-rejected;
}

.sb-task-behind {
    color: @activity-scheduling-behind;
}
.sb-task-on-time{
    color: @activity-scheduling-on-time;
    &.sb-task-has-claims {
        color: @note-color-quality-issue;
    }
    &.sb-task-has-obstructions {
        color: @note-color-obstruction;
    }
    &.sb-task-has-obstructions.sb-task-has-claims {
        color: @note-color-obstruction;
    }
}
.sb-task-unknown{
    color: @sablono-grey-500;
}

// Deliverable representation based on gantt

.sb-deliverable-task-color(@color, @color-bg, @color-progress-bg, @color-progress) {
    background: @color-bg;
    color: @color;
    h2, span, .sb-deliverable-name {
        color: @color;
    }
    md-progress-linear.md-default-theme .md-container,
    md-progress-linear .md-container {
        background: @color-progress-bg;

    }
    md-progress-linear.md-default-theme .md-bar,
    md-progress-linear .md-bar {
        background: @color-progress;

    }
}

.sb-deliverable-task {
    border-radius: 5px;
    overflow: hidden;
    .not-selectable;
    cursor: default;
    // default colouring
    .sb-deliverable-task-color(@light-text-color, @sablono-green-100, @sablono-green-200, @sablono-green-400);

    // greish
    &.sb-task-no-progress {
        .sb-deliverable-task-color(@sablono-grey-600, @sablono-grey-400, @sablono-grey-300, @sablono-grey-400);
    }
    // time-dependent colouring
    &.sb-task-behind {
        .sb-deliverable-task-color(@light-text-color, @activity-scheduling-behind, @riemi-red-100, @riemi-red-500);
    }
    &.sb-task-unknown {
        .sb-deliverable-task-color(@light-text-color, @sablono-grey-400, @sablono-grey-50, @sablono-grey-500);
    }
    &.sb-task-on-time {
        .sb-deliverable-task-color(@light-text-color, @activity-scheduling-on-time, @sablono-green-100, @sablono-green-600)
    }

    h2, span {
        font-weight: @font-weight-normal;
        padding: @padding-small @padding-big;
        font-size: @font-size-normal;
        text-align: center;
        margin: 0 auto;
        .ellipsed-text;
    }
}

span.highlight {
    color: @sablono-green-main !important;
}

.sb-saving {
    padding: @padding-smaller @padding-small;
    font-size: @font-size-smaller;
    text-transform: uppercase;
    font-weight: @font-weight-normal;
    background: @sablono-green-50;
    color: @sablono-green-600;
    border-radius: 5px;
    transition: all 0.4s ease-in-out;
    opacity: 1;
    &.ng-hide {
        opacity: 0;
    }
}

// VARIABLES
@margin-normal: 16px;
@margin-small: 8px;
@margin-smaller: 4px;
@margin-tiny: 2px;
@margin-big: 32px;
@margin-none: 0;

@padding-big: 32px;
@padding-normal: 16px;
@padding-small: 8px;
@padding-smaller: 4px;
@padding-tiny: 2px;
@padding-none: 0;
@sb-fab-padding: 20px;
@border-radius-small: 4px;
@border-none: 0;
@border-solid-thin: 1px solid;
@border-solid-medium: 4px solid;
@main-toolbar-height: 64px;
@small-list-height: 44px; // before 32px


// LAYOUT
@filter-card-width: 250px;
@ouline-no-width: 0;

// MEDIA QUERIES
// Maximum values for small/medium/large screens taken from Angular Material
@media-s: 320px;
@media-sm: 600px;
@media-md: 960px;
@media-lg: 1200px;
@media-xlg: 1350px;
@media-xxlg: 1800px;

// Maximum values for small/medium/large screens taken from Google Chrome
@media-chrome-s: 320px;
@media-chrome-sm: 550px;
@media-chrome-md: 768px;
@media-chrome-lg: 1024px;
@media-chrome-xlg: 1440px;

// Media query shortcuts
@mq-mobile: ~"only screen and (max-width: @{media-sm})";
@mq-tablet: ~"only screen and (max-width: @{media-md})";
@desktop: ~"only screen and ((max-width: @{media-lg})";
@desktop-xl: ~"only screen and (min-width: @{media-xlg})";

.sb-title {
    font-size: @font-size-larger;
    font-weight: @font-weight-light;
}

// Links
a {
    color: @sablono-green-main;
}

// Subbars
@sb-subbar-height: 48px;
@sb-subbar-mini-height: 0;
@sb-toolbar-height: 52px;
@sb-expanded-toolbar-height: 96px;

// Shadows
.sb-whiteframe-glow-z1 {
    box-shadow: 0 0 1px 1px rgba(0, 0, 0, 0.14), 0 0 2px 2px rgba(0, 0, 0, 0.098), 0 0 5px 1px rgba(0, 0, 0, 0.084);
}

.sb-sides-shadow {
    box-shadow: 2px 0px 0px 0px rgba(0, 0, 0, 0.14), -2px 0px 0px 0px rgba(0, 0, 0, 0.14);
}

.sb-sides-and-bottom-shadow {
    box-shadow: 0px 2px 0px 0px rgba(0, 0, 0, 0.14), 2px 0px 0px 0px rgba(0, 0, 0, 0.14), -2px 0px 0px 0px rgba(0, 0, 0, 0.14);
}

.sb-bottom-shadow {
    box-shadow: 0 1px rgb(224, 224, 224);
}

.sb-button-shadow {
    box-shadow: 0 2px 5px 0 rgba(0, 0, 0, 0.26);
}

// Backgrounds
// mixin to create a stripped background
.sb-stripped-background(@angle,@color1, @color2,@distance) {
    background: repeating-linear-gradient(
        @angle,
        @color1,
        @color1 @distance,
        @color2 @distance,
        @color2 @distance*2
    );
}

// Separators
.sb-separator--vertical {
    margin-right: @margin-normal;
    padding-right: @padding-normal;
    border-right: 1px solid @sablono-grey-300;
    height: 18px;
    margin-top: 30px;
}

.sb-separator--horizontal {
    border-top: 1px solid @sablono-grey-300;
}


.sb-separator(@margin: 0px) {
    border-right: 1px solid @sablono-grey-300;
    border-top: 1px solid @sablono-grey-300;

    margin-top: @margin;
    margin-bottom: @margin;
}

.sb-topological-index(@size) {
    font-weight: @font-weight-bold;
    font-size: @font-size-smaller;
    padding: @padding-smaller;
    border: 1px solid;
    min-width: @size;
    height: @size;
    line-height: @size;
    border-radius: 25%;
    text-align: center;
}

@z-indexes: {
    background: 1;
    panels: 10;
    middleground: 100;
    foreground: 10000;
}


// Add users chips and autocomplete
//
@sb-add-user-chip-min-width: 455px;
@sb-add-user-chip-line-height: 20px;

@sb-add-user-autocomplete-suggestion-line-height-overwrite: 24px;

.sb-add-user-autocomplete {
    .sb-add-user-autocomplete-template {
        .flex-column();
        line-height: @sb-add-user-autocomplete-suggestion-line-height-overwrite;
    }
    li {
        line-height: @sb-add-user-chip-line-height;
        border-bottom:1px solid @sablono-grey-100;
        padding: @padding-small;

    }
    .sb-add-user-autocomplete-email {
        font-size: @font-size-small;
        color: @sablono-grey-500;
    }
    .sb-add-user-autocomplete-not-found {
        color:@sablono-green-main;
        white-space: normal;
        font-size:@font-size-smaller;
    }
}

.sb-add-user-chips {
    .md-chip-content{
        font-size: @font-size-small;
    }
    .md-chips .md-chip-input-container md-autocomplete{
        min-width: @sb-add-user-chip-min-width;
    }
}

// Remove state for checkboxes with active selections
//
@checkbox-remove-selection-icon-left-offset: 7px;
@checkbox-remove-selection-icon-bottom-offset: -2px;
.sb-checkbox-remove-selection-state {
    &:before{
        font-size: @font-size-larger;
        position: absolute;
        left: @checkbox-remove-selection-icon-left-offset;
        bottom: @checkbox-remove-selection-icon-bottom-offset;
        content: '-';
    }
}

sb-schedule-stats {
    outline-width: 0;
}


.sb-remove-spinner-from-number-input::-webkit-outer-spin-button,
.sb-remove-spinner-from-number-input::-webkit-inner-spin-button,
.sb-remove-spinner-from-number-input[type=number]{
    appearance: none;
    margin: 0; /* <-- Apparently some margin are still there even though it's hidden */
}

@leanboard-borders-colors: @sablono-grey-500;
@leanboard-today-color: lighten(@sablono-green-100, 2%);
@leanboard-non-working-day-color: lighten(@sablono-grey-300, 2%);
