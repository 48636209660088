@import "../../../../../styles/sb-common/activity";
@import "../../../../../styles/global";

.sb-activity-item{
    .sb-activity-arrow(220px, 44px, auto, auto, 15px, @sablono-green-main, @sablono-green-main);

    font-size:@font-size-small;
    transition: transform 0.1s ease-in-out;
    cursor: pointer;
    z-index: 1;
    overflow: hidden;
    margin: @margin-small @margin-normal;
    max-width:400px;
    &:hover{
        transform: scale(1.05);
        font-weight: @font-weight-bold;
    }
}
