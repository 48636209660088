@import "../../../../../styles/buttons";
@import "../../../../../styles/layout";
@import "./components/checklistTemplateOverview/checklist_template_overview";


@sb-checklist-templates-editor-width: 90%;
@sb-checklist-templates-editor-max-width: 1400px;
@sb-checklist-templates-editor-min-width: 650px;

@sb-checklist-templates-editor-item-width: 90%;
@sb-checklist-templates-editor-item-max-width: 1300px;

@sb-delete-height: 40px;
@sb-delete-width: 45px;

.sb-checklist-templates-editor {
    height: 100%;
    overflow: hidden;
    min-width: @sb-checklist-templates-editor-min-width;
}

// Items list

.sb-checklist-templates-editor__checklist-items-container {
    display: block;
    max-width: @sb-checklist-templates-editor-max-width;
    width: @sb-checklist-templates-editor-width;
    margin: @margin-normal auto -7px;
    overflow-x: hidden;
    overflow-y: auto;
    max-height: calc(~"100% - 195px");
}

.sb-checklist-templates-editor__checklist-items {
    .flex-column();
    align-items: center;
    position: relative;
    padding: 0 @padding-normal;
    border-bottom: 1px solid @border-color;

    &:last-child {
        border-bottom: none;
    }
}

.sb-checklist-templates-editor__checklist-item {
    width: 100%;
    height: 100%;
    display: flex;
    align-items: center;
}

// Switch

.sb-checklist-templates-editor__is-mandatory-switch {
    font-size: @font-size-smaller;
    padding-left: @padding-small;
    padding-right: @padding-small;

}

// Help icon
.sb-checklist-templates-editor__help-icon {
    font-size: @font-size-normal;
    margin-left: @margin-small;
    color: @primary-font-color;
}

.sb-checklist-templates--work-team-editor__help-icon {
    font-size: @font-size-normal;
    margin-left: @margin-small;
    color: @primary-font-color;
}

// Up/down buttons

.sb-checklist-templates-editor__change-order {
    width: @sb-delete-width;
    min-width: @sb-delete-width;
    justify-content: center;
    .flex-column();
}

.md-button.sb-checklist-templates-editor__icon-button {
    font-size: @font-size-title-large;
    color: @border-color;
    line-height: @font-size-large;
    min-width: auto;
    padding: @padding-none;
    margin: @margin-none;
}

.md-button.sb-checklist-templates-editor__icon-button.sb-checklist-templates-editor__icon-button--move {
    color: @primary-font-color;
}

// Delete Button

.md-button.sb-checklist-templates-editor__icon-button {
    width: @sb-delete-width;
    min-width: @sb-delete-width;
    margin-left: @margin-small;
    height: @sb-delete-width;
    border-radius: 45%;

}

 .sb-checklist-templates-editor__icon-button--delete:hover {
        color: @riemi-red-700;
        transform: scale(1.1);
    }


// New item:

.sb-checklist-templates-editor__new-item-container {
    overflow-y: hidden;
    padding: 0 0 10px 0;
    margin: 0 0 -10px 0;
    position: relative;
    top: -1px;
}
.sb-checklist-templates-editor__new-item {
    max-width: @sb-checklist-templates-editor-max-width;
    width: @sb-checklist-templates-editor-width;
    margin: 0 auto 0;
    position: relative;
    border-top: 1px solid @border-color;
}

.sb-checklist-templates-editor__new-item-content {
    padding: 0 @sb-fab-padding;
    overflow: hidden;
}

.sb-checklist-templates-editor__new-item-form {
    position: relative;
    display: flex;
    align-items: center;

    & .sb-checklist-templates-editor__checklist-item-input {
        top: 5px;
    }
}

// Textareas and inputs

.sb-checklist-templates-editor__checklist-item-input {
    width: calc(~"100% - 40px");
    font-size: @font-size-small;

    .md-errors-spacer {
        display: none;
    }

    .sb-checklist-templates-editor__checklist-item-textarea {
        color: @sablono-grey-800;
        padding-right: @padding-big;
    }
}

.sb-checklist-templates-editor__checklist-items .sb-checklist-templates-editor__checklist-item-input:not(.md-input-focused) .sb-checklist-templates-editor__checklist-item-textarea {
    border-bottom: none;
}

.sb-checklist-templates-editor__checklist-item-input.md-input-pristine.md-input-focused input{
    border-color: @sablono-green-main;
}

.sb-checklist-templates-editor__checklist-item-input.md-input-pristine.md-input-focused label{
    color: @sablono-green-main;

    &:after {
        color: @sablono-green-main;
    }
}

.sb-checklist-templates-editor__checklist-item--first-row {
    width: 100%;
    display: flex;
}

.sb-checklist-templates-editor__checklist-item--settings {
    min-width: 290px;
    display: flex;
    flex-direction: column;
    justify-content: center;
}

.sb-checklist-templates-editor__icon-button--wrapper {
    display: flex;
    align-items: center;
}

.sb-checklist-templates-editor__checklist-item--wrapper {
    width: 100%;
    padding-left: @padding-normal;
}

.sb-checklist-templates-editor__checklist-item__require-image {
    display: flex;
    flex-direction: column;
    font-size: @font-size-smaller;
    min-width: 140px;
}

.sb-checklist-templates-editor__checklist-item__require-image__title {
    color: @sablono-grey-500;
    margin-top: 14px;
}

.sb-checklist-templates-editor__checklist-item__require-image__switch {
    flex: 0 0 40px;
    margin: 0;
}
