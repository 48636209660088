@import "./maintenance/maintenance";
@import "./registration/account_register";
@import "./verification/account_verification";
@import "../../../styles/global";

// urls for background images
//
@background-image: url("../../../images/background/login_background_construction_dude.png");

@account-card-max-width: 420px;
@account-layout-swap: 900px;

.account-page {
    position: relative; // needed to hide the intercom button in build version
    z-index: 62;
    height: 100%;
    .flex-column;
    justify-content: space-between;

    @media(max-width: @account-layout-swap){
        background-image: none;
        background-color: #2B2B2B; // color of the image in login_background_construction_dude.png
    }

    background-image: @background-image;
    background-position: center center;
    background-size: cover;
    background-attachment: fixed;
    .logo {
        position: relative;
        height: 100px;
        z-index: 1;
        padding: @padding-normal 0px;
        background-position: center;
        background-repeat: no-repeat;
        background-size: 200px 100px;
        background-image: url(../../../images/sablono_logo.svg);
        border-radius: 2px 2px 0px 0px;
        display: block;
        outline: none;
        &.welcome-animation-allowed{
            cursor: pointer;
        }
    }
    &-background-layer{
        position: absolute;
        top: 0;
        left: 0;
        bottom: 0;
        right: 0;
        background-color: rgba(255,255,255,0.9);
    }
    .account-page-tabs{
        border-radius: 0px 0px 2px 2px;
    }
    &-content {
        width: 452px;
        margin: auto;
        margin-right: calc((50% - 452px) / 2);
        position: relative;
        color: rgba(0, 0, 0, .7);
        @media(max-width: @account-layout-swap){
            margin: auto;
        }
        @media(max-width: @account-card-max-width){
            width: 100%;
        }
        md-content {
            background-color: transparent;
            box-sizing: border-box;
            padding: @padding-normal;
        }
        md-content.account-page-view{
            overflow: hidden;
        }
        md-tabs {
            flex-grow: 1;
        }
        md-tabs.md-default-theme md-ink-bar {
            background-color: @sablono-green-main;
        }
        md-tabs.md-default-theme .md-tab .md-ripple-container {
            .md-ripple {
                background-color: @sablono-green-main !important;
            }
        }
        .sb-login-buttons{
            display: flex;
            flex-direction: row;
            align-items: center;
            justify-content: flex-end;
        }
        .sb-login-avatar{
            margin-top: 22px!important;
        }

    }
    &-footer {
        position: relative;
        display: flex;
        flex-shrink: 0;
        flex-grow: 0;
        overflow: hidden;
        width:100%;
        background-color: rgba(255, 255, 255, 0.75);
        flex-direction: row;
        justify-content: center;
        align-items: center;
        flex-wrap: wrap;
        .copyright {
            font-size: @font-size-small;
            padding:0 @padding-normal;
        }
    }
}

.sb-account-messages{
    min-height: 100px;
    a{
        color: @light-text-color;
    }
    .success{
        color: @sablono-green-700;
    }
    .error{
        color: @riemi-red-400;
    }
    h3{
        font-size:@font-size-larger;
        font-weight:@font-weight-light;
        margin:0 0 @margin-small 0;
    }
    p{
        align-items: center;
    }
    small{
        font-size: @font-size-small;
        display: block;
    }
    .animate-fade-in-long{
        color: @sablono-grey-600;
    }
    .sb-registration-container-image{
        display: flex;
        flex-direction:row;
        p{
            display: flex;
            align-items: center;
        }
    }
    .md-button{
        float: right!important;
    }
    .sb-registration-image{
        float: right;
        width:100px;
        min-width:100px;
        height: 100px;
        min-height: 100px;
        margin:0px 0 0 8px;
    }
}

.sb-login-reset-pw{
    color:@sablono-green-main;
    font-size:@font-size-small;
    text-align: right;
    margin:@margin-small;
    &:visited{
        color: @primary-font-color;
    }
    &:hover{
        font-weight: @font-weight-bold;
    }
}

.account-page .sb-login-form,.maintenance-message,.sb-verification,.sb-account-messages {
    .flex-column();
    width: 100%;

    @media(min-width: @account-card-max-width){
        width: @account-card-max-width;
    }
}

@media (max-width: 599px) {
    .account-page-footer-content {
        .md-button,
        .copyright {
            min-width: 56px;
            margin: 2px 2px;
            flex-grow: 1;
        }
        .copyright {
            margin-top: 0;
            margin-bottom: 0;
            flex-grow: 1;
        }
    }
}

.sb-login__title {
    text-align: center;
    width: 100%;
}

.sb-register__legal-text {
    font-size: 12px;
    color: @light-text-color;
}


// Settings swapping to dark mode
.account-page {
    &-background-layer {
        background-color: transparent;
    }

    .sb-login__title,
    .sb-register__title,
    md-checkbox,
    &md-tabs.md-default-theme .md-tab, md-tabs .md-tab:not(.md-active),
    md-checkbox .md-icon,
    md-input-container:not(.md-input-focused):not(.md-input-invalid) label.md-required:after,
    md-input-container:not(.md-input-invalid) input::placeholder,
    md-input-container:not(.md-input-focused):not(.md-input-invalid) label,
    md-input-container:not(.md-input-invalid) .md-select-icon:after,
    md-input-container:not(.md-input-invalid) .md-select-value:after,
    md-input-container:not(.md-input-invalid) .md-select-value,
    .sb-login-form,
    .sb-user-image,
    .sb-login-reset-pw,
    .sb-registration-container-image
    {
        color: @light-text-color;
    }

    md-input-container input
    {
        caret-color: @sablono-green-main;
    }

    md-input-container.md-input-invalid input,
    md-input-container .md-input-invalid:not(.md-input-focused) input,
    md-input-container.md-input-invalid .md-select-icon:after {
        caret-color: @delete;
        color: @delete;
    }

    md-input-container:not(.md-input-invalid) input,
    md-input-container:not(.md-input-invalid) md-select-value,
    md-checkbox:not([disabled]).md-primary:not(.md-checked) .md-icon{
        color: @light-text-color;
        border-color: @light-text-color;
    }

}
