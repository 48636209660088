@tree-left-padding: 30px;

.sb-editor-tree-node-ghost {
    display: none !important;
}

.sb-editor-tree-layout {
    flex-direction: column;
    display: flex;
}

.sb-editor-tree-node{

    display: flex;
    flex-direction: row;
    align-items: center;

    cursor: pointer;
    outline: none;

    transition: height 200ms, opacity 200ms, padding-top 200ms, padding-bottom 200ms;

    button{
        i{
            position: relative;
            bottom: 9px;
            right: 9px;
            &.toggle-collapse{
                bottom:0;
                right:0;
            }
        }
    }

    i {
        color: inherit;
        font-size: 22px; // make our icons bigger at all.
    }

    span.highlight{
        color: red !important; // see -> global.less (span.highlight)
    }

    .toggle-tree-button {
        @margin-left: 4px;
        width: @tree-left-padding - @margin-left;
        height: @tree-left-padding - @margin-left;
        min-height: @tree-left-padding - @margin-left;
        min-width: @tree-left-padding - @margin-left;
        line-height: @tree-left-padding - @margin-left;
        margin: 0 0 0 @margin-left;
        .md-ripple-container{
          //  background: red!important;
            //position: relative;
        }
        i {
            transition: transform 200ms;
        }
    }
    .category-icon {
        width: 30px;
        height: 30px;
        display: flex;
        align-content: center;
        justify-content: center;
        align-items: center;
        flex-direction: row;
        flex-shrink: 0;
    }

    &.opened-node {
        height: 36px;
        padding-top: 4px;
        padding-bottom: 4px;
        opacity: 1;
    }
    &.closed-node {
        height: 0px;
        min-height: 0px;
        overflow: hidden;
        padding-top: 0px;
        padding-bottom: 0px;
        opacity: 0;
    }

    &.selected-node {
        color: @sablono-sea-green;
        .sb-template-editor__activity_index{
            color: @sablono-sea-green;
        }
    }

    &.highlighted-node i {
        color: @primary-font-color;
        background-color: @white-background;
    }

    &:hover {
        background-color: @sablono-grey-200;
        .sb-editor-tree-node-drag-indicator {
            display: flex;
        }
    }

    .collapse-btn {
        margin-left: auto;
        font-size: 24px;
        padding: 0 8px;
        align-items: center;
        justify-content: center;
        align-self: stretch;
        flex-shrink: 0;
    }

    &.dirty-state {
        &>span {
            font-style: italic;
        }
    }
}

.activity .toggle-tree-button {
    display: none;
}

.sb-editor-tree__asterisk {
    padding: 0;
    visibility: hidden;
}
.dirty-state .sb-editor-tree__asterisk {
    visibility: inherit;
}

.sb-editor-tree__node-text {
    flex-grow: 1;
    .ellipsed-text();
}

.sb-editor-tree-node-drag-indicator {
    display: none;
    margin-left: auto;
    font-size: 24px;
    padding: 0 8px;
    align-items: center;
    justify-content: center;
    align-self: stretch;
}

.sb-editor-tree-node {
    width:auto;
}

.sb-editor-tree-node.ng-move{
    transition:all linear 0.4s;
}

.sb-editor-tree-node.ng-move{
    width:0;
}

.sb-editor-tree-node.ng-move.ng-move-active{
    width:auto;
}
.sb-template-editor__activity_index{

    .sb-topological-index(10px);
    color:@sablono-grey-600;
    background: @white-background;
    display: inline-block;
    margin: 0 2px;
    font-style: normal;
}
