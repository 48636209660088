.sb-structure-autocomplete {
    border-radius: 0;
    &[disabled] {
        border-bottom: none;
        input {
            background: @white-background;
            border: none !important;
            &.ng-invalid {
                border: none !important;
            }
        }
    }
    input {
        border-bottom: 1px solid @sablono-green-main !important;
        &.ng-invalid {
            border-bottom: 1px solid @riemi-red-600 !important;
        }
    }
    md-autocomplete-wrap {
        box-shadow: none !important;
    }
}
