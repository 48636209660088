
sb-content-overflow{
    position: absolute;
    top:0;
    right: 0;
    bottom: 0;
    left: 0;
    display: flex;
    justify-content: center;
    align-items:flex-start;
    width: 100%;
    height: 100%;
    min-height: 0;
    min-width: 0;
    overflow-y: auto;
    overflow-x: hidden;
    padding: 0;
    box-sizing: border-box;
    &.sb-overflow-with-toolbar{
        top: @sb-toolbar-height;
        height: calc(~"100% - 64px");
    }
    > md-content{
        padding: @padding-normal @padding-normal 0;
        flex-wrap: wrap;
        display: flex;
        align-items: flex-start;
        justify-content: center;
        box-sizing: border-box;
        background: transparent;
        height: 100%;
    }
}
