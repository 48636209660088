.reset-activity-dialog {
    width: 480px;
}

.reset-activity-dialog__loading {
    height: 200px;
}

.reset-activity-dialog__description {
    margin: 12px;
    white-space: pre-line;
}

.sb-reset-activity-dialog__confirm-input {
    margin: 0 auto;
    margin-top: 12px;
    width: 460px;
}
