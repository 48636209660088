.sb-filter-sidenav {
    z-index: 99;
    box-shadow: 0 0 10px 0 rgba(0,0,0,0.62);
    margin-left: @margin-big;
    width: 200px;

    .sb-dashboard-container__filter-menu {
        position: relative;
        top: 0;
    }
}

.sb-filter-sidenav__handle {
    z-index: 999;
    position: absolute;
    top: 0;
    left: 0;
    bottom: 0;
    width: @padding-big;
    font-size: @font-size-smaller;
    font-weight: @font-weight-bold;
    line-height: @padding-big;
    box-shadow: 0 0 5px 0 rgba(0,0,0,0.62);
    cursor: pointer;
    transform: rotate(180deg);
    writing-mode: vertical-rl;
    text-orientation: mixed;
    background-color: @sablono-green-700;
    outline: none;

    .sb-filter-sidenav__handle-text {
        display: block;
        text-align: center;
        letter-spacing: 0.2em;
        text-transform: uppercase;
        margin: 0;
    }

    .mdi {
        font-size: @font-size-small;
    }
}
