.sb-content-zoom{
    // to avoid ipad issues this position has to be fixed.
    position: fixed;
    left:0;
    z-index: 1;
    color: @primary-font-color;
    margin: auto;
    md-slider.md-default-theme .md-thumb:after, md-slider .md-thumb:after,
    md-slider.md-default-theme .md-track.md-track-fill, md-slider .md-track.md-track-fill{
        background-color: @sablono-green-main!important;
        border-color: @sablono-green-main!important;
    }
}

.sb-content-zoom__container {
  .flex-row-center();
  height: 100%;
  width: 100%;
  margin: @margin-none;
}

.sb-content-zoom__slider {
  width: 200px;
}

.sb-content-zoom__btn{
    padding: @padding-smaller;
    background: none;
    border: none;
    color: @sablono-green-button;
    outline-width: 0;
}
.sb-content-zoom__icon--first{
    position: relative;
    font-size: 34px;
    border-radius: 6px;
    &:hover{
        cursor: pointer;
        background: @sablono-green-button-hover;
    }
}
.sb-content-zoom__icon--second{
    position: absolute;
    top: 7.3px;
    font-size: 14px;
    left: 6.8px;
}

