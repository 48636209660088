.sb-verification{
    .sb-verification-form{
        padding: @padding-normal;
        md-input-container{
            width: 100%;
        }
        button{
            float: right;
        }
    }

    .sb-description{
        display: block;
    }
}
