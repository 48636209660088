@import "../../../../styles/global";
@import "../../../../styles/colorpalette";

.sb-assign-template__item {
    .md-list-item-inner {
        .flex-row;
        justify-content: space-around;
        padding: @padding-small 0;
    }
}

/**
 * Toolbar with search
 */
@sb-inspect-deliverables--tall-toolbar-height: 104px;
@template-selection-empty-search-min-height: 265px;
@template-selection--magic-number-to-avoid-jumping-dialog: 39px;
@template-selection--list-height: @template-selection-empty-search-min-height - @template-selection--magic-number-to-avoid-jumping-dialog;
@template-selection-search-min-width: 800px;
@template-selection-max-content-height: 600px - @sb-inspect-deliverables--tall-toolbar-height - 53px;

md-toolbar.sb-template-selection__dialog-toolbar{
    .flex-column();
    min-height: @sb-inspect-deliverables--tall-toolbar-height;
}

.sb-template-selection__dialog-content {
    .flex-column();
    justify-content: space-between;
    height: 100%;
    min-height: @template-selection-max-content-height;
}

/**
 * Overwrite parent styling of general dialog
 */
.sb-template-selection__templates-list {
    min-height: @template-selection--list-height !important;
    order: initial !important;
    flex-grow: 1;
}

.sb-template-selection__empty-search, .sb-template-selection__not-data {
    min-height: @template-selection-empty-search-min-height;
    flex-grow: 1;
}

/**
 * Search input based on auto-complete
 */
.sb-template-selection__dialog-search-header {
    .flex-column();
    align-items: flex-start;

    max-width: @template-selection-search-min-width;

    min-height: @sb-inspect-deliverables--tall-toolbar-height;
    height: @sb-inspect-deliverables--tall-toolbar-height;
    max-height: @sb-inspect-deliverables--tall-toolbar-height;
}

.sb-template-selection__dialog-search-title {
    width: 100%;
    padding: @padding-normal;
    font-weight: @font-weight-normal;
    font-size: @font-size-larger;
}

.sb-template-selection__search-input-container {
    width: 100%;
}

/**
 * add magnify glass in front of search input
 */
.sb-template-selection__search-input-container md-autocomplete-wrap:before {
    font-family: "Material Design Icons", sans-serif;
    content: "\F349";
    line-height: 1;
    padding: @padding-small @padding-none @padding-none @padding-small;
    font-weight: normal;
    font-size: @font-size-icon-normal;
    color: @sablono-green-text-color;
}

.sb-template-selection__overwrite-confirmation {
    padding: 16px 16px 0;
    background: @riemi-red-50;
}

.sb-container__copy-teams-qa {
    padding-top: 16px;
    padding-bottom: 8px;

    display: flex;
    flex-direction: column;
}
