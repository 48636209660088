.sb-scheduling-state {
    .flex-row;
    margin: @margin-none 10px;
}

.sb-scheduling-state__action-button {
    background-color: @florange-300;
    color: white;

    &:not([disabled]):hover {
        background-color: @florange-400;
    }
}

.sb-scheduling-state__text {
    margin: @margin-small;
}

.sb-scheduling-state__icon {
    color: @florange-300;
    font-size: 35px;
    margin-top: @margin-small;
}

.sb-scheduling-state-panel{
    .flex-column;
    padding: @padding-small;
    background: white;
    border: 1px solid lightgray;
    width: 300px;
    height: 175px;
    color: @sablono-grey-500;
    font-size: @font-size-small;
}
