@import "../../../../../styles/layout";

@sb-commercial-card-panel-min-width: 750px;

.sb-commercial-header__container, .sb-commercial-card {
    max-width: @media-lg;
    margin: 0 auto @margin-normal;
    overflow: auto;
}

.sb-commercial-header__container {
    overflow-y: hidden;
    overflow-x: auto;
}

.sb-commercial-header {
    min-width: @sb-commercial-card-panel-min-width;
}

.sb-commercial-card__cost-group-container {
    padding: 0;
    min-width: @sb-commercial-card-panel-min-width;
    max-width: @media-lg;
    overflow-y: hidden;
    overflow-x: auto;
}
