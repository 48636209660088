@import "../../../../../styles/colorpalette";

@sb-tree-item-default-height: 64px;
.sb-tree{
    user-select: none;
}
.sb-tree-pull-left {
    float: left;
}

.sb-tree-spacer-left {
    margin-left: 15px;
}

.sb-tree-spacer-left-big {
    margin-left: 25px;
}

.sb-tree-pretty-cut-off {
    white-space: nowrap;
    text-overflow: ellipsis;
    overflow: hidden;
}

.sb-tree-spacer-right {
    margin-right: 15px;
}

.sb-tree-item {
    height:@sb-tree-item-default-height;
    align-items: center;
    justify-content: center;
    h3{
        font-size: @font-size-normal;
        line-height: 1em;
        font-weight: @font-weight-normal;
    }
    h4{
        font-size: @font-size-small;
        padding-top:@padding-smaller;
        line-height: 1em;
        font-weight: @font-weight-normal;
        .ellipsed-text();
    }
    .md-button.md-no-style{
        cursor: default;
    }
}
.sb-tree-open-indicator {
    text-align: center;
    outline-width: 0;
    width: 100%;
}
.sb-tree-open-indicator-icon{
    transition: all 100ms;
}

.sb-structure-code{
    margin: 0px 6px 6px 6px !important;
    color: @primary-font-color !important;
}

.sb-structure-name{
    margin: 0px 6px 6px 6px !important;

}
.sb-tree-item  {

    &.ng-move,
    &.ng-enter,
    &.ng-leave {
        transition: all 100ms;
    }

    &.ng-leave.ng-leave-active,
    &.ng-move,
    &.ng-enter {
        opacity:0;
        max-height:0;
    }

    &.ng-leave,
    &.ng-move.ng-move-active,
    &.ng-enter.ng-enter-active {
        opacity:1;
        max-height:64px;
    }
    &.ng-leave-stagger {
        /* this will have a 100ms delay between each successive leave animation */
        transition-delay: 0ms;

        /* in case the stagger doesn't work then these two values
         must be set to 0 to avoid an accidental CSS inheritance */
        transition-duration: 0s;
    }

    /**
     * Stagger ENTER ANIMATION
     */
    &.ng-enter-stagger {
        /* this will have a 100ms delay between each successive enter animation */
        transition-delay: 0ms;

        /* in case the stagger doesn't work then these two values
         must be set to 0 to avoid an accidental CSS inheritance */
        transition-duration: 0s;
    }
}
