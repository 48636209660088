@import "../../../../../styles/global.less";

@sb-dialog-bulk-state-change-max-width: 600px;

.sb-bulk-state-change-dialog {
    max-width: @sb-dialog-bulk-state-change-max-width;
    min-width: @sb-dialog-bulk-state-change-max-width;
}

.sb-bulk-state-change-dialog__toolbar {
    flex: 0 0 @sb-expanded-toolbar-height;
}

.sb-bulk-state-change-dialog__content {
    display: flex;
    overflow-y: hidden;
}

.sb-bulk-state-change-dialog__activities-list {
    overflow-y: auto;
    margin-bottom: 8px;
}

.sb-bulk-state-change-dialog__action-buttons {
    padding: 0 @padding-normal @padding-small;
    flex: 0 0 @sb-toolbar-height;
}

.sb-bulk-state-change-dialog__progress-selector {
    display: flex;
    height: 100px;
    align-items: center;
    justify-content: space-around;
}

.sb-bulk-state-change-dialog__select-type__progress-option {
    opacity: 0.5;
    border: 1px solid;
    transition: all ease-in-out 0.4s;
    font-size: 32px;
    padding: 12px @padding-normal;
    outline-width: 0;
    border-radius: @border-radius-small;
    cursor: pointer;
}

.sb-bulk-state-change-dialog__select-type--selected {

    &.sb-todo {
        .sb-todo.selected
    }
    &.sb-in-progress {
        .sb-in-progress.selected
    }
    &.sb-done {
        .sb-done.selected
    }
}

.sb-bulk-state-change-dialog__state-select {
    margin: unset;
}

@sb-bulk-state-change-dialog--number-of-visible-options: 6;
@sb-bulk-state-change-dialog-single-option-height: 48px;
@sb-bulk-state-change-dialog-max-expanded-height: (@sb-bulk-state-change-dialog--number-of-visible-options * @sb-bulk-state-change-dialog-single-option-height);

// custom styling for md-select-container
//
.sb-bulk-state-change-dialog__state-select-options {

    // overwrite maximum height to show more items in team selection
    //
    md-select-menu, md-content{
        max-height: @sb-bulk-state-change-dialog-max-expanded-height;
    }
}
