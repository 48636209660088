@sb-filter-checkbox-width: 105px;
@sb-filter-checkbox-width-all: 60px;
.sb-filter-checkbox{
    display: flex;
    justify-content: flex-start;
    align-items: center;
    margin: @margin-small 0;
    font-size: @font-size-smaller;
    :first-letter{
        text-transform: capitalize;
    }
    md-checkbox.sb-filter-checkbox-box{
        margin: 0 @margin-normal 0 0;
        width: @sb-filter-checkbox-width;
        display: flex;
        align-items: center;
        .md-icon{
            border-width: 1px;
        }
        &.sb-filter-checkbox-box-all{
            min-width:@sb-filter-checkbox-width-all;
            width:@sb-filter-checkbox-width-all;
            font-weight: @font-weight-normal;
            border-right: 1px solid @sablono-grey-500;
            padding-right: @padding-normal;
            &.md-checked .md-icon{
                opacity:0.5;
            }
        }
       &.md-checked .md-icon{
            background-color: @sablono-green-main;
        }
    }
}
