@sb-role-user-width: 50px;
@sb-add-user-dialog-width: 600px;
@sb-add-user-dialog-height: 270px;

.sb-add-user-dialog {
    max-width: @sb-add-user-dialog-width;
    width: @sb-add-user-dialog-width;
    min-width: @sb-add-user-dialog-width;
    transform: all 0.3s ease-in-out;
    min-height:@sb-add-user-dialog-height;
    .sb-add-user-dialog-content{
        min-height:@sb-add-user-dialog-height;
        display: flex;
        justify-content: space-around;
        margin-bottom:0;
    }
    .sb-add-user-dialog-loading{
        flex:1;
    }
    .md-toolbar-tools i{
        font-size: 25px;
        margin-right:@margin-small;
    }
    .sb-add-user-roles {
        .flex-column;
        .sb-add-user-roles-container{
            .flex-row-center;
            margin: 0 0 @margin-normal;
        }
        em{
            text-align: center;
            font-size: @font-size-small;
            width:85%;
            margin:0 auto;
            min-height: @font-size-small * 4;
        }
        .sb-add-user-role {
            flex-grow: 1;
            justify-content: center;
            display: flex;
            .sb-add-user-badge {
                width: @sb-role-user-width;
                height: @sb-role-user-width;
                border: 1px solid;
                line-height: @sb-role-user-width;
                text-align: center;
                font-size: @sb-role-user-width / 2;
                cursor: pointer;
                outline-width: 0;
                border-radius: 5px;
                &.selected {
                    padding: 1px;
                    color: @light-text-color;
                }
            }
        }
    }
}

@sb-user-select-team-height: 45px;
.sb-add-user-dialog-team-option{
    margin: 0 auto;
    width:100%;
}
.sb-add-user-dialog-select-team{
    padding-bottom: @padding-normal;
    p{
        margin-top: 0;
    }
}
.sb-add-user-dialog-team-option-container{
    border-left:5px solid;
    min-height: @sb-user-select-team-height;
    .md-select-value{
        padding: @padding-small @padding-small;
    }
    &.md-input-focused{
        .md-select-value >span{
            padding-bottom: @padding-small;
        }
    }
}
.sb-add-user-dialog-chips{
    margin-bottom:@margin-normal;
}
.sb-add-user-dialog-team-option-select{
    md-option{
        border-left: 5px solid;
        margin: 2px 0;
    }
}
