@import "./components/checklistTemplateRow/checklist_template_row.less";
@import "../../../../../styles/layout.less";

@sb-header-height: 112px;

@sb-checklist-templates-list-width: 90%;
@sb-checklist-templates-list-max-width: 1400px;
@sb-checklist-templates-cell-normal-width: 114px;
@sb-checklist-templates-cell-height: 40px;

.sb-checklist-templates-list {
    overflow: scroll;
    height: 100%;
}

.sb-checklist-templates-list__list-header {
    margin-top: 64px;
}

.sb-checklist-templates-list__header-row {
    .flex-row-wrap();
    .flex-row-center();
    width: @sb-checklist-templates-list-width;
    max-width: @sb-checklist-templates-list-max-width;
    margin: 0 auto 2px;
    .sb-whiteframe-glow-z1;
    padding: @padding-small/2 @padding-small;
    border-bottom: 1px solid @sablono-grey-200;
    background: @white-background;
}

.sb-checklist-templates-list__header-cell {
    .flex(column, center, flex-start);
    flex: 1;
    font-size: @font-size-small;
    min-width: @sb-checklist-templates-cell-normal-width;
    height: @sb-checklist-templates-cell-height;
    padding: 2px @padding-small;
    overflow: hidden;
}

.sb-checklist-templates-list__number-of-items, .sb-checklist-templates-list__date {
    flex-grow: 0;

    .md-list-item-text, md-list {
        width: 100%;
        text-align: center;
    }
}

.sb-checklist-templates-list__number-of-items {
    flex-basis: 180px;
    width: 180px;
}

.sb-checklist-templates-list__date {
    flex-basis: 80px;
    width: 80px;
}

.sb-checklist-templates-list__list-items {
    width: 90%;
    max-width: 1400px;
    margin: 0 auto;
    background: @white-background;
    box-shadow: 0px 2px 0px 0px rgba(0, 0, 0, 0.14), 2px 0px 0px 0px rgba(0, 0, 0, 0.14), -2px 0px 0px 0px rgba(0, 0, 0, 0.14);
    &:hover {
        cursor: pointer;
    }
}
