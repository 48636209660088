@import "../../../../../styles/colorpalette";

/*
 * LAYOUT SECTION:
 *  -> please no coloring here. There is separate section
 *      and separate style classes for color dependent manipulation.
 */
.sb-subbar {
    height: auto;
    min-height: @sb-subbar-mini-height;
    max-height: @sb-subbar-height;
    padding: 0;
    transition: all 0.3s ease-in-out;
    z-index: 6;
    .flex-row;
    align-items: center;
    .sb-whiteframe-glow-z1;
    .sb-subbar-title{
        padding: 12px 0;
    }
    .md-button {
        padding-left: 10px;
        padding-right: 10px;
        margin-top: @margin-smaller;
        margin-bottom: @margin-smaller;
        .mdi:only-child {
            // mdi icon size changed from 22pt to 22px if there is just a button with an icon.
            font-size: 22px;
        }
        .mid {
            font-size: 14px; // for button with text and icon.
        }
    }
    .md-icon-button .fa,// sometimes instead of .mdi:only-child this pair changes fontsize.
    .md-icon-button .mdi {
        font-size: 22px;
    }
    .md-button:disabled {
        cursor: not-allowed;
    }
    .md-button.md-raised,
    .md-button.md-raised:active {
        box-shadow: none;
    }
}

/* IE only styles */
@media all and (-ms-high-contrast:none), (-ms-high-contrast: active) {
    .sb-subbar .md-toolbar-tools {
        width: auto!important;
    }
}
.md-button.md-icon-button.sb-toolbar-tools__back-button {
    color: @light-text-color
}

.sb-toolbar-tools{
    width: auto;
    height: auto;
}
md-toolbar.sb-subbar {
    background-color: @sablono-grey-800;
}

md-toolbar.sb-delete {
    background-color: @riemi-red-500;
    color: @light-text-color;
    .md-button {
        color: @sablono-green-text-color !important;
        background-color: @riemi-red-300 !important;
    }
    .md-button:not([disabled]):hover {
        background-color: @riemi-red-200 !important;
    }
    .md-button:disabled {
        color: @sablono-green-text-color-disabled !important;
        background-color: @riemi-red-500 !important;
    }
    .md-icon-button {
        background-color: @riemi-red-500 !important;
    }
}
