@import "../fonts";

.sb-number-outlined--small(@color, @border, @margin-left, @margin-right, @margin-bottom) {
    margin-right: @margin-right;
    margin-bottom: @margin-bottom;
    min-width: @font-size-small;
    line-height: @font-size-small;
    border-radius: 25%;
    text-align: center;
    font-size: @font-size-smallest;
    color: @color;
    border: @border;
    margin-left: @margin-left;
}

.sb-number-outlined--normal(@color, @border, @margin-left, @margin-right, @margin-bottom) {
    .sb-number-outlined--small(@color, @border, @margin-left, @margin-right, @margin-bottom);
    min-width: @font-size-large;
    line-height: @font-size-large;
    font-size: @font-size-smaller;
}
