@row-height: 48px;
@container-width: @media-xlg;

.sb-templates-state-container {
    padding-top: 65px;
    @media (max-width: @media-md) {
        padding-top: 0;
    }
}

.sb-header-container {
    position: absolute;
    top: 0;
    width: 100%;
    .flex-row-center;
    flex-grow: 1;
    background-color: @gray-background;
    padding: 0;

    @media (max-width: @media-md) {
        right: 0;
        padding: 0;
    }
    .header {
        padding: 0 6px 0 8px;
        background-color: @white-background;
        .flex-row;
        margin-top: 0;
        width: @media-md;
        @media (min-width: @media-lg) {
            width: @media-lg;
        }
        @media (min-width: @media-xlg) {
            width: @media-xlg;
        }
        @media (max-width: @media-md) {
            margin-top: 10px;
            width: 100%;
            min-width: 100px;
        }
        div {
            button {
                min-width: 0;
                margin: 5px 0;
                width: 100%;
                i {
                    margin-right: 3px;
                }
            }
        }
    }
}

.templates-container {
    .template {
        border-collapse: separate;
    }
}

.sb-groups-container {
    position: relative;
    width: 100%;
    height: auto;
    min-height: 80%;
    margin: 10px auto 40px;
    box-sizing: border-box;
    display: block;
    padding-bottom: @padding-big;
    .sb-background-info h1 {
        color: @sablono-grey-500;
    }
    @media (max-width: @media-md) {
        width: 100%;
        right: 0;
        margin: 40px 0 0;
        padding: 0;
    }
    .group {
        width: @media-md;
        margin: 0 auto 10px auto;
        @media (min-width: @media-lg) {
            width: @media-lg;
        }
        @media (min-width: @media-xlg) {
            width: @media-xlg;
        }
        @media (max-width: @media-md) {
            width: 100%;
        }
        .group-title {
            font-size: @font-size-large;
            font-weight: @font-weight-normal;
            color: @sablono-grey-500;
            padding-left: @padding-small;
            @media (min-width: @media-md) {
                padding-left: 0;
            }
        }
        .templates-container {
            padding-bottom: @padding-big;
            .template {
                border-collapse: separate;
                .flex-column;
                border-bottom: 1px solid @sablono-grey-200;
                justify-content: center;
                flex-grow: 1;
                background-color: @white-background;
                transition-duration: 0.3s;
                /* IE only styles */
                @media all and (-ms-high-contrast: none), (-ms-high-contrast: active) {
                    box-shadow: none;
                }
                &.opened {
                    @media (min-width: @media-md) {
                        margin: 50px -50px;
                    }
                    margin: 50px 0 0;
                }
                .template-row {
                    height: @row-height;
                    .flex-row-center;
                    outline: none;
                    .row-click {
                        .flex-row-center-start;
                        width: 100%;
                        padding-left: @padding-small;
                        @media (min-width: @media-md) {
                            padding-left: 0;
                        }
                    }
                    .icon-edit {
                        position: relative;
                        right: 7px;
                        bottom: 7px;
                    }
                    &:hover {
                        background-color: @sablono-grey-50;
                        cursor: pointer;

                        .icon-edit {
                            opacity: 1;
                        }

                    }
                    img {
                        height: 40px;
                    }
                    &:last-child {
                        border-bottom: 0;
                    }
                }
                .template-children-row {
                    background-color: @sablono-grey-50;
                    min-height: 200px;
                    .flex-column;
                    padding: @padding-normal @padding-big;
                }
            }
        }
    }
}

.sb-template-column {
    .flex-column-center;
    flex-basis: 50px;
    flex-shrink: 0;
    &.tool-column {
        flex: 4;
        .md-button.md-icon-button {
            width: 30px;
            height: 30px;
            line-height: 30px;
            span {
                font-size: 20px;
            }
        }
    }
    &.code-column {
        flex: 6;
    }
    &.category-column {
        flex: 8;
        .category-icon {
            font-size: 20px;
        }
    }
    &.name-column {
        flex: 15;
    }
    &.revision-column {
        display: none;
        flex: 4;
        flex-direction: row;
        @media(min-width: @media-md) {
            display: flex;
        }
    }
    &.desc-column {
        display: none;
        flex: 16;
        @media (min-width: @media-sm) {
            display: block;
            .ellipsed-text();
            text-align: center;
        }
    }
    &.activities-column {
        display: none;
        flex: 14;
        @media (min-width: @media-md) {
            display: flex;
        }
    }
    &.initials-column {
        display: none;
        flex: 10;
        @media (min-width: @media-md) {
            display: flex;
        }
        .sb-user-image {
            width: 30px;
            height: 30px;
            font-size: 15px;
        }
    }
    &.date-column {
        display: none;
        flex: 12;
        @media (min-width: 600px) {
            display: flex;
        }
    }
    .icon-edit {
        opacity: 0;
        color: @primary-font-color;
        transition: all 0.6s ease-in-out;
    }
}
