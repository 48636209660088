@font-face {
    font-family: 'icomoon';
    src: url('../fonts/backup/icomoon.eot');
    src: url('../fonts/backup/icomoon.eot') format('embedded-opentype'), url('../fonts/backup/icomoon.woff') format('woff'), url('../fonts/backup/icomoon.ttf') format('truetype'), url('../fonts/backup/icomoon.svg') format('svg');
    font-weight: normal;
    font-style: normal;
}

[class^="sb-icon-"],
[class*=" sb-icon-"] {
    font-family: 'icomoon';
    speak: none;
    font-style: normal;
    font-weight: normal;
    font-variant: normal;
    text-transform: none;
    line-height: 1;
    /* Better Font Rendering =========== */
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
}

.sb-icon-activity:before {
    content: "\e631";
}
.sb-icon-group:before {
    content: "\e630";
}
.sb-icon-delivery:before {
    content: "\e633";
}
.sb-icon-trait:before {
    content: "\e634";
}
.sb-icon-room:before {
    content: "\e635";
}
.sb-icon-import_note_template:before {
    content: "\e62d";
}
.sb-icon-project_overview:before {
    content: "\e61d";
}
.sb-icon-project_elements:before {
    content: "\e61e";
}
.sb-icon-inspection_history:before {
    content: "\e621";
}
.sb-icon-inspection_history_2:before {
    content: "\e622";
}
.sb-icon-excel_outline:before {
    content: "\e624";
}
.sb-icon-excel:before {
    content: "\e625";
}
.sb-icon-import:before {
    content: "\e627";
}
.sb-icon-msproject:before {
    content: "\e628";
}
.sb-icon-msproject_outline:before {
    content: "\e62a";
}
.sb-icon-new_project:before {
    content: "\e62b";
}
.sb-icon-wbs_structure:before {
    content: "\e62e";
}
.sb-icon-sort_code_up:before {
    content: "\e61c";
}
.sb-icon-sort_end_down:before {
    content: "\e620";
}
.sb-icon-sort_end_up:before {
    content: "\e623";
}
.sb-icon-sort_name_down:before {
    content: "\e626";
}
.sb-icon-sort_name_up:before {
    content: "\e629";
}
.sb-icon-sort_start_down:before {
    content: "\e62c";
}
.sb-icon-sort_start_up:before {
    content: "\e62f";
}
.sb-icon-sort_code_down:before {
    content: "\e632";
}
.sb-icon-qr_pdf_2:before {
    content: "\e60f";
}
.sb-icon-qr_pdf_12:before {
    content: "\e61a";
}
.sb-icon-cancel:before {
    content: "\e61b";
}
.sb-icon-comment:before {
    content: "\f600";
}
.sb-icon-camera:before {
    content: "\f601";
}
.sb-icon-denied:before {
    content: "\e618";
}
.sb-icon-project-settings:before {
    content: "\e617";
}
.sb-icon-print:before {
    content: "\e608";
}
.sb-icon-report-detail:before {
    content: "\e613";
}
.sb-icon-report:before {
    content: "\e614";
}
.sb-icon-report-pdf:before {
    content: "\e60e";
}
.sb-icon-logout:before {
    content: "\e600";
}
.sb-icon-project:before {
    content: "\e601";
}
.sb-icon-attatch_analysis:before {
    content: "\e602";
}
.sb-icon-attach_mobile:before {
    content: "\e603";
}
.sb-icon-seen-false:before {
    content: "\e604";
}
.sb-icon-seen:before {
    content: "\e605";
}
.sb-icon-analysis:before {
    content: "\e606";
}
.sb-icon-filter_clear:before {
    content: "\e607";
}
.sb-icon-inspect:before {
    content: "\e609";
}
.sb-icon-status_info:before {
    content: "\e60a";
}
.sb-icon-mail:before {
    content: "\e60b";
}
.sb-icon-status_alert:before {
    content: "\e60c";
}
.sb-icon-user:before {
    content: "\e60d";
}
.sb-icon-status-problem:before {
    content: "\e61f";
}
.sb-icon-progress-pending:before {
    content: "\e610";
}
.sb-icon-progress-in-progress:before {
    content: "\e611";
}
.sb-icon-progress-finished:before {
    content: "\e612";
}
.sb-icon-template-company:before {
    content: "\e615";
}
.sb-icon-template:before {
    content: "\e616";
}
.sb-icon-move-parent:before {
    content: "\e636";
}
.sb-icon-collapseall:before {
    content: "\e637";
}
.sb-icon-expandall:before {
    content: "\e638";
}
