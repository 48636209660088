@sb-stepper-circle-size:28px;

.sb-stepper{
    position: relative;
    background: @sablono-grey-100;
    padding: @padding-small @padding-big;
    @media(max-width: @media-sm){
        padding: @padding-small 0;
    }
    .sb-stepper-steps{
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        align-items: center;
        z-index: 1;
        position: relative;
        @media(max-width: @media-sm){
            flex-direction: column;
            align-items: flex-start;
        }
    }
    .sb-stepper-step{

        @media(max-width: @media-sm){
            margin: @margin-small;
        }

        padding:0 @padding-small;
        background: @sablono-grey-100;
        display: flex;
        align-items: center;
        text-align: left;
        font-size: @font-size-small;
        .sb-stepper-icon{
            min-width: @sb-stepper-circle-size;
            max-width: @sb-stepper-circle-size;
            width: @sb-stepper-circle-size;
            height: @sb-stepper-circle-size;
            border-radius: 50%;
            position: relative;
        }
        .sb-stepper-icon-content{
            position: absolute;
            top:0;
            left: 0;
            width: @sb-stepper-circle-size;
            line-height: @sb-stepper-circle-size;
            color: @light-text-color;
            text-align: center;
            font-style: normal;
            font-size: @font-size-small;
            font-weight: @font-weight-normal;
        }
        .sb-stepper-text{
            padding: 0 0 0 @padding-small;
            color: @sablono-grey-800;
        }
    }
    .sb-stepper-step--active {
        // default color for stepper
        .sb-stepper-icon{
            background: @sablono-green-main;
        }
        .sb-stepper-text{
            font-weight: @font-weight-normal;
        }
    }
    .sb-stepper-step--inactive {
        .sb-stepper-icon{
            background: @sablono-grey-400;
        }
        .sb-stepper-text{
            color: @sablono-grey-600;
        }
    }
    hr{
        z-index:0;
        position: absolute;
        top:50%;
        border: 0;
        height: 0;
        border-top: 1px solid rgba(0, 0, 0, 0.1);
        border-bottom: 1px solid rgba(255, 255, 255, 0.3);
        // To have the correct effect on the line between steps without overflowing
        // the borders. 64px is twice the lateral margin of the stepper.
        width: calc(~"100% - 64px");
        margin: 0;
        @media(max-width: @media-sm){
            display: none;
        }
    }
}


// Theming
.sb-stepper-step-active .sb-stepper-icon{
    background:@sablono-green-main;
}

