.sb-diary-entry {
    .smaller-font();
    border-radius: 4px;
    margin: 0 @margin-small @margin-small @margin-small;
    color: @sablono-grey-600;
    line-height: @font-size-small;
}

.sb-diary-entry__author, .sb-diary-entry__finalized {
    display: block;
    margin-bottom: @margin-small;
}

.sb-diary-entry--is-hidden {
    visibility: hidden;
}

.sb-diary-entry__tag-finalized {
    margin: 0;
}
