@import "../../../../styles/global";
@import "../../../../styles/colorpalette";
@import "../../../../styles/fonts";
@import "../../../../scripts/common/ui-elements/components/sbProgressOptions/progress_options";

@min-card-height: 40px;

@progress-box-dimension: 46px;
@progress-box-font-size: @font-size-icon-large;
@progress-box-position-right: calc(-@progress-box-dimension/2);
@progress-box-position-top: calc((@min-card-height - @progress-box-dimension)/2);

@activity-text-left-space: @padding-small;
@activity-text-right-space: calc(@progress-box-dimension / 2 + @activity-text-left-space);

@process-activity-text-group-width: 100%;

.sb-process-activity {
    .flex(row, space-between, center);
    box-sizing: border-box;

    min-height: @min-card-height;

    // to place the progress box relative to this using an absolute positioning
    position: relative;

    cursor: pointer;
    &--selected {
        background-color: @sablono-green-main;
        color: white;
    }

    // needs to be more specific than `sb-progress-option__button.md-icon-button`
    //
    .sb-process-activity__progress_box {
        height: @progress-box-dimension;
        width: @progress-box-dimension;
        i {
            font-size: @progress-box-font-size;
        }
    }
}

.sb-process-activity__content{
    display: flex;
    width: 100%;
    box-sizing: border-box;
    padding-right: @activity-text-right-space;
    padding-left: @activity-text-left-space;
}

.sb-process-activity__color-indicator {
    border-left: 3px solid;
}

.sb-process-activity__text-group {
    min-width: 0;
    width: @process-activity-text-group-width;
    .flex-column();
}

.sb-process-activity__text {
    .smaller-font();
}

.sb-process-activity__progress_box {
    position: absolute;
    right: @progress-box-position-right;
    top: @progress-box-position-top;

    .sb-progress-option__button {
        background-color: @white-background;
    }

}

.sb-activity-progress__panel {
    background-color: @white-background;
}
// on an disabled button any icon should be grayed out like the text
//
.sb-activity-progress__item--disabled {
    color: rgba(0, 0, 0, 0.38);
    i {
        color: rgba(0, 0, 0, 0.38);
    }

    // hard overwrite non-disabled button styling on hover
    // to make it look like a disabled item
    //
    .md-button:not([disabled]):hover {
        background-color: @transparent-background !important;
    }
}

.sb-activity-progress__menu-header {
    padding: @padding-none @padding-normal @padding-small @padding-normal;
    background-color: @white-background;

    .sb-activity-progress__menu-header__title {
        .small-bold-font();
    }
    .sb-activity-progress__menu-header__message {
        .small-thin-font();
    }
}
