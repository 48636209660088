@import "../../../../../styles/global";

@sb-deliverable-notes-photos-container-height: 160px;
//⬇️ This has to be aligned with vm.attachmentsVisible in the controller
@sb-deliverable-notes-photos-per-row: 5;

.sb-attachment-gallery {
    transition: 400ms;
    background-color: @sablono-grey-50;
    display: flex;
    height: 100%;
}

.sb-attachment-gallery__arrow {
    flex: 0 0 50px;
    display: flex;
    align-items: center;
    justify-content: center;
    outline-width: 0;
    position: relative;
    font-size: 40px;
    color: @sablono-grey-300;
}

.sb-attachment-gallery__arrow--active {
    color: black;
    cursor: pointer;
}

.sb-attachment-gallery__photos {
    flex: 1;
    width: auto;
    padding: @padding-small @padding-none;
    overflow: hidden;
    display: flex;
    .sb-file-card__new-card {
        min-height: 110px;
        border-width:2px;
        outline-width: 0;
    }
}

.sb-attachment-gallery__add-card {
    height: 100%;
}

.sb-attachment-gallery__scroll {
    position: relative;
    flex: 1;
    display: flex;
    transition: 400ms;
}

.sb-attachment-gallery__attachment-container {
    position: relative;

    .mdi-close-circle {
        position: absolute;
        right: 4px;
        top: 4px;
        cursor: pointer;
        background: @white-background;
        border-radius:50%;
        padding-top:1px;
        &:hover{
            color: @riemi-red-500;
        }
    }
}

.sb-attachment-gallery__attachment {
    position: absolute;
    @photo-padding: 4px;
    top: @photo-padding;
    bottom: @photo-padding;
    left: @photo-padding;
    right: @photo-padding;
}

.sb-attachment-gallery__attachment.sb-attachment-gallery__attachment--loading {
    position: relative;
}

.sb-attachment-gallery__attachment--image {
    background-size: contain;
    background-color: @white-background;
    background-position: 50% 50%;
    background-repeat: no-repeat;
    outline-width: 0;
}

.sb-attachment-gallery__attachment--selectable {
    cursor: pointer;
    .sb-attachment-gallery__attachment--document:hover {
        color: @sablono-green-700;
    }
}

.sb-attachment-gallery__attachment--document {
    .flex-column-center();
    color: @primary-font-color;
    padding: @padding-smaller;
    .mdi:before {
        font-size: 65px;
    }
}

.sb-attachment-gallery__attachment-title {
    .smaller-font();
    line-break: anywhere; // because a file name usually has no whitespace chars.
    margin: @padding-smaller;
    overflow: hidden;
}
