
// Add new card styles

.sb-add-new-card {
    @main-color: @sablono-grey-500;
    box-sizing: border-box;
    .sb-add-new-card-content {
        width: 100%;
        height: 100%;
        padding: 0 @padding-normal;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        box-sizing: border-box;
        cursor: pointer;
        border: 5px dashed @sablono-grey-300;
        color: @sablono-grey-500;
        text-shadow: 1px 2px 2px @sablono-grey-50;
        font-weight: @font-weight-light;
        font-size: 28px;
        text-align: center;
        transition: all 0.2s ease;
        &:focus, &:hover {
            outline: none;
            border: 5px solid @sablono-grey-300;
            background: @sablono-grey-50;
        }
        .mdi {
            color: @sablono-grey-400;
            font-size: 120px;
            text-shadow: 1px 2px 2px @sablono-grey-50, 0 0 0 @sablono-grey-900, 1px 2px 2px @sablono-grey-50

        }
        .md-primary {
            color: @main-color;
        }
    }

    &:focus {
        outline: none;
    }
    &.sb-add-new-card-mini {
        margin: 0;
        .sb-add-new-card-content {
            .flex-row-center-start;
            border-width: 1px;
            font-size: @font-size-small;
            .ellipsed-text;
        }
        .mdi {
            font-size: 24px;
            background: @sablono-grey-200;
            padding: @padding-small;
            text-shadow: none;
            border-radius: 50%;
            margin-right: @margin-smaller;
            transition: all 0.3s ease-in-out;
        }
        &:hover {
            .mdi {

                background: @white-background;
                color: @sablono-grey-500;
            }
        }
    }
}
