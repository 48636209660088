@no-data-card-width: 520px;
@no-data-card-min-width: 400px;
/*
 * Set up header color dependant on  specified section
 */
.sb-no-data-card-header,h2.sb-no-data-card-header {
    color:@primary-font-color;
    font-weight:@font-weight-normal;
    font-size:24px;
    margin-left:0;
}

.sb-no-data-card__thumb-icon{
    color: @primary-font-color;
    font-weight:@font-weight-light;
    font-size:24px;
    margin-left:auto;
    margin-right: auto;
}

.sb-no-data-card-message, p.sb-no-data-card-message {
    color: @primary-font-color;
    margin:@margin-small 0;
    img{
        width:100%;
        max-width: 100%;
    }
}
.sb-no-data-card-actions{
    .flex-row;
    align-items: center;
    justify-content: flex-end;
    gap: 8px;
    .md-button{
        color: @primary-font-color;
    }
}
.sb-no-data-card {
    max-width: @no-data-card-width;
    min-width: @no-data-card-min-width;
    background-color: @white-background;
    justify-content: space-between;
    text-align:left;
    padding: @padding-small @padding-normal;
    align-self: center;
    min-height: 100%;
    margin:0 auto;
}
sb-no-data{
    margin:@margin-small auto;
}

.sb-no-data-card-header-wrapper {
    .flex-row();
    justify-content: space-between;
}
