/**
 * Animation
 */

.animate-fade-in {}

.animate-fade-in.ng-move,
.animate-fade-in.ng-enter,
.animate-fade-in.ng-leave {
    -webkit-transition: opacity linear 0.2s;
    transition: opacity linear 0.2s;
}

.animate-fade-in.ng-leave.ng-leave-active,
.animate-fade-in.ng-enter {
    opacity: 0;
}

.animate-fade-in.ng-leave,
.animate-fade-in.ng-enter.ng-enter-active {
    opacity: 1;
}

@keyframes animate-pop-once {
    from {
        transform: scale(0,0);
        visibility: visible;
    }

    to {
        transform: scale(1,1);
    }
}

.animate-pop-once {
    animation-name: animate-pop-once;
    animation-duration: 0.6s;
}
.animate-pop {}

.animate-pop.ng-move,
.animate-pop.ng-enter,
.animate-pop.ng-leave {
    transition: all 0.3s ease-in-out;
    &.animate-long{
        transition: all 0.6s ease-in-out;
    }
    &.animate-delay{
        transition-delay: 3.3s;
    }
}

.animate-pop.ng-leave.ng-leave-active,
.animate-pop.ng-enter {
    transform: scale(0,0);
}

.animate-pop.ng-leave,
.animate-pop.ng-enter.ng-enter-active {
    transform: scale(1,1);
}

.animate-fade-in-long {}

.animate-fade-in-long.ng-move,
.animate-fade-in-long.ng-enter,
.animate-fade-in-long.ng-leave {
    -webkit-transition: opacity linear 0.5s;
    transition: opacity linear 0.5s;
    &.animate-long{
        transition: all 0.6s ease-in-out;
    }
    &.animate-delay{
        transition-delay: 1s;
    }
}

.animate-fade-in-long.ng-leave.ng-leave-active,
.animate-fade-in-long.ng-enter {
    opacity: 0;
}

.animate-fade-in-long.ng-leave,
.animate-fade-in-long.ng-enter.ng-enter-active {
    opacity: 1;
}

//fadeInOutDown animation
.fade-in-out-down.ng-hide-add,
.fade-in-out-down.ng-hide-remove {
    /* remember to add this */
    display: block !important;
    transition: none !important;
}

.fade-in-out-down.ng-hide-remove {
    animation: fadeInDown 400ms cubic-bezier(.25, .25, 0, 1);
}

.fade-in-out-down.ng-hide-add {
    animation: fadeOutDown 400ms cubic-bezier(.25, .25, 0, 1);
}

//flipInOutY animation
.flip-in-out-y.ng-hide-add,
.flip-in-out-y.ng-hide-remove {
    /* remember to add this */
    display: block !important;
    transition: none !important;
}

.flip-in-out-y.ng-hide-remove {
    animation: flipInY 800ms cubic-bezier(.25, .25, 0, 1);
}

.flip-in-out-y.ng-hide-add {
    animation: flipOutY 800ms cubic-bezier(.25, .25, 0, 1);
}

//flipInOutX animation
.flip-in-out-x.ng-hide-add,
.flip-in-out-x.ng-hide-remove {
    /* remember to add this */
    display: block !important;
    transition: none !important;
}

.flip-in-out-x.ng-hide-remove {
    animation: flipInX 800ms cubic-bezier(.25, .25, 0, 1);
}

.flip-in-out-x.ng-hide-add {
    animation: flipOutX 800ms cubic-bezier(.25, .25, 0, 1);
}

//fadeInOut animation
.fade-in-out.ng-hide-add,
.fade-in-out.ng-hide-remove {
    /* remember to add this */
}

.fade-in-out.ng-hide-remove {
    animation: fadeIn 400ms;
}

.fade-in-out.ng-hide-add {
    animation: fadeOut 400ms;
}

//rotateInOut animation
.rotate-in-out.ng-hide-add,
.rotate-in-out.ng-hide-remove {
    /* remember to add this */
    display: block !important;
    transition: none !important;
}

.rotate-in-out.ng-hide-remove {
    animation: rotateIn 400ms cubic-bezier(.25, .25, 0, 1);
}

.rotate-in-out.ng-hide-add {
    animation: rotateOut 400ms cubic-bezier(.25, .25, 0, 1);
}

//material ng-hide override
.ng-hide md-progress-circular[md-mode=indeterminate] .md-spinner-wrapper {
    -webkit-animation: outer-rotate 2.91667s linear infinite;
    animation: outer-rotate 2.91667s linear infinite;
}

.ng-hide md-progress-circular[md-mode=indeterminate] .md-spinner-wrapper .md-inner {
    -webkit-animation: sporadic-rotate 5.25s cubic-bezier(0.35, 0, 0.25, 1) infinite;
    animation: sporadic-rotate 5.25s cubic-bezier(0.35, 0, 0.25, 1) infinite;
}

.ng-hide md-progress-circular[md-mode=indeterminate] .md-spinner-wrapper .md-inner .md-left .md-half-circle,
md-progress-circular[md-mode=indeterminate] .md-spinner-wrapper .md-inner .md-right .md-half-circle {
    -webkit-animation-iteration-count: infinite;
    animation-iteration-count: infinite;
    -webkit-animation-duration: 1.3125s;
    animation-duration: 1.3125s;
    -webkit-animation-timing-function: cubic-bezier(0.35, 0, 0.25, 1);
    animation-timing-function: cubic-bezier(0.35, 0, 0.25, 1);
}

.ng-hide md-progress-circular[md-mode=indeterminate] .md-spinner-wrapper .md-inner .md-left .md-half-circle {
    -webkit-animation-name: left-wobble;
    animation-name: left-wobble;
}

.ng-hide md-progress-circular[md-mode=indeterminate] .md-spinner-wrapper .md-inner .md-right .md-half-circle {
    -webkit-animation-name: right-wobble;
    animation-name: right-wobble;
}
