@import "../../../../../styles/global";
@import "../../../../../styles/colorpalette";

.sb-lane__container {
    position: relative;
    background: @white-background;
    .sb-lean-board__deliverable-card,
    .sb-lane {
        background: @white-background;
    }

    .sb-deliverable-card__mini-actions {
        right: 0;
        top: 10px;
        .flex-row();
    }
}

.sb-lane {
    width: 100%;
    margin-left: @sb-leanboard-first-column-width;
    position: absolute;
    top: 0;
    height: 100%;
}

.sb-lane__row {
    .flex-row;
    position: relative;
    z-index: 1;
    width: 100%;
}

.sb-lane__bg {
    height: 100%;
    width: 100%;
    display: flex;
    position: absolute;
    z-index: 0;
}

.sb-lane__column-day {
    flex: 1;
    border-right: 1px solid @leanboard-borders-colors;
    border-bottom: 1px solid @leanboard-borders-colors;
    &.sb-lane__column-day--non-working {
        background: @leanboard-non-working-day-color;
    }
    &.sb-lane__column-day--today {
        background: @leanboard-today-color;
    }
}

.sb-lane__deliverable__button {
    background-color: transparent;
    border-radius: 4px;
    font-size: 16px;
    border: none;
    outline: none;
    &:hover {
        background: @sablono-grey-300;
    }
    i {
        transition: color 0.2s ease-out;
        color: @primary-font-color;
    }
}

.sb-lane__deliverable__button--warning {
    i {
        color: @activity-scheduling-behind;
    }
}

// overwrites for material design styles for progress bar indicator
//
.sb-lane {
    md-progress-linear {
        z-index: @z-indexes[foreground];
        position: absolute;
    }

    .md-progress-linear.md-default-theme .md-container, md-progress-linear .md-container {
        background-color: @sablono-green-button-hover !important;
    }

    md-progress-linear .md-container .md-bar {
        background-color: @sablono-green-main !important;
    }
}

@sb-activity-card-highlight-background: @sablono-green-main;
@sb-activity-card-highlight-border: @sablono-green-main;
@sb-activity-card-highlight-color: @sablono-grey-800;

.sb-activity-card--selected .sb-activity-card{
    box-shadow: 0 8px 8px 0 rgba(0, 0, 0, 0.4);
    background: @sb-activity-card-highlight-background !important;
    border-color: @sb-activity-card-highlight-border !important;
    color: @sb-activity-card-highlight-color;

    .sb-activity-card__info--details {
        color: @sb-activity-card-highlight-color;
    }
    .sb-activity-card__info--topologicalindex {
        color: @sb-activity-card-highlight-color;
    }
    .sb-activity-card__info--details {
        color: @sb-activity-card-highlight-color;
    }
}
