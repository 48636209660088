@sb-deliverable-overlay-max-width: 1000px;
@sb-deliverable-overlay-width: 95%;
md-sidenav.sb-deliverable-overlay {
    height: 100%;
    width: @sb-deliverable-overlay-width;
    max-width: @sb-deliverable-overlay-max-width;
    box-shadow: none;
    background-color: transparent;
}
.sb-deliverable-overlay-content {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    height: 100%;
    margin-left: 20px;
    width: calc(~'100% - 20px');
    box-sizing: border-box;
    border-top: 1px solid @sablono-grey-200;
}
.sb-deliverable-overlay-close {
    background-color: @sablono-grey-400;
    display: flex;
    align-items: center;
    justify-content: center;
    color: white;
    padding: 5px;
    position: absolute;
    top: 50%;
    transform: rotateZ(-90deg);
    z-index: 1;
    cursor: pointer;
    transform-origin: 0 0;
    transition: color 400ms;
    &:hover {
        background-color: @sablono-grey-500;
    }
}
.sb-deliverable-overlay-tabs {
    flex: 1;
    md-tab-content > div{
        height: 100%;
        background-color: @sablono-grey-50;
        overflow: auto;
    }
}

md-sidenav.sb-deliverable-overlay__narrow {
    container-type: inline-size;
    width: 450px;
}

//Mobile styling
@media only screen and (max-width: 960px) {
    .sb-deliverable-overlay{
        padding-left:@padding-smaller;
        .sb-deliverable-overlay-close{
            padding: @padding-small;
        }
    }
}

.sb-filter-by-team {
    .flex-row;
    flex-grow: 1;
    justify-content: flex-end;

    right:@margin-normal;

    .sb-team-select__input{
        height:30px!important;
    }
    .label {
        .flex-column-center;
        margin-right: 5px;
    }
}
