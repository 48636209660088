@import "./colorpalette";

//Bugfix: Material design doesn't visual display that the radio button is disabled
// Last checked: 20/10/2016
md-radio-button[disabled] .md-on,
[disabled] md-radio-button .md-on{
    background-color: @sablono-grey-400;
}

// Bugfix Material update: not showing error login messages
// Last checked: 20/10/2016
md-input-container {
    .md-input-message-animation.sb-show-error {
        opacity: 1!important;
        margin-top: 0 !important;
    }
}

// Bugfix: asterisk red ONLY when it's not correct
md-input-container.md-input-invalid.md-default-theme label.md-required:after,
md-input-container.md-input-invalid label.md-required:after {
    color: @riemi-red;
}
md-input-container.md-default-theme label.md-required:after, md-input-container label.md-required:after {
    color: @primary-font-color;
}
// WARN: I introduced this global style because there is no possibility
//      (for me) to apply a situation specific not fount (see auto-complete-docu)
//
//      But as we want to have rich not found messages -> we need more default space.
//
.md-virtual-repeat-container.md-not-found {
    height: 100px;
}

// BUGFIX: https://leantesting.com/en/projects/01-appsablonocom/6021/bug/711
.select-option-icon.mdi {
    height: 18px;
}

md-ink-bar {
    /* Change the selected tabs color globally */
    color: @primary-font-color !important;
    background: @sablono-green-500!important;
}


//Adding multiline functionality to md-tooltips (MC | 22/08/2016)
md-tooltip.sb-multiline-tooltip{
  width: auto;
  max-width: 250px;
  height: auto;
  white-space: pre-line;
  line-height: 1.4em;
  padding: @padding-small;
}

// Remove border on focus
md-radio-group.md-default-theme:focus:not(:empty) {
    border-color: transparent !important;
}

// Remove ugly transition on toolbars
.md-toolbar-tools .md-button, .md-toolbar-tools .md-button.md-icon-button md-icon{
    transition: none;
}

// Change appearance of number fields for mozilla
md-input-container {
    input[type=number] {
        -moz-appearance: textfield;
    }
}

// https://github.com/angular/material/issues/6214
//
//<div class="md-errors-spacer"></div>
// This has been added recently and pad the fields of one line every input field.
// This causes the text to be misaligned, as well as make overly big forms spacing
// when not using validation and/or without errors.
//
// use
// <md-input-container class="hide-error-msg">
//      <label>Title</label>
//      <input ng-model="">
// </md-input-container>
// to remove this effect.
md-input-container.hide-error-msg .md-errors-spacer:empty {
    display: none;
}

md-content {

    // -webkit-overflow-scrolling is an experimental feature that causes trouble on the our dashboard
    //  only on a few devices
    //      -> iPad Pro
    //      -> Android Touch TV
    //
    -webkit-overflow-scrolling: auto !important;
}
