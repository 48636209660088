@import "../../../../../styles/sb-common/tag";

@sb-tags-max-width: 170px;

.sb-tags {
    display: inline-flex;
    flex-wrap: wrap;
    justify-content: flex-end;
    max-width: @sb-tags-max-width;
    overflow: visible;
}
