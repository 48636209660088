@import "directives/wbs_editor";
.md-fab-top-right-relative {
    top: 20px;
    right: 20px;
    position: relative;
    float: right;
}

//background animation for editor mode toggle
// TODO 14/1/16 BA: move this to proper place
md-toolbar.md-accent.sb-subbar {
    background-color: @sablono-green-500;
    h2{
        color: @light-text-color;
    }
}

.toggle-edit-mode,
.auto-generate-codes{
    transition: all 400ms !important;
}
// @todo BA 19/1/16: i just added some pixels to correct one bug. We should document and fix this numbers
.auto-generate-codes{
    bottom: 90px !important;
    right: (20px + (56px - 40px) / 2) !important;
}

.auto-generate-codes.ng-hide-add,
.auto-generate-codes.ng-hide-remove {
    /* remember to add this */
    display:block !important;
    transition: none !important;
}

.auto-generate-codes.ng-hide-remove{
    animation: zoomIn 200ms cubic-bezier(.25,.25,0,1);
}
.auto-generate-codes.ng-hide-add{
    animation: zoomOut 400ms cubic-bezier(.25,.25,0,1);
}

.editor{
    height:100%;
    overflow: hidden;
}

.editor-toolbar {
    display: flex;
    backface-visibility: hidden;
}

.editor-toolbar.ng-hide-add,
.editor-toolbar.ng-hide-remove{
    opacity: 1;
    display: block !important;
}

.editor-toolbar.ng-hide-add{
    position: absolute;
    top: 64px;
    left: 0;
    right: 0;
}

@editorAnimationDuration: 400ms;

.editor-toolbar.ng-hide-add{
    animation: @editorAnimationDuration rotate-hide ease;
}

.editor-toolbar.ng-hide-remove{
    animation: @editorAnimationDuration rotate-show ease;
}

@keyframes rotate-show{
    0%{
        transform: rotateX(-180deg);
    }
    100%{
        transform: rotateX(0deg);
    }
}

@keyframes rotate-hide{
    0%{
        transform: rotateX(0deg);
        display: none;
    }
    100%{
        transform: rotateX(180deg);
    }
}
