@import "./../../../styles/layout";
@import "./printReport/print_report";
@import "./resetActivity/reset_activity";
@import "./changeDateTime/change-datetime";
@import "./chooseProjectSource/choose-project-source";
@import "./editOrAddTeam/edit_or_add_team";
@import "templateSelector/template_selector";
@import "./assignStructure/assign_structure";
@import "./multiSelectionList/multi_selection_list";
@import "./createNewItems/create_new_items";
@import "./createActivity/create_activity";
@import "issueEditor/issue_editor";
@import "createIssueComment/create_issue_comment";
@import "./confirmDestructiveActionDialog/confirm_destructive_action";
@import "./timeRangeSelector/time-range-selector";
@import "./eula/eula";

@dialog-min-width: 380px;
@dialog-min-height: 180px;
@dialog-max-width: 600px;
@dialog-max-width-lg: 470px;

.sb-dialog-busy {
    min-width: @dialog-min-width;
    text-align: center;
    &-content {
        padding: 0;
        margin-left: 0;
        margin-right: 0;
        margin-top: 0;
        md-progress-circular {
            margin: 0 auto;
            padding-bottom: @padding-normal;
        }
    }
    &-loading {
        margin: @margin-normal 0 @margin-normal;
        float: left;
        width: 100%;
        .sb-loading {
            position: relative;
        }
    }
}

.sb-dialog-alert-content {
    padding: 0;
}

.md-checked .sb-dialog-select-icon {
    color: @sablono-green-main;
}

.sb-dialog-select-options {
    .flex-column();
    color: @sablono-grey-700;
    margin-top: @margin-normal;
    .sb-dialog-select-option {
        .flex-row-center();
        h4 {
            margin: 4px 0;
            font-size: @font-size-large;
            font-weight: normal;
        }
        p {
            margin: 0;
            font-size: @font-size-normal;
        }
    }
    .sb-dialog-select-icon {
        font-size: 36px;
        padding: 6px;
        margin-right: @margin-small;
    }
    .sb-dialog-select-second-options {
        padding-right: @padding-normal;
        h4 {
            font-weight: bold;
            font-size: @font-size-normal;
        }
    }
}

.sb-dialog {
    min-width: @dialog-min-width;
    &.confirm-dialog {
        max-width: @dialog-max-width;
    }
    &.alert-dialog {
        max-width: @dialog-max-width;
    }
    &.error-dialog {
        max-width: @dialog-max-width;
    }
    &.sb-project-creation-dialog {
        max-width: @dialog-max-width-lg;
    }
    .sb-dialog-header {
        padding: 0;
        margin: 0;
        .flex-column;
        justify-content: center;
        h2 {
            margin: 0;
            font-weight: @font-weight-normal;
            padding: @padding-normal;
            font-size: @font-size-larger;
        }
    }
    .sb-dialog-content {
        min-height: @dialog-min-height;
    }
    // center close button on header
    .sb-dialog-header-close {
        position: relative;
        &:before {
            position: absolute;
            top: -2px;
            left: -2px;
        }
    }
    md-dialog-content {
        padding: @padding-smaller @padding-normal;
        margin: @margin-normal 0;
    }
    md-toolbar {
        h1, h2, h3, h4, h5 {
            margin: 0;
        }
    }
}

.sb-dialog {
    md-dialog-actions {
        .md-button:focus {
            .sb-whiteframe-glow-z1;
        }
    }
}

/**
  With this mixin you can customize an angular material dialog
  with any background/foreground color
 */
.sb-dialog-color(@color, @text-color) {
    h2 {
        background: @color;
        color: @text-color;
    }
    md-toolbar {
        background: @color;
    }
    .md-accent, .md-raised {
        background: @color;
        color: @text-color;
    }
    .md-button.md-datepicker-button,
    .md-datepicker-calendar-icon,
    .md-datepicker-triangle-button.md-button.md-icon-button {
        color: @color;
        background: @white-background;
        &:hover, &:focus {
            background: @white-background !important;
            md-icon {
                color: darken(@color, 10%);
            }
        }
    }
    .md-datepicker-open .md-datepicker-calendar-icon,
    .md-default-theme .md-datepicker-open .md-datepicker-calendar-icon {
        fill: @color;
    }

    // center close button on header
    .sb-dialog-header-close {
        &:before {
            color: @text-color;
        }
    }
}

.sb-dialog {
    .sb-dialog-color(@sablono-green-main, @sablono-green-text-color);
}

.sb-dialog.sb-delete {
    .sb-dialog-color(@delete, @delete-text-color);
}

.sb-dialog.sb-custom-dates-detected {
    max-width: 640px;
}

// HACK around the inconsistent and weird margin and padding of our dialogs
//
md-dialog-content.sb-dialog-content--no-margin {
    margin: @margin-none;
    padding: @padding-normal;

    .sb-dialog-select-options {
        margin-top: @margin-none;
    }

    md-input-container:last-child {
        margin-bottom: @margin-none;
    }
}
