.sb-create-note-template-dialog{
    md-input-container{
        margin:@margin-small 0;
        /* IE only style */
        @media all and (-ms-high-contrast:none), (-ms-high-contrast: active){
            margin: 40px 0;
        }
    }

}

sb-note-template-edit-card {
    display: block;
    background-color: @white-background;
    margin: 14px 0;
    .sb-edit-card {
        margin: 0px;

        .edit-card {
            display: flex;
            flex: 1;
            padding: @padding-small @padding-normal 0;
            &-left{
                flex: 1;
            }
            &-right{
                flex: 3;
                padding: 0 0 0 @padding-big;
                justify-content: flex-start;

                md-input-container {
                    .sb-edit-card-text-area {
                        height: 100px!important;
                    }
                }
            }
        }
        .edit-form {
            display: flex;
            flex-wrap: wrap;
            .md-select-value {
                flex-grow: 1;
            }
        }
        .md-actions {
            padding: 0 8px;
        }
        &-text-area {
            max-height: 115px;
            overflow: auto;
        }
        &-container-text-area{
            width:100%;
        }
        .edit-card-loading-indication{
            position: absolute;
            right: 32px;
            bottom: 20px;
            color: @sablono-green-700;
            padding: 5px 0px 5px 10px;
            font-size: @font-size-small;
        }
    }

    // make input container stay same width on parent width change
    //
    md-input-container {
        &.input-value {
            &-medium {
                flex-grow: 1;
                flex-shrink: 0;
                flex-basis: content;
            }
        }
    }
}
