@import "../../../../../styles/global.less";

@sb-dialog-pick-project-member-max-width: 600px;

.sb-pick-project-member-dialog {
    max-width: @sb-dialog-pick-project-member-max-width;
}

.sb-dialog__action-buttons {
    padding: 0 @padding-normal @padding-small;
}
