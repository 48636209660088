@import "../../../../../styles/sb-common/activity";
@import "../../../../../styles/global";

sb-activity-item{

    .sb-activity-arrow(100%, 70px, 50%, 50%, 25px, @sablono-green-main, @sablono-green-main);

    margin: 0 5% 30px 5%;
    max-width: 630px;
    .task, .state{

        sb-multiline-edit{
            overflow: hidden;
            margin-left: 30px;
            margin-right: 5px;
            width: 100%;
            height: 100%;
            text-align: center;
        }
        .activity-edit-icon{
            position: absolute;
            top:10px;
            right: -30px;
            z-index: 1;
            .mdi{
                color: @white-background !important;
            }
        }
    }
}
