sb-properties-source-icon {
    display: inline;
    position: relative;
}
.sb-properties-source-icon__inherit-background-color {
    background-color: inherit;
    .sb-properties-source-icon__secondary-icon--background {
        background-color: inherit;
    }
}
.sb-properties-source-icon__secondary-icon {
    position: absolute;
    font-size: 0.85em;
    bottom: -0.1em;
    right: -0.3em;
}
.sb-properties-source-icon__secondary-icon--background {
    position: absolute;
    width: 0.3em;
    height: 0.8em;
    bottom: -0.07em;
    right: 0.05em;
    background-color: @white-background;
}
