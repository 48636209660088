@import "../../../../../styles/global";
@import "../../../../../styles/colorpalette";
@import "../../../../../styles/fonts";

.sb-watcher-counter {
    background: @sablono-green-main;
    color: @white-background;
    position: absolute;
    top: 0;
    right: 0;
    padding: @padding-smaller @padding-normal;
    font-weight: @font-weight-bold;
    z-index: 10000;
}
