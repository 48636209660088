@import "./sbFilterPanel/filter_panel";
@import "../../../../../styles/global";

@sb-deliverables-list-max-width: 1400px;
@sb-filter-button-size: 40px;

.sb-view-filters {
    font-size: @font-size-small;
    text-align: center;
    padding-top:@padding-small;
    span{
        &.sb-filter-label{
            display: block;
            white-space:nowrap;
        }
    }
}
.sb-deliverables-filters-container{
    display: flex;
    width: 95%;
    max-width: @sb-deliverables-list-max-width;
    margin: 0 auto @margin-small;
    flex-direction: row;
    align-items: flex-end;
    justify-content: flex-end;
    .sb-bottom-shadow;
}
.sb-filter-chips {
    display: inline-flex;
    align-items: center;
    justify-content: flex-start;
    flex-wrap: wrap;
    width: 100%;
    min-width: 0;
    md-chips{
        width: inherit;
    }
    md-chips .md-chips{
        box-shadow: none;
    }
    .md-chip-remove{
        margin-left: 10px;
    }
    .md-chip-remove md-icon{
        min-width: 18px;
        min-height: 18px;
        border-radius: 50%;
        padding: 1px;
        &:hover{
            background: @sablono-grey-200;
        }
    }
    .md-chip-content{
        font-size: @font-size-smaller;
        padding-right: @padding-small!important;
    }
}
.sb-filter-actions {
    min-width: inherit;
    .flex-row-center-end();
}
.md-button.sb-filter-button{
    background-color: @sablono-green-main;
    width:@sb-filter-button-size;
    height: @sb-filter-button-size;
    min-width:@sb-filter-button-size;
    min-height: @sb-filter-button-size;
    border-radius: 50%;
    transition: background-color 0.5s ease-in-out;
    &:active,
    &:hover{
        background-color: @sablono-green-main;

    }
    .mdi{
        color:@light-text-color;
    }
}

.md-button.sb-filter-remove-button{
    width:@sb-filter-button-size;
    height: @sb-filter-button-size;
    min-width:@sb-filter-button-size;
    min-height: @sb-filter-button-size;
    background-color: @sablono-grey-400;
    border-radius: 50%;
    transition: background-color 0.5s ease-in-out;
    .mdi{
        color:@light-text-color;
    }
}

// To limit the width as german was overflowing outside the screen
md-tooltip.sb-deliverables-filters-tooltip{
    width: 90px;
    z-index:0;
}
