@import "../../../../styles/global";

.sb-delete-project-dialog {
    width: 500px;
    max-width: 80%;
}

.sb-delete-project-dialog-content {
    min-height: 50px;
    position: relative;

    .sb-delete {
        font-size: @font-size-normal;
        position: absolute;
        top: 8px;
        right: 16px;
    }
}

.sb-delete-dialog__show-error {
    color: red;
}

.sb-delete-dialog__center-content {
    text-align: center;
}

.sb-delete-dialog__input-field-width {
    width: 300px;
    margin-left: auto;
    margin-right: auto;
}
