@sb-table-footer-height: 48px;
@sb-table-content-cell-height: 40px;
@sb-table-content-cell-width: 100px;
@sb-table-header-height: 56px;


sb-table{
    position: absolute;
    top: 0;
    right:0;
    left:0;
    bottom:0;
    display: flex;
    justify-content: center;
    align-items: flex-start;
    flex-direction:row;
    overflow: hidden;
    height: calc(100% ~"-"@sb-table-footer-height);
    background: @sablono-grey-200;
}
.sb-table-container{
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    overflow: hidden;
    flex-wrap: nowrap;
    height: 100%;
    width: 100%;
}

.sb-table-header{
    height: @sb-table-header-height;
 /*   .flex-row;
    flex-shrink: 0;
    background: @sablono-grey-200;
    color: @sablono-grey-600;
    font-weight: @font-weight-normal;
    .sb-table-cell{
        .flex-row-center-start;
        width: 100%;
        height: @sb-table-header-height - 4;
    }*/
}
.sb-table-body{
    overflow-y: auto;
    flex-grow: 1;
    background: @white-background;
    height: calc(100% ~"-"@sb-table-footer-height);
    min-height: calc(100% ~"-"@sb-table-footer-height);
    table{
        border-collapse: collapse;
        width: 100%;
    }
    .sb-table-row{
        width: 100%;
        max-width: 100%;
        display: flex;
        flex-direction: row;
        background: @white-background;
    }
}
.sb-table-cell{
    border-collapse: collapse;
    text-align: left;
    .flex-row-center-start;
    flex:1;
    //max-width: @sb-table-content-cell-width;
    width: auto;
    min-width: 100px;
    height: @sb-table-content-cell-height;
    border-bottom:1px solid @sablono-grey-300;
    align-items: center;
    justify-content: flex-start;
    padding: 2px @padding-small;
    span{
        .ellipsed-text;
    }

}
.sb-table-cell-rows{
    .flex-column;
}
.sb-table {
    width:100%;
    min-width:100%;
    .not-selectable;
}
// Hover effect, to help the user follow the line
.sb-table-body tbody tr:hover, .sb-table tbody tr:focus{
    background: @sablono-grey-50;
}
.sb-table-footer{
    flex-shrink: 0;
    display: inline-flex;
    width: 100%;
    align-items: center;
    padding: @padding-normal;
    box-sizing: border-box;
    height: @sb-table-footer-height;
    font-weight: @font-weight-light;
    font-size: @font-size-normal;
    background: @sablono-grey-200;
    // overwrite default disabled style because actually it is "selected" style
    // only inside footer
    .md-button.md-raised[disabled]{
        background-color: @sablono-grey-500;
        span{
            color: @light-text-color;
        }
    }
    .sb-table-page-active {
        background-color: @sablono-grey-300;
    }
    .md-button{
         min-width: 1em;
     }



}
