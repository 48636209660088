
@sb-site-diary-team-row-max-height: 35px;
@sb-site-diary-team-row-label-max-width: 180px;
@sb-site-diary-team-name-max-width: 160px;
@sb-site-diary-team-input-container-max-width: 80px;

.sb-diary-teams {
    overflow: auto;

    .md-input:disabled {

        // !important required to overwrite material styles
        color: initial !important;
    }
}

.site_diary__field-set--teams {
   padding-left: @padding-big;
}

.sb-diary-teams__team-row {
    .flex-row();
    max-height: @sb-site-diary-team-row-max-height;
}

.sb-diary-teams__team-name-and-icon {
    .flex-row();
    align-items: center;
    min-width: @sb-site-diary-team-row-label-max-width;
    max-width: @sb-site-diary-team-row-label-max-width;
    margin-right: @margin-normal;
    max-height: @sb-site-diary-team-row-max-height;
}

.sb-diary-teams__team-icon {
    .larger-thin-font();
    margin-right: @margin-small;
}

.sb-diary-teams__team-name {
    .ellipsed-text();
    max-width: @sb-site-diary-team-name-max-width;
}

.sb-diary-teams__input-container {
    max-width: @sb-site-diary-team-input-container-max-width;
    min-width: @sb-site-diary-team-input-container-max-width;
    margin: @margin-none;
    padding:@margin-none @margin-small @margin-none @margin-none ;
}

.sb-diary-teams__input-container--last {
    position: relative;
}

.sb-diary-teams__input--readonly {
    pointer-events: none;
    .normal-thin-font();

    // !important required to overwrite material styles
    border: none !important;
}

.sb-diary-teams__errors {
    .font(smaller);
    .flex-row();
    align-items: center;
    color: @riemi-red-A400;
    margin-left: @margin-normal;
}

.sb-diary-teams__total-row {
    .flex-row();
    margin-top: @margin-small;
}

.sb-diary-teams__total-row-label {
    min-width: @sb-site-diary-team-row-label-max-width;
    max-width: @sb-site-diary-team-row-label-max-width;
    position: relative;
    top: @margin-smaller + @margin-tiny;
    margin-right: @margin-normal;
}
