@import "../../../../../styles/colorpalette";

.sb-traffic-light-selector__label {
    color: @sablono-grey-600;
}

.sb-traffic-light-selector__label-not-selected {
    text-decoration: underline;
    color: @riemi-red;
}

.sb-traffic-light-selector__select-type {
    display: flex;
    height: 100px;
    align-items: center;
    justify-content: space-around ;
}

.sb-traffic-light-selector__type{
    opacity: 0.5;
    border: 1px solid;
    transition: all ease-in-out 0.4s;
    font-size: 32px;
    padding: 12px @padding-normal;
    outline-width: 0;
    border-radius: @border-radius-small;
    cursor: pointer;
}

.sb-note-selected(@color){
    background-color: @color;
    color: @light-text-color;
    opacity: 1;
}

.sb-traffic-light-selector__type--selected {

    &.sb-claim {
        .sb-note-selected(@note-color-quality-issue);
    }
    &.sb-info {
        .sb-note-selected(@note-color-info);
    }
    &.sb-obstruction {
        .sb-note-selected(@note-color-obstruction);
    }

    &.sb-todo {
        .sb-todo.selected;
    }
    &.sb-in-progress {
        .sb-in-progress.selected;
    }
    &.sb-done {
        .sb-done.selected;
    }
}
