@import "../../../../../styles/layout";
@import "../../../../../styles/colorpalette";
@import "../../../../../styles/global";

@sb-visualization-configurator-width: 440px;
@structure-node-chip-max-width: 250px;
@structure-node-chips-list-max-height: 165px;

.sb-visualization-creation-page {
    .flex-column();
    flex: 1 0 100%;

    .md-chip-input-container {
        width: 100%;
    }
}

.sb-stepper__header {}

.sb-stepper__content {
    .flex-row();
    align-content: stretch;

    flex: 1;
}

.sb-visualization-configurator {
    max-width: @sb-visualization-configurator-width;
    padding: @padding-normal;
    overflow: auto;
    flex: 1;
}

.sb-visualization-configurator__step-content {
    .flex-column();
    justify-content: space-between;
    box-sizing: border-box;
}

.sb-configurator-preview-divider {
    .sb-separator();
}

.sb-visualization-title__main-title,
.sb-visualization-title__sub-title {
    width: 100%;
    margin: 17px 5px;
}

.sb-visualization-label {
    padding: @padding-normal;
    margin-bottom: @margin-small;
    box-sizing: border-box;
}

.sb-stepper__next-button {
    background-color: @sablono-green-main;

    &:not([disabled]):hover {
        background-color: @sablono-green-main
    }
}

.sb-visualization-preview {
    flex: 1;
    box-sizing: border-box;

    // introduced for preview to not take more space than allowed
    // would otherwise push the next button out of view
    position: relative;
    overflow: auto;
    svg {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;

        box-sizing: border-box;
        padding: @padding-normal;
    }

    .sb-content-zoom {
        display: none;
    }
}

.sb-visualization-structure-node {
    max-width: @sb-visualization-configurator-width;
    md-chip {
        max-width: @structure-node-chip-max-width;
    }
}

.sb-visualization-structure-node__nodes {
    max-height: @structure-node-chips-list-max-height;
    display: block;
    overflow-x: scroll;
}

.sb-visualization-structure-node_labeling {
    margin-top: @margin-normal;
}

.sb-visualization-name__input {
    width: 100%;
}

.sb-visualization-highlighted__input {
    width: 100%;
}

.sb-visualization-highlighted__indexes {
    .flex-row();
    justify-content: flex-start;
}

.sb-visualization-highlighted__start-index {
    flex-basis: 50%;
}

.sb-visualization-highlighted__end-index {
    flex-basis: 50%;
}

.sb-visualization-empty {
    margin: 0 auto;
    display: flex;
    background-color: @sablono-grey-50;
    padding: @padding-normal;
    margin-top: @margin-big;
    width: 350px;
    align-self: center;

}

.sb-visualization-empty__text {
    .flex-column();
}

.sb-visualization-empty__button {
    align-self: center;
    margin-top: @margin-small;
}

.sb-autocomplete-menu--multiline {
    .md-autocomplete-suggestion {
        min-height: 24px;
        height: auto;
        line-height: 1.15;
        padding: @padding-small @padding-normal;
        white-space: normal;
    }
}
