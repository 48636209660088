@import "../../../../../../styles/layout";

.sb-activity-form__duration-container {
    .flex-row-center-start;
    width: 100%;
}

.sb-activity-form__duration-input-container {
    display: flex;
}

.sb-activity-form__duration-input {
    min-width: 80px;
}

.sb-activity-form__duration-unit {
    width: 100%;
    margin-left: 8px;
    float: right;
}
