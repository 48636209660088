@import "../../../../../styles/global";

@sb-week-selector-height: 25px;
@sb-week-selector-width: 63px;
@sb-week-selector-label-height: 20px;

.sb-lean-board__week-selector {
    height: @sb-week-selector-height;
    min-height: @sb-week-selector-height;
    width: @sb-week-selector-width;
    min-width: 0;

    margin: @margin-none;
    padding: @padding-none;

    line-height: @sb-week-selector-label-height;
    font-size: @font-size-smallest;
}

.sb-lean-board__week-selector.sb-button-group__button {
    margin: @margin-none;
}

.sb-lean-board__header-row--smaller {
    font-size: @font-size-smaller;
}

.sb-lean-board__header__week button.sb-lean-board__week-nav-button {
    margin: 0;
    padding: 0;
    min-height: 10px;
    height: 30px;
    border-radius: 0%;
    i {
        color: @primary-font-color;
    }
}

.sb-lean-board__header__week button.sb-lean-board__week-nav-button.open-session:hover,
.sb-lean-board__time-range-button.open-session:hover {
    background-color: @sablono-green-button-hover;
}

.sb-lean-board__header__week button.sb-lean-board__week-nav-button.open-session,
.sb-lean-board__time-range-button.open-session{
    background-color: @sablono-green-main;
}

.sb-lean-board__header__week button.sb-lean-board__week-nav-button.open-session i::before,
.open-session .sb-lean-board__time-range-button--text-wrapper{
    background: @sablono-green-button-hover;
    border-radius: 5%;
}

.sb-lean-board__header__week button.sb-lean-board__week-nav-button.closed-session,
.sb-lean-board__time-range-button.closed-session{
background-color: @sablono-green-main;
}

.sb-lean-board__header__week button.sb-lean-board__week-nav-button.closed-session:hover,
.sb-lean-board__time-range-button.closed-session:hover{
    background: @sablono-green-button-hover;
}

.sb-lean-board__header__week button.sb-lean-board__week-nav-button.closed-session i::before,
.closed-session .sb-lean-board__time-range-button--text-wrapper{
    background: @sablono-green-button-hover;
    border-radius: 5%;
}

.sb-lean-board--disabled {
pointer-events: none;
}

.sb-lean-board__column-day--non-working {
background: @leanboard-non-working-day-color;
}
.sb-lean-board__column-day--today {
background: @leanboard-today-color;
}

.sb-lean-board__time-range-button {
width: 68%;
margin: 0;
padding: 0;
min-height: 20px;
height: 30px;
}

.sb-lean-board__time-range-button--text-wrapper {
    height: 80%;
    display: flex;
    justify-content: center;
    align-items: center;
    color: @sablono-grey-600;
}
.sb-lean-board__header-items-wrapper {
    .flex-row();
    height: 100%;
    border-bottom: solid @leanboard-borders-colors 1px;
    background-color: @white-background;
    width: inherit;
    margin-left: -1px;
}

