@sb-leanboard-first-column-width: 300px;
@sb-leanboard-header-height: 30px;
@sb-leanboard-options-label-height: 45px;
@sb-leanboard-show-late-button-width: 180px;
@sb-leanboard-show-late-button-height: 20px;
@sb-leanboard-start-of-list-spacing: 38px;
@sb-leanboard-end-of-list-spacing: 60px;
@sb-leanboard-footer-min-height: 70px;

@sb-leanboard-download-button-bottom: 34px;
@sb-leanboard-download-button-right: 90px;
@sb-leanboard-download-button-icon-offset: -3px;

@import "components/sbLeanBoardLane/lean_board_lane";
@import "components/sbActivityCard/activity_card";
@import "components/sbDeliverableCard/deliverable_card";
@import "components/sbLeanBoard/lean_board";
@import "components/sbLateActivitiesPanel/sb_late_activities_panel";
@import "panels/sbActivityDetailsPanel/common/sb_activity_details_panel";
@import "dialogs/exitRunningSessionDialog/exit_running_session_dialog";
@import "dialogs/sbLookAheadDefinitionDialog/sb_look_ahead_definition_dialog";

/**
 * Default styling of lean board related elements.
 */

.sb-lean-board__container {
    width: inherit;
}

.sb-page__lean-board {
    .setColoring(@sablono-green-main, @light-text-color, @sablono-green-button, @sablono-green-button-hover);
}

.sb-page__lean-board--scroll-fix {
    overflow: scroll;
}

/**
 * Active session styling of lean board related elements.
 */
.sb-page__lean-board--open-session {
    .setColoring(@sablono-green-main, @primary-font-color, @sablono-green-button, @sablono-green-button-hover);
}

.setColoring(@handle, @handleText, @button, @hover) {

    // change to plan color with active session
    //
    .sb-lean-board__header__week {
        background: @handle;
    }

    .sb-filter-sidenav__handle {
        color: @primary-font-color;
        background-color: @handle;
    }

    .sb-lean-board__week-selector {

        &.md-button {
            color: @primary-font-color;
            background-color: @button;
        }

        &.active,
        &.md-button:not([disabled]):hover {
            background-color: @hover !important;
        }
    }

    .sb-deliverable-card__loading {
        .md-container {
            background-color: @hover !important;
        }
        .md-container .md-bar {
            background-color: @handle !important;
        }
    }
}


sb-lean-board {
    height: 100%;
    width: 100%;
    overflow: auto;
}

.sb-lean-board {
    margin-left: -1px;
    position: relative;
    max-width: calc(100% - @sb-leanboard-first-column-width);
}

.sb-page__lean-board--pagination-showing .sb-lean-board {
    margin-top: @margin-tiny;
}

.sb-lean-board__filter {
    position: fixed;
    bottom: @padding-normal;
    right: 40%;
    z-index: 2;
    padding-right: @padding-small;
    padding-left: @padding-small;
}

.sb-lean-board__teams-filter, .sb-lean-board__size-selector {
    .flex-row();
    height: @sb-leanboard-options-label-height;
    text-align: right;
    position: relative;
    .sb-lean-board__size-select, .sb-team-select {
        margin: @margin-small @margin-none;
        span {
            text-align: left;
        }
    }
    .sb-lean-board__size-select {
        margin-right: @margin-smaller;
    }
    .sb-team-select {
        position: relative;
        min-width: initial;
    }
    .label {
        margin-right: @padding-small;
        line-height: @sb-leanboard-options-label-height;
    }
}

.sb-lean-board__area-manager-filter {
    position: relative;
    top: @padding-small;
    margin: @margin-none;
    margin-bottom: @margin-smaller;
}

.sb-lean-board__pagination-button {
    position: relative;
    min-height: @sb-leanboard-footer-min-height;
}

.sb-lean-board__header-row {
    height: @sb-leanboard-header-height;
    width: inherit;
    z-index: 4;
    padding-right: 10px;
    top: 0;
    .flex-row();
    justify-content: flex-start;
    position: sticky;
    top: 0;
}

.sb-lean-board__header__week {
    .sb-sides-shadow();
    .flex-row-center;
    height: @sb-leanboard-header-height;
    min-width: @sb-leanboard-first-column-width - @padding-big;
    padding-left: @padding-big;
    position: sticky;
    left: 0;
    border-bottom: solid @leanboard-borders-colors 1px;
    z-index: @z-indexes[foreground];
}

.sb-lean-board__header-item {
    flex: 1;
    height: 100%;
    .flex-column-center;
    border-right: solid @leanboard-borders-colors 1px;
}

.sb-next-page-button, .sb-previous-page-button{
    width: 90vw;
    position: sticky;
    left: 5vw;
    margin-top: @margin-normal;
}

.sb-work-page .sb-filter-sidenav__handle{
    background-color: @sablono-green-700;
}

.sb-lean-board__download {
    .sb-button-shadow();
    position: absolute;
    right: @sb-leanboard-download-button-right;
    bottom: @sb-leanboard-download-button-bottom;

    height: @sb-leanboard-show-late-button-height;
    width: @sb-leanboard-show-late-button-height;
    z-index: @z-indexes[foreground];

    border-radius: @sb-leanboard-show-late-button-height;
    background: @sablono-grey-500;

    &.md-button:not([disabled]).md-icon-button:hover {
        background: @sablono-grey-500;
    }
}

.sb-lean-board__download-icon {
    position: relative;
    color: @white-background;
    left: @sb-leanboard-download-button-icon-offset;
}

.sb-lean-board__view-switcher {
    font-size: @font-size-smaller;
}

.sb-lean-board__options-panel-entry {
    height: auto;
}
