@import "../../../styles/layout";
@import "../../../styles/global";

/**
 * Overall page layout
 */
.sb-inspect-deliverables {
    .flex-column-center-start;

    height: 100%;

    background-color: @gray-background;
}

/**
 * Toolbar styling for desktop (tall) and mobile (small).
 */
@sb-inspect-deliverables--tall-toolbar-height: 128px;
@sb-inspect-deliverables--small-toolbar-height: 56px;

@sb-inspect-deliverables--toolbar-color: @sablono-green-main;

md-toolbar.sb-inspect-deliverables__toolbar{

    .flex-row;
    justify-content: center;

    min-height: @sb-inspect-deliverables--tall-toolbar-height;
    height: @sb-inspect-deliverables--tall-toolbar-height;
    max-height: @sb-inspect-deliverables--tall-toolbar-height;

    @media(max-width: @media-md) {
        min-height: @sb-inspect-deliverables--small-toolbar-height;
        height: @sb-inspect-deliverables--small-toolbar-height;
        max-height: @sb-inspect-deliverables--small-toolbar-height;
    }

    background-color: @sb-inspect-deliverables--toolbar-color;
    color: @sablono-green-text-color;
}

.sb-inspect-deliverables__toolbar-button {
    color: @sablono-green-text-color;

    background-color: @sablono-green-button;
    &:hover {
        background-color: @sablono-green-button-hover;
    }
}

/**
 * Main content area underneath the toolbar.
 *
 * Reduces the centered main area to 960px.
 */
.sb-inspect-deliverables__content {
    .flex-column-center;

    min-width: @media-s;
    max-width: @media-md;
    width: 100%;
    flex: 1;
    display: flex;
    flex-direction: column;

    // overlap the content area with the toolbar
    //
    margin-top: -48px;
    z-index: 3;

    background-color: @gray-background;

    // Reset margin on medium sized screens (< 960px)
    //
    @media(max-width: @media-md) {
        margin: 0;
    }
}

/**
 * Search input based on auto-complete
 */
.inspect-deliverables__form {
    max-width: 800px;
}

.inspect-deliverables__search-input-container {
    width: 100%;
}

/**
 * add magnify glass in front of search input
 */
.inspect-deliverables__search-input-container md-autocomplete-wrap:before {
    font-family: "Material Design Icons", sans-serif;
    content: "\F349";
    line-height: 1;
    padding: @padding-small @padding-none @padding-none @padding-small;
    font-weight: normal;
    font-size: @font-size-icon-normal;
    color: @sablono-green-text-color;
}
