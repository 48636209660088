@sb-loading-blocks-size: 18px;
@sb-loading-blocks-mini-size: 8px;
.sb-loading > div {
    display: inline-block;
    width: @sb-loading-blocks-size;
    height: @sb-loading-blocks-size;
    background-color: @sablono-green-main;
    animation: bouncedelay 1.4s infinite ease-in-out both
}
.sb-loading.mini > div {
    width: @sb-loading-blocks-mini-size;
    height: @sb-loading-blocks-mini-size;
    margin: 0;
}

.sb-loading .one {
    animation-delay: -0.32s
}

.sb-loading .two {
    animation-delay: -0.16s;
    background: @sablono-green-main;
}

.sb-loading .three {
    background: @sablono-green-main;
}

@keyframes bouncedelay {
    0%, 80%, 100% {
        transform: scale(0)
    }
    40% {
        transform: scale(1);
    }
}

