@import "angular-material/angular-material.css";

html {
  --mdc-typography-font-family: "Work Sans", "Helvetica Neue", sans-serif;
}

html, body {
  margin: 0;
  padding: 0;
  font-family: "Work Sans", "Helvetica Neue", sans-serif;
}

// HOTFIX: Overwrite internally hard-coded angular-material font family Roboto for all elements.
button,
select,
html,
textarea,
input {
  font-family: "Work Sans", "Helvetica Neue", sans-serif;
}

md-bottom-sheet {
  md-bottom-sheet .md-subheader {
    font-family: "Work Sans", "Helvetica Neue", sans-serif;
  }
}

.md-chips {
  font-family: "Work Sans", "Helvetica Neue", sans-serif;
}

@import "@mdi/font/css/materialdesignicons.css";
@import "animate.css/animate.css";
@import "v-accordion/dist/v-accordion.css";

@import "colorpalette";
@import "buttons";
@import "fonts";
@import "sablono-font";
@import "animation";
@import "layout";
@import "roles";
@import "global";
@import "bugfixes";
@import "icons";
@import "sb-common/sb-common";
@import "../scripts/common/dialog/dialog";
@import "../scripts/common/services/services";

#ngbootstrap {
  height: 100%;

  & > div {
    height: 100%;
  }
}
