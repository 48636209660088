@import "../../../../../../../styles/global";
@import "../../../../../../../styles/colorpalette";
@import "../../../../../../../styles/fonts";

@sb-data-presenter-width: 270px;

// layout for deliverable date and timezone information
//
.sb-deliverable-date-info {
    flex: 0 100%;
    .flex-column;
    justify-content: flex-start;

    color: @sablono-grey-600;
    outline: none;
}


// layout for rows of dates
//
.sb-deliverable-times {
    display: flex;
    flex-direction: column;
}

.sb-deliverable-times:hover {
    color: @primary-font-color;
    cursor: pointer;

    .sb-data-presenter {
        color: @primary-font-color;
        cursor: pointer;
    }
}

// styling for a single date row
//
.sb-data-presenter {
    max-width: @sb-data-presenter-width;
    width: @sb-data-presenter-width;
    min-width: @sb-data-presenter-width;
    display: flex;
    justify-content: space-between;
    align-items: center;
    min-height: @font-size-normal;

    .thin-font;
}

.sb-date-presenter__icon {
    padding-right: 5px;

    .normal-font;
}

.sb-date-presenter__label {
    flex: 2;
    min-width: 96px;
}

.sb-date-presenter__text {
    min-width: 80px;
    margin-left: @margin-small;

    .small-font;
}

.sb-date-presenter__text--warning{
    color: @riemi-red-600;
}

.sb-date-presenter__state-indicator {
    padding-left: 5px;

    .normal-font;
}
