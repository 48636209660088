@height-row-edit-note-opened: 200px;
@height-row-edit-note-closed: 50px;

@container-table-with-filter-card-max-width: 970px;

.sb-note-templates-table {

    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background: @gray-background;
    padding-top: 50px;
    padding-left: 5px;
    padding-right: 5px;
}

.sb-manipulation{
    width: 100%;
}

.rows-container {
    .hide {
        display: none;
    }
    .row-container{
        padding-right: @filter-card-width;
        &.opened {
            .row{
                color: @sablono-grey-900;
                background-color: @sablono-grey-50;
                margin: @margin-normal auto;

                padding: 52px 15px 0 15px;
                box-shadow: 0px 2px 4px -1px rgba(0, 0, 0, 0.2), 0px 4px 5px 0px rgba(0, 0, 0, 0.14), 0px 1px 10px 0px rgba(0, 0, 0, 0.12);
                height: @height-row-edit-note-opened;
                .info-row {
                    background: @white-background;
                    position: absolute;
                    top: 0;
                    left: 0;
                    width: 100%;
                }
                sb-note-template-edit-card{
                    display: block;
                }

            }
        }
    }
    .row {
        max-width: @container-table-with-filter-card-max-width;
        display: flex;
        flex-direction: column;
        flex-grow: 1;
        background-color: @white-background;
        transition: 0.2s;
        position: relative;
        z-index: @z-indexes[background];
        left: 8px;
        margin: 0 auto;
        .info-row {
            height: @height-row-edit-note-closed;
            display: flex;
            align-items: center;
            outline: none;
            padding: @row-padding @table-indentation;
            box-sizing: border-box;
            &:hover {
                background-color: @sablono-grey-100;
                cursor: pointer;
            }
            &:last-child {
                border-bottom: 0px;
            }
        }
        sb-note-template-edit-card{
            display: none;
        }

    }
}
.sb-note-templates-column-type-image{
    width: 50px;
    min-width: 50px;
    display: flex;
    justify-content: center;
}

.sb-note-templates-column-code{
    flex: 1;
    overflow: hidden;
    display: flex;
    justify-content: center;
}
.sb-note-templates-column-text{
    display: flex;
    overflow-y: hidden;
    flex: 4;
    justify-content: flex-start;
}
.sb-note-templates-column-creator{
    display:flex;
    width: 100px;
    min-width: 100px;
    justify-content: center;
}
.sb-note-templates-column-date{
    width: 100px;
    min-width: 100px;
    display: flex;
    justify-content: center;
}
