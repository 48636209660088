@import "./components/sbCommercialCard/commercial_card";
@import "./components/sbCommercialCardHeader/commercial_header";
@import "./components/sbCostGroup/cost_group";
@import "./components/sbDateSelect/date_select";
@import "dialogs/createNewCostGroup/create_new_cost_group";
@import "dialogs/combineCostGroupActivities/combine_cost_group_activities";
@import "dialogs/editCostGroupMembership/edit_cost_group_membership";

@sb-cost-group-dialog-width: 700px;

.sb-commercial {
    height: 100%;
}

.sb-cost-group-dialog {
    width: @sb-cost-group-dialog-width;
}

.sb-cost-group-dialog__toolbar {
    .flex-row();
    justify-content: space-between;
    align-items: center;
}
