.sb-eula-dialog {
    max-width: 650px;
}

.sb-eula-dialog__checkbox-wrapper {
    margin-top: @margin-big;
    text-align: center;
}

.sb-eula-dialog__checkbox {
    color: @sablono-green-main;
}
