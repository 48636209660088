.sb-filter-date{
    .sb-filter-date-select{
        margin: 4px 0 @margin-small;
        background: @sablono-grey-100;
        padding:4px;
    }
    md-select .md-select-value{
        border-bottom-color: transparent;
    }
    .sb-filter-date-chooser{
        margin: 4px 0 @margin-normal;
        .md-datepicker-input-container{
            margin-left:0;
            margin-right: @margin-big;
            border-bottom-width: 0;
        }
        .md-button.md-icon-button{
            margin:0;
            padding: 0;
            width: 30px;
            md-icon{
                color: @sablono-grey-500;
                margin-bottom: 4px;
            }
        }
        span{
            padding-left:2px;
        }
    }
}

.sb-filter-date-selection{
    md-select-menu{
        max-height: none;
        md-content{
            max-height: none;
        }
    }
}
