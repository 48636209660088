@import "./../../../../styles/global";

@radio-button-text-left-aligned: 30px;

.sb-scheduling-mode__option-text {
    .normal-bold-font;
}

.sb-scheduling-mode__description {
    .normal-thin-font;

    // align descriptive text with text of radio button
    //
    margin-left: @radio-button-text-left-aligned;
}
