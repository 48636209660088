@import '../../../../../styles/global';
@import '../../../../../styles/fonts';
@import '../../../../../styles/layout';
@import '../../../../../styles/colorpalette';
@import '../../../../../styles/sb-common/card';

.sb-single-action-card {
    display: flex;
    transition: all 0.2s ease-in-out;

    &:hover{
        cursor: pointer;
        .sb-card--hover();
    }
}

.sb-single-action-card__section {
    .flex-row();
    align-items: center;
    justify-content: space-around;
    box-sizing: border-box;

    //flex: 1;
}

.sb-single-action-card__spacer {
    flex: 1;
}

.sb-single-action-card__description {
    flex: 1;
    .small-thin-font();
}

.sb-single-action-card__icon {
    font-size: 46px;
    padding: @margin-small;
}

.sb-single-action-card__action {
    display: flex;
    justify-content: flex-end;
    align-items: flex-end;
}
