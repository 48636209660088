@sb-filter-text-row-height: 40px;
.sb-filter-text{
    display: flex;
    flex-direction: row;
    align-items: flex-end;
    height: @sb-filter-text-row-height;
    .sb-filter-text-input{
        margin: 4px 0;
        width: 100%;
        position: relative;
        input{
            height: 100%;
            font-size: @font-size-small;
            border-width: 0;
            border:1px solid @sablono-grey-300;
            width:100%;
            box-sizing: border-box;
            padding: @padding-small @padding-normal @padding-small 2px;
            outline-width: 0px;
            text-indent:4px;
            &:disabled{
                background: @sablono-grey-50;
                border-bottom: 1px dashed @sablono-grey-400;
            }
            &:focus{
                border-bottom-color: @sablono-green-main;
            }
        }
        .sb-filter-text-dismiss{
            position: absolute;
            top:4px;
            right: 4px;
            font-size: 20px;
            color: @primary-font-color;
            &:hover{
                background-color: inherit;
            }
        }

    }
    .md-icon{
         border-width: 1px;
    }
    .md-checked .md-icon{
         background-color: @sablono-green-main;
    }
    md-checkbox .md-container
    {
        left: -1px;
    }
    .sb-filter-text-checkbox{
        margin: 0 0 11px 15px;
        min-width: 30%;
        font-size: @font-size-smaller;
        align-self: flex-end;
        .md-label{
            line-height: 20px;
        }
    }
    .md-errors-spacer{
        display: none;
    }
}
