.thin-font {
  font-family: 'Work Sans', 'Helvetica Neue', sans-serif;
  font-size: 12px;
  font-weight: 300;
}
.small-font {
  font-family: 'Work Sans', 'Helvetica Neue', sans-serif;
  font-size: 14px;
  font-weight: 400;
}
.smaller-font {
  font-family: 'Work Sans', 'Helvetica Neue', sans-serif;
  font-size: 12px;
  font-weight: 400;
}
.normal-font {
  font-family: 'Work Sans', 'Helvetica Neue', sans-serif;
  font-size: 16px;
  font-weight: 400;
}
.large-thin-font {
  font-family: 'Work Sans', 'Helvetica Neue', sans-serif;
  font-size: 18px;
  font-weight: 300;
}
.normal-thin-font {
  font-family: 'Work Sans', 'Helvetica Neue', sans-serif;
  font-size: 16px;
  font-weight: 300;
}
.normal-bold-font {
  font-family: 'Work Sans', 'Helvetica Neue', sans-serif;
  font-size: 16px;
  font-weight: 500;
}
.small-bold-font {
  font-family: 'Work Sans', 'Helvetica Neue', sans-serif;
  font-size: 14px;
  font-weight: 500;
}
.smaller-bold-font {
  font-family: 'Work Sans', 'Helvetica Neue', sans-serif;
  font-size: 12px;
  font-weight: 500;
}
.small-thin-font {
  font-family: 'Work Sans', 'Helvetica Neue', sans-serif;
  font-size: 14px;
  font-weight: 300;
}
.larger-thin-font {
  font-family: 'Work Sans', 'Helvetica Neue', sans-serif;
  font-size: 24px;
  font-weight: 300;
}
.larger-bold-font {
  font-family: 'Work Sans', 'Helvetica Neue', sans-serif;
  font-size: 20px;
  font-weight: 400;
}
.sb-lowercase {
  text-transform: lowercase;
}
.sb-uppercase-first-letter {
  display: inline-block;
}
.sb-uppercase-first-letter::first-letter {
  text-transform: uppercase;
}
/**
 * Mixin to easily create @font-face rules by font, weight and style.
 */
@font-face {
  font-family: "Work Sans";
  src: url('../fonts/Thin/WorkSans-Thin.woff2') format('woff2'), /* Super Modern Browsers */ url('../fonts/Thin/WorkSans-Thin.woff') format('woff'), /* Pretty Modern Browsers */ url('../fonts/Thin/WorkSans-Thin.ttf') format('truetype');
  /* Safari, Android, iOS */
  font-weight: 100;
  font-style: normal;
}
@font-face {
  font-family: "Work Sans";
  src: url('../fonts/Light/WorkSans-Light.woff2') format('woff2'), /* Super Modern Browsers */ url('../fonts/Light/WorkSans-Light.woff') format('woff'), /* Pretty Modern Browsers */ url('../fonts/Light/WorkSans-Light.ttf') format('truetype');
  /* Safari, Android, iOS */
  font-weight: 300;
  font-style: normal;
}
@font-face {
  font-family: "Work Sans";
  src: url('../fonts/Regular/WorkSans-Regular.woff2') format('woff2'), /* Super Modern Browsers */ url('../fonts/Regular/WorkSans-Regular.woff') format('woff'), /* Pretty Modern Browsers */ url('../fonts/Regular/WorkSans-Regular.ttf') format('truetype');
  /* Safari, Android, iOS */
  font-weight: 400;
  font-style: normal;
}
@font-face {
  font-family: "Work Sans";
  src: url('../fonts/Regular/WorkSans-Regular.woff2') format('woff2'), /* Super Modern Browsers */ url('../fonts/Regular/WorkSans-Regular.woff') format('woff'), /* Pretty Modern Browsers */ url('../fonts/Regular/WorkSans-Regular.ttf') format('truetype');
  /* Safari, Android, iOS */
  font-weight: normal;
  font-style: normal;
}
@font-face {
  font-family: "Work Sans";
  src: url('../fonts/Medium/WorkSans-Medium.woff2') format('woff2'), /* Super Modern Browsers */ url('../fonts/Medium/WorkSans-Medium.woff') format('woff'), /* Pretty Modern Browsers */ url('../fonts/Medium/WorkSans-Medium.ttf') format('truetype');
  /* Safari, Android, iOS */
  font-weight: 500;
  font-style: normal;
}
@font-face {
  font-family: "Work Sans";
  src: url('../fonts/Medium/WorkSans-Medium.woff2') format('woff2'), /* Super Modern Browsers */ url('../fonts/Medium/WorkSans-Medium.woff') format('woff'), /* Pretty Modern Browsers */ url('../fonts/Medium/WorkSans-Medium.ttf') format('truetype');
  /* Safari, Android, iOS */
  font-weight: bold;
  font-style: normal;
}
.sb-sidenav {
  background-color: #2B2B2B;
  color: #FFFFFF;
  z-index: 80;
}
.sb-sidenav md-content {
  background-color: #2B2B2B;
  color: #FFFFFF;
}
.sb-sidenav .md-button {
  border-radius: 0;
  margin: 0;
  padding: 0 16px;
  text-align: left;
  width: 100%;
}
.sb-sidenav .md-button.sb-sidenav-list-header {
  margin: 6px 0 12px 0;
}
.sb-sidenav-head-bar {
  cursor: pointer;
  height: 64px;
}
.sb-sidenav-head-bar-image {
  background-size: cover;
  background-position: 50% 50%;
  height: 200px;
}
.sb-sidenav-head-bar-image-project {
  display: flex;
  flex-direction: column;
  height: 100%;
  justify-content: flex-end;
}
.sb-sidenav-head-bar-image-project p {
  background-color: rgba(43, 43, 43, 0.85);
  font-weight: 400;
  margin: 0;
  padding-bottom: 10px;
  padding-left: 16px;
}
.sb-sidenav-head-bar-image-project p:first-child {
  padding-bottom: 2px;
  padding-top: 10px;
}
.sb-sidenav-project-code,
.sb-sidenav-project-name {
  font-weight: 300;
  margin-left: 8px;
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
  min-width: 0;
  min-height: 0;
}
.sb-sidenav-project-code {
  font-size: 14px;
}
.sb-sidenav-project-name {
  font-size: 18px;
  margin-top: 3px;
}
.sb-sidenav-progress,
.sb-sidenav-progress .md-mode-determinate,
.sb-sidenav-progress .md-bar {
  height: 6px;
}
.sb-sidenav-list {
  margin: 0;
  padding: 0;
}
.sb-sidenav-list-link {
  display: flex;
  height: 40px;
}
.sb-sidenav-list-link:disabled .sb-sidenav-link-icon {
  color: #CECECE;
}
.sb-sidenav-link-icon {
  min-width: 25px;
  text-align: left;
}
.sb-sidenav-link-new-badge::after {
  content: 'new!';
  transform: rotate(10deg);
  padding: 6px;
  background: #36B381;
  color: #FFFFFF;
  font-weight: 500;
  font-size: 12px;
  line-height: 12px;
  border-radius: 12px;
  position: absolute;
  right: 40px;
  top: 10px;
}
.sb-sidenav-link-pro-badge::after {
  content: 'Pro';
  transform: rotate(10deg);
  padding: 6px;
  background: #36B381;
  color: #FFFFFF;
  font-weight: 500;
  font-size: 12px;
  line-height: 12px;
  border-radius: 12px;
  position: absolute;
  right: 40px;
  top: 10px;
}
.sb-sidenav-link-beta-badge::after {
  content: 'Beta';
  transform: rotate(10deg);
  padding: 6px;
  background: #36B381;
  color: #FFFFFF;
  font-weight: 500;
  font-size: 12px;
  line-height: 12px;
  border-radius: 12px;
  position: absolute;
  right: 40px;
  top: 10px;
}
.sb-sidenav-link-trial-badge::after {
  content: 'Trial';
  transform: rotate(10deg);
  padding: 6px;
  background: #36B381;
  color: #FFFFFF;
  font-weight: 500;
  font-size: 12px;
  line-height: 12px;
  border-radius: 12px;
  position: absolute;
  right: 40px;
  top: 10px;
}
.sb-sidenav-list-menu-header,
.sb-sidenav-list-menu-item {
  text-transform: none;
}
.sb-sidenav-list-menu-header span {
  font-weight: 500;
}
.sb-sidenav-list-menu-header > i {
  padding-right: 12px;
}
.sb-sidenav-list-menu-item > i {
  padding-left: 32px;
}
.sb-sidenav-no-project {
  display: flex;
  font-size: 26px;
  padding: 8px 20px 14px 20px;
}
md-backdrop.md-opaque {
  z-index: 70;
}
sb-subbar-actions {
  width: 100%;
  flex: 1;
}
sb-subbar-actions .sb-subbar-content {
  display: flex;
  align-items: center;
  justify-content: flex-end;
  padding: 4px;
}
sb-subbar-actions .sb-subbar-content h1,
sb-subbar-actions .sb-subbar-content h2,
sb-subbar-actions .sb-subbar-content h3,
sb-subbar-actions .sb-subbar-content h4,
sb-subbar-actions .sb-subbar-content h5,
sb-subbar-actions .sb-subbar-content p {
  margin: 0;
  font-size: 20px;
  font-weight: 400;
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
  min-width: 0;
  min-height: 0;
}
sb-subbar-actions .sb-subbar-content h1 span,
sb-subbar-actions .sb-subbar-content h2 span,
sb-subbar-actions .sb-subbar-content h3 span,
sb-subbar-actions .sb-subbar-content h4 span,
sb-subbar-actions .sb-subbar-content h5 span,
sb-subbar-actions .sb-subbar-content p span {
  font-weight: 300;
}
.sb-subbar-mode {
  /*display: flex;
    flex-grow: 1;*/
}
.sb-subbar-mode-hide {
  display: none !important;
}
.sb-toolbar-state__user {
  cursor: pointer;
}
.sb-toolbar-state__icon-button {
  padding: 0;
  background-color: #FFFFFF;
}
.sb-toolbar-state__icon-button md-icon {
  color: #2B2B2B !important;
}
.sb-toolbar-state__icon-button:hover {
  background-color: #F4F3F2 !important;
}
.sb-toolbar-state__user.sb-role-admin-color .sb-user-image {
  background-color: #8F2B2B;
  color: #FFFFFF;
}
.sb-toolbar-state__user.sb-role-manager-color .sb-user-image {
  background-color: #F8C244;
  color: #2B2B2B;
}
.sb-toolbar-state__user.sb-role-controller-color .sb-user-image {
  background-color: #365781;
  color: #FFFFFF;
}
.sb-toolbar-state__user.sb-role-inspector-color .sb-user-image {
  background-color: #36B381;
  color: #FFFFFF;
}
.sb-toolbar-state__user.sb-role-reviewer-color .sb-user-image {
  background-color: #6C6C6C;
  color: #FFFFFF;
}
.sb-toolbar-state__timeline {
  position: absolute;
  width: 500px;
  right: 0;
}
.sb-inspection-button_container {
  position: relative;
}
.sb-inspection_badge {
  position: absolute;
  top: -8px;
  right: -2px;
  height: 22px;
  width: 22px;
  cursor: pointer;
  background-color: #90F9C4;
  color: #2B2B2B;
  border: 1px solid #2B2B2B;
  border-radius: 12px;
  font-family: 'Work Sans', 'Helvetica Neue', sans-serif;
  font-size: 14px;
  font-weight: 400;
  line-height: 20px;
  text-align: center;
}
.sb-toolbar-search-bar {
  display: flex;
  flex-direction: row;
  align-items: center;
  max-width: 800px;
  margin: auto;
}
.sb-toolbar-search-bar__input {
  width: 100%;
  margin: auto;
  height: 40px;
  background-color: #FFFFFF;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: row;
  border-radius: 5px;
}
.sb-toolbar-search-bar__input input {
  font-family: 'Work Sans', 'Helvetica Neue', sans-serif;
  font-size: 16px;
  font-weight: 300;
  border-bottom: none;
  width: 100%;
  min-width: 100%;
}
.sb-toolbar-search-bar__icon {
  color: #858585 !important;
}
.sb-toolbar-search-bar__cancel {
  color: #858585 !important;
  outline: none;
}
.sb-toolbar-search-bar__cancel:focus {
  outline: none;
}
.sb-toolbar-search-bar__cancel.mdi-close {
  cursor: pointer;
}
.profile-card-container {
  display: flex;
  flex-direction: column;
  flex-grow: 1;
  background: white;
  border: 1px solid lightgray;
}
.profile-card__actions {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
}
.profile-card__language-select {
  font-family: 'Work Sans', 'Helvetica Neue', sans-serif;
  font-size: 16px;
  font-weight: 300;
  margin: 8px;
}
.sb-scheduling-state {
  display: flex;
  flex-direction: row;
  margin: 0 10px;
}
.sb-scheduling-state__action-button {
  background-color: #ED9833;
  color: white;
}
.sb-scheduling-state__action-button:not([disabled]):hover {
  background-color: #EA8B1A;
}
.sb-scheduling-state__text {
  margin: 8px;
}
.sb-scheduling-state__icon {
  color: #ED9833;
  font-size: 35px;
  margin-top: 8px;
}
.sb-scheduling-state-panel {
  display: flex;
  flex-direction: column;
  padding: 8px;
  background: white;
  border: 1px solid lightgray;
  width: 300px;
  height: 175px;
  color: #858585;
  font-size: 14px;
}
/*
 * LAYOUT SECTION:
 *  -> please no coloring here. There is separate section
 *      and separate style classes for color dependent manipulation.
 */
.sb-subbar {
  height: auto;
  min-height: 0;
  max-height: 48px;
  padding: 0;
  transition: all 0.3s ease-in-out;
  z-index: 6;
  display: flex;
  flex-direction: row;
  align-items: center;
  box-shadow: 0 0 1px 1px rgba(0, 0, 0, 0.14), 0 0 2px 2px rgba(0, 0, 0, 0.098), 0 0 5px 1px rgba(0, 0, 0, 0.084);
}
.sb-subbar .sb-subbar-title {
  padding: 12px 0;
}
.sb-subbar .md-button {
  padding-left: 10px;
  padding-right: 10px;
  margin-top: 4px;
  margin-bottom: 4px;
}
.sb-subbar .md-button .mdi:only-child {
  font-size: 22px;
}
.sb-subbar .md-button .mid {
  font-size: 14px;
}
.sb-subbar .md-icon-button .fa,
.sb-subbar .md-icon-button .mdi {
  font-size: 22px;
}
.sb-subbar .md-button:disabled {
  cursor: not-allowed;
}
.sb-subbar .md-button.md-raised,
.sb-subbar .md-button.md-raised:active {
  box-shadow: none;
}
/* IE only styles */
@media all and (-ms-high-contrast: none), (-ms-high-contrast: active) {
  .sb-subbar .md-toolbar-tools {
    width: auto!important;
  }
}
.md-button.md-icon-button.sb-toolbar-tools__back-button {
  color: #FFFFFF;
}
.sb-toolbar-tools {
  width: auto;
  height: auto;
}
md-toolbar.sb-subbar {
  background-color: #4C4C4C;
}
md-toolbar.sb-delete {
  background-color: #B33636;
  color: #FFFFFF;
}
md-toolbar.sb-delete .md-button {
  color: #FFFFFF !important;
  background-color: #C25E5E !important;
}
md-toolbar.sb-delete .md-button:not([disabled]):hover {
  background-color: #CA7272 !important;
}
md-toolbar.sb-delete .md-button:disabled {
  color: #CECECE !important;
  background-color: #B33636 !important;
}
md-toolbar.sb-delete .md-icon-button {
  background-color: #B33636 !important;
}
body,
html {
  height: 100%;
  overflow: hidden;
  font-weight: 300;
  font-family: "Work Sans", "Helvetica Neue", sans-serif;
}
.sb-main {
  background: #F4F3F2;
}
.ng-sablono {
  font-size: 16px;
  font-family: "Work Sans", "Helvetica Neue", sans-serif;
  height: calc(100% - 64px);
  min-height: calc(100% - 64px);
  overflow: hidden;
  background-color: #F4F3F2;
  display: flex;
  flex-direction: column;
}
.ng-sablono md-toolbar {
  flex: 0 0 auto;
  z-index: 1;
}
.ng-sablono .sablono-project {
  display: flex;
  flex-direction: column;
  flex: 1;
  height: calc(100% - 48px);
  min-height: calc(100% - 48px);
  position: relative;
  z-index: 0;
}
.ng-sablono .sablono-project.sb-subbar-hidden {
  height: 100%;
  min-height: 100%;
}
.sb-toolbar {
  background-color: #2B2B2B !important;
  z-index: 1;
}
.sb-toolbar .md-toolbar-tools {
  display: grid;
  grid-template-columns: 1fr 300px auto;
  align-items: center;
  height: 64px;
  max-height: 64px;
  padding: 0 0 0 16px;
}
.sb-toolbar__middle-part {
  flex-grow: 1;
}
.sb-toolbar__right-part {
  margin-left: 8px;
  margin-right: 8px;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
}
.sb-toolbar__right-part > .md-button {
  margin-left: 8px;
  margin-right: 8px;
}
.sb-toolbar__right-part > .sb-toolbar-state__user {
  margin-left: 8px;
  margin-right: 8px;
}
.ng-sablono--fullscreen {
  height: 100%;
}
.sb-error {
  animation: 1200ms sb-error ease-in-out 400ms;
}
.sb-error.ng-enter,
.sb-error.ng-leave {
  transition: 400ms all;
  overflow: hidden;
}
.sb-error.ng-enter {
  max-height: 0;
}
.sb-error.ng-enter.ng-enter-active {
  max-height: 60px;
  opacity: 1;
}
.sb-error.ng-leave {
  max-height: 60px;
  opacity: 1;
}
.sb-error.ng-leave.ng-leave-active {
  max-height: 0;
  opacity: 1;
}
.sb-error-message {
  font-size: 11pt;
  margin: 0;
  padding: 2px 2px;
}
@keyframes sb-error {
  0% {
    color: inherit;
  }
  22% {
    color: #B33636;
  }
  45% {
    color: #B33636;
  }
  100% {
    color: inherit;
  }
}
sb-multiline-edit {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100%;
  user-select: none;
  outline: none;
}
sb-multiline-edit .edit-text {
  background: transparent;
  resize: none;
  border: none;
  overflow: hidden;
  color: #4C4C4C;
  border-bottom: 1px dotted #858585;
}
sb-multiline-edit .edit-text,
sb-multiline-edit .plain-text {
  text-align: inherit;
  line-height: 20px;
  display: block;
  width: 100%;
  outline: none;
}
.sb-background-info {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  height: 100%;
  min-height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  background: #F4F3F2;
}
.sb-background-info-plain-text {
  max-width: 480px;
  margin-left: 32px;
  margin-right: 32px;
}
.sb-background-info .sb-trascluded-content {
  display: flex;
  flex-direction: column;
  max-width: 1400px;
  width: 96%;
}
.sb-background-info .sb-trascluded-content.loading {
  margin-bottom: 64px;
}
.sb-info-large,
.sb-background-info {
  text-align: center;
  color: #858585;
}
.sb-info-large h1,
.sb-background-info h1,
.sb-info-large h2,
.sb-background-info h2,
.sb-info-large h3,
.sb-background-info h3,
.sb-info-large h4,
.sb-background-info h4,
.sb-info-large h5,
.sb-background-info h5 {
  font-weight: 300;
  color: #CECECE;
  margin-left: 10%;
  margin-right: 10%;
}
.sb-info-large h2,
.sb-background-info h2 {
  color: #6C6C6C;
  font-weight: 300;
  font-size: 32px;
}
.sb-info-large h3,
.sb-background-info h3 {
  color: #858585;
  font-weight: 300;
  font-size: 26px;
}
.sb-info-large h4,
.sb-background-info h4 {
  font-weight: 300;
  font-size: 20px;
  color: #858585;
}
.sb-info-large p,
.sb-background-info p {
  font-size: 18px;
  margin: 16px;
}
.sb-info-large h2.md-primary,
.sb-background-info h2.md-primary,
.sb-info-large h3.md-primary,
.sb-background-info h3.md-primary,
.sb-info-large h4.md-primary,
.sb-background-info h4.md-primary {
  color: #2B2B2B;
}
.sb-info-large i,
.sb-background-info i {
  font-size: 180px;
  text-shadow: 0 1px 0 rgba(0, 0, 0, 0.5);
  padding: 36px;
}
.sb-info-large button,
.sb-background-info button {
  margin-top: 16px;
}
.sb-background-info .sb-background-list {
  display: flex;
  flex-direction: column;
}
.sb-background-info .sb-background-list span {
  font-size: 18px;
  margin-bottom: 8px;
}
.sb-background-info .sb-buttons-container {
  display: flex;
  width: 100%;
  justify-content: center;
  align-items: center;
  margin: 16px 0;
}
.sb-background-info .sb-buttons-container button {
  margin: 0;
}
sb-breadcrumbs {
  overflow: hidden;
  display: flex;
  flex-shrink: 1;
  flex-direction: row;
  flex-wrap: nowrap;
  align-items: center;
  align-content: flex-start;
  justify-content: flex-start;
  color: #4C4C4C;
}
sb-breadcrumbs .breadcrumb-item-container {
  display: flex;
  align-items: center;
  flex-grow: 0;
  flex-shrink: 1;
  align-self: flex-start;
  overflow: hidden;
}
sb-breadcrumbs .breadcrumb-item-container:last-of-type {
  flex-shrink: 0;
}
sb-breadcrumbs .breadcrumb-delimiter {
  flex-shrink: 0;
  font-size: 15pt;
  color: #6C6C6C !important;
  position: relative;
}
sb-breadcrumbs .breadcrumb-item,
sb-breadcrumbs .breadcrumb-menu-button {
  flex-shrink: 1;
  white-space: nowrap;
  text-overflow: ellipsis;
  min-width: 0;
  text-transform: none;
  color: #4C4C4C;
}
sb-breadcrumbs .breadcrumb-menu-button {
  display: flex;
  align-items: center;
}
sb-breadcrumbs .breadcrumb-menu-button .select-icon {
  margin-left: 8px;
  color: #4C4C4C;
  font-size: 20px;
}
sb-breadcrumbs .breadcrumb-menu {
  margin: 0px;
  padding: 0px;
}
.breadcrumb-menu-icon {
  font-size: 20px;
  text-align: center;
}
.sb-tree {
  user-select: none;
}
.sb-tree-pull-left {
  float: left;
}
.sb-tree-spacer-left {
  margin-left: 15px;
}
.sb-tree-spacer-left-big {
  margin-left: 25px;
}
.sb-tree-pretty-cut-off {
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
}
.sb-tree-spacer-right {
  margin-right: 15px;
}
.sb-tree-item {
  height: 64px;
  align-items: center;
  justify-content: center;
}
.sb-tree-item h3 {
  font-size: 16px;
  line-height: 1em;
  font-weight: 400;
}
.sb-tree-item h4 {
  font-size: 14px;
  padding-top: 4px;
  line-height: 1em;
  font-weight: 400;
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
  min-width: 0;
  min-height: 0;
}
.sb-tree-item .md-button.md-no-style {
  cursor: default;
}
.sb-tree-open-indicator {
  text-align: center;
  outline-width: 0;
  width: 100%;
}
.sb-tree-open-indicator-icon {
  transition: all 100ms;
}
.sb-structure-code {
  margin: 0px 6px 6px 6px !important;
  color: #2B2B2B !important;
}
.sb-structure-name {
  margin: 0px 6px 6px 6px !important;
}
.sb-tree-item {
  /**
     * Stagger ENTER ANIMATION
     */
}
.sb-tree-item.ng-move,
.sb-tree-item.ng-enter,
.sb-tree-item.ng-leave {
  transition: all 100ms;
}
.sb-tree-item.ng-leave.ng-leave-active,
.sb-tree-item.ng-move,
.sb-tree-item.ng-enter {
  opacity: 0;
  max-height: 0;
}
.sb-tree-item.ng-leave,
.sb-tree-item.ng-move.ng-move-active,
.sb-tree-item.ng-enter.ng-enter-active {
  opacity: 1;
  max-height: 64px;
}
.sb-tree-item.ng-leave-stagger {
  /* this will have a 100ms delay between each successive leave animation */
  transition-delay: 0ms;
  /* in case the stagger doesn't work then these two values
         must be set to 0 to avoid an accidental CSS inheritance */
  transition-duration: 0s;
}
.sb-tree-item.ng-enter-stagger {
  /* this will have a 100ms delay between each successive enter animation */
  transition-delay: 0ms;
  /* in case the stagger doesn't work then these two values
         must be set to 0 to avoid an accidental CSS inheritance */
  transition-duration: 0s;
}
sb-table {
  position: absolute;
  top: 0;
  right: 0;
  left: 0;
  bottom: 0;
  display: flex;
  justify-content: center;
  align-items: flex-start;
  flex-direction: row;
  overflow: hidden;
  height: calc(100% - 48px);
  background: #E1E1E1;
}
.sb-table-container {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  overflow: hidden;
  flex-wrap: nowrap;
  height: 100%;
  width: 100%;
}
.sb-table-header {
  height: 56px;
  /*   .flex-row;
    flex-shrink: 0;
    background: @sablono-grey-200;
    color: @sablono-grey-600;
    font-weight: @font-weight-normal;
    .sb-table-cell{
        .flex-row-center-start;
        width: 100%;
        height: @sb-table-header-height - 4;
    }*/
}
.sb-table-body {
  overflow-y: auto;
  flex-grow: 1;
  background: #FFFFFF;
  height: calc(100% - 48px);
  min-height: calc(100% - 48px);
}
.sb-table-body table {
  border-collapse: collapse;
  width: 100%;
}
.sb-table-body .sb-table-row {
  width: 100%;
  max-width: 100%;
  display: flex;
  flex-direction: row;
  background: #FFFFFF;
}
.sb-table-cell {
  border-collapse: collapse;
  text-align: left;
  display: flex;
  flex-direction: row;
  flex: 1;
  width: auto;
  min-width: 100px;
  height: 40px;
  border-bottom: 1px solid #CECECE;
  align-items: center;
  justify-content: flex-start;
  padding: 2px 8px;
}
.sb-table-cell span {
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
  min-width: 0;
  min-height: 0;
}
.sb-table-cell-rows {
  display: flex;
  flex-direction: column;
}
.sb-table {
  width: 100%;
  min-width: 100%;
  -moz-user-select: none;
  -webkit-user-select: none;
  -ms-user-select: none;
  cursor: default;
}
.sb-table-body tbody tr:hover,
.sb-table tbody tr:focus {
  background: #FBFAFA;
}
.sb-table-footer {
  flex-shrink: 0;
  display: inline-flex;
  width: 100%;
  align-items: center;
  padding: 16px;
  box-sizing: border-box;
  height: 48px;
  font-weight: 300;
  font-size: 16px;
  background: #E1E1E1;
}
.sb-table-footer .md-button.md-raised[disabled] {
  background-color: #858585;
}
.sb-table-footer .md-button.md-raised[disabled] span {
  color: #FFFFFF;
}
.sb-table-footer .sb-table-page-active {
  background-color: #CECECE;
}
.sb-table-footer .md-button {
  min-width: 1em;
}
sb-activity-item {
  text-align: center;
  box-sizing: border-box;
  width: 100%;
  float: left;
  display: block;
  outline: none;
  margin: 0 5% 30px 5%;
  max-width: 630px;
}
sb-activity-item .activity-wrapper,
sb-activity-item .task,
sb-activity-item .state {
  position: relative;
  height: 70px;
  display: flex;
  width: 90%;
  user-select: none;
  user-focus: none;
}
sb-activity-item .task,
sb-activity-item .state {
  width: 50%;
  flex: 1;
  align-items: center;
  justify-content: center;
}
sb-activity-item .task:after,
sb-activity-item .state:after {
  content: "";
  position: absolute;
  right: -25px;
  top: 0;
  width: 0;
  height: 0;
  border-top: 35px solid transparent;
  border-left: 25px solid #858585;
  border-bottom: 35px solid transparent;
}
sb-activity-item .task:before,
sb-activity-item .state:before {
  content: "";
  position: absolute;
  left: 0;
  top: 0;
  width: 0;
  height: 0;
  border-top: 35px solid transparent;
  border-left: 25px solid #FFFFFF;
  border-bottom: 35px solid transparent;
}
sb-activity-item .task span,
sb-activity-item .state span {
  padding: 0 25px;
  text-indent: 25px;
}
sb-activity-item .task {
  background-color: #36B381;
  z-index: 1;
}
sb-activity-item .task:after {
  border-left: 25px solid #36B381;
}
sb-activity-item .state {
  background-color: #36B381;
  margin-left: 2px;
}
sb-activity-item .state:after {
  border-left: 25px solid #36B381;
}
sb-activity-item .task sb-multiline-edit,
sb-activity-item .state sb-multiline-edit {
  overflow: hidden;
  margin-left: 30px;
  margin-right: 5px;
  width: 100%;
  height: 100%;
  text-align: center;
}
sb-activity-item .task .activity-edit-icon,
sb-activity-item .state .activity-edit-icon {
  position: absolute;
  top: 10px;
  right: -30px;
  z-index: 1;
}
sb-activity-item .task .activity-edit-icon .mdi,
sb-activity-item .state .activity-edit-icon .mdi {
  color: #FFFFFF !important;
}
.sb-user-image {
  width: 40px;
  height: 40px;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 16px;
  overflow: hidden;
  user-select: none;
  outline: none;
  background-color: transparent;
  color: #6C6C6C;
  border-color: #6C6C6C;
  border: 1px solid;
}
.sb-user-image--filled .sb-user-image {
  background-color: #36B381;
  color: #FFFFFF;
  border: none;
}
.sb-user-image--mini .sb-user-image {
  height: 30px;
  width: 30px;
  font-size: 15px;
}
.sb-resizeable-flex-box-drag-zone {
  position: absolute;
  top: 0;
  right: -7px;
  bottom: 0;
  width: 14px;
  cursor: col-resize;
  user-select: none;
}
[sb-resizeable-flex-box] {
  position: relative;
}
.sb-project-detail-card.sb-project-detail-card {
  padding: 0;
}
.sb-project-edit {
  padding-top: 320px;
}
.sb-project-edit-container {
  background: rgba(255, 255, 255, 0.9);
  position: relative;
  padding: 0 16px;
  display: flex;
  flex-direction: row;
}
.sb-project-edit-container:first-child {
  padding-top: 8px;
}
.sb-project-edit-container md-input-container {
  flex: 1;
}
.sb-project-edit-container-wrapper .sb-project-edit-container {
  padding: 0;
}
.sb-dialog .sb-project-edit {
  padding: 0;
}
.sb-dialog .sb-project-edit .sb-project-edit-container {
  display: flex;
  flex-direction: column;
  min-height: 36px;
}
.sb-project-edit-cover {
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-size: cover;
  background-position: 50% 50%;
  position: absolute;
}
.sb-project-edit-cover--not-editable {
  height: 491px;
}
.sb-project-edit-cover__file-input {
  width: 100%;
  height: 100%;
  position: absolute;
  top: -95px;
}
.sb-project-edit-cover__file-input .md-button {
  position: absolute;
  right: 0;
  top: 240px;
  color: #2B2B2B;
  background: rgba(255, 255, 255, 0.8);
}
.sb-project-edit-cover__file-input .md-button:hover,
.sb-project-edit-cover__file-input .md-button:focus,
.sb-project-edit-cover__file-input .md-button:active {
  background: rgba(255, 255, 255, 0.8);
}
.sb-project-edit-cover__saving {
  position: absolute;
  top: 8px;
  right: 8px;
}
.sb-project-edit-cover__suggestion {
  position: absolute;
  top: 195px;
  right: 8px;
  padding: 2px 8px;
  border-radius: 2px;
  background: rgba(255, 255, 255, 0.8);
}
.sb-project-edit--edit-mode {
  padding-top: 226px;
}
.sb-project-edit--edit-mode .sb-project-edit__dates {
  order: 3;
  flex-basis: 100%;
}
.sb-project-edit--edit-mode .sb-project-edit-container {
  flex-wrap: wrap;
}
.sb-project-edit__project-type__input {
  width: 100%;
  margin-bottom: 0;
}
.sb-project-edit__project-type__select {
  margin-bottom: 12px;
}
.sb-project-edit__dates {
  display: flex;
  margin-left: -4px;
}
.sb-project-edit__dates__start-date {
  margin-left: -12px;
}
.sb-project-edit__language-timezone {
  display: flex;
}
.sb-project-edit__language-timezone__language {
  flex: none;
}
.sb-project-edit__language-timezone__timezone {
  flex: 2;
}
.sb-template-group-edit-row {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  align-items: stretch;
}
.sb-template-group-edit-row sb-team-select {
  margin-top: 8px;
}
@media only screen and (max-width: 960px) {
  .sb-template-group-edit-row {
    display: flex;
    flex-direction: column;
  }
}
.sb-template-group-edit-category {
  flex: 1 0;
  min-width: 120px;
}
.sb-template-group-edit-code {
  flex: 1 0;
  min-width: 100px;
}
.sb-template-group-edit-name {
  min-width: 200px;
  flex: 6 0;
}
.sb-template-group-edit-desc {
  flex: 8 0;
}
@media only screen and (max-width: 960px) {
  .sb-template-group-edit-desc {
    max-height: 200px;
  }
}
.sb-template-group-duration-tooltip-trigger-display {
  display: flex;
  position: absolute;
  top: 10px;
  right: 0;
  cursor: pointer;
  z-index: 100;
}
md-input-container.sb-template-group-edit-duration.sb-template-group-edit-category {
  padding-left: 10px;
}
md-input-container.sb-template-group-edit-duration.sb-template-group-edit-category label {
  padding-left: 13px;
}
md-input-container.sb-template-group-edit-work-hours.sb-template-group-edit-category {
  padding-left: 10px;
}
md-input-container.sb-template-group-edit-work-hours.sb-template-group-edit-category label {
  padding-left: 13px;
}
.sb-template-activity-edit-row {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  align-items: flex-end;
}
.sb-template-activity-edit-row .sb-team-select {
  margin-left: inherit;
}
.sb-template-activity__edit {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
}
.sb-template-activity-edit-code {
  flex: 0 0 100px;
}
.sb-template-activity-edit-name {
  flex: 1 1;
}
@media only screen and (max-width: 960px) {
  .sb-template-activity-edit-name {
    flex: 0 0 48%;
  }
}
.sb-template-activity-edit-state {
  flex: 1 1;
}
@media only screen and (max-width: 960px) {
  .sb-template-activity-edit-state {
    flex: 0 0 48%;
  }
}
.sb-template-activity-edit-desc {
  flex: 1;
  min-width: 320px;
}
.sb-template-activity-color {
  flex: 1;
  max-width: 84px;
  margin-left: 8px;
}
.sb-template-activity__team-select {
  flex-grow: 1;
}
.sb-template-activity__team-select .sb-team-select__input {
  height: auto;
}
.sb-template-activity__checklist-input-container {
  max-width: 100%;
  flex-grow: 2;
}
.sb-template-activity-edit-duration {
  flex: 1 1 7%;
  max-width: 7%;
}
@media only screen and (max-width: 960px) {
  .sb-template-activity-edit-duration {
    flex: 1 1 20%;
    max-width: 20%;
  }
}
@media only screen and (max-width: 600px) {
  .sb-template-activity-edit-duration {
    flex: 1 1 25%;
    max-width: 25%;
  }
}
.sb-template-activity-edit-unit {
  flex: 1 1 20%;
  max-width: 20%;
}
@media only screen and (max-width: 960px) {
  .sb-template-activity-edit-unit {
    flex: 1 1 35%;
    max-width: 35%;
  }
}
@media only screen and (max-width: 600px) {
  .sb-template-activity-edit-unit {
    flex: 1 1 45%;
    max-width: 45%;
  }
}
.sb-template-activity-shrunk-control span.md-select-icon {
  position: absolute;
  right: 15px;
  top: 5px;
}
.sb-template-activity-tooltip-trigger-display {
  display: flex;
  position: absolute;
  top: 8px;
  right: 0px;
  cursor: pointer;
}
.sb-template-activity-layout-row-alignment {
  align-items: flex-end;
  height: 95px;
}
.sb-template-activity__labour {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  max-width: 175px;
}
@media only screen and (max-width: 960px) {
  .sb-template-activity__labour {
    max-width: 130px;
  }
}
.sb-template-activity__labour__unit {
  margin-bottom: 16px;
}
.sb-template-activity__checklist-input .md-select-icon {
  text-align: left;
}
.sb-template-activity__confirm-team-tooltip {
  margin-bottom: 12px;
}
.sb-toolbar-navigator.md-button {
  background-color: transparent;
  border-radius: 0;
  font-size: inherit;
  padding: 0;
  text-transform: none;
}
.sb-toolbar-navigator.md-button:hover {
  background-color: transparent;
}
.sb-toolbar-navigator {
  display: flex;
  height: 64px;
  margin-left: -16px;
}
.sb-toolbar-navigator-burger-icon {
  color: #FFFFFF;
  padding: 16px;
  font-size: 20px;
}
.sb-toolbar-navigator-logo {
  height: 24px;
}
.sb-toolbar-navigator-title {
  font-family: 'Work Sans', 'Helvetica Neue', sans-serif;
  font-size: 20px;
  font-weight: 400;
  color: #FFFFFF;
  padding-left: 16px;
  padding-right: 16px;
}
/*
 * Set up header color dependant on  specified section
 */
.sb-no-data-card-header,
h2.sb-no-data-card-header {
  color: #2B2B2B;
  font-weight: 400;
  font-size: 24px;
  margin-left: 0;
}
.sb-no-data-card__thumb-icon {
  color: #2B2B2B;
  font-weight: 300;
  font-size: 24px;
  margin-left: auto;
  margin-right: auto;
}
.sb-no-data-card-message,
p.sb-no-data-card-message {
  color: #2B2B2B;
  margin: 8px 0;
}
.sb-no-data-card-message img,
p.sb-no-data-card-message img {
  width: 100%;
  max-width: 100%;
}
.sb-no-data-card-actions {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-end;
  gap: 8px;
}
.sb-no-data-card-actions .md-button {
  color: #2B2B2B;
}
.sb-no-data-card {
  max-width: 520px;
  min-width: 400px;
  background-color: #FFFFFF;
  justify-content: space-between;
  text-align: left;
  padding: 8px 16px;
  align-self: center;
  min-height: 100%;
  margin: 0 auto;
}
sb-no-data {
  margin: 8px auto;
}
.sb-no-data-card-header-wrapper {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}
.sb-config-card-box-left {
  display: block;
  position: absolute;
  top: 60px;
  bottom: 0;
  left: 0;
  overflow-y: scroll;
}
.sb-config-card-box-left md-card {
  margin: 12px 0;
  width: 240px;
  max-width: 240px;
}
.sb-config-card-box-right {
  display: block;
  position: absolute;
  top: 0;
  bottom: 0;
  left: 250px;
  right: 0;
  margin: 32px 0 0;
  overflow-x: hidden;
}
.sb-config-card-box-right .sb-background-info .sb-trascluded-content {
  padding-right: 250px;
  box-sizing: border-box;
}
@media (max-width: 1350px) {
  .sb-config-card-box-right .sb-background-info .sb-trascluded-content {
    padding-right: 0;
  }
}
.sb-config-card-box {
  display: block;
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  overflow: hidden;
  background-color: #F4F3F2;
}
@media (max-width: 600px) {
  .sb-config-card-box-right {
    left: 0;
  }
}
.sb-checkbox {
  width: 32px;
  display: flex;
  justify-content: center;
  align-items: center;
  opacity: 0;
  transition: all 0.4s ease-in-out;
}
.sb-checkbox i {
  outline: none;
  padding: 8px;
  font-size: 18px;
}
.sb-checkbox.sb-checkbox-show {
  animation-duration: 0.5s;
  animation-name: sb-checkbox-fadeInFromNone;
  opacity: 1;
}
.sb-checkbox .mdi-checkbox-marked {
  color: #2B8F67;
}
.sb-checkbox .mdi-checkbox-marked {
  color: #309f73;
}
.sb-checkbox-parent-hover:hover .sb-checkbox {
  opacity: 1;
}
.sb-checkbox-layout {
  width: 32px;
}
@keyframes sb-checkbox-fadeInFromNone {
  0% {
    display: none;
    opacity: 0;
  }
  1% {
    display: block;
    opacity: 0;
  }
  100% {
    display: block;
    opacity: 1;
  }
}
.sb-selection-toolbar-layout-text {
  position: relative;
  top: 3px;
}
.sb-selection-toolbar-layout {
  justify-content: center;
}
.sb-selection-toolbar-content-layout {
  width: 100%;
  position: relative;
}
.sb-selection-toolbar-layout-right {
  position: absolute;
  top: 0px;
  right: 0px;
  height: 100%;
  padding: 0px;
  display: flex;
  align-items: center;
}
.sb-selection-toolbar-layout-right .md-menu {
  padding: 0px;
}
.sb-selection-toolbar-layout-right .md-menu .md-button {
  height: 40px;
  width: 40px;
  line-height: 40px;
  margin: 0 4px;
}
.sb-selection-toolbar-layout-right .md-menu .md-button .mdi {
  font-size: 22px;
}
.sb-subbar-mode {
  width: 970px;
  position: relative;
  height: auto;
  max-height: 48px;
}
.sb-subbar-mode--wide {
  background: #36B381;
  width: 100%;
}
.sb-subbar-mode--wide .sb-subbar-mode {
  width: 100%;
  max-width: 1400px;
  margin: 0 auto;
  padding-left: 0;
}
.sb-subbar-mode--full-size {
  width: 100%;
}
.sb-subbar-mode--full-size .sb-subbar-mode {
  width: 100%;
}
.sb-selection-toolbar-layout__button__icon {
  color: #FFFFFF;
}
[sb-content-fill] {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  display: flex;
  justify-content: center;
  align-items: flex-start;
  flex-direction: row;
  width: 100%;
  height: 100%;
  margin: 0 auto;
  overflow: hidden;
  box-sizing: border-box;
}
sb-content-overflow {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  display: flex;
  justify-content: center;
  align-items: flex-start;
  width: 100%;
  height: 100%;
  min-height: 0;
  min-width: 0;
  overflow-y: auto;
  overflow-x: hidden;
  padding: 0;
  box-sizing: border-box;
}
sb-content-overflow.sb-overflow-with-toolbar {
  top: 52px;
  height: calc(100% - 64px);
}
sb-content-overflow > md-content {
  padding: 16px 16px 0;
  flex-wrap: wrap;
  display: flex;
  align-items: flex-start;
  justify-content: center;
  box-sizing: border-box;
  background: transparent;
  height: 100%;
}
.sb-card {
  overflow-y: auto;
  overflow-x: hidden;
  width: 100%;
  position: relative;
  display: flex;
  flex: 1;
  flex-direction: column;
  flex-wrap: nowrap;
  height: 100%;
}
.sb-card-header {
  flex-shrink: 0;
}
.sb-card md-card-content {
  flex-grow: 1;
  overflow: auto;
  padding: 0;
}
.sb-card md-card-footer {
  flex-shrink: 0;
  display: flex;
  flex-direction: column;
}
.sb-card__narrow md-card-header {
  padding-bottom: 0;
}
.sb-card__narrow md-card-content {
  padding-top: 0;
}
.sb-card--hover {
  box-shadow: 0 4px 5px -2px rgba(0, 0, 0, 0.2), 0px 7px 10px 1px rgba(0, 0, 0, 0.14), 0px 2px 16px 1px rgba(0, 0, 0, 0.12);
}
.sb-tile {
  position: relative;
  flex: 300px 1 1;
  max-width: 300px;
  height: 300px;
  min-height: 300px;
  transition: all 0.3s ease-in-out;
  cursor: pointer;
  -moz-user-select: none;
  -webkit-user-select: none;
  -ms-user-select: none;
  cursor: default;
  background: #FFFFFF;
  color: #858585;
}
.sb-tile:hover {
  background: #F4F3F2;
}
.sb-tile .sb-tile-content {
  display: flex;
  flex: 1;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  align-content: center;
  box-sizing: border-box;
  width: 100%;
  min-width: 100%;
}
.sb-tile-just-text .sb-tile-content {
  justify-content: space-around;
}
.sb-tile .sb-tile-title,
.sb-tile h2 {
  max-width: 100%;
  font-size: 28px;
  font-weight: 300;
  padding: 16px 0;
  text-align: center;
  margin: 0;
  color: #858585;
}
.sb-tile .sb-tile-subtitle,
.sb-tile h3 {
  font-size: 20px;
  font-weight: 300;
  text-align: center;
  margin: 0;
  color: #858585;
}
.sb-tile .sb-tile-text,
.sb-tile p {
  margin: 8px 0;
  font-size: 16px;
  width: 100%;
}
.sb-tile .sb-tile-icon,
.sb-tile i {
  font-size: 92px;
  padding: 58px 0 0;
}
.sb-tile .sb-tile-icon.mdi,
.sb-tile i.mdi {
  font-size: 155px;
  padding-top: 20px;
  color: #36B381;
}
.sb-tile .sb-tile-image,
.sb-tile img {
  max-width: 100%;
  box-sizing: border-box;
}
.sb-tile--selectable {
  transition: all 0.2s ease-in-out;
}
.sb-tile--selectable:hover {
  cursor: pointer;
  box-shadow: 0 4px 5px -2px rgba(0, 0, 0, 0.2), 0px 7px 10px 1px rgba(0, 0, 0, 0.14), 0px 2px 16px 1px rgba(0, 0, 0, 0.12);
}
.sb-tile--selectable:focus {
  outline: none;
}
.sb-tile-container {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: center;
  align-items: center;
  background: transparent;
  position: relative;
  width: 100%;
  margin: 0 auto;
}
.import-file-input {
  display: flex;
  flex-direction: row;
  min-width: 304px;
}
.sb-file-card {
  box-sizing: border-box;
  height: 100%;
  margin: 2px;
}
.sb-file-card__new-card {
  height: 95%;
  padding: 0 16px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  box-sizing: border-box;
  cursor: pointer;
  border: 2px dashed #CECECE;
  color: #858585;
  text-shadow: 1px 2px 2px #FBFAFA;
  font-weight: 300;
  text-align: center;
  transition: all 0.2s ease;
}
.sb-file-card__new-card:hover {
  outline: none;
  border: 2px solid #CECECE;
  background: #F4F3F2;
}
.sb-file-card__new-card__icon {
  font-size: 32px;
  display: block;
}
.sb-warning-box {
  display: flex;
  flex-direction: row;
  margin: 16px auto 16px auto;
  width: 100%;
  max-width: 500px;
  border: 1px solid #E1E1E1;
}
.sb-warning-box-image {
  background-color: #EFA54D;
  display: flex;
  width: 60px;
  max-width: 60px;
  min-width: 60px;
}
.sb-warning-box-image .mdi {
  color: #FFFFFF;
}
.sb-warning-box-text {
  padding: 16px 8px;
  display: flex;
  flex-direction: column;
  width: 100%;
}
.sb-warning-box-text span {
  text-align: left;
}
.sb-warning-box-text small {
  text-align: left;
  padding: 16px 0;
}
.sb-loading > div {
  display: inline-block;
  width: 18px;
  height: 18px;
  background-color: #36B381;
  animation: bouncedelay 1.4s infinite ease-in-out both;
}
.sb-loading.mini > div {
  width: 8px;
  height: 8px;
  margin: 0;
}
.sb-loading .one {
  animation-delay: -0.32s;
}
.sb-loading .two {
  animation-delay: -0.16s;
  background: #36B381;
}
.sb-loading .three {
  background: #36B381;
}
@keyframes bouncedelay {
  0%,
  80%,
  100% {
    transform: scale(0);
  }
  40% {
    transform: scale(1);
  }
}
.sb-calendar-selection-container {
  overflow: hidden;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
}
.sb-calendar-selection-container .sb-days-container .sb-day-container {
  padding: 0 16px 12px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}
.sb-calendar-selection-container .sb-days-container .sb-day-container:first-child {
  padding-left: 0;
}
.sb-calendar-selection-container .sb-days-container .sb-day-container:last-child {
  padding-right: 0;
}
.sb-calendar-selection-container .sb-days-container .sb-day-container .sb-day {
  height: 40px;
  width: 40px;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  border-radius: 3px;
  background-color: #F4F3F2;
  outline: none;
}
.sb-calendar-selection-container .sb-days-container .sb-day-container .sb-day.sb-selected {
  background-color: #36B381;
  color: #FFFFFF;
  font-weight: 400;
}
.sb-calendar-selection-container .sb-days-container .sb-day-container .sb-day.sb-selectable {
  cursor: pointer;
}
.sb-calendar-selection-container .sb-hours-container {
  margin: 10px 0;
}
.sb-calendar-selection-container .sb-hours-container .sb-hours-content {
  flex: 1;
}
.sb-calendar-selection-container .sb-hours-container .sb-hours-content:first-child {
  margin-right: 7px;
}
.sb-calendar-selection-container .sb-hours-container .sb-hours-content:last-child {
  margin-left: 7px;
}
.sb-calendar-selection-container .sb-hours-container .sb-hours-content .sb-time-pickers-container {
  display: flex;
  flex: 1;
}
.sb-calendar-selection-container .sb-hours-container .sb-hours-content .sb-time-pickers-container sb-time-picker {
  flex: 1;
  display: flex;
}
.sb-calendar-selection-container .sb-hours-container .sb-hours-content .sb-time-pickers-container .sb-break-line {
  flex: 0 0 10px;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
}
.sb-calendar-selection-container .sb-hours-container .sb-hours-content md-input-container {
  margin: 0;
  width: 100px;
  flex: 1;
}
.sb-calendar-selection-container .sb-hours-container .sb-hours-content md-input-container label {
  display: none;
}
.sb-calendar-selection-container .sb-hours-container .sb-hours-content md-input-container md-select[disabled] .md-select-value {
  color: black;
}
.sb-calendar-selection-container .sb-container.sb-days-container .sb-content {
  margin-top: 7px;
}
.sb-calendar-selection-container .sb-container .sb-small-title {
  margin-left: 5px;
  font-size: 14px;
  color: #6C6C6C;
}
.sb-calendar-selection-container .sb-container .sb-content {
  flex: 1;
  display: flex;
  align-items: center;
  justify-content: center;
}
sb-timezone-selector {
  flex: 1;
}
sb-timezone-selector md-autocomplete.sb-timezone-autocomplete md-autocomplete-wrap md-input-container {
  padding-bottom: 0;
}
.sb-timezone-selector__tooltip {
  margin-top: 15px;
}
.sb-tag {
  font-size: 11px;
  font-weight: 400;
  padding: 2px 4px 1px;
  border-radius: 4px;
  margin: 1px;
  color: #FFFFFF;
}
.sb-tag.sb-tag-on-time {
  background: #5EC29A;
}
.sb-tag.sb-tag-behind {
  background: #B33636;
}
.sb-tag.sb-tag-unscheduled {
  background: #6C6C6C;
}
.sb-tag.sb-tag-open-claims {
  background: #E87E00;
}
.sb-tag.sb-tag-open-obstructions {
  background: #FF4141;
}
.sb-tag.sb-tag-infos {
  background: #365781;
}
.sb-tag.sb-tag-user-date {
  background: #36B381;
}
.sb-tag.sb-tag-user-date-conflict {
  background: #BA6500;
}
.sb-tag--normal {
  font-family: 'Work Sans', 'Helvetica Neue', sans-serif;
  font-size: 14px;
  font-weight: 500;
  line-height: 20px;
  padding: 1px 12px;
  border-radius: 12px;
  border: 1px solid;
  margin: 1px;
  color: #6C6C6C;
}
.sb-image-card {
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  margin: 8px;
  padding: 0;
}
.sb-image-card md-card-content {
  padding: 0 16px;
  cursor: pointer;
  outline: none;
}
.sb-image-card:hover,
.sb-image-card:focus {
  cursor: pointer;
  background-color: #F4F3F2;
}
.sb-image-card a {
  color: inherit;
  text-decoration: inherit;
}
.sb-image-card__content {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  flex: 1;
}
.sb-image-card__header {
  font-family: 'Work Sans', 'Helvetica Neue', sans-serif;
  font-size: 16px;
  font-weight: 500;
}
.sb-image-card-info {
  display: flex;
  flex-direction: row;
  align-items: center;
  width: 100%;
  float: left;
  box-sizing: border-box;
  overflow: hidden;
  font-family: 'Work Sans', 'Helvetica Neue', sans-serif;
  font-size: 14px;
  font-weight: 400;
  line-height: 20px;
  color: #2B2B2B;
}
.sb-image-card-info .sb-image-card-info-icon {
  float: left;
  padding-right: 4px;
}
.sb-image-card-info .sb-image-card-info-text {
  float: left;
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
  min-width: 0;
  min-height: 0;
}
.sb-image-card__footer {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  max-height: 36px;
  height: 36px;
}
.sb-image-card__delete-action:hover .mdi-delete,
.sb-image-card__delete-action:focus .mdi-delete {
  color: #B33636;
}
.sb-image-card__source-indicator {
  font-family: 'Work Sans', 'Helvetica Neue', sans-serif;
  font-size: 14px;
  font-weight: 500;
  line-height: 20px;
  padding: 1px 12px;
  border-radius: 12px;
  border: 1px solid;
  margin: 1px;
  color: #6C6C6C;
  border-color: #B3B3B3;
  text-transform: uppercase;
  white-space: nowrap;
  margin-left: 8px;
}
.sb-image-card__photo {
  height: 180px;
  background-size: contain;
  background-repeat: no-repeat;
  background-position: center;
  box-sizing: border-box;
  background-origin: content-box;
  padding-bottom: 16px;
}
.sb-tags {
  display: inline-flex;
  flex-wrap: wrap;
  justify-content: flex-end;
  max-width: 170px;
  overflow: visible;
}
.sb-inspection-badge {
  flex: 1;
  display: flex;
  width: 55px;
  max-width: 55px;
  height: 50px;
  justify-content: center;
  align-items: center;
  padding: 0 0 0 8px;
  position: relative;
  top: -1px;
}
.sb-inspection-badge-date {
  background-color: #FFFFFF;
  border: 1px solid #6C6C6C;
  color: #6C6C6C;
  font-weight: 400;
  font-size: 10px;
  padding: 2px 4px;
  border-radius: 3px;
  cursor: default;
  line-height: 1em;
  position: absolute;
  bottom: -1px;
  left: -8px;
}
.sb-filter-text {
  display: flex;
  flex-direction: row;
  align-items: flex-end;
  height: 40px;
}
.sb-filter-text .sb-filter-text-input {
  margin: 4px 0;
  width: 100%;
  position: relative;
}
.sb-filter-text .sb-filter-text-input input {
  height: 100%;
  font-size: 14px;
  border-width: 0;
  border: 1px solid #CECECE;
  width: 100%;
  box-sizing: border-box;
  padding: 8px 16px 8px 2px;
  outline-width: 0px;
  text-indent: 4px;
}
.sb-filter-text .sb-filter-text-input input:disabled {
  background: #FBFAFA;
  border-bottom: 1px dashed #B3B3B3;
}
.sb-filter-text .sb-filter-text-input input:focus {
  border-bottom-color: #36B381;
}
.sb-filter-text .sb-filter-text-input .sb-filter-text-dismiss {
  position: absolute;
  top: 4px;
  right: 4px;
  font-size: 20px;
  color: #2B2B2B;
}
.sb-filter-text .sb-filter-text-input .sb-filter-text-dismiss:hover {
  background-color: inherit;
}
.sb-filter-text .md-icon {
  border-width: 1px;
}
.sb-filter-text .md-checked .md-icon {
  background-color: #36B381;
}
.sb-filter-text md-checkbox .md-container {
  left: -1px;
}
.sb-filter-text .sb-filter-text-checkbox {
  margin: 0 0 11px 15px;
  min-width: 30%;
  font-size: 12px;
  align-self: flex-end;
}
.sb-filter-text .sb-filter-text-checkbox .md-label {
  line-height: 20px;
}
.sb-filter-text .md-errors-spacer {
  display: none;
}
.sb-filter-checkbox {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  margin: 8px 0;
  font-size: 12px;
}
.sb-filter-checkbox :first-letter {
  text-transform: capitalize;
}
.sb-filter-checkbox md-checkbox.sb-filter-checkbox-box {
  margin: 0 16px 0 0;
  width: 105px;
  display: flex;
  align-items: center;
}
.sb-filter-checkbox md-checkbox.sb-filter-checkbox-box .md-icon {
  border-width: 1px;
}
.sb-filter-checkbox md-checkbox.sb-filter-checkbox-box.sb-filter-checkbox-box-all {
  min-width: 60px;
  width: 60px;
  font-weight: 400;
  border-right: 1px solid #858585;
  padding-right: 16px;
}
.sb-filter-checkbox md-checkbox.sb-filter-checkbox-box.sb-filter-checkbox-box-all.md-checked .md-icon {
  opacity: 0.5;
}
.sb-filter-checkbox md-checkbox.sb-filter-checkbox-box.md-checked .md-icon {
  background-color: #36B381;
}
.sb-filter-date .sb-filter-date-select {
  margin: 4px 0 8px;
  background: #F4F3F2;
  padding: 4px;
}
.sb-filter-date md-select .md-select-value {
  border-bottom-color: transparent;
}
.sb-filter-date .sb-filter-date-chooser {
  margin: 4px 0 16px;
}
.sb-filter-date .sb-filter-date-chooser .md-datepicker-input-container {
  margin-left: 0;
  margin-right: 32px;
  border-bottom-width: 0;
}
.sb-filter-date .sb-filter-date-chooser .md-button.md-icon-button {
  margin: 0;
  padding: 0;
  width: 30px;
}
.sb-filter-date .sb-filter-date-chooser .md-button.md-icon-button md-icon {
  color: #858585;
  margin-bottom: 4px;
}
.sb-filter-date .sb-filter-date-chooser span {
  padding-left: 2px;
}
.sb-filter-date-selection md-select-menu {
  max-height: none;
}
.sb-filter-date-selection md-select-menu md-content {
  max-height: none;
}
.sb-filter-workflow .sb-filter-autocomplete-checkbox {
  margin-left: 16px;
}
.sb-filter-autocomplete {
  display: flex;
  flex-direction: row;
}
.sb-filter-autocomplete sb-structure-autocomplete {
  width: 100%;
}
.sb-filter-autocomplete md-autocomplete {
  flex: 1;
  margin-bottom: 3px;
  height: 32px;
  width: 100%;
  padding: 4px 0 9px 0;
  background: #FFFFFF;
}
.sb-filter-autocomplete md-autocomplete input,
.sb-filter-autocomplete md-autocomplete input.ng-invalid {
  border: none!important;
}
.sb-filter-autocomplete md-autocomplete button {
  margin: 4px 16px;
}
.sb-filter-autocomplete md-autocomplete md-autocomplete-wrap {
  box-shadow: none;
  background-color: #F4F3F2;
  align-items: flex-end;
  margin-bottom: 2px;
  height: 38px;
}
.sb-filter-autocomplete md-autocomplete md-autocomplete-wrap input {
  width: 90%;
  padding: 0 2px 6px;
  height: 33px;
  text-indent: 4px;
}
.sb-filter-autocomplete md-autocomplete md-autocomplete-wrap:after {
  font-family: 'Material Design Icons';
  content: '\25BC';
  position: absolute;
  bottom: 10px;
  right: 8px;
  speak: none;
  transform: scaleY(0.5) scaleX(1);
}
.sb-filter-autocomplete md-autocomplete[disabled] {
  background: #FFFFFF;
}
.sb-filter-autocomplete md-autocomplete[disabled] md-autocomplete-wrap {
  background: #FBFAFA;
  padding: 0;
  border-bottom: 1px dashed #B3B3B3;
}
.sb-filter-autocomplete md-autocomplete[disabled] input {
  color: #858585;
  background: #FBFAFA;
  border: none;
  box-shadow: none;
}
.sb-filter-autocomplete md-select[disabled] .md-select-value {
  background: #F4F3F2;
  height: 35px;
  top: 4px;
}
.sb-filter-autocomplete md-checkbox.md-checked .md-icon {
  background: #36B381;
}
.sb-filter-autocomplete md-checkbox .md-icon {
  border-width: 1px;
}
.sb-filter-autocomplete md-checkbox .md-container {
  left: -1px;
}
.sb-filter-autocomplete .sb-filter-autocomplete-checkbox {
  width: 30%;
  font-size: 12px;
  margin-bottom: 16px;
  align-self: flex-end;
}
.sb-filter-autocomplete .sb-filter-autocomplete-checkbox .md-label {
  line-height: 20px;
}
.sb-autocomplete-item-multiline md-autocomplete-parent-scope {
  display: flex;
  align-items: flex-start;
  max-height: 100%;
}
.sb-autocomplete-item-multiline li {
  line-height: normal;
  display: flex;
  align-items: center;
  margin: 4px 0;
  height: auto;
  min-height: 48px;
  border-bottom: 1px solid #FBFAFA;
}
.sb-autocomplete-item-multiline-text {
  white-space: normal;
  line-height: normal;
  height: auto;
  padding-left: 20px;
  text-indent: -20px;
}
.sb-filter-user {
  display: flex;
  flex-direction: row;
  align-items: flex-end;
  height: 40px;
}
.sb-filter-user .sb-filter-user-input {
  width: 100%;
  position: relative;
}
.sb-filter-user .sb-filter-user-input input {
  height: 100%;
  font-size: 14px;
  border-width: 0;
  border: 1px solid #CECECE;
  width: 100%;
  box-sizing: border-box;
  padding: 8px 16px 8px 2px;
  outline-width: 0px;
  text-indent: 4px;
}
.sb-filter-user .sb-filter-user-input input:disabled {
  background: #FBFAFA;
  border-bottom: 1px dashed #B3B3B3;
}
.sb-filter-user .sb-filter-user-input input:focus {
  border-bottom-color: #36B381;
}
.sb-filter-user .sb-filter-user-input md-autocomplete-wrap {
  box-shadow: none;
}
.sb-filter__container md-select .md-select-value {
  border-bottom-color: transparent;
}
.sb-filter__container md-select .md-select-placeholder {
  color: #6C6C6C;
  font-weight: 400;
}
.sb-filter__selector {
  margin: 4px 0 8px;
  background: #F4F3F2;
  padding: 4px;
}
.sb-filter-box {
  padding: 16px 16px 0;
  text-align: left;
  float: left;
  display: flex;
  flex-direction: column;
  max-height: 520px;
  overflow-y: auto;
}
.sb-filter-box .filter-advanced {
  max-height: 0;
  height: 0;
}
.sb-filter-box .filter-advanced.sb-filter-box-row {
  padding: 0;
}
.sb-filter-box.advanced-view .filter-advanced {
  max-height: 70px;
  height: inherit;
}
.sb-filter-box.advanced-view .filter-advanced.sb-filter-box-row {
  padding: 2px 0;
}
.sb-filter-box .sb-filter-box-row {
  min-width: 750px;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  justify-content: space-between;
  padding: 2px 0;
}
.sb-filter-box .sb-filter-column {
  display: flex;
  flex-direction: column;
}
.sb-filter-box .sb-filter-box-label {
  overflow: hidden;
  flex: 1;
}
.sb-filter-box .sb-filter-box-label :first-letter {
  text-transform: capitalize;
}
.sb-filter-box .sb-filter-box-input {
  height: 100%;
  min-height: 100%;
  max-height: 100%;
  overflow: hidden;
  flex: 3;
}
.sb-filter-box .sb-filter-box-input ::placeholder {
  text-overflow: ellipsis !important;
}
.filter-advanced .sb-filter-box-label {
  max-height: inherit;
  height: inherit;
}
.sb-expand-box .mdi-close {
  position: absolute;
  right: 0;
  top: 0;
  cursor: pointer;
  font-size: 20px;
  color: #B3B3B3;
  background-color: #FFFFFF;
  box-shadow: 0 0 1px 1px rgba(0, 0, 0, 0.14), 0 0 2px 2px rgba(0, 0, 0, 0.098), 0 0 5px 1px rgba(0, 0, 0, 0.084);
  border-radius: 50%;
  outline-width: 0;
}
.sb-expand-box .mdi-close:hover {
  background-color: #CECECE;
}
.sb-view-filters {
  font-size: 14px;
  text-align: center;
  padding-top: 8px;
}
.sb-view-filters span.sb-filter-label {
  display: block;
  white-space: nowrap;
}
.sb-deliverables-filters-container {
  display: flex;
  width: 95%;
  max-width: 1400px;
  margin: 0 auto 8px;
  flex-direction: row;
  align-items: flex-end;
  justify-content: flex-end;
  box-shadow: 0 1px #e0e0e0;
}
.sb-filter-chips {
  display: inline-flex;
  align-items: center;
  justify-content: flex-start;
  flex-wrap: wrap;
  width: 100%;
  min-width: 0;
}
.sb-filter-chips md-chips {
  width: inherit;
}
.sb-filter-chips md-chips .md-chips {
  box-shadow: none;
}
.sb-filter-chips .md-chip-remove {
  margin-left: 10px;
}
.sb-filter-chips .md-chip-remove md-icon {
  min-width: 18px;
  min-height: 18px;
  border-radius: 50%;
  padding: 1px;
}
.sb-filter-chips .md-chip-remove md-icon:hover {
  background: #E1E1E1;
}
.sb-filter-chips .md-chip-content {
  font-size: 12px;
  padding-right: 8px !important;
}
.sb-filter-actions {
  min-width: inherit;
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  align-items: center;
}
.md-button.sb-filter-button {
  background-color: #36B381;
  width: 40px;
  height: 40px;
  min-width: 40px;
  min-height: 40px;
  border-radius: 50%;
  transition: background-color 0.5s ease-in-out;
}
.md-button.sb-filter-button:active,
.md-button.sb-filter-button:hover {
  background-color: #36B381;
}
.md-button.sb-filter-button .mdi {
  color: #FFFFFF;
}
.md-button.sb-filter-remove-button {
  width: 40px;
  height: 40px;
  min-width: 40px;
  min-height: 40px;
  background-color: #B3B3B3;
  border-radius: 50%;
  transition: background-color 0.5s ease-in-out;
}
.md-button.sb-filter-remove-button .mdi {
  color: #FFFFFF;
}
md-tooltip.sb-deliverables-filters-tooltip {
  width: 90px;
  z-index: 0;
}
.sb-user-badge .sb-user-badge-container {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  padding: 8px;
}
.sb-user-badge .sb-user-badge-container:hover .sb-user-badge-hover {
  display: block;
}
.sb-user-badge .sb-user-badge-user {
  display: flex;
  flex-direction: column;
  font-size: 14px;
  justify-content: space-between;
  width: calc(100% - 40px + 4px);
}
.sb-user-badge .sb-user-badge-user-name,
.sb-user-badge .sb-user-badge-user-display-name {
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
  min-width: 0;
  min-height: 0;
}
.sb-user-badge .sb-user-badge-user-name {
  font-size: 12px;
  color: #6C6C6C;
}
.sb-user-badge .sb-user-badge-user-display-name {
  font-size: 14px;
}
.sb-user-badge .sb-user-badge-user-role {
  text-transform: uppercase;
  font-size: 10px;
  font-weight: 500;
  margin-bottom: 2px;
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
  min-width: 0;
  min-height: 0;
}
.sb-user-badge .sb-user-badge-icon {
  position: relative;
}
.sb-user-badge .sb-user-badge-icon .sb-user-image {
  margin-right: 4px;
}
.sb-user-badge .sb-user-badge-icon .sb-user-badge-hover {
  outline-width: 0;
  cursor: pointer;
  display: none;
  position: absolute;
  top: 0;
  width: 40px;
  height: 40px;
  border-radius: 50%;
}
.sb-user-badge .sb-user-badge-icon .sb-user-badge-hover.mdi {
  font-size: 24px;
  text-align: center;
  line-height: 40px;
}
.sb-user-badge sb-user-image {
  outline-width: 0;
}
.sb-user-badge .sb-user-badge-container {
  background: rgba(54, 179, 129, 0.15);
}
.sb-user-badge .sb-user-image {
  background-color: #36B381;
}
.sb-user-badge .sb-user-badge-hover {
  color: #FFFFFF;
  background: #36B381;
}
.sb-user-badge .sb-user-badge-user-role {
  color: #2a8c65;
}
.sb-user-badge.admin .sb-user-badge-container {
  background: rgba(143, 43, 43, 0.15);
}
.sb-user-badge.admin .sb-user-image {
  background-color: #8F2B2B;
}
.sb-user-badge.admin .sb-user-badge-hover {
  color: #FFFFFF;
  background: #8F2B2B;
}
.sb-user-badge.admin .sb-user-badge-user-role {
  color: #681f1f;
}
.sb-user-badge.manager .sb-user-badge-container {
  background: rgba(248, 194, 68, 0.15);
}
.sb-user-badge.manager .sb-user-image {
  background-color: #F8C244;
}
.sb-user-badge.manager .sb-user-badge-hover {
  color: #FFFFFF;
  background: #F8C244;
}
.sb-user-badge.manager .sb-user-badge-user-role {
  color: #f6b213;
}
.sb-user-badge.commercial_manage .sb-user-badge-container {
  background: rgba(248, 194, 68, 0.15);
}
.sb-user-badge.commercial_manage .sb-user-image {
  background-color: #F8C244;
}
.sb-user-badge.commercial_manage .sb-user-badge-hover {
  color: #FFFFFF;
  background: #F8C244;
}
.sb-user-badge.commercial_manage .sb-user-badge-user-role {
  color: #f6b213;
}
.sb-user-badge.controller .sb-user-badge-container {
  background: rgba(54, 87, 129, 0.15);
}
.sb-user-badge.controller .sb-user-image {
  background-color: #365781;
}
.sb-user-badge.controller .sb-user-badge-hover {
  color: #FFFFFF;
  background: #365781;
}
.sb-user-badge.controller .sb-user-badge-user-role {
  color: #273f5d;
}
.sb-user-badge.inspector .sb-user-badge-container {
  background: rgba(54, 179, 129, 0.15);
}
.sb-user-badge.inspector .sb-user-image {
  background-color: #36B381;
}
.sb-user-badge.inspector .sb-user-badge-hover {
  color: #FFFFFF;
  background: #36B381;
}
.sb-user-badge.inspector .sb-user-badge-user-role {
  color: #2a8c65;
}
.sb-user-badge.reviewer .sb-user-badge-container {
  background: rgba(108, 108, 108, 0.15);
}
.sb-user-badge.reviewer .sb-user-image {
  background-color: #6C6C6C;
}
.sb-user-badge.reviewer .sb-user-badge-hover {
  color: #FFFFFF;
  background: #6C6C6C;
}
.sb-user-badge.reviewer .sb-user-badge-user-role {
  color: #525252;
}
.sb-user-badge.commercial_read .sb-user-badge-container {
  background: rgba(108, 108, 108, 0.15);
}
.sb-user-badge.commercial_read .sb-user-image {
  background-color: #6C6C6C;
}
.sb-user-badge.commercial_read .sb-user-badge-hover {
  color: #FFFFFF;
  background: #6C6C6C;
}
.sb-user-badge.commercial_read .sb-user-badge-user-role {
  color: #525252;
}
.sb-odata-row-container {
  position: relative;
  box-shadow: 2px 0px 0px 0px rgba(0, 0, 0, 0.14), -2px 0px 0px 0px rgba(0, 0, 0, 0.14);
}
.sb-odata-row-container:nth-last-child(1) {
  margin-bottom: 8px;
  box-shadow: 0px 2px 0px 0px rgba(0, 0, 0, 0.14), 2px 0px 0px 0px rgba(0, 0, 0, 0.14), -2px 0px 0px 0px rgba(0, 0, 0, 0.14);
}
.sb-odata-row-container .sb-checkbox {
  position: absolute;
  height: 100%;
  z-index: 1;
  color: #858585;
  cursor: pointer;
}
.sb-odata-list-content {
  flex: 1;
  margin: 0 auto;
  background: #F4F3F2;
  overflow: hidden;
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 100%;
  min-height: 100%;
}
.sb-odata-list-content .sb-info-large {
  margin: auto;
  max-width: 100%;
}
.sb-odata-list-repeater {
  flex: 1;
  height: 100%;
}
@media screen and (max-width: 960px) {
  .sb-odata-row-container,
  .sb-odata-list-repeater {
    height: 100%;
    padding-bottom: 0;
  }
  .sb-odata-row-container:nth-last-child(1) {
    margin-bottom: 0;
  }
}
.sb-search-bar {
  width: 100%;
  display: flex;
  flex-direction: row;
}
.sb-search-bar .sb-search-bar-input,
.sb-search-bar .sb-search-input {
  margin: 0;
  width: 100%;
  font-weight: 300;
  font-size: 18px;
}
.sb-search-bar .sb-search-bar-input.md-input-focused .sb-search-bar-input,
.sb-search-bar .sb-search-input.md-input-focused .sb-search-bar-input {
  padding: 8px 16px;
  background: #E1E1E1;
  border-bottom-color: #E1E1E1;
}
.sb-search-bar .sb-search-bar-input .md-errors-spacer,
.sb-search-bar .sb-search-input .md-errors-spacer {
  min-height: 0;
}
.sb-search-bar .sb-search-bar-input ::-ms-clear,
.sb-search-bar .sb-search-input ::-ms-clear {
  display: none;
}
.sb-search-bar .sb-search-icon {
  font-size: 24px;
  color: #858585;
  line-height: 36px;
  padding-right: 10px;
  cursor: pointer;
  outline-width: 0;
}
.sb-search-bar .sb-search-bar-input {
  background: #FBFAFA;
  box-sizing: content-box;
  padding: 8px 16px;
  border-radius: 4px;
  border: 1px solid #E1E1E1;
}
.sb-elastic-input-ruler {
  visibility: hidden;
  white-space: normal;
  position: absolute;
  top: 0;
  left: 0;
  height: 0!important;
  border: 1px solid transparent;
}
.sb-elastic-input-container {
  margin: 0;
  padding: 0;
  height: 24px;
}
.sb-elastic-input-container .md-input.sb-elastic-input.disabled {
  color: #4C4C4C;
  pointer-events: none;
  border-bottom: 1px solid transparent;
}
.sb-elastic-input-container .sb-elastic-input {
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
  min-width: 0;
  min-height: 0;
}
.sb-elastic-input-container.editable .md-errors-spacer {
  min-height: 12px;
}
.sb-elastic-input-container.editable .md-input-message-animation {
  background: rgba(255, 255, 255, 0.7);
  padding-top: 0;
}
.sb-elastic-input-container.editable:not(.md-input-invalid) .sb-elastic-input {
  outline-width: 0;
  box-sizing: border-box;
  border-bottom: 1px solid #36B381;
  margin-bottom: 4px;
}
.sb-elastic-input-container.editable:not(.md-input-invalid) .sb-elastic-input:hover {
  outline: 1px dashed #36B381;
  border-bottom: 1px solid #36B381;
}
.sb-elastic-input-container.editable:not(.md-input-invalid) .sb-elastic-input:hover:focus,
.sb-elastic-input-container.editable:not(.md-input-invalid) .sb-elastic-input:hover:active {
  border: none;
  border-bottom: 1px solid #36B381;
}
.sb-elastic-input-container.editable:not(.md-input-invalid) .sb-elastic-input:focus,
.sb-elastic-input-container.editable:not(.md-input-invalid) .sb-elastic-input:active {
  border-bottom: 1px solid #36B381;
}
.sb-content-zoom {
  position: fixed;
  left: 0;
  z-index: 1;
  color: #2B2B2B;
  margin: auto;
}
.sb-content-zoom md-slider.md-default-theme .md-thumb:after,
.sb-content-zoom md-slider .md-thumb:after,
.sb-content-zoom md-slider.md-default-theme .md-track.md-track-fill,
.sb-content-zoom md-slider .md-track.md-track-fill {
  background-color: #36B381 !important;
  border-color: #36B381 !important;
}
.sb-content-zoom__container {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  height: 100%;
  width: 100%;
  margin: 0;
}
.sb-content-zoom__slider {
  width: 200px;
}
.sb-content-zoom__btn {
  padding: 4px;
  background: none;
  border: none;
  color: #36B381;
  outline-width: 0;
}
.sb-content-zoom__icon--first {
  position: relative;
  font-size: 34px;
  border-radius: 6px;
}
.sb-content-zoom__icon--first:hover {
  cursor: pointer;
  background: #86D1B3;
}
.sb-content-zoom__icon--second {
  position: absolute;
  top: 7.3px;
  font-size: 14px;
  left: 6.8px;
}
.sb-structure-autocomplete {
  border-radius: 0;
}
.sb-structure-autocomplete[disabled] {
  border-bottom: none;
}
.sb-structure-autocomplete[disabled] input {
  background: #FFFFFF;
  border: none !important;
}
.sb-structure-autocomplete[disabled] input.ng-invalid {
  border: none !important;
}
.sb-structure-autocomplete input {
  border-bottom: 1px solid #36B381 !important;
}
.sb-structure-autocomplete input.ng-invalid {
  border-bottom: 1px solid #A13131 !important;
}
.sb-structure-autocomplete md-autocomplete-wrap {
  box-shadow: none !important;
}
.sb-choice-group {
  background-color: #FFFFFF;
}
.sb-choice {
  display: flex;
  flex-direction: row;
  height: auto;
  border-bottom: 1px solid #B3B3B3;
  padding: 16px 0;
}
.sb-choice:hover {
  background: #FBFAFA;
  cursor: pointer;
}
.sb-choice[disabled=disabled] {
  cursor: default;
  opacity: 0.6;
}
.sb-choice__icons {
  position: relative;
}
.sb-choice__icons .sb-choice-first-icon {
  font-size: 30px;
  color: #36B381;
}
.sb-choice__icons .sb-choice-first-icon.smaller {
  font-size: 30px;
}
.sb-choice__icons .sb-choice-first-icon.blue {
  color: #36B381;
}
.sb-choice__icons .sb-choice-second-icon {
  color: #36B381;
  font-size: 30px;
  position: absolute;
  bottom: 13px;
  left: 19px;
}
.sb-choice__icons .sb-choice-second-icon::before {
  color: #B3B3B3;
}
.sb-choice__icon-container,
.sb-choice__loading-container {
  flex: 0 0 auto;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 90px;
}
.sb-choice__icon-container .mdi-chevron-right,
.sb-choice__loading-container .mdi-chevron-right {
  align-self: flex-end;
  margin-right: 16px;
  color: #858585;
  font-size: 24px;
}
.sb-choice__icon-container md-progress-circular,
.sb-choice__loading-container md-progress-circular {
  align-self: flex-end;
  margin-right: 16px;
}
.sb-choice__image img {
  width: 40px;
}
.sb-choice__text-container {
  flex: 1;
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
}
.sb-choice__text-container h3,
.sb-choice__text-container p {
  margin: 4px;
  font-weight: 300;
  color: #6C6C6C;
}
.sb-choice__text-container h3 {
  color: #565656;
}
.sb-choice__text-container .sb-choice__warning {
  color: #E87E00;
}
.sb-choice__text-container .sb-choice__error {
  color: #FF4141;
}
.sb-info-message {
  padding: 8px 16px;
  margin: 0;
  position: relative;
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  background: #C0FFDE;
}
.sb-info-message i {
  font-size: 18px;
  margin-right: 16px;
}
.sb-info-message i.mdi-close {
  position: absolute;
  top: 0;
  right: 0;
  cursor: pointer;
  border-radius: 50%;
  padding: 2px;
  margin: 2px;
}
.sb-info-message span {
  margin-right: 8px;
}
.sb-info-message i {
  color: #72CAA7;
}
.sb-info-message i.mdi-close:hover {
  background: #86D1B3;
}
.sb-info-message-warning {
  background: #FFDEC0;
}
.sb-info-message-warning i {
  color: #EFA54D;
}
.sb-info-message-warning i.mdi-close:hover {
  background: #F1B266;
}
.sb-info-message-alert {
  background: #FFC0C0;
}
.sb-info-message-alert i {
  color: #B33636;
}
.sb-info-message-alert i.mdi-close:hover {
  background: #D18686;
}
.sb-info-message--clickable:hover {
  cursor: pointer;
}
.sb-datepicker-checkbox {
  margin-top: 24px;
  margin-left: 32px;
  padding-right: 16px;
}
.sb-datepicker-checkbox.md-checked .md-icon {
  background: #36B381;
  border-color: #36B381;
}
.sb-datepicker-checkbox .md-icon {
  border-color: #858585;
}
.sb-datepicker-checkbox.sb-datepicker-checkbox-range {
  margin: 0 16px 32px;
}
.sb-datepicker-input-group {
  display: flex;
  flex-direction: column;
}
.sb-datepicker-input-group > span {
  text-indent: 16px;
  margin-bottom: 8px;
}
.sb-datepicker-input-group > div {
  display: flex;
  flex-direction: row;
  align-items: flex-start;
}
.sb-datepicker-input-group .sb-datepicker {
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: flex-start;
  flex: 1;
}
.sb-datepicker-input-group .sb-datepicker-container {
  min-width: 230px;
  margin-right: 32px;
}
.sb-datepicker-input-group .sb-datepicker-container.disabled md-datepicker input {
  color: #FFFFFF;
}
.sb-datepicker-input-group .sb-datepicker-container.disabled .md-datepicker-calendar-icon {
  color: #B3B3B3;
}
.sb-datepicker-input-group .sb-datepicker-container input {
  font-size: 16px;
  font-weight: 300;
}
.sb-datepicker .md-datepicker-input {
  min-width: 140px;
}
.sb-datepicker__not-set-text {
  position: absolute;
  left: 4px;
  bottom: 8px;
  color: transparent;
}
.sb-datepicker--undefined-value md-datepicker input {
  color: transparent !important;
}
.sb-filter-menu {
  font-family: 'Work Sans', 'Helvetica Neue', sans-serif;
  font-size: 14px;
  font-weight: 400;
  color: #565656;
  width: 200px;
  box-sizing: border-box;
  padding: 16px;
}
.sb-filter-menu__process_activity {
  flex-direction: column;
}
.sb-filter-menu__area-manager-toggle {
  font-size: 12px;
}
.sb-filter-menu__area-manager-toggle .md-container {
  min-width: 34px;
}
.sb-filter-menu__area-manager-toggle .md-label span {
  line-height: 15px;
  max-width: 140px;
  white-space: initial;
  display: inline-block;
}
.sb-filter-menu__page {
  border-top: none;
  padding-top: 0;
}
.sb-checklists-ratio {
  padding: 1px 8px;
}
.sb-team-select {
  width: 100%;
  min-width: 220px;
  text-indent: 8px;
  margin-left: 16px;
  margin: 1em 0;
  padding: 0;
}
.sb-team-select__input {
  border-left: 5px solid;
  padding-right: 5px;
  height: auto;
}
.sb-team-select__input--colorless {
  border-color: transparent;
}
.sb-team-select__options md-select-menu,
.sb-team-select__options md-content {
  max-height: 320px;
}
.sb-team-select__option {
  border-left: 5px solid;
  margin: 2px 0;
}
.sb-team-select__option--colorless {
  margin: 2px 0;
  padding: 0 21px;
}
.md-select-value span:first-child {
  white-space: normal;
}
.sb-team-select__select--invalid {
  margin-top: 8px;
  font-size: 12px;
  white-space: nowrap;
  color: #FF4141;
}
.sb-inspections__loader {
  margin-bottom: 12px;
}
.sb-inspect__no-content .sb-no-data-card {
  margin-top: 8px;
  max-width: calc(100% - 16px);
}
.sb-inspections__content {
  overflow-y: auto;
}
.sb-inspections__list {
  padding: 0;
  color: #6C6C6C;
}
.sb-inspections__item .sb-inspection-row__part {
  padding-right: 20px;
}
.sb-inspections__navigation-arrow {
  font-size: 30px;
  position: absolute;
  top: 10px;
  right: 5px;
}
.sb-process-template {
  display: flex;
  width: 100%;
  align-items: center;
}
.sb-process-template .sb-process-template__icon {
  font-size: 24px;
  width: 30px;
  padding: 0 16px 0 8px;
  transform: translate(0, 0);
}
.sb-process-template .sb-process-template__text {
  display: flex;
  flex-direction: column;
  flex: 1;
  margin-right: 8px;
  max-width: 270px;
}
.sb-process-template .sb-process-template__text span,
.sb-process-template .sb-process-template__text small {
  padding-top: 4px !important;
  line-height: 16px;
  white-space: normal!important;
}
.sb-process-template .sb-process-template__fixed--text {
  align-items: flex-end;
  max-width: 105px;
}
.sb-process-template .sb-process-template__fixed--text small {
  margin-right: 0!important;
}
.sb-process-template .sb-user-image {
  color: #6C6C6C;
}
.sb-template-process--selected {
  background-color: #CECECE;
}
.sb-template-process--selected .sb-process-template__icon,
.sb-template-process--selected .sb-user-image {
  color: #2B2B2B;
}
.sb-progress-option__button.md-icon-button {
  height: 36px;
  width: 36px;
  padding: 0;
  line-height: inherit;
}
.sb-progress-option__button.md-icon-button .mdi {
  font-size: 24px;
}
.sb-progress-option__button.md-icon-button[disabled] {
  background: #E1E1E1;
  box-shadow: 0 1px #e0e0e0;
}
.sb-progress-option__button--square .sb-progress-option__button {
  background: #F4F3F2;
  box-shadow: none!important;
  position: relative;
}
.sb-progress-option__button--square .sb-progress-option__button[disabled] {
  background: none;
}
.sb-progress-option__button--square .sb-progress-option__button.md-icon-button {
  border-radius: 0;
  padding: 0;
}
.sb-progress-option__button--square .sb-progress-option__button[disabled]:hover {
  background: none!important;
}
.sb-progress-option__button--square .sb-progress-option__button:hover {
  background: #E1E1E1 !important;
}
.sb-progress-option__button--square .sb-progress-option__button:after {
  width: 0;
  height: 0;
  border-bottom: 12px solid #CECECE;
  border-left: 12px solid transparent;
  position: absolute;
  content: '';
  bottom: 0;
  right: 0;
}
.sb-progress-option__button--square .sb-progress-option__button[disabled]:after {
  border: none;
}
.sb-progress-option__button--square .sb-progress-option__button .mdi {
  font-size: 22px;
}
.sb-progress-option__button--square .sb-progress-option__button .mdi.sb-todo {
  font-size: 20px;
}
.sb-progress-option--cannot-start-icon {
  font-size: 12px;
}
.sb-progress-options__item .md-button[disabled] i {
  color: #CECECE;
}
.sb-progress-option__menu-header {
  padding: 0 16px 8px 16px;
  background-color: #FFFFFF;
  max-width: 500px;
  overflow: visible;
}
.sb-progress-option__menu-header .sb-progress-option__menu-header__title {
  font-family: 'Work Sans', 'Helvetica Neue', sans-serif;
  font-size: 14px;
  font-weight: 500;
}
.sb-progress-option__menu-header .sb-progress-option__menu-header__message {
  font-family: 'Work Sans', 'Helvetica Neue', sans-serif;
  font-size: 14px;
  font-weight: 300;
}
.sb-workflow-status {
  max-height: none;
}
.sb-activity-item {
  text-align: center;
  box-sizing: border-box;
  width: 220px;
  float: left;
  display: block;
  outline: none;
  font-size: 14px;
  transition: transform 0.1s ease-in-out;
  cursor: pointer;
  z-index: 1;
  overflow: hidden;
  margin: 8px 16px;
  max-width: 400px;
}
.sb-activity-item .activity-wrapper,
.sb-activity-item .task,
.sb-activity-item .state {
  position: relative;
  height: 44px;
  display: flex;
  width: 90%;
  user-select: none;
  user-focus: none;
}
.sb-activity-item .task,
.sb-activity-item .state {
  width: 50%;
  flex: 1;
  align-items: center;
  justify-content: center;
}
.sb-activity-item .task:after,
.sb-activity-item .state:after {
  content: "";
  position: absolute;
  right: -15px;
  top: 0;
  width: 0;
  height: 0;
  border-top: 22px solid transparent;
  border-left: 15px solid #858585;
  border-bottom: 22px solid transparent;
}
.sb-activity-item .task:before,
.sb-activity-item .state:before {
  content: "";
  position: absolute;
  left: 0;
  top: 0;
  width: 0;
  height: 0;
  border-top: 22px solid transparent;
  border-left: 15px solid #FFFFFF;
  border-bottom: 22px solid transparent;
}
.sb-activity-item .task span,
.sb-activity-item .state span {
  padding: 0 15px;
  text-indent: 15px;
}
.sb-activity-item .task {
  background-color: #36B381;
  z-index: 1;
}
.sb-activity-item .task:after {
  border-left: 15px solid #36B381;
}
.sb-activity-item .state {
  background-color: #36B381;
  margin-left: 2px;
}
.sb-activity-item .state:after {
  border-left: 15px solid #36B381;
}
.sb-activity-item:hover {
  transform: scale(1.05);
  font-weight: 500;
}
.sb-activity-name-input {
  width: 100%;
}
.sb-activity-state-name-input {
  width: 100%;
}
.sb-activity-form__labour-container {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  width: 100%;
}
.sb-activity-form__input-container {
  display: flex;
}
.sb-activity-form__input {
  min-width: 130px;
  width: 130px;
}
.sb-activity-form__labour-unit {
  margin-left: 8px;
  float: right;
}
.sb-activity-form__duration-container {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  width: 100%;
}
.sb-activity-form__duration-input-container {
  display: flex;
}
.sb-activity-form__duration-input {
  min-width: 80px;
}
.sb-activity-form__duration-unit {
  width: 100%;
  margin-left: 8px;
  float: right;
}
.sb-activity-annotation {
  width: 100%;
}
.sb-activity-color__container {
  width: 100%;
  max-width: 84px;
}
.sb-activity-form {
  display: flex;
  flex-direction: column;
}
.sb-activity-form__grouped-row {
  display: flex;
  flex-direction: row;
}
.sb-form__row--align-end {
  align-items: flex-end;
  flex: 1;
}
.sb-activity-form__name-input {
  min-width: 200px;
}
.sb-activity-form__state-input {
  flex: 5;
}
.sb-activity-form__color-input {
  flex: 2;
}
.sb-activity-form__annotation-input {
  display: flex;
  height: 100%;
}
.sb-activity-form__labour-input {
  display: flex;
  min-width: 200px;
}
.sb-activity-form__team-input {
  display: flex;
  width: 100%;
  margin-bottom: 16px;
}
.sb-activity-form__team-input .sb-team-select__input {
  height: 45px;
}
.sb-activity-form__team-input .sb-team-select {
  width: 100%;
  text-indent: 8px;
}
.sb-activity-form__start-date-input {
  min-width: 200px;
  float: right;
}
.sb-activity-form__duration-input {
  width: 100%;
}
.sb-traffic-light-selector__label {
  color: #6C6C6C;
}
.sb-traffic-light-selector__label-not-selected {
  text-decoration: underline;
  color: #B33636;
}
.sb-traffic-light-selector__select-type {
  display: flex;
  height: 100px;
  align-items: center;
  justify-content: space-around;
}
.sb-traffic-light-selector__type {
  opacity: 0.5;
  border: 1px solid;
  transition: all ease-in-out 0.4s;
  font-size: 32px;
  padding: 12px 16px;
  outline-width: 0;
  border-radius: 4px;
  cursor: pointer;
}
.sb-traffic-light-selector__type--selected.sb-claim {
  background-color: #E87E00;
  color: #FFFFFF;
  opacity: 1;
}
.sb-traffic-light-selector__type--selected.sb-info {
  background-color: #365781;
  color: #FFFFFF;
  opacity: 1;
}
.sb-traffic-light-selector__type--selected.sb-obstruction {
  background-color: #FF4141;
  color: #FFFFFF;
  opacity: 1;
}
.sb-traffic-light-selector__type--selected.sb-todo {
  background-color: #B3B3B3;
  color: #2B2B2B;
  opacity: 1;
}
.sb-traffic-light-selector__type--selected.sb-in-progress {
  background-color: #61A2F5;
  color: #FFFFFF;
  opacity: 1;
}
.sb-traffic-light-selector__type--selected.sb-done {
  background-color: #36B381;
  color: #FFFFFF;
  opacity: 1;
}
.sb-gallery {
  display: flex;
  flex: 1;
  position: relative;
  -moz-user-select: none;
  -webkit-user-select: none;
  -ms-user-select: none;
  cursor: default;
}
.sb-gallery__container {
  display: flex;
  flex: 1;
  overflow: hidden;
}
.sb-gallery__scroll {
  display: flex;
  flex: 1;
}
.sb-gallery__photo {
  width: 100%;
  background-position: center;
  background-size: contain;
  background-repeat: no-repeat;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
}
.sb-gallery__arrows {
  display: flex;
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
}
.sb-gallery__arrow {
  flex: 0 0 50%;
  display: flex;
  align-items: center;
  font-size: 80px;
  color: #FFFFFF;
  position: relative;
  outline-width: 0;
  opacity: 0;
  cursor: pointer;
}
.sb-gallery__arrow.sb-gallery__arrow--active {
  opacity: 1;
}
.sb-gallery__arrow--left {
  justify-content: flex-start;
}
.sb-gallery__arrow--right {
  justify-content: flex-end;
}
.sb-attachment-gallery {
  transition: 400ms;
  background-color: #FBFAFA;
  display: flex;
  height: 100%;
}
.sb-attachment-gallery__arrow {
  flex: 0 0 50px;
  display: flex;
  align-items: center;
  justify-content: center;
  outline-width: 0;
  position: relative;
  font-size: 40px;
  color: #CECECE;
}
.sb-attachment-gallery__arrow--active {
  color: black;
  cursor: pointer;
}
.sb-attachment-gallery__photos {
  flex: 1;
  width: auto;
  padding: 8px 0;
  overflow: hidden;
  display: flex;
}
.sb-attachment-gallery__photos .sb-file-card__new-card {
  min-height: 110px;
  border-width: 2px;
  outline-width: 0;
}
.sb-attachment-gallery__add-card {
  height: 100%;
}
.sb-attachment-gallery__scroll {
  position: relative;
  flex: 1;
  display: flex;
  transition: 400ms;
}
.sb-attachment-gallery__attachment-container {
  position: relative;
}
.sb-attachment-gallery__attachment-container .mdi-close-circle {
  position: absolute;
  right: 4px;
  top: 4px;
  cursor: pointer;
  background: #FFFFFF;
  border-radius: 50%;
  padding-top: 1px;
}
.sb-attachment-gallery__attachment-container .mdi-close-circle:hover {
  color: #B33636;
}
.sb-attachment-gallery__attachment {
  position: absolute;
  top: 4px;
  bottom: 4px;
  left: 4px;
  right: 4px;
}
.sb-attachment-gallery__attachment.sb-attachment-gallery__attachment--loading {
  position: relative;
}
.sb-attachment-gallery__attachment--image {
  background-size: contain;
  background-color: #FFFFFF;
  background-position: 50% 50%;
  background-repeat: no-repeat;
  outline-width: 0;
}
.sb-attachment-gallery__attachment--selectable {
  cursor: pointer;
}
.sb-attachment-gallery__attachment--selectable .sb-attachment-gallery__attachment--document:hover {
  color: #2B8F67;
}
.sb-attachment-gallery__attachment--document {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  color: #2B2B2B;
  padding: 4px;
}
.sb-attachment-gallery__attachment--document .mdi:before {
  font-size: 65px;
}
.sb-attachment-gallery__attachment-title {
  font-family: 'Work Sans', 'Helvetica Neue', sans-serif;
  font-size: 12px;
  font-weight: 400;
  line-break: anywhere;
  margin: 4px;
  overflow: hidden;
}
sb-properties-source-icon {
  display: inline;
  position: relative;
}
.sb-properties-source-icon__inherit-background-color {
  background-color: inherit;
}
.sb-properties-source-icon__inherit-background-color .sb-properties-source-icon__secondary-icon--background {
  background-color: inherit;
}
.sb-properties-source-icon__secondary-icon {
  position: absolute;
  font-size: 0.85em;
  bottom: -0.1em;
  right: -0.3em;
}
.sb-properties-source-icon__secondary-icon--background {
  position: absolute;
  width: 0.3em;
  height: 0.8em;
  bottom: -0.07em;
  right: 0.05em;
  background-color: #FFFFFF;
}
sb-inspection-progress-list {
  min-width: 320px;
  max-width: 960px;
  width: 100%;
  flex: 1;
  display: flex;
  flex-direction: column;
}
.sb-inspection-progress-list {
  min-width: 320px;
  max-width: 960px;
  width: 100%;
  flex: 1;
  background: #FFFFFF;
}
.sb-inspection-progress-list__deliverable {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  font-size: 18px;
  padding: 0 16px;
  cursor: pointer;
  line-height: 1.2em;
  outline-width: 0;
}
.sb-inspection-progress-list__deliverable button {
  cursor: pointer;
}
.sb-inspection-progress-list__item--dirty {
  font-style: italic;
  color: #2B2B2B;
}
.sb-inspection-progress-list__item--dirty .sb-inspection-progress-list__deliverable__info {
  color: #2B2B2B;
  text-indent: 8px;
}
.sb-inspection-progress-list__item--dirty .sb-inspection-progress-list__deliverable__info:before {
  content: '*';
  position: absolute;
  left: -6px;
}
.sb-inspection-progress-list__activity-list {
  list-style: none;
  padding: 0;
  margin: 0;
}
.sb-inspection-progress-list__deliverable__info {
  display: flex;
  flex-direction: column;
  position: relative;
  align-items: flex-start;
  padding: 8px;
  color: #565656;
}
.sb-inspection-progress-list__deliverable__info__code {
  font-size: 12px;
}
.sb-inspection-progress-list__deliverable__info__name {
  font-size: 16px;
  font-weight: 400;
}
.sb-inspection-progress-list__deliverable__info__structure {
  font-size: 14px;
}
.sb-inspection-progress-list__activity {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  font-size: 14px;
  justify-content: space-between;
  padding: 8px;
  width: 90%;
  margin: 0 0 0 auto;
  line-height: 1.2em;
}
.sb-inspection-progress-list__activity__row {
  background: #FBFAFA;
}
.sb-inspection-progress-list__activity__row:nth-child(even) {
  background: #FFFFFF;
}
.sb-inspection-progress-list__activity__info {
  display: flex;
  flex-direction: column;
}
.sb-inspection-progress-list__row {
  box-shadow: 0 1px #e0e0e0;
  outline-width: 0;
}
.sb-inspection-progress-list__progress-change .sb-progress-option__button.md-icon-button {
  background-color: #E1E1E1;
}
.sb-inspection-progress-list__progress-change .sb-progress-option__button.md-icon-button:hover {
  background-color: #CECECE !important;
}
.sb-inspection-progress-list__progress-change .sb-progress-option__button.md-icon-button:after {
  border-bottom-color: #B3B3B3;
}
.sb-activities-list-selector-searchbar {
  width: 92%;
  margin: 0 auto;
  position: relative;
  top: -8px;
}
.sb-activities-list-selector__header {
  box-shadow: 0 0 1px 1px rgba(0, 0, 0, 0.14), 0 0 2px 2px rgba(0, 0, 0, 0.098), 0 0 5px 1px rgba(0, 0, 0, 0.084);
  display: flex;
  flex-direction: row;
  align-content: center;
  align-items: center;
  margin: 8px auto;
  width: 92%;
  padding: 16px;
}
.sb-activities-list__header-checkbox {
  margin: 0;
}
.sb-activities-list__header--clickable {
  cursor: pointer;
  outline: none;
}
.sb-activities-list__header-checkbox--has-active-selections:before {
  font-size: 20px;
  position: absolute;
  left: 7px;
  bottom: -2px;
  content: '-';
}
.sb-activities-list__activity-name,
.sb-activities-list__template-progress-name {
  outline: none;
}
.sb-activities-list-selector__spacer {
  margin: 0 8px;
}
.sb-activities-list-selector__activity-team-name {
  font-family: 'Work Sans', 'Helvetica Neue', sans-serif;
  font-size: 12px;
  font-weight: 400;
  font-style: italic;
}
.sb-activities-list-selector__table {
  margin: 10px;
  margin-bottom: 0;
  position: relative;
  top: -10px;
  padding: 0;
  border: 2px solid #E1E1E1;
  border-bottom: none;
  border-top: none;
}
.sb-activities-list-selector__activity-list {
  display: flex;
  flex-direction: row;
  align-items: center;
  position: relative;
  color: #565656;
  outline-width: 0;
  border-bottom: 2px solid #E1E1E1;
  padding: 8px;
  margin: 0;
}
md-checkbox:last-of-type.sb-activities-list-selector__checkbox {
  flex: 0 0 auto;
  margin: 4px;
  margin-left: 2px;
}
md-checkbox:last-of-type.sb-activities-list-selector__checkbox.md-checked .md-icon {
  background-color: #36B381;
}
.sb-activities-list-selector__activity {
  cursor: pointer;
}
.sb-activities-list-selector__activity--selected {
  font-weight: 500;
  color: #36B381;
}
.sb-stepper {
  position: relative;
  background: #F4F3F2;
  padding: 8px 32px;
}
@media (max-width: 600px) {
  .sb-stepper {
    padding: 8px 0;
  }
}
.sb-stepper .sb-stepper-steps {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  z-index: 1;
  position: relative;
}
@media (max-width: 600px) {
  .sb-stepper .sb-stepper-steps {
    flex-direction: column;
    align-items: flex-start;
  }
}
.sb-stepper .sb-stepper-step {
  padding: 0 8px;
  background: #F4F3F2;
  display: flex;
  align-items: center;
  text-align: left;
  font-size: 14px;
}
@media (max-width: 600px) {
  .sb-stepper .sb-stepper-step {
    margin: 8px;
  }
}
.sb-stepper .sb-stepper-step .sb-stepper-icon {
  min-width: 28px;
  max-width: 28px;
  width: 28px;
  height: 28px;
  border-radius: 50%;
  position: relative;
}
.sb-stepper .sb-stepper-step .sb-stepper-icon-content {
  position: absolute;
  top: 0;
  left: 0;
  width: 28px;
  line-height: 28px;
  color: #FFFFFF;
  text-align: center;
  font-style: normal;
  font-size: 14px;
  font-weight: 400;
}
.sb-stepper .sb-stepper-step .sb-stepper-text {
  padding: 0 0 0 8px;
  color: #4C4C4C;
}
.sb-stepper .sb-stepper-step--active .sb-stepper-icon {
  background: #36B381;
}
.sb-stepper .sb-stepper-step--active .sb-stepper-text {
  font-weight: 400;
}
.sb-stepper .sb-stepper-step--inactive .sb-stepper-icon {
  background: #B3B3B3;
}
.sb-stepper .sb-stepper-step--inactive .sb-stepper-text {
  color: #6C6C6C;
}
.sb-stepper hr {
  z-index: 0;
  position: absolute;
  top: 50%;
  border: 0;
  height: 0;
  border-top: 1px solid rgba(0, 0, 0, 0.1);
  border-bottom: 1px solid rgba(255, 255, 255, 0.3);
  width: calc(100% - 64px);
  margin: 0;
}
@media (max-width: 600px) {
  .sb-stepper hr {
    display: none;
  }
}
.sb-stepper-step-active .sb-stepper-icon {
  background: #36B381;
}
.sb-filter-sidenav {
  z-index: 99;
  box-shadow: 0 0 10px 0 rgba(0, 0, 0, 0.62);
  margin-left: 32px;
  width: 200px;
}
.sb-filter-sidenav .sb-dashboard-container__filter-menu {
  position: relative;
  top: 0;
}
.sb-filter-sidenav__handle {
  z-index: 999;
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  width: 32px;
  font-size: 12px;
  font-weight: 500;
  line-height: 32px;
  box-shadow: 0 0 5px 0 rgba(0, 0, 0, 0.62);
  cursor: pointer;
  transform: rotate(180deg);
  writing-mode: vertical-rl;
  text-orientation: mixed;
  background-color: #2B8F67;
  outline: none;
}
.sb-filter-sidenav__handle .sb-filter-sidenav__handle-text {
  display: block;
  text-align: center;
  letter-spacing: 0.2em;
  text-transform: uppercase;
  margin: 0;
}
.sb-filter-sidenav__handle .mdi {
  font-size: 14px;
}
.sb-filter-menu md-input-container {
  width: 100%;
}
.sb-menu-filter__container md-select-menu {
  max-height: 432px;
}
.sb-menu-filter__container md-select-menu md-content {
  max-height: 432px;
}
.sb-menu-filter__container md-select-menu:not(.md-overflow) md-content {
  padding-top: 0;
}
.sb-menu-filter__search {
  position: sticky;
  top: 0;
  height: 48px;
  z-index: 2;
  border-bottom: 1px solid #CECECE;
  background-color: #FFFFFF;
  cursor: pointer;
  display: flex;
}
.sb-menu-filter__search-input {
  font-style: italic;
  border: none;
  outline: none;
  height: 100%;
  width: 100%;
  padding: 0;
  margin-left: 16px;
}
.sb-schedule-stats-card {
  position: relative;
}
.sb-card__overlay {
  position: absolute;
  width: 100%;
  z-index: 10;
}
.sb-components-schedule-stats__header,
.sb-components-schedule-stats__template-selector {
  display: flex;
  flex-direction: column;
  width: 100%;
}
.sb-template-bar-chart {
  display: flex;
}
.sb-template-bar-chart .sb-background-info {
  background-color: #FFFFFF;
}
.sb-chart__header-wrapper {
  z-index: 10;
  justify-content: space-between;
}
.sb-chart__header {
  margin: 0;
  color: #2B2B2B;
  font-size: 16px;
  font-weight: 400;
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
  min-width: 0;
  min-height: 0;
}
.sb-chart__subheader-label {
  color: #6C6C6C;
  font-family: 'Work Sans', 'Helvetica Neue', sans-serif;
  font-size: 12px;
  font-weight: 400;
}
.sb-chart__subheader-text {
  color: #565656;
  font-family: 'Work Sans', 'Helvetica Neue', sans-serif;
  font-size: 14px;
  font-weight: 400;
}
.sb-chart__header-content--text {
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
  min-width: 0;
  min-height: 0;
}
.sb-template-bar-chart__card {
  max-height: 100vh;
  overflow: auto;
}
.sb-edit__edited-label {
  transform: rotate(-30deg);
  font-size: 10px;
  border-radius: 4px;
  border: 1px solid;
  padding: 0 2px;
}
.sb-edit__badge {
  flex: 1;
  display: flex;
  width: 25px;
  max-width: 25px;
  height: 15px;
  justify-content: left;
  align-items: center;
  padding: 0 0 0 8px;
  position: relative;
  top: -1px;
}
.sb-warning-label {
  color: #B33636;
}
.sb-deliverables-checklists {
  background-color: #FBFAFA;
  height: 100%;
  display: block;
}
.sb-deliverables-checklists__title-wrapper {
  z-index: 1;
  position: sticky;
  top: 0;
  width: 100%;
  display: flex;
  align-items: center;
  background-color: #FBFAFA;
}
.sb-deliverables-checklists__title {
  margin-left: 16px;
  padding-right: 8px;
  font-weight: 300;
  color: #6C6C6C;
}
.sb-deliverables-checklists__list-container-wrapper {
  margin-top: 16px;
}
.sb-deliverables-checklists__list-container {
  padding-top: 0;
}
.sb-deliverables-checklists__list {
  background-color: #FFFFFF;
  border-bottom: 1px solid #E1E1E1;
}
.sb-deliverables-checklists__list:last-child {
  border-bottom: none;
}
.sb-deliverables-checklists__list:hover {
  background-color: #E1E1E1;
}
.sb-deliverables-checklists__list .md-button:not([disabled]):hover {
  background-color: initial;
}
.sb-deliverables-checklists__item {
  display: flex;
  flex-direction: row;
  outline: none;
  width: 100%;
  padding: 0 8px;
  background-color: #FFFFFF;
}
.sb-deliverables-checklists__item--not-started {
  display: flex;
  flex-direction: row;
  outline: none;
  width: 100%;
  padding: 0 8px;
  background-color: #FFFFFF;
  padding: 8px;
}
.sb-deliverables-checklists__open {
  align-self: center;
  width: 34px;
  font-size: 24px;
}
.sb-deliverables-checklists__checklist-name {
  flex: 1 0 0;
}
.sb-deliverables-checklists__attached-to {
  font-size: 12px;
}
.sb-deliverables-checklists__activity-name {
  font-size: 16px;
}
.sb-deliverables-checklists__checklist-name,
.sb-deliverables-checklists__activity,
.sb-deliverables-checklists__results,
.sb-deliverables-checklists__confirmer,
.sb-deliverables-checklists__trade-person {
  align-self: center;
}
.sb-deliverables-checklists__checklist-name {
  font-size: 20px;
  color: #2B2B2B;
}
.sb-deliverables-checklists__checklist-title {
  padding-top: 8px;
  flex-direction: column;
  flex: 1;
}
.sb-deliverables-checklists__activity {
  display: inline;
  color: #565656;
  width: 250px;
  padding-right: 8px;
}
.sb-deliverables-checklists__trade-person,
.sb-deliverables-checklists__confirmer {
  display: block;
  padding: 8px 0;
}
.sb-deliverables-checklists__results {
  display: flex;
  position: relative;
  min-width: 126px;
  padding-top: 8px;
  font-size: 12px;
  color: #565656;
}
.sb-deliverables-checklists__results--first {
  padding-left: 0;
}
.sb-deliverables-checklists__results.sb-deliverables-checklists__results--draft {
  color: #B3B3B3;
}
.sb-deliverables-checklists__results .sb-deliverable-cell {
  display: inline;
}
.sb-deliverables-checklists__results .sb-deliverables-checklists__tag-cloud {
  height: auto;
}
.sb-deliverables-checklists__tag-cloud > .sb-tags {
  flex-direction: column;
}
.sb-deliverables-checklists__checklist-ratio {
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  justify-content: space-evenly;
}
.sb-deliverables-checklists__divider {
  width: 1px;
  background-color: #E1E1E1;
  align-self: center;
  height: 54px;
}
.sb-deliverables-checklists__no-data {
  position: relative;
  top: 50%;
  transform: translate(0, -50%);
}
.sb-deliverables-checklists__badge {
  position: absolute;
  font-family: 'Work Sans', 'Helvetica Neue', sans-serif;
  font-size: 20px;
  font-weight: 400;
  transform: rotate(-10deg);
  top: 20px;
  right: -2px;
}
.sb-deliverables-checklists__draft-badge {
  position: absolute;
  font-family: 'Work Sans', 'Helvetica Neue', sans-serif;
  font-size: 20px;
  font-weight: 400;
  transform: rotate(-10deg);
  top: 20px;
  right: -2px;
  color: #565656;
}
.sb-deliverables-checklists__badge--rejected {
  position: absolute;
  font-family: 'Work Sans', 'Helvetica Neue', sans-serif;
  font-size: 20px;
  font-weight: 400;
  transform: rotate(-10deg);
  top: 20px;
  right: -2px;
  color: #8F2B2B;
}
.sb-deliverables-checklists__badge--confirmed {
  position: absolute;
  font-family: 'Work Sans', 'Helvetica Neue', sans-serif;
  font-size: 20px;
  font-weight: 400;
  transform: rotate(-10deg);
  top: 20px;
  right: -2px;
  color: #2B8F67;
}
.sb-deliverables-checklist__print-btn.md-fab {
  transition: width 0.2s ease-in-out;
  position: absolute;
  right: 0;
  top: 0;
  width: 0;
  height: 66px;
  padding: 0;
  margin: 0;
  border-radius: 0;
}
.sb-deliverables-checklists__row {
  width: 100%;
  flex-grow: 1;
  line-height: normal;
  padding: 8px;
}
.sb-deliverables-checklists__disabled {
  margin: 24px;
}
.sb-deliverables-checklists__no-checklists {
  margin: 24px;
  text-align: center;
}
#angular-checklist-viewer {
  width: 100%;
  height: 100%;
}
#angular-checklist {
  width: 100%;
  height: 100%;
}
.sb-fill-checklist {
  position: absolute;
  right: 0;
  bottom: 0;
  z-index: 1;
  width: 0;
  transition: all 0.3s ease-in-out;
  overflow-x: hidden;
  height: 100%;
  min-height: 100%;
  background-color: #FFFFFF;
}
.sb-fill-checklist.animate-from-left {
  top: 0;
  left: 0;
}
.sb-fill-checklist.open {
  width: 100%;
  height: 100%;
}
.sb-fill-checklist .sb-fill-checklist-close {
  z-index: 2;
  position: absolute;
  right: 0;
  font-size: 20px;
  cursor: pointer;
  border-radius: 50%;
  padding: 8px;
  outline-width: 0;
}
.sb-fill-checklist .sb-fill-checklist-close:hover {
  background: #E1E1E1;
}
.sb-fill-checklist .sb-fill-checklist-close:active {
  background: #858585;
}
md-sidenav.sb-deliverable-overlay {
  height: 100%;
  width: 95%;
  max-width: 1000px;
  box-shadow: none;
  background-color: transparent;
}
.sb-deliverable-overlay-content {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  height: 100%;
  margin-left: 20px;
  width: calc(100% - 20px);
  box-sizing: border-box;
  border-top: 1px solid #E1E1E1;
}
.sb-deliverable-overlay-close {
  background-color: #B3B3B3;
  display: flex;
  align-items: center;
  justify-content: center;
  color: white;
  padding: 5px;
  position: absolute;
  top: 50%;
  transform: rotateZ(-90deg);
  z-index: 1;
  cursor: pointer;
  transform-origin: 0 0;
  transition: color 400ms;
}
.sb-deliverable-overlay-close:hover {
  background-color: #858585;
}
.sb-deliverable-overlay-tabs {
  flex: 1;
}
.sb-deliverable-overlay-tabs md-tab-content > div {
  height: 100%;
  background-color: #FBFAFA;
  overflow: auto;
}
md-sidenav.sb-deliverable-overlay__narrow {
  container-type: inline-size;
  width: 450px;
}
@media only screen and (max-width: 960px) {
  .sb-deliverable-overlay {
    padding-left: 4px;
  }
  .sb-deliverable-overlay .sb-deliverable-overlay-close {
    padding: 8px;
  }
}
.sb-filter-by-team {
  display: flex;
  flex-direction: row;
  flex-grow: 1;
  justify-content: flex-end;
  right: 16px;
}
.sb-filter-by-team .sb-team-select__input {
  height: 30px!important;
}
.sb-filter-by-team .label {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin-right: 5px;
}
.sb-deliverable-date-info {
  flex: 0 100%;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  color: #6C6C6C;
  outline: none;
}
.sb-deliverable-times {
  display: flex;
  flex-direction: column;
}
.sb-deliverable-times:hover {
  color: #2B2B2B;
  cursor: pointer;
}
.sb-deliverable-times:hover .sb-data-presenter {
  color: #2B2B2B;
  cursor: pointer;
}
.sb-data-presenter {
  max-width: 270px;
  width: 270px;
  min-width: 270px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  min-height: 16px;
  font-family: 'Work Sans', 'Helvetica Neue', sans-serif;
  font-size: 12px;
  font-weight: 300;
}
.sb-date-presenter__icon {
  padding-right: 5px;
  font-family: 'Work Sans', 'Helvetica Neue', sans-serif;
  font-size: 16px;
  font-weight: 400;
}
.sb-date-presenter__label {
  flex: 2;
  min-width: 96px;
}
.sb-date-presenter__text {
  min-width: 80px;
  margin-left: 8px;
  font-family: 'Work Sans', 'Helvetica Neue', sans-serif;
  font-size: 14px;
  font-weight: 400;
}
.sb-date-presenter__text--warning {
  color: #A13131;
}
.sb-date-presenter__state-indicator {
  padding-left: 5px;
  font-family: 'Work Sans', 'Helvetica Neue', sans-serif;
  font-size: 16px;
  font-weight: 400;
}
.small-font {
  font-size: 14px;
  font-weight: 400;
}
.sb-deliverable-details__responsibilities,
.sb-deliverable-details__deliverable-link {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  padding: 3px 8px 2px 8px;
  font-family: 'Work Sans', 'Helvetica Neue', sans-serif;
  font-size: 16px;
  font-weight: 400;
  color: #6C6C6C;
}
.sb-deliverable-details__deliverable-type {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  padding: 3px 8px 2px 8px;
  font-family: 'Work Sans', 'Helvetica Neue', sans-serif;
  font-size: 16px;
  font-weight: 400;
  color: #6C6C6C;
}
.sb-deliverable-details__cm-deliverable-text {
  font-family: 'Work Sans', 'Helvetica Neue', sans-serif;
  font-size: 14px;
  font-weight: 400;
}
.sb-deliverable-details__cm-label {
  min-width: 170px;
  padding-left: 4px;
  font-family: 'Work Sans', 'Helvetica Neue', sans-serif;
  font-size: 12px;
  font-weight: 400;
}
.sb-deliverable-details__cm-text {
  font-family: 'Work Sans', 'Helvetica Neue', sans-serif;
  font-size: 14px;
  font-weight: 400;
}
a.sb-deliverable-details__cm-text {
  text-decoration: underline;
  cursor: pointer;
  color: #2B2B2B;
}
.sb-deliverable-details {
  position: relative;
}
.sb-deliverable-details .sb-deliverable-details__loading {
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  background-color: #FFFFFF;
  z-index: 2;
}
.sb-deliverable-details .sb-deliverable-details-header {
  display: flex;
  padding: 4px 4px 0;
}
.sb-deliverable-details .sb-deliverable-details-header .sb-deliverable-details-header-info {
  padding: 6px 0 6px 8px;
  box-sizing: border-box;
  flex: 3;
}
@media (max-width: 960px) {
  .sb-deliverable-details .sb-deliverable-details-header .sb-deliverable-details-header-info {
    width: 200px;
  }
}
.sb-deliverable-details .sb-deliverable-details-header .sb-deliverable-details-header-info .sb-info-name-code {
  min-width: 40px;
}
.sb-deliverable-details .sb-deliverable-details-header .sb-deliverable-details-header-info .sb-info-name-code input,
.sb-deliverable-details .sb-deliverable-details-header .sb-deliverable-details-header-info .sb-info-name-code textarea,
.sb-deliverable-details .sb-deliverable-details-header .sb-deliverable-details-header-info .sb-info-name-code span {
  font-weight: 300;
  font-size: 18px;
  padding: 0;
  height: 21px;
}
.sb-deliverable-details .sb-deliverable-details-header .sb-deliverable-details-header-info .sb-info-structure {
  color: #6C6C6C;
  font-size: 14px;
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
}
.sb-deliverable-details .sb-deliverable-details-header .sb-deliverable-details-header-info .sb-info-structure span {
  width: 90%;
  padding-left: 4px;
}
.sb-deliverable-details .sb-deliverable-details-header .sb-deliverable-details-header-info .sb-info-structure i {
  color: #B3B3B3;
  padding: 0 4px 3px 0;
}
.sb-deliverable-details .sb-deliverable-details-header .sb-deliverable-details-header-info .sb-info-structure-autocomplete {
  width: 93%;
}
.sb-deliverable-details .sb-deliverable-details-header .sb-deliverable-details-header-info .sb-info-structure-autocomplete md-autocomplete,
.sb-deliverable-details .sb-deliverable-details-header .sb-deliverable-details-header-info .sb-info-structure-autocomplete md-autocomplete md-autocomplete-wrap {
  height: 24px;
}
.sb-deliverable-details .sb-deliverable-details-header .sb-deliverable-details-header-info .sb-info-structure-autocomplete md-autocomplete input:not(.md-input) {
  height: 24px;
  line-height: 24px;
  font-size: 14px;
  font-weight: 300;
  color: #6C6C6C;
  padding: 4px;
}
.sb-deliverable-details .sb-deliverable-details-header .sb-deliverable-details-header-info .sb-info-structure-autocomplete md-autocomplete button {
  position: absolute;
  right: -30px;
  bottom: -2px;
  width: 40px;
  background: linear-gradient(to right, rgba(255, 255, 255, 0) 0%, #ffffff 26%, #ffffff 100%);
}
.sb-deliverable-details .sb-deliverable-details-header .sb-deliverable-details-header-info .sb-info-structure-autocomplete md-autocomplete button md-icon {
  right: 0;
}
.sb-deliverable-details .sb-deliverable-details-header .sb-info-overview {
  flex: 2;
  justify-content: space-between;
}
.sb-deliverable-details .sb-deliverable-details-header .sb-info-description {
  flex: 1;
  padding: 3px 0px 0px 0px;
  margin: 0;
  width: 400px;
  font-size: 14px;
  height: 50px;
  max-height: 50px;
  border-bottom: 1px solid #36B381;
  position: relative;
  overflow: auto;
}
@media (max-width: 1024px) {
  .sb-deliverable-details .sb-deliverable-details-header .sb-info-description {
    width: 300px;
  }
}
@media (max-width: 960px) {
  .sb-deliverable-details .sb-deliverable-details-header .sb-info-description {
    width: 200px;
  }
}
.sb-deliverable-details .sb-deliverable-details-header .sb-info-description label {
  position: relative;
}
.sb-deliverable-details .sb-deliverable-details-header .sb-info-description textarea {
  position: absolute;
  font-weight: 300;
  line-height: 1.3em;
  border: none;
}
.sb-deliverable-details .sb-deliverable-details-header .sb-info-description textarea.ng-invalid {
  border-bottom: 2px solid red;
}
.sb-deliverable-details .sb-deliverable-details-header .sb-info-description textarea[disabled] {
  border: none;
  color: #4C4C4C;
  -moz-user-select: none;
  -webkit-user-select: none;
  -ms-user-select: none;
  cursor: default;
}
.sb-deliverable-details .sb-deliverable-details-header .sb-info-description[disabled] {
  border: none;
}
.sb-deliverable-details .sb-deliverable-details-saved {
  display: flex;
  justify-content: flex-end;
  align-items: center;
  margin: 0 16px 0 16px;
}
.sb-deliverable-details .sb-deliverable-details-saved .sb-saving {
  position: absolute;
  right: 68px;
  top: 15px;
}
.sb-deliverable-details .sb-deliverable-details-saved .sb-toggle-edit-button,
.sb-deliverable-details .sb-deliverable-details-saved .sb-open-in-mobile-button {
  cursor: pointer;
  border-radius: 10px;
  background: #F4F3F2;
  width: 40px;
  height: 40px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  transition: all 0.2s;
}
.sb-deliverable-details .sb-deliverable-details-saved .sb-toggle-edit-button:hover .sb-toggle-edit-button-icon,
.sb-deliverable-details .sb-deliverable-details-saved .sb-open-in-mobile-button:hover .sb-toggle-edit-button-icon,
.sb-deliverable-details .sb-deliverable-details-saved .sb-toggle-edit-button:hover .sb-open-in-mobile-button-icon,
.sb-deliverable-details .sb-deliverable-details-saved .sb-open-in-mobile-button:hover .sb-open-in-mobile-button-icon {
  color: #36B381;
  transform: scale(1.1);
}
.sb-deliverable-details .sb-deliverable-details-saved .sb-toggle-edit-button .sb-toggle-edit-button-icon,
.sb-deliverable-details .sb-deliverable-details-saved .sb-open-in-mobile-button .sb-toggle-edit-button-icon,
.sb-deliverable-details .sb-deliverable-details-saved .sb-toggle-edit-button .sb-open-in-mobile-button-icon,
.sb-deliverable-details .sb-deliverable-details-saved .sb-open-in-mobile-button .sb-open-in-mobile-button-icon {
  font-size: 24px;
  color: #36B381;
}
.sb-deliverable-details .sb-deliverable-details-saved .sb-toggle-edit-button .sb-toggle-edit-button-icon.edit-mode-active,
.sb-deliverable-details .sb-deliverable-details-saved .sb-open-in-mobile-button .sb-toggle-edit-button-icon.edit-mode-active,
.sb-deliverable-details .sb-deliverable-details-saved .sb-toggle-edit-button .sb-open-in-mobile-button-icon.edit-mode-active,
.sb-deliverable-details .sb-deliverable-details-saved .sb-open-in-mobile-button .sb-open-in-mobile-button-icon.edit-mode-active {
  color: #36B381;
}
.sb-deliverable-details .sb-toggle-edit-button {
  margin-right: 8px;
}
.sb-deliverable-details .sb-deliverable-details-info-section {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: stretch;
  padding: 8px;
}
.sb-deliverable-details .sb-deliverable-details-info-section .sb-info-left {
  min-height: 60px;
  display: flex;
  flex-direction: column;
  flex: 1;
  position: relative;
  margin-right: 16px;
}
.sb-deliverable-details .sb-deliverable-details-info-section .sb-info-left .sb-deliverable-times-last-update {
  position: absolute;
  bottom: 1px;
}
.sb-deliverable-details .sb-deliverable-details-info-section .sb-info-right {
  display: flex;
  flex-direction: column;
  flex: 1;
  align-items: flex-end;
  justify-content: center;
  margin-right: 4px;
}
.sb-deliverable-details .sb-info-progress {
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
  max-width: 80px;
  padding: 8px;
  font-size: 12px;
  font-weight: 400;
  height: 40px;
  -moz-user-select: none;
  -webkit-user-select: none;
  -ms-user-select: none;
  cursor: default;
}
.sb-deliverable-details .sb-info-progress .mdi {
  font-size: 40px;
}
.sb-deliverable-details .sb-info-progress-value {
  position: absolute;
  right: 1px;
  bottom: 8px;
  border-radius: 10px;
  padding: 0 4px;
  background: #FFFFFF;
  color: #6C6C6C;
  font-size: 12px;
  font-weight: 500;
  -moz-user-select: none;
  -webkit-user-select: none;
  -ms-user-select: none;
  cursor: default;
}
.sb-deliverable-details .sb-data-title {
  min-width: 60px;
  font-size: 12px;
  font-weight: 300;
  color: #565656;
  -moz-user-select: none;
  -webkit-user-select: none;
  -ms-user-select: none;
  cursor: default;
}
.sb-deliverable-notes md-list,
.sb-deliverable-notes md-list-item {
  padding: 0;
}
.sb-deliverable-notes .md-subheader {
  top: 40px !important;
}
.sb-deliverable-notes .sb-deliverable-notes-summary {
  z-index: 1;
  position: sticky;
  top: 0;
  z-index: 3;
  background-color: #FBFAFA;
  display: flex;
  flex-direction: row;
  padding: 0 16px;
  justify-content: space-between;
}
.sb-deliverable-notes .sb-deliverable-notes-summary h3 {
  font-weight: 300;
  color: #6C6C6C;
  padding-right: 8px;
  margin: 0 0 4px 0;
}
.sb-deliverable-notes .sb-deliverable-notes-summary.sb-deliverable-notes-no-issues-happy .sb-all-issues-resolved-text {
  display: flex;
  align-items: center;
}
.sb-deliverable-notes .sb-deliverable-notes-summary.sb-deliverable-notes-no-issues-happy .sb-all-issues-resolved-text h3 {
  color: #267D5A;
}
.sb-deliverable-notes .sb-deliverable-notes-summary.sb-deliverable-notes-no-issues-happy .sb-all-issues-resolved-text i {
  color: #267D5A;
  margin-left: 5px;
  font-size: 20px;
}
.sb-deliverable-notes .sb-info-summary-notes {
  display: flex;
  flex-direction: row;
  font-size: 12px;
}
.sb-deliverable-notes .sb-info-summary-notes .sb-info-summary-value {
  display: flex;
  flex-direction: row;
  align-items: center;
}
.sb-deliverable-notes .sb-info-summary-notes .sb-info-summary-value span {
  padding-right: 4px;
}
.sb-deliverable-notes .sb-info-summary-notes .sb-info-summary-value i {
  padding-right: 12px;
}
.sb-deliverable-notes .sb-info-summary-notes .sb-claim {
  color: #E87E00;
}
.sb-deliverable-notes .sb-info-summary-notes .sb-obstruction {
  color: #FF4141;
}
.sb-deliverable-notes .sb-info-summary-notes .sb-info {
  color: #365781;
}
.sb-deliverable-notes .sb-info-summary-notes .sb-issue-closed {
  color: #B3B3B3;
}
.sb-deliverable-notes-no-issues i {
  font-size: 100px;
  padding-bottom: 0;
}
.sb-deliverable-notes-no-issues h1 {
  margin-top: 16px;
  margin-bottom: 0;
  color: #858585;
}
.sb-deliverable-notes-no-issues span {
  color: #2B2B2B;
}
.sb-deliverable-notes__header-layout {
  max-height: 55px;
  display: flex;
  flex-grow: 1;
  flex-direction: row;
  align-items: center;
}
.sb-deliverable-notes__header {
  display: flex;
  flex-direction: column;
}
.sb-deliverable-notes__add-issue-btn {
  background: #36B381;
  border-radius: 50%;
  margin-left: 8px;
  height: 20px;
  width: 20px;
  outline-width: 0;
  font-size: 18px;
  color: #FBFAFA;
  cursor: pointer;
}
.sb-progress-option__new-badge {
  margin-left: 8px;
  content: 'new!';
  transform: rotate(10deg);
  padding: 6px;
  background: #36B381;
  color: #FFFFFF;
  font-weight: 500;
  font-size: 12px;
  line-height: 12px;
  border-radius: 12px;
}
.sb-workflow-activity-row {
  display: flex;
  flex-direction: row;
  align-items: center;
  flex-wrap: nowrap;
  align-self: flex-start;
  min-height: 50px;
  height: 50px;
  color: #6C6C6C;
  font-weight: 400;
  padding: 4px;
  position: relative;
}
.sb-workflow-activity-row v-accordion {
  width: 100%;
}
.sb-workflow-activity-row .sb-workflow-activity-info {
  display: flex;
  align-items: center;
  flex: 1;
  max-width: 388px;
}
.sb-workflow-activity-row .sb-workflow-activity-info .sb-workflow-index {
  margin-right: 8px;
  margin-bottom: 0;
  min-width: 14px;
  line-height: 14px;
  border-radius: 25%;
  text-align: center;
  font-size: 10px;
  color: #858585;
  border: 1px solid;
  margin-left: 0;
  min-width: 18px;
  line-height: 18px;
  font-size: 12px;
}
.sb-workflow-activity-row .sb-user-image {
  margin-left: 8px;
}
.sb-workflow-activity-row .sb-workflow--violation {
  color: #E87E00;
}
.sb-workflow-activity-row .sb-data-title {
  min-width: 60px;
  font-size: 12px;
  font-weight: 300;
}
.sb-workflow__name-and-status {
  display: flex;
  flex-direction: row;
  justify-content: center;
  flex: 1 0 80px;
  padding-right: 8px;
}
.sb-workflow__details {
  display: flex;
  flex-direction: row;
  flex: 1 0 0;
}
.sb-workflow__details-inspection {
  padding-left: 16px;
  flex: 0 0 60px;
}
.sb-workflow__details-tags {
  flex: 0 0 80px;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  line-height: 1em;
}
.sb-workflow__details-tags sb-component-tag-cloud {
  text-align: right;
  width: 100%;
}
.sb-workflow__details-team {
  padding-right: 8px;
  max-width: 100px;
  width: 100px;
  flex: 0 0 100px;
  justify-content: center;
  display: flex;
  flex-direction: column;
}
.sb-workflow__details-team.sb-workflow__details-team--hidden {
  visibility: hidden;
}
.sb-workflow__dates {
  flex: 1 0 0;
  display: flex;
  flex-direction: column;
}
@media (max-width: 850px) {
  .sb-workflow__dates {
    display: none;
  }
}
@media (max-width: 850px) {
  .sb-workflow__activity-details-cell.sb-workflow__activity-details-cell--center {
    display: none;
  }
}
.sb-workflow__dates-row {
  display: flex;
  flex-direction: row;
}
.sb-workflow__dates-row-title {
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
  min-width: 0;
  min-height: 0;
  font-size: 12px;
  font-weight: 300;
}
.sb-workflow__cell-label {
  padding-right: 4px;
  flex: 0 0 120px;
}
.sb-workflow__cell-value {
  flex: 1 0 50px;
}
.sb-workflow-activity-plane__expanded-icon {
  position: absolute;
  top: 50%;
  transform: translate(-8px, -50%);
}
.sb-workflow-activity-plane__expanded-icon.mdi-minus {
  display: none;
}
.sb-workflow-activity-plane__expanded-icon.mdi-plus {
  display: inline-block;
}
v-pane.is-expanded .sb-workflow-activity-pane-header .mdi-plus {
  display: none;
}
v-pane.is-expanded .sb-workflow-activity-pane-header .mdi-minus {
  display: inline-block;
}
.sb-group__progress-icon {
  font-size: 20px;
  padding: 14px;
}
.sb-workflow-duration__spacer {
  min-width: 100px;
  max-width: 100px;
  width: 100px;
  flex: 1 0 100px;
}
.sb-workflow-total-float-box__spacer {
  min-width: 100px;
  max-width: 100px;
  width: 100px;
}
.sb-workflow-properties-source-icon__spacer {
  min-width: 30px;
  max-width: 30px;
  width: 30px;
  flex: 1 0 30px;
}
.sb-workflow-activity-group.sb-workflow-activity-row {
  margin-left: 20px;
  position: relative;
}
.sb-workflow-activity-group.sb-workflow-activity-row .sb-workflow__dates,
.sb-workflow-activity-group.sb-workflow-activity-row .sb-workflow-activity-info,
.sb-workflow-activity-group.sb-workflow-activity-row .sb-data-title,
.sb-workflow-activity-group.sb-workflow-activity-row .sb-workflow__completed-activities,
.sb-workflow-activity-group.sb-workflow-activity-row .sb-workflow-dates-row-title {
  font-weight: 500;
}
.sb-workflow-activity-group.sb-workflow-activity-row .sb-workflow-activity-info {
  display: flex;
  flex-direction: row;
  flex: 1 0 60px;
}
.sb-workflow-activity-group.sb-workflow-activity-row .sb-workflow-activity-group__chevron {
  position: absolute;
  transform: translate(-27px, 0);
}
.sb-workflow__completed-activities {
  width: 108px;
  align-self: center;
}
.sb-workflow__activity-details {
  display: block;
  padding: 4px 0 4px 82px;
  border-top: 1px solid #E1E1E1;
}
.sb-workflow__activity-details-row {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
}
.sb-workflow__activity-details-cell {
  display: flex;
  flex-direction: row;
  flex: 1 0 0;
  width: 100%;
}
.sb-workflow__activity-details-cell span {
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
  min-width: 0;
  min-height: 0;
  font-weight: 400;
}
.sb-workflow__activity-details-cell .sb-workflow__activity-details-label {
  font-size: 12px;
  font-weight: 300;
}
.sb-workflow__activity-details-cell .md-primary {
  background-color: #72CAA7;
}
.sb-workflow__activity-details-delete-date {
  font-size: 12px;
  color: #36B381;
  cursor: pointer;
  outline: none;
}
.sb-workflow__activity-details-delete-date:hover {
  text-decoration: underline;
}
.sb-workflow__activity-details-cell--multiple-entries ul {
  padding-left: 0;
}
.sb-workflow__activity-details-cell--multiple-entries ul li {
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
  min-width: 0;
  min-height: 0;
}
.sb-activity-tree {
  background: #FFFFFF;
  padding: 0;
}
.sb-activity-tree .angular-ui-tree-node,
.sb-activity-tree .angular-ui-tree-placeholder {
  position: static;
}
.sb-activity-tree .angular-ui-tree-nodes {
  position: static;
}
.sb-activity-tree .angular-ui-tree-nodes .angular-ui-tree-nodes {
  padding-left: 0;
  position: static;
}
hr.sb-activity-tree__separator {
  border-top: 1px solid #CECECE;
  border-bottom: none;
  margin: 0;
  position: absolute;
  width: 100%;
  left: 0;
}
.sb-activity-tree__toggle_btn {
  position: absolute;
  top: 50%;
  transform: translate(0, -50%);
  left: -4px;
}
.sb-activity-tree__item {
  min-height: 36px;
  margin: 0;
}
.sb-activity-tree__item.selected {
  background: #9BD9C0;
}
.sb-activity-tree__row {
  color: #565656;
  outline-width: 0;
}
.sb-activity-tree__row.selected {
  font-weight: 500;
  color: #2B2B2B;
}
.sb-activity-tree__row--default {
  position: relative;
  padding: 4px 0;
  cursor: pointer;
}
.sb-activity-tree__row--default .sb-activity-tree__toggle_btn {
  left: -20px;
  top: 10px;
}
.angular-ui-tree-nodes {
  list-style: none;
  margin: 0;
  padding: 0;
}
.sb-workflow-activity-pane-content {
  background-color: #FBFAFA;
}
.sb-deliverable-workflow {
  position: relative;
  flex: 1;
}
.sb-deliverable-workflow .sb-deliverable-workflow-tree {
  font-size: 14px;
  padding: 0;
  background: #FFFFFF;
}
.sb-deliverable-workflow .sb-deliverable-workflow-tree .md-list-item-content {
  flex: 1;
}
.sb-deliverable-workflow .sb-deliverable-workflow-tree md-list-item .md-avatar {
  width: 20px;
  height: 20px;
  margin-top: -8px;
  margin-right: 8px;
}
.sb-deliverable-workflow .sb-deliverable-workflow-header {
  display: flex;
  flex-direction: row;
  padding: 0 16px;
  justify-content: space-between;
  min-height: 60px;
}
.sb-deliverable-workflow .sb-deliverable-workflow-header h3 {
  font-weight: 300;
  color: #6C6C6C;
}
.sb-deliverable-workflow .sb-info-summary-activities,
.sb-deliverable-workflow .sb-info-summary-issues {
  display: flex;
  flex-direction: row;
  padding: 8px 0 0 16px;
  height: 40px;
  font-size: 14px;
}
.sb-deliverable-workflow .sb-info-summary-activities .mdi,
.sb-deliverable-workflow .sb-info-summary-issues .mdi {
  font-size: 18px;
}
.sb-deliverable-workflow .sb-info-summary-activities .sb-info-summary-value,
.sb-deliverable-workflow .sb-info-summary-issues .sb-info-summary-value {
  display: flex;
  flex-direction: column;
  text-align: center;
  justify-content: space-around;
  margin: 0 6px;
}
.sb-deliverable-workflow .sb-info-summary-issues {
  box-shadow: 1px 0 #CECECE;
  padding: 0 16px 0 0;
}
.sb-deliverable-workflow .sb-info-summary-values {
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
}
.sb-deliverable-workflow .sb-user-image-by {
  margin-right: 0 !important;
}
.sb-deliverable-workflow-header-wrapper {
  z-index: 1;
  position: sticky;
  top: 0;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  background-color: #F4F3F2;
}
.sb-deliverable-notes-row-container {
  font-size: 14px;
  display: flex;
  flex-direction: column;
  flex: 1;
  background: #FFFFFF;
}
.sb-deliverable-notes-row-container.sb-row-is-openable .sb-deliverable-notes-row {
  cursor: pointer;
}
.sb-deliverable-notes-row-container .sb-deliverable-notes-row {
  display: flex;
  flex-direction: row;
  align-items: center;
  flex-wrap: nowrap;
  align-self: flex-start;
  width: 100%;
  color: #6C6C6C;
  font-weight: 400;
  border-bottom: 1px solid #E1E1E1;
  padding: 4px 16px;
  position: relative;
  outline-width: 0;
  box-sizing: border-box;
}
.sb-deliverable-notes-row-container .sb-deliverable-notes-row .sb-deliverable-notes-cell {
  display: flex;
  flex-direction: column;
  padding: 0 8px;
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
  min-width: 0;
  min-height: 0;
}
.sb-deliverable-notes-row-container .sb-deliverable-notes-row .sb-deliverable-notes-cell > div {
  display: flex;
  flex-direction: row;
}
.sb-deliverable-notes-row-container .sb-deliverable-notes-row .sb-deliverable-notes-cell .sb-deliverable-notes-cell-field-name {
  align-self: flex-end;
  padding-bottom: 1px;
  margin-right: 8px;
}
.sb-deliverable-notes-row-container .sb-deliverable-notes-row .sb-deliverable-cell-open-ability {
  flex: 0 0 30px;
}
.sb-deliverable-notes-row-container .sb-deliverable-notes-row .sb-deliverable-cell-open-ability i {
  transition: 400ms;
}
.sb-deliverable-notes-row-container .sb-deliverable-notes-row .sb-deliverable-notes-cell-field-name {
  font-size: 12px;
  color: #858585;
  display: flex;
}
.sb-deliverable-notes-row-container .sb-deliverable-notes-row .sb-deliverable-notes-cell-team-value {
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
  min-width: 0;
  min-height: 0;
  font-size: 12px;
}
.sb-deliverable-notes-row-container .sb-deliverable-notes-row > div {
  flex: 1;
  flex-direction: column;
  align-content: center;
  justify-content: center;
}
.sb-deliverable-notes-row-container .sb-deliverable-notes-row .sb-deliverable-notes-cell-date {
  max-width: 65px;
  padding-left: 8px;
  display: flex;
  flex-direction: row;
}
.sb-deliverable-notes-row-container .sb-deliverable-notes-row .sb-deliverable-notes__edited-badge {
  top: 10px;
  position: relative;
}
.sb-deliverable-notes-row-container .sb-deliverable-notes-row .sb-deliverable-notes-cell-icon {
  max-width: 30px;
}
.sb-deliverable-notes-row-container .sb-deliverable-notes-row .sb-deliverable-notes-cell-icon .sb-claim {
  color: #E87E00;
}
.sb-deliverable-notes-row-container .sb-deliverable-notes-row .sb-deliverable-notes-cell-icon .sb-obstruction {
  color: #FF4141;
}
.sb-deliverable-notes-row-container .sb-deliverable-notes-row .sb-deliverable-notes-cell-icon .sb-info {
  color: #365781;
}
.sb-deliverable-notes-row-container .sb-deliverable-notes-row .sb-deliverable-notes-cell-icon .sb-issue-closed {
  color: #B3B3B3;
}
.sb-deliverable-notes-row-container .sb-deliverable-notes-row .sb-deliverable-notes-text-cell {
  flex: 3;
  word-break: break-word;
  overflow-wrap: anywhere;
  padding: 0 8px;
}
.sb-deliverable-notes-row-container .sb-deliverable-notes-row .sb-deliverable-notes-code-cell {
  flex: 2;
}
.sb-deliverable-notes-row-container .sb-deliverable-notes-row .sb-deliverable-notes-cell-date .sb-inspection-badge {
  position: relative;
  top: -5px;
}
.sb-deliverable-notes-row-container .sb-deliverable-notes-row i {
  display: inline-block;
  font-size: 24px;
}
.sb-deliverable-notes-row-container .sb-deliverable-notes-row-container__loading {
  height: 200px;
}
.sb-deliverable-notes-row-container .sb-deliverable-notes-row-container__photos {
  height: 0;
  opacity: 0;
}
.sb-deliverable-notes-row-container.sb-is-open .sb-deliverable-cell-open-ability i {
  transform: rotateZ(90deg);
}
.sb-deliverable-notes-row-container.sb-is-open .sb-deliverable-notes-row-container__photos {
  height: auto;
  opacity: 1;
}
@media only screen and (max-width: 960px) {
  .sb-deliverable-notes-row-container .sb-deliverable-notes-row {
    padding: 0;
  }
  .sb-deliverable-notes-row-container .sb-deliverable-notes-row .sb-deliverable-notes-cell > div {
    flex-direction: column;
  }
  .sb-deliverable-notes-row-container .sb-deliverable-notes-row .sb-deliverable-notes-cell > div .sb-deliverable-notes-cell-field-name {
    align-self: flex-start;
  }
  .sb-deliverable-notes-row-container .sb-deliverable-notes-row .sb-deliverable-notes-text-cell {
    font-size: 14px;
  }
}
.sb-deliverable-notes__state-change.not-visible {
  visibility: hidden;
  display: block!important;
}
.sb-deliverable-notes__teams-section {
  display: flex;
  width: 100%;
}
.sb-deliverable-note__row-actions {
  align-items: center;
}
.sb-dependency {
  background-color: #FBFAFA;
  padding: 8px 16px;
}
.sb-dependency__container {
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  float: left;
}
.sb-dependency__container.sb-dependency__container--predecessor {
  margin: 8px 0;
}
.sb-dependency__container.sb-dependency__container--successor .sb-dependency__container-title {
  justify-content: flex-end;
}
.sb-dependency__container .sb-dependency__container--empty {
  font-size: 18px;
  padding: 16px;
  text-align: center;
}
.sb-dependency__container-title {
  margin: 4px 8px;
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
}
.sb-dependency__container-title .sb-title {
  margin: 0;
  font-size: 18px;
}
.sb-dependency__container-title .sb-dependency-add {
  font-size: 20px;
  color: #858585;
  margin: 0 16px;
  outline-width: 0;
  cursor: pointer;
  transition: all 0.1s ease-in-out;
  transition: all 0.2s ease-in-out;
  transform: rotate(0deg);
}
.sb-dependency__container-title .sb-dependency-add:hover {
  transform: rotate(90deg);
}
.sb-dependency__container-title .sb-dependency-add:hover {
  color: #31A174;
}
.sb-dependency-add-new {
  color: #B3B3B3;
  border: 1px dashed;
  cursor: pointer;
  height: 35px;
  min-height: 35px;
  line-height: 35px;
  font-size: 20px;
  margin: 8px;
  text-align: center;
  transition: all 0.2s ease-in-out;
  transform: rotate(0deg);
}
.sb-dependency-add-new .mdi {
  transition: all 0.2s ease-in-out;
  transform: rotate(0deg);
  font-size: 22px;
  margin-right: 8px;
}
.sb-dependency-add-new:hover {
  background: #FBFAFA;
  color: #858585;
  border-style: solid;
}
.sb-dependency-add-new:hover .mdi {
  transform: rotate(90deg);
}
.sb-dependency__row {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  border-bottom: 1px solid #CECECE;
  height: 52px;
  min-height: 52px;
  cursor: pointer;
}
.sb-dependency__row .sb-progress-icon {
  font-size: 24px;
  padding: 16px;
}
.sb-dependency__row .sb-dependency__description {
  flex-grow: 1;
  font-family: 'Work Sans', 'Helvetica Neue', sans-serif;
  font-size: 16px;
  font-weight: 300;
}
.sb-dependency__row .sb-dependency__description b {
  font-family: 'Work Sans', 'Helvetica Neue', sans-serif;
  font-size: 16px;
  font-weight: 400;
}
.sb-dependency__row .sb-dependency-remove {
  padding: 16px;
  font-size: 24px;
  cursor: pointer;
  outline-width: 0;
  color: #B3B3B3;
  transition: all 0.2s ease-in-out;
  transform: rotate(0deg);
}
.sb-dependency__row .sb-dependency-remove:hover {
  transform: rotate(90deg);
}
.sb-dependency__row .sb-dependency-remove:hover {
  color: #A13131;
}
.sb-deliverable-separator {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  flex: 1;
  height: 60px;
  max-height: 60px;
  width: 80%;
  min-height: 60px;
  margin: 0 auto;
}
.sb-deliverable-separator .sb-dependency-current {
  flex: 1;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}
.sb-deliverable-separator .arrow-from-current,
.sb-deliverable-separator .arrow-to-current {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
}
.sb-deliverable-separator .arrow-from-current .bg,
.sb-deliverable-separator .arrow-to-current .bg {
  width: 60px;
  height: 60px;
  background-size: 80%;
  background-repeat: no-repeat;
  background-position: center center;
  display: block;
}
.sb-deliverable-separator .arrow-from-current .bg {
  background-image: url(../images/arrow-from-current.svg);
}
.sb-deliverable-separator .arrow-to-current .bg {
  background-image: url(../images/arrow-to-current.svg);
  transform: scaleY(-1);
}
.sb-dependency__scheduling-mode-info {
  margin: 8px;
  cursor: pointer;
}
.sb-dependency-manager {
  position: absolute;
  right: 0;
  bottom: 0;
  z-index: 1;
  width: 0;
  transition: all 0.3s ease-in-out;
  overflow-x: hidden;
  height: 100%;
  min-height: 100%;
}
.sb-dependency-manager.animate-from-left {
  top: 0;
  left: 0;
}
.sb-dependency-manager.open {
  width: 100%;
  height: 100%;
}
.sb-dependency-manager .sb-dependency-manager-content {
  padding: 16px;
  width: 100%;
  height: 100%;
  box-sizing: border-box;
  background: #FFFFFF;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  justify-content: flex-start;
}
.sb-dependency-manager .sb-dependency-manager-close {
  position: absolute;
  top: 0;
  right: 0;
  font-size: 20px;
  cursor: pointer;
  border-radius: 50%;
  padding: 8px;
  outline-width: 0;
}
.sb-dependency-manager .sb-dependency-manager-close:hover {
  background: #E1E1E1;
}
.sb-dependency-manager .sb-dependency-manager-close:active {
  background: #858585;
}
.sb-dependency-manager h3,
.sb-dependency-manager .sb-dependency-manager-add-list-actions {
  height: 34px;
  margin: 0;
}
.sb-dependency-manager .sb-dependency-manager-add-list-container {
  flex: 1;
  min-height: 60px;
  height: 90%;
}
.sb-dependency-manager-add-list-container {
  width: 98%;
  margin: 16px auto;
}
.sb-dependency-manager-add-list-container .sb-odata-list-content {
  background: #FFFFFF;
}
.sb-dependency-manager-add-list-container .sb-odata-list-repeater {
  border-bottom: 1px solid #CECECE;
}
.sb-dependency-manager-add-list-container .sb-odata-row-container {
  width: 100%;
}
.sb-dependency-manager-add-list-container .sb-odata-row-container:nth-last-child(1) {
  margin-bottom: 0;
}
.sb-dependency-manager-add-list-container .sb-dependency-manager-add-list {
  overflow: auto;
  width: 100%;
  height: 90%;
  min-height: 90%;
}
.sb-dependency-manager-add-list-container .sb-dependency-manager__row {
  height: 52px;
  min-height: 52px;
  border: 1px solid #CECECE;
  border-bottom: none;
  padding: 0 8px 0 16px;
  box-sizing: border-box;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
}
.sb-dependency-manager-add-list-container .sb-dependency-manager__row .sb-deliverable-row {
  border: 0;
  width: 85%;
}
.sb-dependency-manager-add-list-container .sb-deliverable-dependency-action-wrapper {
  width: 15%;
  max-height: 32px;
}
.sb-dependency-manager-add-list-container .sb-deliverable-dependency-action-has {
  font-size: 12px;
  text-transform: uppercase;
  color: #2B8F67;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  align-items: flex-end;
  justify-content: space-around;
  transition: opacity 0.3s ease-in-out;
  opacity: 1;
}
.sb-dependency-manager-add-list-container .sb-deliverable-dependency-action-has.ng-hide {
  opacity: 0;
}
.sb-dependency-manager-add-list-container .sb-deliverable-dependency-action-has-not {
  color: #2B8F67;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  align-items: flex-end;
  justify-content: space-around;
  transition: opacity 0.1s ease-in-out 0.3s;
  opacity: 1;
}
.sb-dependency-manager-add-list-container .sb-deliverable-dependency-action-has-not.ng-hide {
  opacity: 0;
}
.sb-dependency-manager-add-list-container .sb-deliverable-dependency-button-add {
  cursor: pointer;
  transition: all 0.2s ease-in-out;
  transform: rotate(0deg);
}
.sb-dependency-manager-add-list-container .sb-deliverable-dependency-button-add .mdi {
  font-size: 30px;
}
.sb-dependency-manager-add-list-container .sb-deliverable-dependency-button-add:hover {
  transform: rotate(90deg);
}
.sb-group--header-text__sorted {
  font-weight: 500;
}
.sb-sort-panel {
  background-color: #FBFAFA;
}
.sb-group-item-text {
  font-size: 14px;
}
.sb-sort-group {
  white-space: nowrap;
  width: 100%;
  height: 100%;
  cursor: pointer;
}
.sb-sort-group div[md-menu-origin] {
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  outline: none;
}
.sb-sort-item--unsortable {
  cursor: initial;
  pointer-events: none;
}
.sb-help_link__icon {
  color: #2B2B2B;
  z-index: 10000;
  display: inline-block;
}
.sb-app-performance-dashboard-pro__container {
  height: 100%;
  overflow: hidden;
  display: flex;
  flex-direction: column;
  align-content: center;
  justify-content: center;
}
.sb-excel-subheader {
  font-size: 18px;
  font-weight: 300;
  border-top: 1px solid #CECECE;
  border-bottom: 1px solid #CECECE;
  background: #FBFAFA;
}
.sb-excel-subheader:nth-child(1) {
  border-top-width: 0px;
}
.sb-excel-level-select,
.sb-excel-row-select {
  margin: 4px auto;
  padding: 8px;
}
.sb-excel-component-label,
.sb-excel-level-label {
  font-size: 12px;
  padding: 16px 8px 0;
}
.sb-excel-level-remove {
  padding: 0 8px;
  margin-left: 6px;
  margin-right: -6px;
  min-width: 1em;
  height: auto;
}
.row-assign-list-column {
  display: flex;
  flex-direction: column;
  width: 100%;
}
.row-assign-list-column.sortable-ghost {
  visibility: hidden;
}
.row-assign-list-column:nth-last-child(1) {
  margin-bottom: 8px;
}
.sb-excel-not-assigned {
  color: #858585;
}
.sb-excel-sidenav-settings {
  flex: 1;
  z-index: 1;
  height: 100%;
  min-height: 100%;
  border-right: 1px solid #B3B3B3;
}
.sb-excel {
  top: 0;
  height: 100%;
  flex-direction: row;
}
.sb-excel md-content {
  padding: 0;
  overflow-x: hidden;
}
.sb-excel sb-table {
  height: 100%;
  top: 0;
  font-size: 14px;
}
.sb-excel .sb-excel-view {
  height: 100%;
  min-height: 100%;
  flex: 3;
}
.sb-excel .sb-excel-view-file {
  height: 100%;
  min-height: 100%;
  flex: 3;
}
.sb-excel .sb-excel-level {
  display: flex;
  flex-direction: row;
  align-items: center;
}
.sb-excel .sb-excel-settings-container {
  overflow-x: hidden;
  width: 100%;
  height: calc(100% - 48px - 16px);
  position: absolute;
  top: 64px;
  left: 0;
}
.sb-excel .sb-upload-preview-container .sb-upload-preview-content {
  flex-direction: column;
  flex: 2;
  height: 100%;
  min-height: 100%;
}
.sb-excel .sb-upload-preview-container .sb-upload-preview-structure {
  flex: 1;
  border-right: 1px solid #B3B3B3;
  height: 100%;
  min-height: 100%;
}
.sb-required .sb-excel-component-label {
  color: #B33636;
}
.sb-import-upload__background-info--custom-dates-detected .sb-trascluded-content {
  align-items: center;
  text-align: start;
}
.sb-import-upload__background-info--custom-dates-detected .sb-trascluded-content h1,
.sb-import-upload__background-info--custom-dates-detected .sb-trascluded-content h2,
.sb-import-upload__background-info--custom-dates-detected .sb-trascluded-content h3,
.sb-import-upload__background-info--custom-dates-detected .sb-trascluded-content h4,
.sb-import-upload__background-info--custom-dates-detected .sb-trascluded-content h5,
.sb-import-upload__background-info--custom-dates-detected .sb-trascluded-content h6,
.sb-import-upload__background-info--custom-dates-detected .sb-trascluded-content p {
  margin-left: 0;
  margin-right: 0;
}
.sb-import-upload__background-info--custom-dates-detected .sb-trascluded-content .sb-import-upload__custom-dates-detected-container {
  max-width: 560px;
}
.sb-import-upload__background-info--custom-dates-detected .sb-trascluded-content .sb-import-upload__custom-dates-detected--select-heading {
  margin-top: 0.2em;
  margin-bottom: 0.2em;
  font-weight: bold;
}
.sb-import-upload__background-info--custom-dates-detected .sb-trascluded-content .sb-import-upload__custom-dates-detected--select-description {
  margin-top: 0.2em;
  margin-bottom: 0.2em;
}
.sb-import-upload__background-info--custom-dates-detected .sb-trascluded-content .sb-import-upload__custom-dates-detected--actions {
  display: flex;
  justify-content: flex-end;
}
.sb-import-progress {
  top: 0;
  height: 100%;
  flex-direction: row;
}
.sb-import-progress md-content {
  padding: 0;
  overflow-x: hidden;
}
.sb-import-progress sb-table {
  height: 100%;
  top: 0;
  font-size: 14px;
}
.sb-import-progress .sb-import-progress-view {
  height: 100%;
  min-height: 100%;
  flex: 3;
}
.sb-import-progress .sb-import-progress-view-file {
  height: 100%;
  min-height: 100%;
  flex: 3;
}
.sb-import-progress .sb-upload-preview-container .sb-upload-preview-content {
  flex-direction: column;
  flex: 2;
  height: 100%;
  min-height: 100%;
}
.sb-import-progress__upload-has-failure--message {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 80px;
  width: 100%;
}
.sb-import-progress__upload-has-failure-message-shown {
  height: calc(100% - 80px) !important;
  margin-top: 80px;
}
.sb-import-progress__header-wrapper {
  justify-content: center;
  display: flex;
  flex-direction: row;
}
.sb-import-progress__tutorial-wrapper {
  position: relative;
}
.sb-import-progress__tutorial-icon i {
  font-size: 22px;
  padding: 0;
  position: absolute;
  left: 2px;
  text-shadow: none;
}
.sb-import-progress-view__success-deliverables-button {
  margin-top: 32px;
}
.sb-upload-preview-container {
  display: flex;
  height: 100%;
}
.sb-upload-preview-container sb-table {
  height: calc(100% - 64px);
  top: 64px;
}
.sb-upload-preview-container .sb-upload-preview-structure {
  z-index: 2;
  display: flex;
  flex: 35;
  flex-direction: column;
  border-right: 1px solid #B3B3B3;
  height: 100%;
}
.sb-upload-preview-container .sb-upload-preview-structure md-list {
  padding: 0;
}
.sb-upload-preview-container .sb-upload-preview-content {
  display: flex;
  flex-direction: column;
  flex: 90;
  height: 100%;
  overflow: hidden;
}
.sb-upload-preview-message {
  position: absolute;
  top: 20%;
  left: 10%;
  right: 10%;
  color: #858585;
  font-size: 20px;
  font-weight: 300;
}
.sb-upload-preview-headers {
  color: #2B2B2B;
  height: 64px;
  min-height: 64px;
  box-sizing: border-box;
  -moz-user-select: none;
  -webkit-user-select: none;
  -ms-user-select: none;
  cursor: default;
}
.sb-upload-preview-headers .md-toolbar-tools,
.sb-upload-preview-headers.md-toolbar-tools {
  background: #F4F3F2;
  border-bottom: 1px solid #B3B3B3;
}
.sb-upload-preview-headers i {
  color: #6C6C6C;
}
.sb-upload-preview-headers span {
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
  min-width: 0;
  min-height: 0;
  font-size: 18px;
  font-weight: 300;
}
.sb-upload-preview-structure-content {
  height: calc(100% - 64px);
  min-height: calc(100% - 64px);
}
.sb-excel-viewer {
  top: 0;
  height: 100%;
  flex-direction: row;
}
.sb-excel-viewer md-content {
  padding: 0;
  overflow-x: hidden;
}
.sb-excel-viewer sb-table {
  height: 100%;
  top: 0;
  font-size: 14px;
}
.sb-excel-viewer .sb-excel-viewer-view {
  height: 100%;
  min-height: 100%;
  flex: 3;
}
.sb-excel-viewer .sb-excel-viewer-view-file {
  height: 100%;
  min-height: 100%;
  flex: 3;
}
.sb-excel-viewer .sb-upload-preview-container .sb-upload-preview-content {
  flex-direction: column;
  flex: 2;
  height: 100%;
  min-height: 100%;
}
.sb-excel-viewer__upload-has-failure--message {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 80px;
  width: 100%;
}
.sb-excel-viewer__upload-has-failure-message-shown {
  height: calc(100% - 80px) !important;
  margin-top: 80px;
}
.sb-excel-viewer__header-wrapper {
  justify-content: center;
  display: flex;
  flex-direction: row;
}
.sb-excel-viewer__tutorial-wrapper {
  position: relative;
}
.sb-excel-viewer__tutorial-icon i {
  font-size: 22px;
  padding: 0;
  position: absolute;
  left: 2px;
  text-shadow: none;
}
.sb-excel-viewer__success-deliverables-button {
  margin-top: 32px;
}
.sb-excel-viewer__progress-bar {
  position: absolute;
  z-index: 10;
}
.sb-select-file__header-wrapper {
  justify-content: center;
  display: flex;
  flex-direction: row;
}
.sb-select-file__tutorial-wrapper {
  position: relative;
}
.sb-select-file__tutorial-icon i {
  font-size: 22px;
  padding: 0;
  position: absolute;
  left: 2px;
  text-shadow: none;
}
[ui-view].import.ng-hide-add,
[ui-view].import.ng-hide-remove {
  /* remember to add this */
  display: flex !important;
  opacity: 1;
  background: red;
}
[ui-view].import.ng-hide-remove {
  animation: zoomIn 400ms;
}
[ui-view].import.ng-hide-add {
  animation: zoomOut 400ms;
}
[ui-view].import-settings.ng-hide-add,
[ui-view].import-settings.ng-hide-remove {
  /* remember to add this */
  display: flex !important;
  opacity: 1;
}
.import-sidenav.ng-hide-remove {
  animation: zoomIn 400ms;
}
.import-sidenav.ng-hide-add {
  animation: zoomOut 400ms;
}
.sb-icon-msproject {
  color: #36B381;
  font-size: 35px;
  margin: auto;
}
.sb-icon-excel {
  color: #2B8F67;
}
.sb-import-selection {
  margin: 32px 0 0;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}
.sb-import-selection-text {
  padding: 8px;
  margin: 32px 8px;
  text-align: center;
  color: #6C6C6C;
  font-size: 18px;
  white-space: pre-line;
  font-weight: 300;
  background: transparent;
}
@media (min-width: 960px) {
  .sb-import-selection-text {
    font-size: 20px;
  }
}
@media (min-width: 1200px) {
  .sb-import-selection-text {
    font-size: 24px;
  }
}
.sb-import-tiles-container {
  background: inherit;
  overflow: unset;
}
.sb-integrate-tile {
  text-decoration: none;
}
.sb-integrate-icon-container {
  display: flex;
  flex: 1;
  flex-wrap: wrap;
  justify-content: center;
}
.sb-integrate-icon {
  width: 50%;
  object-fit: contain;
}
.sb-profile md-card {
  max-width: 600px;
  margin-left: auto;
  margin-right: auto;
}
.sb-profile {
  background-color: inherit;
  padding: 0 8px;
}
.sb-profile-lowercase {
  text-transform: none;
}
.sb-profile__password-input {
  flex: 1 1 200px;
}
.sb-project-card {
  position: relative;
  width: 380px;
  max-width: 380px;
  height: 360px;
  display: flex;
  flex-direction: column;
  flex: 380px 1 1;
  align-items: stretch;
  margin: 8px;
  box-sizing: border-box;
  overflow: hidden;
  transition: all 0.2s ease-in-out;
}
@media (min-width: 600px) {
  .sb-project-card {
    flex: 380px 1 1;
    max-width: 380px;
  }
}
.sb-project-card:hover {
  cursor: pointer;
  box-shadow: 0px 4px 5px -2px rgba(0, 0, 0, 0.2), 0px 7px 10px 1px rgba(0, 0, 0, 0.14), 0px 2px 16px 1px rgba(0, 0, 0, 0.12);
}
.sb-project-card:hover.sb-add-new-card {
  box-shadow: none;
}
.sb-project-card:hover .sb-project-card-edit {
  display: block;
}
.sb-project-card .sb-project-card-edit {
  position: absolute;
  right: 10px;
  top: 10px;
  z-index: 1;
  color: #2B2B2B;
  background: rgba(255, 255, 255, 0.7);
  border-radius: 50%;
  padding: 4px;
  display: none;
  transition: all 0.1s ease-in-out;
  outline-width: 0;
}
.sb-project-card .sb-project-card-edit .mdi {
  font-size: 24px;
}
.sb-project-card .sb-project-card-edit:active {
  background: rgba(255, 255, 255, 0.9) !important;
}
.sb-project-card .sb-project-card-edit:hover {
  background: rgba(255, 255, 255, 0.9) !important;
}
.sb-project-card .sb-project-card-header {
  width: 100%;
  background-size: cover;
  background-position: 50% 50%;
  position: relative;
  flex: 5;
}
.sb-project-card .sb-project-card-header .sb-project-card-header-content {
  display: flex;
  flex-direction: column;
  position: absolute;
  min-height: 78px;
  color: #2B2B2B;
  font-size: 14px;
  bottom: 0;
  box-sizing: border-box;
  width: 100%;
  padding: 8px;
  background: rgba(255, 255, 255, 0.85);
}
.sb-project-card .sb-project-card-content {
  padding: 8px 4px 4px;
  color: #2B2B2B;
  font-size: 14px;
  display: flex;
  height: 24px;
  min-height: 24px;
  flex-direction: column;
  background: #FFFFFF;
  justify-content: space-between;
}
.sb-project-card .sb-project-card-content .sb-project-card-progress-label {
  font-weight: 400;
  font-size: 12px;
}
.sb-project-card .sb-project-card-infos {
  padding: 8px 4px 0 0;
  display: flex;
  justify-content: space-between;
  font-size: 12px;
  position: absolute;
  right: 0;
  top: 0;
}
.sb-project-card .sb-project-card-infos .mdi {
  margin-right: 4px;
}
.sb-project-card .sb-project-card-row {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}
.sb-project-card .sb-project-card-status {
  flex: 1;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}
.sb-project-card .sb-project-card-description {
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
  min-width: 0;
  min-height: 0;
  font-size: 14px;
}
.sb-project-card .sb-project-card-name {
  font-size: 18px;
  font-weight: 400;
  color: #2B2B2B;
  padding-bottom: 4px;
}
.sb-project-card .sb-project-card__content {
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: stretch;
  box-sizing: border-box;
  overflow: hidden;
}
.sb-project-card a {
  color: inherit;
  text-decoration: inherit;
}
.sb-project-demo-card {
  color: #A13131;
}
.sb-pending-invite-card {
  position: relative;
  width: 280px;
  max-width: 280px;
  height: 270px;
  display: flex;
  flex-direction: column;
  flex: 280px 1 1;
  align-items: stretch;
  margin: 8px;
  box-sizing: border-box;
  overflow: hidden;
  transition: all 0.2s ease-in-out;
}
@media (min-width: 600px) {
  .sb-pending-invite-card {
    flex: 380px 1 1;
    max-width: 380px;
  }
}
.sb-pending-invite-card:hover {
  box-shadow: 0px 4px 5px -2px rgba(0, 0, 0, 0.2), 0px 7px 10px 1px rgba(0, 0, 0, 0.14), 0px 2px 16px 1px rgba(0, 0, 0, 0.12);
}
.sb-pending-invite-card:hover.sb-add-new-card {
  box-shadow: none;
}
.sb-pending-invite-card:hover .sb-pending-invite-card-edit {
  display: block;
}
.sb-pending-invite-card:hover .sb-pending-invite-card__sender {
  transform: translateY(0);
}
.sb-pending-invite-card .sb-pending-invite-card__sender {
  width: 100%;
  position: absolute;
  transform: translateY(-100%);
  transition: transform 200ms;
  z-index: 100;
  font-size: 12px;
  background-color: #565656;
  padding: 8px;
}
.sb-pending-invite-card .sb-pending-invite-card__sender span {
  font-weight: bold;
  color: #FFFFFF;
}
.sb-pending-invite-card .sb-pending-invite-card-header {
  width: 100%;
  background-size: cover;
  background-position: 50% 50%;
  position: relative;
  flex: 5;
}
.sb-pending-invite-card .sb-pending-invite-card-header .sb-pending-invite-card-header-content {
  display: flex;
  flex-direction: column;
  position: absolute;
  min-height: 78px;
  color: #565656;
  font-size: 14px;
  bottom: 0;
  box-sizing: border-box;
  width: 100%;
  padding: 8px;
  background: rgba(255, 255, 255, 0.85);
}
.sb-pending-invite-card .sb-pending-invite-control--decline {
  color: #E87E00;
}
.sb-pending-invite-card .sb-pending-invite-control--accept {
  color: #36B381;
}
.sb-pending-invite-card .sb-pending-invite-card-infos {
  padding: 8px 4px 0 0;
  display: flex;
  justify-content: space-between;
  font-size: 12px;
  position: absolute;
  right: 0;
  top: 0;
}
.sb-pending-invite-card .sb-pending-invite-card-infos .mdi {
  margin-right: 4px;
}
.sb-pending-invite-card .sb-pending-invite-card-name {
  font-size: 18px;
  font-weight: 400;
  color: #2B2B2B;
  padding-bottom: 4px;
}
.sb-pending-invite-card .sb-pending-invite-card__content {
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: stretch;
  box-sizing: border-box;
  overflow: hidden;
}
.sb-project-selection-layout {
  background-color: #F4F3F2;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  min-width: 0;
  min-height: 0;
  padding-bottom: 16px;
}
.sb-project-selection-cards-container {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  flex-wrap: wrap;
  padding-bottom: 16px;
  margin: 0 auto;
}
.sb-project-selection-cards-container,
.sb-project-selection-sort-toolbar,
.sb-project-selection-pending-invites {
  max-width: 1200px;
}
@media (max-width: 1199px) {
  .sb-project-selection-cards-container,
  .sb-project-selection-sort-toolbar,
  .sb-project-selection-pending-invites {
    max-width: 800px;
  }
}
@media (max-width: 800px) {
  .sb-project-selection-cards-container,
  .sb-project-selection-sort-toolbar,
  .sb-project-selection-pending-invites {
    max-width: 400px;
  }
}
@media (max-width: 380px) {
  .sb-project-selection-cards-container,
  .sb-project-selection-sort-toolbar,
  .sb-project-selection-pending-invites {
    width: 100%;
  }
}
.sb-project-selection-sort-toolbar {
  width: 100%;
  min-height: 52px;
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  margin: 8px 0 0;
}
.sb-project-selection-sort-toolbar-button {
  text-transform: capitalize;
  font-size: 18px;
  font-weight: 400;
  color: #6C6C6C;
}
@media (max-width: 600px) {
  .sb-project-selection-sort-toolbar-button {
    font-size: 16px;
    min-width: auto;
  }
}
@media (max-width: 320px) {
  .sb-project-selection-sort-toolbar-button {
    font-size: 14px;
  }
}
.sb-project-selection-pending-invites {
  width: 100%;
  margin: 8px 0;
}
.sb-project-selection-pending-invites .sb-project-selection-pending-invites-cards-container {
  width: 100%;
  display: flex;
  flex-wrap: wrap;
}
.sb-project-card.sb-project-card-welcome {
  cursor: default;
  height: 360px;
  background-color: #FFFFFF;
  padding: 0;
}
.sb-project-card.sb-project-card-welcome .sb-project-card-header {
  background: #2B2B2B;
  font-size: 20px;
  min-height: 72px;
}
.sb-project-card.sb-project-card-welcome .sb-project-card-header .md-toolbar-tools {
  justify-content: space-between;
  align-content: center;
  padding-right: 32px;
}
.sb-project-card.sb-project-card-welcome .sb-project-card-header .md-toolbar-tools .md-icon-button {
  display: flex;
  align-items: center;
  justify-content: center;
}
.sb-project-card.sb-project-card-welcome .md-toolbar-tools {
  justify-content: space-between;
}
.sb-project-card.sb-project-card-welcome .sb-project-card-header-label {
  font-size: 24px;
}
.sb-project-card.sb-project-card-welcome .sb-project-card-header-remove {
  color: #2B2B2B !important;
}
.sb-project-card.sb-project-card-welcome .sb-project-card-content {
  height: 250px;
  background: #FFFFFF;
  display: flex;
  flex-direction: column;
  justify-content: space-around;
}
.sb-project-card.sb-project-card-welcome .sb-project-card-welcome-section {
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  font-size: 14px;
}
.sb-project-card.sb-project-card-welcome .sb-project-card-welcome-section-text {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-around;
}
.sb-project-card.sb-project-card-welcome .sb-project-card-welcome-section-text span {
  width: 280px;
}
.sb-project-card.sb-project-card-welcome .sb-project-card-welcome-section .mdi {
  width: 50px;
  padding: 0;
  font-size: 50px;
  align-self: center;
  flex: 0 0 auto;
  color: #2B2B2B;
}
.sb-project-card.sb-project-card-welcome .sb-project-card-welcome-section .question-image {
  display: block;
  width: 50px;
  min-width: 50px;
  height: 50px;
  padding: 0 8px;
  align-self: center;
  padding: 0;
}
.sb-project-card.sb-project-card-welcome .sb-project-card-welcome-section .md-primary {
  color: #2B2B2B;
  align-self: flex-end;
}
.sb-project-card.sb-project-card-welcome:hover {
  box-shadow: 0px 1px 3px 0px rgba(0, 0, 0, 0.2), 0px 1px 1px 0px rgba(0, 0, 0, 0.14), 0px 2px 1px -1px rgba(0, 0, 0, 0.12);
}
.project-selection__new-project-card {
  display: flex;
  flex-direction: column;
  gap: 12px;
  height: 360px;
  margin: 0 8px 0 8px;
  width: 380px;
}
.project-selection__new-project-card__project:hover {
  border-color: #2B2B2B;
}
.project-selection__new-project-card .mdi {
  color: #2B2B2B;
  font-size: 50px;
}
.project-selection__new-project-card__demo {
  flex: 1;
}
.project-selection__new-project-card__project {
  align-items: center;
  border: solid 1px #FFFFFF;
  cursor: pointer;
  display: flex;
  flex: 1;
  flex-direction: column;
  justify-content: center;
  margin: 0;
}
.project-selection__new-project-card__title {
  color: #2B2B2B;
  font-size: 22px;
  font-weight: 400;
}
.editor {
  background-color: #FFFFFF;
}
.dialog-alert-content {
  padding: 0;
  margin: 0;
}
.md-fab-top-right-relative {
  top: 20px;
  right: 20px;
  position: relative;
  float: right;
}
md-toolbar.md-accent.sb-subbar {
  background-color: #36B381;
}
md-toolbar.md-accent.sb-subbar h2 {
  color: #FFFFFF;
}
.toggle-edit-mode,
.auto-generate-codes {
  transition: all 400ms !important;
}
.auto-generate-codes {
  bottom: 90px !important;
  right: 28px !important;
}
.auto-generate-codes.ng-hide-add,
.auto-generate-codes.ng-hide-remove {
  /* remember to add this */
  display: block !important;
  transition: none !important;
}
.auto-generate-codes.ng-hide-remove {
  animation: zoomIn 200ms cubic-bezier(0.25, 0.25, 0, 1);
}
.auto-generate-codes.ng-hide-add {
  animation: zoomOut 400ms cubic-bezier(0.25, 0.25, 0, 1);
}
.editor {
  height: 100%;
  overflow: hidden;
}
.editor-toolbar {
  display: flex;
  backface-visibility: hidden;
}
.editor-toolbar.ng-hide-add,
.editor-toolbar.ng-hide-remove {
  opacity: 1;
  display: block !important;
}
.editor-toolbar.ng-hide-add {
  position: absolute;
  top: 64px;
  left: 0;
  right: 0;
}
.editor-toolbar.ng-hide-add {
  animation: 400ms rotate-hide ease;
}
.editor-toolbar.ng-hide-remove {
  animation: 400ms rotate-show ease;
}
@keyframes rotate-show {
  0% {
    transform: rotateX(-180deg);
  }
  100% {
    transform: rotateX(0deg);
  }
}
@keyframes rotate-hide {
  0% {
    transform: rotateX(0deg);
    display: none;
  }
  100% {
    transform: rotateX(180deg);
  }
}
/* Role based color classes */
.sb-role-admin,
.sb-role-admin-color,
.md-fab.sb-role-admin-color {
  color: #8F2B2B;
}
.sb-role-controller,
.sb-role-controller-color,
.md-fab.sb-role-controller-color {
  color: #365781;
}
.sb-role-manager,
.sb-role-manager-color,
.md-fab.sb-role-manager-color {
  color: #F8C244;
}
.sb-role-inspector,
.sb-role-inspector-color,
.md-fab.sb-role-inspector-color {
  color: #36B381;
}
.sb-role-reviewer,
.sb-role-reviewer-color,
.md-fab.sb-role-reviewer-color {
  color: #6C6C6C;
}
.sb-role-default,
.sb-role-default-color,
.md-fab.sb-role-default-color {
  color: #2B2B2B;
}
.sb-role-admin-bg,
.sb-role-admin.selected {
  background-color: #8F2B2B;
}
.sb-role-controller-bg,
.sb-role-controller.selected {
  background-color: #365781;
}
.sb-role-manager-bg,
.sb-role-manager.selected {
  background-color: #F8C244;
}
.sb-role-inspector-bg,
.sb-role-inspector.selected {
  background-color: #36B381;
}
.sb-role-reviewer-bg,
.sb-role-reviewer.selected {
  background-color: #6C6C6C;
}
.sb-role-default-bg,
.sb-role-default.selected {
  background-color: #2B2B2B;
}
.hide-visibility {
  visibility: hidden;
}
.ellipsed-text {
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
  min-width: 0;
  min-height: 0;
}
.wrapped-text {
  word-wrap: break-word;
  overflow-wrap: break-word;
  hyphens: auto;
  white-space: normal;
}
.not-selectable {
  -moz-user-select: none;
  -webkit-user-select: none;
  -ms-user-select: none;
  cursor: default;
}
.sb-clickable {
  cursor: pointer;
}
.sb-clickable button {
  cursor: pointer;
}
.sb-readability-fix {
  text-rendering: auto;
  font-smoothing: antialiased;
  -moz-font-smoothing: antialiased;
  -webkit-font-smoothing: antialiased;
}
.sb-sticky-header {
  z-index: 1;
  position: sticky;
  top: 0;
}
.sb-hover-rotate-90 {
  transition: all 0.2s ease-in-out;
  transform: rotate(0deg);
}
.sb-hover-rotate-90:hover {
  transform: rotate(90deg);
}
.sb-error-message-on-input-global {
  padding-right: 10px !important;
}
.sb-todo {
  color: #6C6C6C;
}
.sb-todo.selected {
  background-color: #B3B3B3;
  color: #2B2B2B;
  opacity: 1;
}
.sb-done {
  color: #36B381;
}
.sb-done.selected {
  background-color: #36B381;
  color: #FFFFFF;
  opacity: 1;
}
.sb-in-progress {
  color: #61A2F5;
}
.sb-in-progress.selected {
  background-color: #61A2F5;
  color: #FFFFFF;
  opacity: 1;
}
.sb-wfc {
  color: #6C6C6C;
}
.sb-rejected {
  color: #FF4141;
}
.sb-task-behind {
  color: #B33636;
}
.sb-task-on-time {
  color: #5EC29A;
}
.sb-task-on-time.sb-task-has-claims {
  color: #E87E00;
}
.sb-task-on-time.sb-task-has-obstructions {
  color: #FF4141;
}
.sb-task-on-time.sb-task-has-obstructions.sb-task-has-claims {
  color: #FF4141;
}
.sb-task-unknown {
  color: #858585;
}
.sb-deliverable-task {
  border-radius: 5px;
  overflow: hidden;
  -moz-user-select: none;
  -webkit-user-select: none;
  -ms-user-select: none;
  cursor: default;
  background: #86D1B3;
  color: #FFFFFF;
}
.sb-deliverable-task h2,
.sb-deliverable-task span,
.sb-deliverable-task .sb-deliverable-name {
  color: #FFFFFF;
}
.sb-deliverable-task md-progress-linear.md-default-theme .md-container,
.sb-deliverable-task md-progress-linear .md-container {
  background: #72CAA7;
}
.sb-deliverable-task md-progress-linear.md-default-theme .md-bar,
.sb-deliverable-task md-progress-linear .md-bar {
  background: #4ABB8E;
}
.sb-deliverable-task.sb-task-no-progress {
  background: #B3B3B3;
  color: #6C6C6C;
}
.sb-deliverable-task.sb-task-no-progress h2,
.sb-deliverable-task.sb-task-no-progress span,
.sb-deliverable-task.sb-task-no-progress .sb-deliverable-name {
  color: #6C6C6C;
}
.sb-deliverable-task.sb-task-no-progress md-progress-linear.md-default-theme .md-container,
.sb-deliverable-task.sb-task-no-progress md-progress-linear .md-container {
  background: #CECECE;
}
.sb-deliverable-task.sb-task-no-progress md-progress-linear.md-default-theme .md-bar,
.sb-deliverable-task.sb-task-no-progress md-progress-linear .md-bar {
  background: #B3B3B3;
}
.sb-deliverable-task.sb-task-behind {
  background: #B33636;
  color: #FFFFFF;
}
.sb-deliverable-task.sb-task-behind h2,
.sb-deliverable-task.sb-task-behind span,
.sb-deliverable-task.sb-task-behind .sb-deliverable-name {
  color: #FFFFFF;
}
.sb-deliverable-task.sb-task-behind md-progress-linear.md-default-theme .md-container,
.sb-deliverable-task.sb-task-behind md-progress-linear .md-container {
  background: #D18686;
}
.sb-deliverable-task.sb-task-behind md-progress-linear.md-default-theme .md-bar,
.sb-deliverable-task.sb-task-behind md-progress-linear .md-bar {
  background: #B33636;
}
.sb-deliverable-task.sb-task-unknown {
  background: #B3B3B3;
  color: #FFFFFF;
}
.sb-deliverable-task.sb-task-unknown h2,
.sb-deliverable-task.sb-task-unknown span,
.sb-deliverable-task.sb-task-unknown .sb-deliverable-name {
  color: #FFFFFF;
}
.sb-deliverable-task.sb-task-unknown md-progress-linear.md-default-theme .md-container,
.sb-deliverable-task.sb-task-unknown md-progress-linear .md-container {
  background: #FBFAFA;
}
.sb-deliverable-task.sb-task-unknown md-progress-linear.md-default-theme .md-bar,
.sb-deliverable-task.sb-task-unknown md-progress-linear .md-bar {
  background: #858585;
}
.sb-deliverable-task.sb-task-on-time {
  background: #5EC29A;
  color: #FFFFFF;
}
.sb-deliverable-task.sb-task-on-time h2,
.sb-deliverable-task.sb-task-on-time span,
.sb-deliverable-task.sb-task-on-time .sb-deliverable-name {
  color: #FFFFFF;
}
.sb-deliverable-task.sb-task-on-time md-progress-linear.md-default-theme .md-container,
.sb-deliverable-task.sb-task-on-time md-progress-linear .md-container {
  background: #86D1B3;
}
.sb-deliverable-task.sb-task-on-time md-progress-linear.md-default-theme .md-bar,
.sb-deliverable-task.sb-task-on-time md-progress-linear .md-bar {
  background: #31A174;
}
.sb-deliverable-task h2,
.sb-deliverable-task span {
  font-weight: 400;
  padding: 8px 32px;
  font-size: 16px;
  text-align: center;
  margin: 0 auto;
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
  min-width: 0;
  min-height: 0;
}
span.highlight {
  color: #36B381 !important;
}
.sb-saving {
  padding: 4px 8px;
  font-size: 12px;
  text-transform: uppercase;
  font-weight: 400;
  background: #9BD9C0;
  color: #31A174;
  border-radius: 5px;
  transition: all 0.4s ease-in-out;
  opacity: 1;
}
.sb-saving.ng-hide {
  opacity: 0;
}
.sb-title {
  font-size: 20px;
  font-weight: 300;
}
a {
  color: #36B381;
}
.sb-whiteframe-glow-z1 {
  box-shadow: 0 0 1px 1px rgba(0, 0, 0, 0.14), 0 0 2px 2px rgba(0, 0, 0, 0.098), 0 0 5px 1px rgba(0, 0, 0, 0.084);
}
.sb-sides-shadow {
  box-shadow: 2px 0px 0px 0px rgba(0, 0, 0, 0.14), -2px 0px 0px 0px rgba(0, 0, 0, 0.14);
}
.sb-sides-and-bottom-shadow {
  box-shadow: 0px 2px 0px 0px rgba(0, 0, 0, 0.14), 2px 0px 0px 0px rgba(0, 0, 0, 0.14), -2px 0px 0px 0px rgba(0, 0, 0, 0.14);
}
.sb-bottom-shadow {
  box-shadow: 0 1px #e0e0e0;
}
.sb-button-shadow {
  box-shadow: 0 2px 5px 0 rgba(0, 0, 0, 0.26);
}
.sb-separator--vertical {
  margin-right: 16px;
  padding-right: 16px;
  border-right: 1px solid #CECECE;
  height: 18px;
  margin-top: 30px;
}
.sb-separator--horizontal {
  border-top: 1px solid #CECECE;
}
.sb-add-user-autocomplete .sb-add-user-autocomplete-template {
  display: flex;
  flex-direction: column;
  line-height: 24px;
}
.sb-add-user-autocomplete li {
  line-height: 20px;
  border-bottom: 1px solid #F4F3F2;
  padding: 8px;
}
.sb-add-user-autocomplete .sb-add-user-autocomplete-email {
  font-size: 14px;
  color: #858585;
}
.sb-add-user-autocomplete .sb-add-user-autocomplete-not-found {
  color: #36B381;
  white-space: normal;
  font-size: 12px;
}
.sb-add-user-chips .md-chip-content {
  font-size: 14px;
}
.sb-add-user-chips .md-chips .md-chip-input-container md-autocomplete {
  min-width: 455px;
}
.sb-checkbox-remove-selection-state:before {
  font-size: 20px;
  position: absolute;
  left: 7px;
  bottom: -2px;
  content: '-';
}
sb-schedule-stats {
  outline-width: 0;
}
.sb-remove-spinner-from-number-input::-webkit-outer-spin-button,
.sb-remove-spinner-from-number-input::-webkit-inner-spin-button,
.sb-remove-spinner-from-number-input[type=number] {
  appearance: none;
  margin: 0;
  /* <-- Apparently some margin are still there even though it's hidden */
}
.sb-calendar-day {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  margin-top: 18px;
}
.sb-calendar-day__label {
  margin: 2px 0;
}
.sb-calendar-days-list {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  overflow: hidden;
}
.sb-calendar-days-list__list {
  width: 470px;
}
.sb-calendar-days-list__header {
  margin-left: 5px;
  font-size: 14px;
  color: #6C6C6C;
}
.sb-calendar-days-list-dates__error {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  padding-left: 16px;
  font-size: 14px;
  color: #B33636;
  font-weight: 500;
}
.sb-calendar-days-list-dates__error i {
  margin-right: 4px;
}
.sb-calendar-days-list-add-more {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
}
.sb-calendar-days-list-dates__day-item {
  width: 100%;
}
md-list-item.sb-calendar-days-list-dates__list-item {
  padding: 0;
}
.sb-scheduling-mode__option-text {
  font-family: 'Work Sans', 'Helvetica Neue', sans-serif;
  font-size: 16px;
  font-weight: 500;
}
.sb-scheduling-mode__description {
  font-family: 'Work Sans', 'Helvetica Neue', sans-serif;
  font-size: 16px;
  font-weight: 300;
  margin-left: 30px;
}
.sb-project-settings-cards-container {
  width: 700px;
  min-width: 700px;
  max-width: 700px;
}
@media (max-width: 700px) {
  .sb-project-settings-cards-container {
    width: 100%;
    min-width: 100%;
  }
}
.sb-project-settings-cards-container .sb-project-settings-card-title {
  color: #6C6C6C;
  font-weight: 300;
  margin-bottom: 4px;
}
.sb-project-settings-card {
  position: relative;
  background-color: #FFFFFF;
  margin: 8px 0 32px 0;
}
.sb-project-settings-card .errors-container {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  padding-left: 16px;
  font-size: 14px;
  color: #B33636;
  font-weight: 500;
}
.sb-project-settings-card .errors-container i {
  margin-right: 4px;
}
.sb-project-settings-card .md-button.md-accent {
  float: right;
}
.sb-project-settings-card-switch {
  white-space: normal;
  padding: 0 16px;
  display: block;
  line-height: 1.2em;
}
.sb-radio-button-image-container img {
  border: 1px solid #CECECE;
  -moz-user-select: none;
  -webkit-user-select: none;
  -ms-user-select: none;
  cursor: default;
}
.sb-radio-button-image-container .md-label {
  display: flex;
  flex-direction: row;
  justify-content: space-around;
}
.sb-project-settings-card__header {
  display: flex;
  flex-direction: row;
  align-items: baseline;
}
.sb-project-settings-card__topic-hint {
  color: #6C6C6C;
  font-size: 24px;
  cursor: help;
  margin-left: 8px;
}
.sb-project-settings-card__topic-hint-text {
  font-family: 'Work Sans', 'Helvetica Neue', sans-serif;
  font-size: 12px;
  font-weight: 300;
}
.sb-project-settings__team_selection {
  display: flex;
}
.sb-project-settings-currency-select {
  width: 165px;
}
.sb-templates-state-container {
  padding-top: 65px;
}
@media (max-width: 960px) {
  .sb-templates-state-container {
    padding-top: 0;
  }
}
.sb-header-container {
  position: absolute;
  top: 0;
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  flex-grow: 1;
  background-color: #F4F3F2;
  padding: 0;
}
@media (max-width: 960px) {
  .sb-header-container {
    right: 0;
    padding: 0;
  }
}
.sb-header-container .header {
  padding: 0 6px 0 8px;
  background-color: #FFFFFF;
  display: flex;
  flex-direction: row;
  margin-top: 0;
  width: 960px;
}
@media (min-width: 1200px) {
  .sb-header-container .header {
    width: 1200px;
  }
}
@media (min-width: 1350px) {
  .sb-header-container .header {
    width: 1350px;
  }
}
@media (max-width: 960px) {
  .sb-header-container .header {
    margin-top: 10px;
    width: 100%;
    min-width: 100px;
  }
}
.sb-header-container .header div button {
  min-width: 0;
  margin: 5px 0;
  width: 100%;
}
.sb-header-container .header div button i {
  margin-right: 3px;
}
.templates-container .template {
  border-collapse: separate;
}
.sb-groups-container {
  position: relative;
  width: 100%;
  height: auto;
  min-height: 80%;
  margin: 10px auto 40px;
  box-sizing: border-box;
  display: block;
  padding-bottom: 32px;
}
.sb-groups-container .sb-background-info h1 {
  color: #858585;
}
@media (max-width: 960px) {
  .sb-groups-container {
    width: 100%;
    right: 0;
    margin: 40px 0 0;
    padding: 0;
  }
}
.sb-groups-container .group {
  width: 960px;
  margin: 0 auto 10px auto;
}
@media (min-width: 1200px) {
  .sb-groups-container .group {
    width: 1200px;
  }
}
@media (min-width: 1350px) {
  .sb-groups-container .group {
    width: 1350px;
  }
}
@media (max-width: 960px) {
  .sb-groups-container .group {
    width: 100%;
  }
}
.sb-groups-container .group .group-title {
  font-size: 18px;
  font-weight: 400;
  color: #858585;
  padding-left: 8px;
}
@media (min-width: 960px) {
  .sb-groups-container .group .group-title {
    padding-left: 0;
  }
}
.sb-groups-container .group .templates-container {
  padding-bottom: 32px;
}
.sb-groups-container .group .templates-container .template {
  border-collapse: separate;
  display: flex;
  flex-direction: column;
  border-bottom: 1px solid #E1E1E1;
  justify-content: center;
  flex-grow: 1;
  background-color: #FFFFFF;
  transition-duration: 0.3s;
  /* IE only styles */
}
@media all and (-ms-high-contrast: none), (-ms-high-contrast: active) {
  .sb-groups-container .group .templates-container .template {
    box-shadow: none;
  }
}
.sb-groups-container .group .templates-container .template.opened {
  margin: 50px 0 0;
}
@media (min-width: 960px) {
  .sb-groups-container .group .templates-container .template.opened {
    margin: 50px -50px;
  }
}
.sb-groups-container .group .templates-container .template .template-row {
  height: 48px;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  outline: none;
}
.sb-groups-container .group .templates-container .template .template-row .row-click {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  width: 100%;
  padding-left: 8px;
}
@media (min-width: 960px) {
  .sb-groups-container .group .templates-container .template .template-row .row-click {
    padding-left: 0;
  }
}
.sb-groups-container .group .templates-container .template .template-row .icon-edit {
  position: relative;
  right: 7px;
  bottom: 7px;
}
.sb-groups-container .group .templates-container .template .template-row:hover {
  background-color: #FBFAFA;
  cursor: pointer;
}
.sb-groups-container .group .templates-container .template .template-row:hover .icon-edit {
  opacity: 1;
}
.sb-groups-container .group .templates-container .template .template-row img {
  height: 40px;
}
.sb-groups-container .group .templates-container .template .template-row:last-child {
  border-bottom: 0;
}
.sb-groups-container .group .templates-container .template .template-children-row {
  background-color: #FBFAFA;
  min-height: 200px;
  display: flex;
  flex-direction: column;
  padding: 16px 32px;
}
.sb-template-column {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  flex-basis: 50px;
  flex-shrink: 0;
}
.sb-template-column.tool-column {
  flex: 4;
}
.sb-template-column.tool-column .md-button.md-icon-button {
  width: 30px;
  height: 30px;
  line-height: 30px;
}
.sb-template-column.tool-column .md-button.md-icon-button span {
  font-size: 20px;
}
.sb-template-column.code-column {
  flex: 6;
}
.sb-template-column.category-column {
  flex: 8;
}
.sb-template-column.category-column .category-icon {
  font-size: 20px;
}
.sb-template-column.name-column {
  flex: 15;
}
.sb-template-column.revision-column {
  display: none;
  flex: 4;
  flex-direction: row;
}
@media (min-width: 960px) {
  .sb-template-column.revision-column {
    display: flex;
  }
}
.sb-template-column.desc-column {
  display: none;
  flex: 16;
}
@media (min-width: 600px) {
  .sb-template-column.desc-column {
    display: block;
    text-overflow: ellipsis;
    white-space: nowrap;
    overflow: hidden;
    min-width: 0;
    min-height: 0;
    text-align: center;
  }
}
.sb-template-column.activities-column {
  display: none;
  flex: 14;
}
@media (min-width: 960px) {
  .sb-template-column.activities-column {
    display: flex;
  }
}
.sb-template-column.initials-column {
  display: none;
  flex: 10;
}
@media (min-width: 960px) {
  .sb-template-column.initials-column {
    display: flex;
  }
}
.sb-template-column.initials-column .sb-user-image {
  width: 30px;
  height: 30px;
  font-size: 15px;
}
.sb-template-column.date-column {
  display: none;
  flex: 12;
}
@media (min-width: 600px) {
  .sb-template-column.date-column {
    display: flex;
  }
}
.sb-template-column .icon-edit {
  opacity: 0;
  color: #2B2B2B;
  transition: all 0.6s ease-in-out;
}
.red {
  background-color: red;
}
.activity-container {
  display: inline-block;
  width: 100%;
}
.activity-container:hover .activity-tag-icon.md-icon-button .mdi {
  opacity: 1;
}
.activities-container {
  display: block;
}
.activity-tag-icon.md-icon-button .mdi {
  opacity: 0;
  font-size: 15pt!important;
  transition: all 0.2s linear;
  color: #2B2B2B;
}
.activity-tag .md-button.md-icon-button {
  height: 42px;
  padding-top: 5px;
}
.sb-dependency-container-empty {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  min-height: 40px;
}
.sb-dependency-container-empty i {
  padding-right: 8px;
}
.sb-editor-tree-node-ghost {
  display: none !important;
}
.sb-editor-tree-layout {
  flex-direction: column;
  display: flex;
}
.sb-editor-tree-node {
  display: flex;
  flex-direction: row;
  align-items: center;
  cursor: pointer;
  outline: none;
  transition: height 200ms, opacity 200ms, padding-top 200ms, padding-bottom 200ms;
}
.sb-editor-tree-node button i {
  position: relative;
  bottom: 9px;
  right: 9px;
}
.sb-editor-tree-node button i.toggle-collapse {
  bottom: 0;
  right: 0;
}
.sb-editor-tree-node i {
  color: inherit;
  font-size: 22px;
}
.sb-editor-tree-node span.highlight {
  color: red !important;
}
.sb-editor-tree-node .toggle-tree-button {
  width: 26px;
  height: 26px;
  min-height: 26px;
  min-width: 26px;
  line-height: 26px;
  margin: 0 0 0 4px;
}
.sb-editor-tree-node .toggle-tree-button i {
  transition: transform 200ms;
}
.sb-editor-tree-node .category-icon {
  width: 30px;
  height: 30px;
  display: flex;
  align-content: center;
  justify-content: center;
  align-items: center;
  flex-direction: row;
  flex-shrink: 0;
}
.sb-editor-tree-node.opened-node {
  height: 36px;
  padding-top: 4px;
  padding-bottom: 4px;
  opacity: 1;
}
.sb-editor-tree-node.closed-node {
  height: 0px;
  min-height: 0px;
  overflow: hidden;
  padding-top: 0px;
  padding-bottom: 0px;
  opacity: 0;
}
.sb-editor-tree-node.selected-node {
  color: #36B381;
}
.sb-editor-tree-node.selected-node .sb-template-editor__activity_index {
  color: #36B381;
}
.sb-editor-tree-node.highlighted-node i {
  color: #2B2B2B;
  background-color: #FFFFFF;
}
.sb-editor-tree-node:hover {
  background-color: #E1E1E1;
}
.sb-editor-tree-node:hover .sb-editor-tree-node-drag-indicator {
  display: flex;
}
.sb-editor-tree-node .collapse-btn {
  margin-left: auto;
  font-size: 24px;
  padding: 0 8px;
  align-items: center;
  justify-content: center;
  align-self: stretch;
  flex-shrink: 0;
}
.sb-editor-tree-node.dirty-state > span {
  font-style: italic;
}
.activity .toggle-tree-button {
  display: none;
}
.sb-editor-tree__asterisk {
  padding: 0;
  visibility: hidden;
}
.dirty-state .sb-editor-tree__asterisk {
  visibility: inherit;
}
.sb-editor-tree__node-text {
  flex-grow: 1;
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
  min-width: 0;
  min-height: 0;
}
.sb-editor-tree-node-drag-indicator {
  display: none;
  margin-left: auto;
  font-size: 24px;
  padding: 0 8px;
  align-items: center;
  justify-content: center;
  align-self: stretch;
}
.sb-editor-tree-node {
  width: auto;
}
.sb-editor-tree-node.ng-move {
  transition: all linear 0.4s;
}
.sb-editor-tree-node.ng-move {
  width: 0;
}
.sb-editor-tree-node.ng-move.ng-move-active {
  width: auto;
}
.sb-template-editor__activity_index {
  font-weight: 500;
  font-size: 12px;
  padding: 4px;
  border: 1px solid;
  min-width: 10px;
  height: 10px;
  line-height: 10px;
  border-radius: 25%;
  text-align: center;
  color: #6C6C6C;
  background: #FFFFFF;
  display: inline-block;
  margin: 0 2px;
  font-style: normal;
}
.template-editor-no-data-cards {
  min-height: 125px;
}
sb-group-activity-rows .section h2.title {
  color: #2B2B2B;
  font-weight: 400;
}
sb-group-activity-rows .section .containers {
  margin: 2px 8px 20px 8px;
}
sb-group-activity-rows .section .containers .row {
  background-color: white;
  min-height: 50px;
  color: #2B2B2B;
  display: flex;
  flex-direction: row;
  align-items: center;
  outline: none;
  text-decoration: none;
  border-bottom: 1px solid #E1E1E1;
}
sb-group-activity-rows .section .containers .row .sb-icon-activity,
sb-group-activity-rows .section .containers .row .sb-icon-group {
  color: #2B2B2B;
  font-size: 18pt;
}
sb-group-activity-rows .section .containers .row .delete-row i {
  font-size: 24px;
  opacity: 0;
  transition: opacity 200ms;
  color: #B33636;
}
sb-group-activity-rows .section .containers .row .delete-row:focus i {
  opacity: 1;
}
sb-group-activity-rows .section .containers .row:hover {
  background-color: #F4F3F2;
  cursor: pointer;
}
sb-group-activity-rows .section .containers .row:hover .delete-row i {
  opacity: 1;
}
sb-group-activity-rows .section .containers .row .image-column {
  display: flex;
  flex-basis: 50px;
  justify-content: center;
}
sb-group-activity-rows .section .containers .row .code-column {
  display: flex;
  flex: 10;
  justify-content: center;
  text-align: center;
}
sb-group-activity-rows .section .containers .row .activity-name-column {
  flex: 30;
}
sb-group-activity-rows .section .containers .row .activity-name-column md-input-container {
  padding: 0;
  margin: 0;
}
sb-group-activity-rows .section .containers .row .activity-name-column md-input-container input {
  padding: 0;
  margin: 0;
}
sb-group-activity-rows .section .containers .row .name-column {
  flex: 60;
}
sb-group-activity-rows .section .containers .row .name-column md-input-container {
  padding-bottom: 0px;
}
sb-group-activity-rows .section .containers .row .add-new-autocomplete {
  background-color: transparent;
}
sb-group-activity-rows .section .containers .row .add-new-autocomplete md-autocomplete-wrap {
  box-shadow: none;
}
sb-group-activity-rows .section .containers .row .add-new-autocomplete md-autocomplete-wrap input {
  margin: 0;
  padding: 0;
  border-bottom: 1px solid #CECECE;
  font-size: 16px;
}
sb-group-activity-rows .section .containers .row .add-new-autocomplete md-autocomplete-wrap button {
  display: none;
}
sb-group-activity-rows .section .containers.not-editable {
  box-shadow: none;
}
sb-group-activity-rows .section .containers.not-editable .row:hover {
  background: #FFFFFF;
  cursor: auto;
}
sb-group-activity-rows .section .containers .activity-edit-name-column {
  flex: 30;
}
sb-group-activity-rows .section .containers .activity-edit-name-column md-input-container {
  padding: 0;
  margin: 0;
}
sb-group-activity-rows .section .containers .activity-edit-name-column md-input-container input {
  padding: 0;
  margin: 0;
}
sb-group-activity-rows .section .containers .activity-edit-state-column {
  flex: 30;
}
sb-group-activity-rows .section .containers .activity-edit-state-column md-input-container {
  padding: 0;
  margin: 0;
}
sb-group-activity-rows .section .containers .activity-edit-state-column md-input-container input {
  padding: 0;
  margin: 0;
}
.group-item-template-activity {
  font-weight: 500;
  display: flex;
  align-items: center;
}
.group-item-template-activity .activity-delimiter {
  font-size: 1.5em;
  padding: 0 0.2em;
}
.group-item {
  display: flex;
  flex-direction: row;
}
.group-item-template-title .group-code {
  padding-right: 8px;
}
.group-item-template-title .group-code .highlight {
  color: #B33636;
}
.group-item-template-title .group-name {
  flex: 1;
  font-weight: 500;
}
.group-item-template-title .group-name .highlight {
  color: #B33636;
}
.group-item-template-title .activity-count {
  flex-shrink: 0;
  font-weight: 400;
}
.group-item-template-info {
  display: flex;
  flex-direction: column;
}
.group-item-template-author {
  font-weight: 400;
  color: rgba(0, 0, 0, 0.53);
}
.group-item-template-new {
  display: flex;
  flex-direction: column;
}
.group-item-template-new span {
  font-weight: 500;
}
.sb-change-parent-dialog .dialog-header-icon {
  margin-right: 15px;
}
.sb-change-parent-dialog .dialog-header-icon {
  margin-right: 15px;
}
.sb-editor-card {
  position: absolute;
  overflow-x: hidden;
  background-color: transparent;
  height: 100%;
  flex-grow: 1;
  width: 100%;
}
.sb-editor-card .info-container {
  overflow: auto;
  display: flex;
  flex-direction: column;
  flex: 1;
  background-color: #F4F3F2;
  padding: 8px;
  margin-bottom: 48px;
}
.sb-editor-card .info-container .sb-template-activity__checklist-input-container {
  margin-top: 18px;
}
.sb-editor-card .info-container md-input-container {
  margin: 6px 0;
}
.sb-editor-card .info-container .sb-no-data-card {
  min-width: 100%;
  margin: 0;
}
.sb-editor-card .info-container h2.sb-no-data-card-header {
  color: #36B381 !important;
  margin: 8px 0px !important;
  font-weight: 300 !important;
}
.sb-editor-card .info-container .cards-container {
  flex: 1;
  display: flex;
  justify-content: space-around;
}
.sb-editor-card .info-container h2 {
  margin: 8px;
  font-weight: 400;
  color: rgba(0, 0, 0, 0.53);
}
.editor-tree-layout {
  position: absolute;
  left: 0;
  top: 0;
  width: 26%;
  bottom: 0;
  z-index: 1;
  box-shadow: 0 0 1px 1px rgba(0, 0, 0, 0.14), 0 0 2px 2px rgba(0, 0, 0, 0.098), 0 0 5px 1px rgba(0, 0, 0, 0.084);
}
.sb-templates-editor {
  position: absolute;
}
.sb-templates-editor-breadcrumbs {
  flex-shrink: 0;
  z-index: 3;
  box-shadow: none;
}
.group-and-activity-container {
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  align-content: stretch;
  flex-wrap: wrap;
}
.group-and-activity-container sb-group-activity-rows {
  flex: 1 1 500px;
}
.activity-tag {
  display: flex;
  justify-content: space-between;
  align-items: flex-end;
}
.activity-tag-text {
  padding: 4px 10px;
  font-size: 16px;
  color: rgba(0, 0, 0, 0.53);
  font-weight: 400;
}
.activity-dependency-autocomplete.md-autocomplete-suggestions li,
.group-item-autocomplete.md-autocomplete-suggestions li {
  padding-top: 5px;
  padding-bottom: 5px;
  height: auto;
  line-height: 20px;
  white-space: normal;
  border-bottom: 1px solid #E1E1E1;
}
.group-autocomplete md-autocomplete-wrap {
  box-shadow: none!important;
}
.group-item-template-title {
  display: flex;
  flex-direction: row;
}
.activity-dependency-autocomplete li {
  display: flex;
  flex-direction: column;
}
.activity-dependency-autocomplete li .activity-state {
  font-weight: 500;
}
.card {
  display: flex;
  flex-grow: 1;
  background-color: white;
  flex-direction: column;
  align-items: stretch;
}
.examining {
  background-color: red;
}
.current .activities-container .activity {
  cursor: default;
}
.current .activities-container .task:before,
.current .activities-container .state:before {
  border-left-color: #F4F3F2;
}
.cards-container {
  display: flex;
}
.cards-container .left-card,
.cards-container .right-card {
  padding: 16px;
}
.cards-container .card-with-title {
  flex: 1;
}
.cards-container h2 {
  margin: 8px;
  text-align: left;
}
.cards-container h2.right {
  text-align: right;
}
.current-container {
  display: flex;
}
.current-container .arrow-from-current,
.current-container .arrow-to-current {
  flex: 1;
  justify-content: center;
}
.current-container .arrow-from-current .bg,
.current-container .arrow-to-current .bg {
  width: 40%;
  height: 104px;
  background-size: 100% 100%;
  background-repeat: no-repeat;
  display: block;
}
.current-container .arrow-from-current {
  display: flex;
  justify-content: flex-start;
  align-items: flex-end;
}
.current-container .arrow-from-current .bg {
  background-image: url(../images/arrow-from-current.svg);
}
.current-container .arrow-to-current {
  display: flex;
  justify-content: flex-end;
  align-items: flex-end;
}
.current-container .arrow-to-current .bg {
  background-image: url(../images/arrow-to-current.svg);
}
.current-container h2 {
  margin: 0 auto ;
  color: #2B2B2B;
  text-align: center;
}
.current-container .current-card {
  flex: 1.5;
  margin: auto;
  background: #F4F3F2;
}
.current-container .current-card sb-activity-item {
  cursor: auto;
  left: 0;
}
.current-container .current-card sb-activity-item:hover {
  transform: scale(1) !important;
}
.editor-detail-animation {
  position: absolute;
  top: 0;
  left: 26%;
  bottom: 0;
  right: 0;
}
.editor-detail-animation.ng-leave,
.editor-detail-animation.ng-enter {
  animation-duration: 500ms;
  animation-timing-function: ease-in-out;
}
.editor-detail-animation.ng-leave {
  animation-name: zoomOut;
}
.template-view-go-left.ng-enter {
  animation-name: slideInLeft;
}
.template-view-go-right.ng-enter {
  animation-name: slideInRight;
}
.template-view-go-bottom.ng-enter {
  animation-name: slideInUp;
}
.template-view-go-top.ng-enter {
  animation-name: slideInDown;
}
.template-view-go-center.ng-enter {
  animation-name: zoomIn;
}
.md-select-menu-container.md-active md-select-menu {
  min-width: 170px;
}
.md-select-menu-container {
  z-index: 10011;
}
.template-editor-message {
  white-space: -moz-pre-wrap;
  /* Firefox */
  white-space: -o-pre-wrap;
  /* Opera */
  white-space: pre-wrap;
  /* Chrome */
  word-wrap: break-word;
  /* IE */
  white-space: nowrap;
  font-size: 12pt;
  letter-spacing: 0.005em;
  font-weight: 400;
}
.template-editor-message-name {
  font-size: 20px;
  letter-spacing: 0.005em;
  font-weight: 400;
  position: absolute;
  left: 80px;
  top: 12px;
}
.sb-create-note-template-dialog md-input-container {
  margin: 8px 0;
  /* IE only style */
}
@media all and (-ms-high-contrast: none), (-ms-high-contrast: active) {
  .sb-create-note-template-dialog md-input-container {
    margin: 40px 0;
  }
}
sb-note-template-edit-card {
  display: block;
  background-color: #FFFFFF;
  margin: 14px 0;
}
sb-note-template-edit-card .sb-edit-card {
  margin: 0px;
}
sb-note-template-edit-card .sb-edit-card .edit-card {
  display: flex;
  flex: 1;
  padding: 8px 16px 0;
}
sb-note-template-edit-card .sb-edit-card .edit-card-left {
  flex: 1;
}
sb-note-template-edit-card .sb-edit-card .edit-card-right {
  flex: 3;
  padding: 0 0 0 32px;
  justify-content: flex-start;
}
sb-note-template-edit-card .sb-edit-card .edit-card-right md-input-container .sb-edit-card-text-area {
  height: 100px!important;
}
sb-note-template-edit-card .sb-edit-card .edit-form {
  display: flex;
  flex-wrap: wrap;
}
sb-note-template-edit-card .sb-edit-card .edit-form .md-select-value {
  flex-grow: 1;
}
sb-note-template-edit-card .sb-edit-card .md-actions {
  padding: 0 8px;
}
sb-note-template-edit-card .sb-edit-card-text-area {
  max-height: 115px;
  overflow: auto;
}
sb-note-template-edit-card .sb-edit-card-container-text-area {
  width: 100%;
}
sb-note-template-edit-card .sb-edit-card .edit-card-loading-indication {
  position: absolute;
  right: 32px;
  bottom: 20px;
  color: #2B8F67;
  padding: 5px 0px 5px 10px;
  font-size: 14px;
}
sb-note-template-edit-card md-input-container.input-value-medium {
  flex-grow: 1;
  flex-shrink: 0;
  flex-basis: content;
}
.filter-accordion {
  display: flex;
  flex-direction: column;
}
.filter-accordion v-pane {
  transition: transform 0.5s;
  border-bottom: 1px solid #F4F3F2;
}
.filter-accordion v-pane i {
  transition: transform 0.5s;
}
.filter-accordion v-pane.is-expanded > v-pane-header i {
  transform: rotate(90deg);
  opacity: 1;
}
.filter-accordion v-pane.is-expanded > v-pane-header .header-option-name {
  display: none;
}
.filter-accordion v-pane.is-expanded > v-pane-content > div {
  opacity: 1;
  transform: translate3d(0, 0, 0);
}
.filter-accordion v-pane.is-expanded-add > v-pane-content,
.filter-accordion v-pane.is-expanded-remove > v-pane-content {
  transition: max-height 0.5s;
}
.filter-accordion v-pane-header {
  padding-right: 15px;
  padding-left: 15px;
  transition: color 0.25s, border-color 0.5s;
  font-size: 16px;
  color: #565656;
  /*************************/
  /*************************/
}
.filter-accordion v-pane-header .header-option {
  flex-grow: 2;
  font-weight: 500;
}
.filter-accordion v-pane-header .sb-user-image {
  font-size: 12px;
  height: 25px;
  width: 25px;
  justify-content: center;
}
.filter-accordion v-pane-header div {
  display: flex;
  justify-content: space-between;
  height: 44px;
  align-items: center;
}
.filter-accordion v-pane-header div span {
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
  min-width: 0;
  min-height: 0;
}
.filter-accordion v-pane-header::after,
.filter-accordion v-pane-header::before {
  content: '';
  display: block;
  position: absolute;
  top: 50%;
  right: 0;
}
.filter-accordion v-pane-header::before {
  transform: rotate(-90deg);
}
.filter-accordion v-pane-header:hover {
  color: #4C4C4C;
}
.filter-accordion v-pane-content > div {
  opacity: 0;
  transform: translate3d(0, 30px, 0);
  transition: transform 0.5s, opacity 0.5s;
}
.sb-filter-heading {
  color: #565656;
  font-weight: 100;
  margin: 0 10px;
}
.sb-filter-card {
  font-size: 12px;
  align-self: flex-start;
  border-top: 4px solid #36B381;
}
.sb-filter-card md-list {
  padding: 0;
  background-color: #FBFAFA;
}
.sb-filter-card md-list md-list-item:hover {
  background-color: #FBFAFA !important;
}
.sb-filter-card md-list-item {
  color: #6C6C6C;
}
.sb-filter-card md-list-item:not(.selected) md-icon {
  color: #858585;
}
.sb-filter-card md-list-item:not(.selected) .sb-user-image,
.sb-filter-card md-list-item:not(.selected) .sb-icon-date-last-week {
  color: #858585;
  border-color: #858585;
}
.sb-filter-card md-list-item {
  min-height: 44px;
  padding-left: 4px;
  border-top: 1px solid #E1E1E1;
}
.sb-filter-card md-list-item.selected {
  border-left: 4px solid #36B381;
  padding-left: 0;
  background-color: #FBFAFA;
  /*************************/
  /*************************/
}
.sb-filter-card md-list-item.selected .md-list-item-inner {
  font-weight: 500;
}
.sb-filter-card md-list-item .md-list-item-inner {
  min-height: 44px;
  /*************************/
  /*************************/
}
.sb-filter-card md-list-item .md-list-item-inner > md-icon:first-child {
  margin: 6px;
}
.sb-filter-card md-list-item .md-list-item-inner p {
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
  min-width: 0;
  min-height: 0;
  flex-grow: 2;
}
.sb-filter-card md-list-item .md-list-item-inner .sb-user-image--mini {
  margin-right: 8px;
  font-size: 12px;
  height: 25px;
  width: 25px;
}
.sb-filter-card md-list-item .md-list-item-inner .time-filter {
  height: 25px;
  width: 25px;
  font-size: 12px;
  margin-top: 1px !important;
}
.sb-filter-card md-list-item md-icon {
  margin: 0 8px;
  font-size: 18px;
  border: 0;
  padding-top: 3px;
}
.sb-filter-card md-list-item .sb-user-image {
  margin-right: 8px;
  font-size: 12px;
  width: 25px;
  height: 25px;
}
.sb-filter-card-scroll-container {
  margin: 0 0 0 8px;
  padding: 0 9px 1px 0;
  overflow-x: hidden;
  overflow-y: auto;
}
.sb-type-header-icon {
  height: 18px;
  width: 18px;
  margin-right: 2px;
}
.sb-duration-header-icon {
  font-size: 12px;
  line-height: 22px;
  margin-right: 2px;
}
.sb-note-templates-table {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: #F4F3F2;
  padding-top: 50px;
  padding-left: 5px;
  padding-right: 5px;
}
.sb-manipulation {
  width: 100%;
}
.rows-container .hide {
  display: none;
}
.rows-container .row-container {
  padding-right: 250px;
}
.rows-container .row-container.opened .row {
  color: #2B2B2B;
  background-color: #FBFAFA;
  margin: 16px auto;
  padding: 52px 15px 0 15px;
  box-shadow: 0px 2px 4px -1px rgba(0, 0, 0, 0.2), 0px 4px 5px 0px rgba(0, 0, 0, 0.14), 0px 1px 10px 0px rgba(0, 0, 0, 0.12);
  height: 200px;
}
.rows-container .row-container.opened .row .info-row {
  background: #FFFFFF;
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
}
.rows-container .row-container.opened .row sb-note-template-edit-card {
  display: block;
}
.rows-container .row {
  max-width: 970px;
  display: flex;
  flex-direction: column;
  flex-grow: 1;
  background-color: #FFFFFF;
  transition: 0.2s;
  position: relative;
  z-index: 1;
  left: 8px;
  margin: 0 auto;
}
.rows-container .row .info-row {
  height: 50px;
  display: flex;
  align-items: center;
  outline: none;
  padding: 8px 8px;
  box-sizing: border-box;
}
.rows-container .row .info-row:hover {
  background-color: #F4F3F2;
  cursor: pointer;
}
.rows-container .row .info-row:last-child {
  border-bottom: 0px;
}
.rows-container .row sb-note-template-edit-card {
  display: none;
}
.sb-note-templates-column-type-image {
  width: 50px;
  min-width: 50px;
  display: flex;
  justify-content: center;
}
.sb-note-templates-column-code {
  flex: 1;
  overflow: hidden;
  display: flex;
  justify-content: center;
}
.sb-note-templates-column-text {
  display: flex;
  overflow-y: hidden;
  flex: 4;
  justify-content: flex-start;
}
.sb-note-templates-column-creator {
  display: flex;
  width: 100px;
  min-width: 100px;
  justify-content: center;
}
.sb-note-templates-column-date {
  width: 100px;
  min-width: 100px;
  display: flex;
  justify-content: center;
}
.sb-create-note-template-dialog {
  min-width: 500px;
}
.empty-error {
  font-weight: 300;
  text-align: center;
  margin-top: 250px;
}
.md-button.md-fab.md-fab-bottom-right.add-button {
  position: fixed;
}
.sb-note-templates-delete {
  left: 286px;
}
.notes-header-container {
  z-index: 5;
  position: absolute;
  left: 0;
  right: 0;
  top: 0;
  width: 100%;
  display: flex;
  flex-grow: 1;
  align-items: center;
  justify-content: center;
  background-color: #F4F3F2;
  padding: 0 0px;
}
.notes-header-container .notes-header {
  margin-right: 240px;
  padding-left: 16px;
  padding-right: 16px;
  background-color: #FFFFFF;
  display: flex;
  flex-direction: row;
  margin-top: 0px;
  max-width: 1350px;
  width: 100%;
}
.notes-header-container .notes-header div button {
  min-width: 0px;
  margin: 5px 0px;
  width: 100%;
}
.notes-header-container .notes-header div button i {
  margin-right: 3px;
}
.notes-header-container .notes-header md-checkbox {
  margin: auto;
}
@media (max-width: 1350px) {
  .rows-container .row-container {
    padding-right: 10px;
  }
  .notes-header-container .notes-header {
    margin-right: 10px;
  }
  .sb-note-templates-table .rows-container .row {
    left: 2px;
  }
  .sb-note-templates-table .md-virtual-repeat-offsetter {
    width: 100%;
  }
  .sb-note-templates-table .notes-header-container {
    z-index: 10;
    position: absolute;
    right: 0;
  }
  .sb-note-templates-table .notes-header-container .notes-header {
    padding: 0 9px;
  }
}
@media (max-width: 600px) {
  .rows-container .row-container {
    padding-left: 0;
    padding-right: 0;
  }
  .notes-header-container .notes-header {
    margin-right: 0;
    margin-left: 0;
  }
  .sb-note-templates-table {
    padding-left: 0;
    padding-right: 0;
  }
}
@media (max-width: 959px) {
  .note-templates-select-bar {
    width: 100%;
    left: 0;
  }
}
@media (min-width: 960px) and (max-width: 1050px) {
  .note-templates-select-bar {
    width: 82%;
    left: 21%;
  }
}
@media (min-width: 1049px) and (max-width: 1179px) {
  .note-templates-select-bar {
    width: 82%;
    left: 19%;
  }
}
@media (min-width: 1180px) and (max-width: 1279px) {
  .note-templates-select-bar {
    width: 98%;
    left: 13%;
  }
}
@media (min-width: 1280px) and (max-width: 1349px) {
  .note-templates-select-bar {
    width: 100%;
    left: 10%;
  }
}
@media (min-width: 1350px) and (max-width: 1389px) {
  .note-templates-select-bar {
    width: 97%;
    left: 1%;
  }
}
@media (min-width: 1390px) and (max-width: 1440px) {
  .note-templates-select-bar {
    width: 97%;
    left: 1%;
  }
}
/* Adding some styles for the maintenance message */
.maintenance-message {
  padding: 16px;
  display: flex;
  flex-direction: column;
  text-align: center;
}
.maintenance-message p {
  color: #FFFFFF;
  font-size: 18px;
  margin-top: 16px;
}
.maintenance-message h1 {
  color: #FFFFFF;
}
.maintenance-message h2 {
  color: #FFFFFF;
  font-weight: 400;
}
.maintenance-message .md-button {
  margin-top: 16px;
}
.registration-form {
  padding-top: 16px;
}
.registration-form md-input-container {
  margin: 8px 0;
  width: 100%;
  float: left;
  padding: 0 8px;
  text-indent: 8px;
  box-sizing: border-box;
}
.registration-form md-input-container.short-field {
  width: 50%;
}
@media (max-width: 600px) {
  .registration-form md-input-container.short-field {
    width: 100%;
  }
}
.registration-form md-input-container .sb-register__language-picker {
  margin-bottom: 20px;
}
.registration-form .sb-register-button {
  margin-left: 50%;
  width: 50%;
}
.registration-form .sb-register-paragraph {
  padding-bottom: 1em;
}
.registration-form .sb-progress-over-button {
  position: absolute;
  bottom: 15px;
  right: 9px;
  overflow: hidden;
}
.registration-form input[type=number]::-webkit-inner-spin-button,
.registration-form input[type=number]::-webkit-outer-spin-button {
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  margin: 0;
}
.questionnaire__container {
  padding: 0!important;
  width: 100%;
}
.sb-register__title {
  text-align: center;
  margin: 12px 0 24px 0;
}
.sb-account-messages__hs-widget__title,
.sb-account-messages__hs-widget__text {
  margin: 0 0 12px 0;
  padding: 0;
}
.meetings-iframe-container {
  width: 100%;
  height: 605px;
  border: 0;
}
.sb-account-messages {
  color: #FFFFFF;
}
.sb-verification .sb-verification-form {
  padding: 16px;
}
.sb-verification .sb-verification-form md-input-container {
  width: 100%;
}
.sb-verification .sb-verification-form button {
  float: right;
}
.sb-verification .sb-description {
  display: block;
}
.account-page {
  position: relative;
  z-index: 62;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  background-image: url("../images/background/login_background_construction_dude.png");
  background-position: center center;
  background-size: cover;
  background-attachment: fixed;
}
@media (max-width: 900px) {
  .account-page {
    background-image: none;
    background-color: #2B2B2B;
  }
}
.account-page .logo {
  position: relative;
  height: 100px;
  z-index: 1;
  padding: 16px 0px;
  background-position: center;
  background-repeat: no-repeat;
  background-size: 200px 100px;
  background-image: url(../images/sablono_logo.svg);
  border-radius: 2px 2px 0px 0px;
  display: block;
  outline: none;
}
.account-page .logo.welcome-animation-allowed {
  cursor: pointer;
}
.account-page-background-layer {
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  background-color: rgba(255, 255, 255, 0.9);
}
.account-page .account-page-tabs {
  border-radius: 0px 0px 2px 2px;
}
.account-page-content {
  width: 452px;
  margin: auto;
  margin-right: calc((50% - 452px) / 2);
  position: relative;
  color: rgba(0, 0, 0, 0.7);
}
@media (max-width: 900px) {
  .account-page-content {
    margin: auto;
  }
}
@media (max-width: 420px) {
  .account-page-content {
    width: 100%;
  }
}
.account-page-content md-content {
  background-color: transparent;
  box-sizing: border-box;
  padding: 16px;
}
.account-page-content md-content.account-page-view {
  overflow: hidden;
}
.account-page-content md-tabs {
  flex-grow: 1;
}
.account-page-content md-tabs.md-default-theme md-ink-bar {
  background-color: #36B381;
}
.account-page-content md-tabs.md-default-theme .md-tab .md-ripple-container .md-ripple {
  background-color: #36B381 !important;
}
.account-page-content .sb-login-buttons {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-end;
}
.account-page-content .sb-login-avatar {
  margin-top: 22px!important;
}
.account-page-footer {
  position: relative;
  display: flex;
  flex-shrink: 0;
  flex-grow: 0;
  overflow: hidden;
  width: 100%;
  background-color: rgba(255, 255, 255, 0.75);
  flex-direction: row;
  justify-content: center;
  align-items: center;
  flex-wrap: wrap;
}
.account-page-footer .copyright {
  font-size: 14px;
  padding: 0 16px;
}
.sb-account-messages {
  min-height: 100px;
}
.sb-account-messages a {
  color: #FFFFFF;
}
.sb-account-messages .success {
  color: #2B8F67;
}
.sb-account-messages .error {
  color: #C25E5E;
}
.sb-account-messages h3 {
  font-size: 20px;
  font-weight: 300;
  margin: 0 0 8px 0;
}
.sb-account-messages p {
  align-items: center;
}
.sb-account-messages small {
  font-size: 14px;
  display: block;
}
.sb-account-messages .animate-fade-in-long {
  color: #6C6C6C;
}
.sb-account-messages .sb-registration-container-image {
  display: flex;
  flex-direction: row;
}
.sb-account-messages .sb-registration-container-image p {
  display: flex;
  align-items: center;
}
.sb-account-messages .md-button {
  float: right!important;
}
.sb-account-messages .sb-registration-image {
  float: right;
  width: 100px;
  min-width: 100px;
  height: 100px;
  min-height: 100px;
  margin: 0px 0 0 8px;
}
.sb-login-reset-pw {
  color: #36B381;
  font-size: 14px;
  text-align: right;
  margin: 8px;
}
.sb-login-reset-pw:visited {
  color: #2B2B2B;
}
.sb-login-reset-pw:hover {
  font-weight: 500;
}
.account-page .sb-login-form,
.maintenance-message,
.sb-verification,
.sb-account-messages {
  display: flex;
  flex-direction: column;
  width: 100%;
}
@media (min-width: 420px) {
  .account-page .sb-login-form,
  .maintenance-message,
  .sb-verification,
  .sb-account-messages {
    width: 420px;
  }
}
@media (max-width: 599px) {
  .account-page-footer-content .md-button,
  .account-page-footer-content .copyright {
    min-width: 56px;
    margin: 2px 2px;
    flex-grow: 1;
  }
  .account-page-footer-content .copyright {
    margin-top: 0;
    margin-bottom: 0;
    flex-grow: 1;
  }
}
.sb-login__title {
  text-align: center;
  width: 100%;
}
.sb-register__legal-text {
  font-size: 12px;
  color: #FFFFFF;
}
.account-page-background-layer {
  background-color: transparent;
}
.account-page .sb-login__title,
.account-page .sb-register__title,
.account-page md-checkbox,
.account-pagemd-tabs.md-default-theme .md-tab,
.account-page md-tabs .md-tab:not(.md-active),
.account-page md-checkbox .md-icon,
.account-page md-input-container:not(.md-input-focused):not(.md-input-invalid) label.md-required:after,
.account-page md-input-container:not(.md-input-invalid) input::placeholder,
.account-page md-input-container:not(.md-input-focused):not(.md-input-invalid) label,
.account-page md-input-container:not(.md-input-invalid) .md-select-icon:after,
.account-page md-input-container:not(.md-input-invalid) .md-select-value:after,
.account-page md-input-container:not(.md-input-invalid) .md-select-value,
.account-page .sb-login-form,
.account-page .sb-user-image,
.account-page .sb-login-reset-pw,
.account-page .sb-registration-container-image {
  color: #FFFFFF;
}
.account-page md-input-container input {
  caret-color: #36B381;
}
.account-page md-input-container.md-input-invalid input,
.account-page md-input-container .md-input-invalid:not(.md-input-focused) input,
.account-page md-input-container.md-input-invalid .md-select-icon:after {
  caret-color: #B33636;
  color: #B33636;
}
.account-page md-input-container:not(.md-input-invalid) input,
.account-page md-input-container:not(.md-input-invalid) md-select-value,
.account-page md-checkbox:not([disabled]).md-primary:not(.md-checked) .md-icon {
  color: #FFFFFF;
  border-color: #FFFFFF;
}
.sb-tools-select-file {
  outline: none;
  transition: all 0.3s ease-in-out;
  color: #858585;
}
.sb-tools-select-file:hover {
  color: #B3B3B3;
  cursor: pointer;
}
.sb-step {
  text-align: center;
  padding: 16px 0;
  box-sizing: border-box;
  width: 100%;
  min-width: 100%;
  height: 100%;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
}
.sb-step .sb-loading {
  margin-top: 16px;
}
.sb-step .sb-step-content {
  min-width: 800px;
  max-width: 800px;
  margin: 0 auto;
  padding: 32px 0;
}
.sb-step .sb-step-content h2 {
  margin: 16px 0;
}
@media (max-width: 800px) {
  .sb-step .sb-step-content {
    min-width: 100%;
    max-width: 100%;
  }
}
.sb-step p {
  margin: 16px;
}
.sb-step .sb-step-action {
  float: right;
}
.sb-step .sb-tools-select-file,
.sb-step .sb-step-icon-large {
  font-size: 140px;
  margin: 32px auto;
  color: #858585;
}
.sb-step .sb-tools-select-file:hover,
.sb-step .sb-step-icon-large:hover {
  cursor: pointer;
  color: #B3B3B3;
}
.sb-step .sb-side-note {
  font-size: 14px;
  text-align: right;
}
.sb-step a.md-button.md-primary.md-raised {
  color: #FFFFFF;
  background: #36B381;
  box-shadow: none;
}
.sb-step a.md-button.md-primary.md-raised:active,
.sb-step a.md-button.md-primary.md-raised.active,
.sb-step a.md-button.md-primary.md-raised:not([disabled]):hover {
  background: #65d1a6 !important;
}
.sb-step a.md-button.md-primary.md-raised:not([disabled]):focus {
  color: #FFFFFF;
  background: #36B381;
}
.sb-step .md-button.md-primary {
  color: #36B381;
  background: #FFFFFF;
  background: transparent;
}
.sb-step .md-button.md-primary:active,
.sb-step .md-button.md-primary.active,
.sb-step .md-button.md-primary:not([disabled]):hover {
  color: #FFFFFF;
  background-color: #65d1a6 !important;
}
.sb-step .md-button.md-primary:not([disabled]):focus {
  color: #FFFFFF;
  background: #36B381;
}
.sb-step h2,
.sb-step h3 {
  font-weight: 300;
}
.sb-list-container.delete .sb-list-checkboxes-row md-checkbox.md-checked {
  color: #A13131;
  background: #D99B9B;
}
.sb-list-container.delete .sb-list-checkboxes-row md-checkbox.md-checked .md-icon {
  background-color: #C25E5E;
}
.sb-list-container.delete .sb-list-checkboxes-row md-checkbox.md-checked .md-ink-ripple {
  background-color: #D18686;
}
.sb-list-container.delete .sb-list-select-all.md-checked .md-icon {
  background: #B33636;
}
.sb-list-container.select .sb-list-checkboxes-row md-checkbox.md-checked {
  color: #267D5A;
  background: #9BD9C0;
}
.sb-list-container.select .sb-list-checkboxes-row md-checkbox.md-checked .md-icon {
  border-color: #31A174;
}
.sb-list-container.select .sb-list-checkboxes-row md-checkbox {
  color: #B33636;
}
.sb-list-container.select .sb-list-checkboxes-row md-checkbox .md-icon {
  border-color: #C25E5E;
}
.sb-list-container.select .sb-list-checkboxes-row md-checkbox.md-checked .md-icon {
  background-color: #4ABB8E;
}
.sb-list-container.select .sb-list-checkboxes-row md-checkbox.md-checked .md-ink-ripple {
  background-color: #86D1B3;
}
.sb-list-container.select .sb-list-select-all.md-checked .md-icon {
  background: #36B381;
}
.sb-list-container {
  margin: 16px auto;
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  height: 300px;
}
.sb-list-container .sb-list-checkboxes {
  width: 800px;
  flex: 1;
  max-width: 100%;
  overflow-y: auto;
  overflow-x: hidden;
  padding: 4px 0;
  height: 100%;
  background: #FBFAFA;
  border: 1px solid #CECECE;
}
@media (max-width: 800px) {
  .sb-list-container .sb-list-checkboxes {
    width: 100%;
  }
}
.sb-list-container .sb-list-checkboxes-row {
  max-height: 32px;
  color: #565656;
  font-size: 14px;
}
.sb-list-container .sb-list-checkboxes-row md-checkbox {
  padding: 8px 16px;
  margin: 0;
  background: #FBFAFA;
}
.sb-list-container .sb-list-checkboxes-row md-checkbox .md-icon {
  border-color: #858585;
}
.sb-list-container .sb-list-checkboxes-row:nth-child(even) md-checkbox {
  background: #FFFFFF;
}
.sb-list-container .sb-list-checkboxes-row md-checkbox .md-container {
  left: 8px;
}
.sb-list-container .sb-list-checkboxes-header {
  border: 1px solid #CECECE;
  background: #F4F3F2;
  width: 800px;
  padding: 8px 8px 8px 32px;
  box-sizing: border-box;
}
@media (max-width: 800px) {
  .sb-list-container .sb-list-checkboxes-header {
    width: 100%;
  }
}
.sb-list-container .sb-list-checkboxes-header span {
  text-align: center;
  text-transform: uppercase;
  font-size: 14px;
}
.sb-list-container .sb-list-select-all {
  margin: 8px 0 8px 16px;
  width: 800px;
  max-width: 100%;
  text-align: left;
  line-height: 18px;
  font-size: 14px;
}
@media (max-width: 800px) {
  .sb-list-container .sb-list-select-all {
    width: 100%;
  }
}
.sb-list-container .md-label,
.sb-list-container .sb-list-checkboxes-header {
  display: table;
  table-layout: fixed;
  width: 100%;
}
.sb-list-container .md-label .sb-list-checkbox-content,
.sb-list-container .sb-list-checkboxes-header .sb-list-checkbox-content {
  display: table-row;
}
.sb-list-container .md-label .sb-list-checkbox-content span,
.sb-list-container .sb-list-checkboxes-header .sb-list-checkbox-content span {
  padding: 0 8px;
  display: table-cell;
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
  min-width: 0;
  min-height: 0;
}
.sb-list-container .md-label .sb-list-checkbox-content span.mdi,
.sb-list-container .sb-list-checkboxes-header .sb-list-checkbox-content span.mdi {
  width: 32px;
}
.flex-auto {
  flex: 0 0 auto;
}
.flex-column {
  display: flex;
  flex-direction: column;
}
.flex-row {
  display: flex;
  flex-direction: row;
}
.flex-row-wrap {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
}
.flex-column-center {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}
.flex-column-center-start {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
}
.flex-row-center {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
}
.flex-row-center-start {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
}
.flex-row-center-end {
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  align-items: center;
}
.sb-single-action-card {
  display: flex;
  transition: all 0.2s ease-in-out;
}
.sb-single-action-card:hover {
  cursor: pointer;
  box-shadow: 0 4px 5px -2px rgba(0, 0, 0, 0.2), 0px 7px 10px 1px rgba(0, 0, 0, 0.14), 0px 2px 16px 1px rgba(0, 0, 0, 0.12);
}
.sb-single-action-card__section {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-around;
  box-sizing: border-box;
}
.sb-single-action-card__spacer {
  flex: 1;
}
.sb-single-action-card__description {
  flex: 1;
  font-family: 'Work Sans', 'Helvetica Neue', sans-serif;
  font-size: 14px;
  font-weight: 300;
}
.sb-single-action-card__icon {
  font-size: 46px;
  padding: 8px;
}
.sb-single-action-card__action {
  display: flex;
  justify-content: flex-end;
  align-items: flex-end;
}
.sb-svg-legend {
  display: flex;
  flex-direction: column;
  padding: 0;
  margin: 0;
  min-width: 300px;
  background: #FBFAFA;
}
.sb-svg-legend__header {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  padding: 0 16px;
  font-family: 'Work Sans', 'Helvetica Neue', sans-serif;
  font-size: 12px;
  font-weight: 500;
  text-transform: uppercase;
  color: #2B2B2B;
  cursor: pointer;
  outline-width: 0;
}
.sb-svg-legend__header__button {
  border-radius: 50%;
  padding: 4px;
  margin: 2px;
  background: #E1E1E1;
}
.sb-svg-legend__header__button:hover {
  background: #CECECE;
}
.sb-svg-legend__entries {
  display: flex;
  flex-direction: column;
  min-width: 268px;
  max-height: calc(100vh - 64px - 48px - 48px - 116px);
  overflow-x: hidden;
  overflow-y: auto;
}
.sb-svg-legend__entry {
  display: flex;
  flex-direction: column;
  flex-shrink: 0;
  position: relative;
  max-width: 248px;
  font-family: 'Work Sans', 'Helvetica Neue', sans-serif;
  font-size: 12px;
  font-weight: 400;
}
.sb-svg-legend__entry ~ .sb-svg-legend__entry {
  margin-top: 8px;
}
.sb-svg-legend__entry__key::first-letter,
.sb-svg-legend__entry__icon::first-letter {
  text-transform: uppercase;
}
.sb-svg-legend__entry__icon--rotated {
  font-family: 'Work Sans', 'Helvetica Neue', sans-serif;
  font-size: 18px;
  font-weight: 400;
  position: relative;
  transform: rotate(-50deg);
  top: -4px;
  left: -2px;
}
.sb-svg-legend__entry__icon--rotated-right {
  font-family: 'Work Sans', 'Helvetica Neue', sans-serif;
  font-size: 18px;
  font-weight: 400;
  position: relative;
  transform: rotate(50deg) scale(0.75) translate(-3px, 1px);
  top: -4px;
  left: -2px;
}
.sb-svg-legend__entry-icon--hatched {
  position: absolute;
  width: 11px;
  height: 11px;
  border-radius: 50%;
  overflow: hidden;
  margin-right: 8px;
  top: 2px;
  left: 0;
  transform: scale(0.9);
}
.sb-svg-legend__entry__icon {
  position: absolute;
}
.sb-svg-legend__entry__key {
  display: flex;
  position: relative;
  right: -20px;
}
.sb-svg-legend__entry__key__percentage {
  margin-left: 8px;
}
.sb-svg-legend__topological-index {
  margin-right: 4px;
  margin-bottom: auto;
  min-width: 14px;
  line-height: 14px;
  border-radius: 25%;
  text-align: center;
  font-size: 10px;
  color: #858585;
  border: 1px solid;
  margin-left: 0;
}
.sb-svg-legend__entry__header-wrapper {
  width: 100%;
}
.sb-svg-legend__entry__header {
  font-family: 'Work Sans', 'Helvetica Neue', sans-serif;
  font-size: 12px;
  font-weight: 500;
  text-transform: uppercase;
  color: #2B2B2B;
  width: 100%;
  text-align: center;
}
.sb-svg-legend__line-icon-wrapper {
  display: flex;
  flex-direction: row;
  padding: 2px 2px 2px 5px;
}
.sb-svg-legend__line-icon {
  width: 2px;
  height: 15px;
  margin-right: 14px;
}
.sb-svg-legend__line-icon--strong {
  background-color: #B33636;
}
.sb-svg-legend__line-icon--medium {
  background-color: #E87E00;
}
.sb-svg-legend__line-icon--weak {
  background-color: #F8C224;
}
.sb-searchable-select {
  display: flex;
  flex-direction: column;
  height: 100%;
  width: 100%;
  margin: 0;
}
.sb-selectable-item-container {
  box-sizing: border-box;
}
.sb-selectable-item-container:hover {
  background-color: #CECECE;
}
.sb-selectable-item-container.md-checked {
  background-color: #CECECE;
}
.sb-selectable-item {
  padding: 8px 0;
  margin-left: 8px;
  margin-bottom: 0;
}
md-toolbar.sb-searchable-select__toolbar {
  display: flex;
  flex-direction: column;
  background-color: #36B381;
}
.sb-searchable-select__search {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}
.sb-searchable-select__search-field {
  width: 100%;
}
.mdi.sb-close-icon {
  font-size: 18pt;
  vertical-align: initial;
}
.sb-searchable-select__list-item--selected {
  background-color: #CECECE;
}
.sb-searchable-select__item-header-wrapper {
  width: 100%;
  position: relative;
}
.sb-searchable-select__item-header {
  font-family: 'Work Sans', 'Helvetica Neue', sans-serif;
  font-size: 14px;
  font-weight: 500;
  text-transform: uppercase;
  word-wrap: break-word;
  overflow-wrap: break-word;
  hyphens: auto;
  white-space: normal;
  color: #2B2B2B;
  width: 70%;
  text-align: center;
  margin-left: auto;
  margin-right: auto;
}
.sb-searchable-select__button {
  position: absolute;
  transform: scale(0.7);
  color: #2B2B2B;
}
.sb-searchable-select__button--place-nicely-next-to-header {
  right: 0;
  top: -14px;
}
.sb-searchable-select__button--place-nicely-in-autocomplete {
  top: 12px;
  right: 50px;
}
.sb-config-card {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  color: #6C6C6C;
}
.sb-config-card:hover,
.sb-config-card:focus {
  cursor: pointer;
  background: #F4F3F2;
}
.sb-config-card a {
  color: inherit;
  text-decoration: inherit;
}
.sb-config-card__content {
  padding-bottom: 0;
}
.sb-config-card__header {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  justify-content: space-around;
  height: 162px;
}
.sb-config-card__header-icon {
  font-size: 90px;
  color: #B3B3B3;
}
.sb-config-card__header-text {
  font-family: 'Work Sans', 'Helvetica Neue', sans-serif;
  font-size: 14px;
  font-weight: 300;
}
.sb-config-card__title {
  margin: 0;
  padding-top: 12px;
  font-family: 'Work Sans', 'Helvetica Neue', sans-serif;
  font-size: 18px;
  font-weight: 300;
  color: #2B2B2B;
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
  min-width: 0;
  min-height: 0;
}
.sb-config-card__footer {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
}
.sb-config-card__details {
  font-size: 14px;
}
.sb-config-card__delete-action:hover .mdi-delete,
.sb-config-card__delete-action:focus .mdi-delete {
  color: #B33636;
}
.tooltip-header {
  text-align: center;
  margin: 0;
}
.sb-visualization-page {
  padding: 32px;
  height: 100%;
  width: 100%;
  box-sizing: border-box;
  overflow: auto;
}
.sb-visualization-page.sb-visualization-page--with-searchable-select {
  margin-left: calc(300px + 16px);
}
.sb-visualization-page .sb-svg-container {
  height: 100%;
  width: 100%;
  margin: 0 auto;
}
.sb-visualization-page .sb-svg-container svg {
  max-width: 100%;
  height: auto;
  padding-bottom: 32px;
}
.sb-visualization-page .sb-svg-container svg polyline,
.sb-visualization-page .sb-svg-container svg text {
  pointer-events: none;
}
.sb-visualization-page .sb-svg-element:hover {
  stroke-dasharray: 0 !important;
  stroke-opacity: 0.8;
  cursor: pointer;
}
.sb-visualization-page .sb-content-zoom {
  bottom: 0;
  right: 0;
  left: auto;
  width: 300px;
  margin: 16px;
}
.sb-visualization-page .sb-svg-element--selected {
  stroke: #36B381 !important;
  stroke-width: 4px;
  stroke-dasharray: 0 !important;
  stroke-opacity: 0.8;
}
.sb-subbar-mode__bulk-update .sb-subbar-mode .sb-button--disabled.sb-subbar-mode__bulk-update-button,
.sb-subbar-mode__bulk-update .sb-subbar-mode .sb-button--disabled.sb-subbar-mode__bulk-update-button:active {
  color: #CECECE;
}
.sb-subbar-mode__bulk-update,
.sb-subbar-mode__bulk-update .sb-subbar-mode {
  height: 48px;
  width: 100%;
}
.sb-subbar-mode__bulk-update-title {
  padding-left: 16px;
  font-weight: 300;
}
.sb-visualization-page__controls {
  position: absolute;
  bottom: 0;
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  width: 300px;
  box-sizing: border-box;
  margin: 16px;
}
.sb-visualization-page__controls--left {
  top: 0;
  left: 0;
}
.sb-visualization-page__controls--right {
  right: 0;
  max-height: calc(100% - 72px + 1px);
  margin-bottom: 72px;
}
.sb-visualization-page--searchable-select {
  flex: 1;
  width: 100%;
  max-height: 100%;
}
.sb-visualization-page__legend {
  display: flex;
  flex-direction: row;
  align-items: flex-end;
  width: 100%;
  max-height: calc(100% - 1px);
}
.sb-visualization-page__legend .sb-svg-legend {
  width: 100%;
  height: 100%;
}
.sb-visualization-page__fullscreen-card {
  margin-top: 10px;
}
.sb-visualization-page__stage-selection-header {
  font-family: 'Work Sans', 'Helvetica Neue', sans-serif;
  font-size: 12px;
  font-weight: 500;
  text-transform: uppercase;
  color: #2B2B2B;
  margin-left: 24px;
}
.sb-visualization-page__stage-selection-entries {
  font-family: 'Work Sans', 'Helvetica Neue', sans-serif;
  font-size: 14px;
  font-weight: 400;
  margin-top: 8px;
  color: #2B2B2B;
  margin-left: 16px;
}
.sb-visualization-page__stage-selection-entry {
  margin-bottom: 4px;
}
.sb-visualization-page__stage-selection-entry__list {
  padding-left: 16px;
}
.sb-visualization-creation-page {
  display: flex;
  flex-direction: column;
  flex: 1 0 100%;
}
.sb-visualization-creation-page .md-chip-input-container {
  width: 100%;
}
.sb-stepper__content {
  display: flex;
  flex-direction: row;
  align-content: stretch;
  flex: 1;
}
.sb-visualization-configurator {
  max-width: 440px;
  padding: 16px;
  overflow: auto;
  flex: 1;
}
.sb-visualization-configurator__step-content {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  box-sizing: border-box;
}
.sb-configurator-preview-divider {
  border-right: 1px solid #CECECE;
  border-top: 1px solid #CECECE;
  margin-top: 0px;
  margin-bottom: 0px;
}
.sb-visualization-title__main-title,
.sb-visualization-title__sub-title {
  width: 100%;
  margin: 17px 5px;
}
.sb-visualization-label {
  padding: 16px;
  margin-bottom: 8px;
  box-sizing: border-box;
}
.sb-stepper__next-button {
  background-color: #36B381;
}
.sb-stepper__next-button:not([disabled]):hover {
  background-color: #36B381;
}
.sb-visualization-preview {
  flex: 1;
  box-sizing: border-box;
  position: relative;
  overflow: auto;
}
.sb-visualization-preview svg {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  box-sizing: border-box;
  padding: 16px;
}
.sb-visualization-preview .sb-content-zoom {
  display: none;
}
.sb-visualization-structure-node {
  max-width: 440px;
}
.sb-visualization-structure-node md-chip {
  max-width: 250px;
}
.sb-visualization-structure-node__nodes {
  max-height: 165px;
  display: block;
  overflow-x: scroll;
}
.sb-visualization-structure-node_labeling {
  margin-top: 16px;
}
.sb-visualization-name__input {
  width: 100%;
}
.sb-visualization-highlighted__input {
  width: 100%;
}
.sb-visualization-highlighted__indexes {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
}
.sb-visualization-highlighted__start-index {
  flex-basis: 50%;
}
.sb-visualization-highlighted__end-index {
  flex-basis: 50%;
}
.sb-visualization-empty {
  margin: 0 auto;
  display: flex;
  background-color: #FBFAFA;
  padding: 16px;
  margin-top: 32px;
  width: 350px;
  align-self: center;
}
.sb-visualization-empty__text {
  display: flex;
  flex-direction: column;
}
.sb-visualization-empty__button {
  align-self: center;
  margin-top: 8px;
}
.sb-autocomplete-menu--multiline .md-autocomplete-suggestion {
  min-height: 24px;
  height: auto;
  line-height: 1.15;
  padding: 8px 16px;
  white-space: normal;
}
.sb-svg-fullscreen {
  display: flex;
  flex-direction: column;
  padding: 0;
  margin: 0;
  min-width: 300px;
  background: #FBFAFA;
  align-items: center;
  justify-content: center;
}
.sb-svg-fullscreen__button {
  border-radius: 50%;
  padding: 4px;
  margin: 2px;
  width: auto !important;
  background: #E1E1E1;
  transform: scale(0.75);
}
.sb-svg-fullscreen__button:hover {
  background: #CECECE;
}
.sb-svg-fullscreen__entries {
  display: flex;
  flex-direction: column;
  min-width: 268px;
  max-height: calc(100vh - 64px - 48px - 48px - 116px);
  overflow-x: hidden;
  overflow-y: auto;
}
.sb-svg-fullscreen__entry {
  display: flex;
  flex-direction: row;
  flex-shrink: 0;
  position: relative;
  max-width: 248px;
  font-family: 'Work Sans', 'Helvetica Neue', sans-serif;
  font-size: 12px;
  font-weight: 400;
}
.sb-svg-fullscreen__entry ~ .sb-svg-fullscreen__entry {
  margin-top: 8px;
}
.sb-svg-fullscreen__entry__key::first-letter,
.sb-svg-fullscreen__entry__icon::first-letter {
  text-transform: uppercase;
}
.sb-svg-fullscreen__buttons-wrapper {
  display: flex;
  flex-direction: row;
}
.sb-svg-fullscreen__entry-text {
  margin-top: auto;
  margin-bottom: auto;
}
.sb-visualization-list {
  background-color: #FBFAFA;
  height: 100%;
}
.sb-visualization-list .sb-visualization-list-container {
  max-width: 960px;
  margin-left: auto;
  margin-right: auto;
  display: flex;
  flex-wrap: wrap;
  padding: 8px;
}
.sb-visualization-list .sb-visualization-list-container .sb-visualization-card {
  box-sizing: border-box;
  flex: 0 0 33.33%;
  max-width: 33.33%;
  display: flex;
  flex-direction: column;
  overflow: hidden;
}
.sb-visualization-list .sb-visualization-list-container .sb-visualization-card > * {
  flex: 1 0 auto;
}
@media (max-width: 1440px) {
  .sb-visualization-list .sb-visualization-list-container .sb-visualization-card {
    flex: 0 0 33.33%;
    max-width: 33.33%;
  }
}
@media (max-width: 1024px) {
  .sb-visualization-list .sb-visualization-list-container .sb-visualization-card {
    flex: 0 0 33.33%;
    max-width: 33.33%;
  }
}
@media (max-width: 768px) {
  .sb-visualization-list .sb-visualization-list-container .sb-visualization-card {
    flex: 0 0 50%;
    max-width: 50%;
  }
}
@media (max-width: 550px) {
  .sb-visualization-list .sb-visualization-list-container .sb-visualization-card {
    flex: 0 0 100%;
    max-width: 100%;
  }
}
.sb-visualization-list .sb-visualization-list-container .sb-visualization-card .sb-image-card-image {
  background-size: cover;
  padding: 8px;
}
.sb-visualization-list .sb-visualization-list-container .sb-visualization-card .sb-image-card-title {
  position: relative;
  top: 0;
}
.sb-visualization-list .sb-add-new-card .sb-add-new-card-content {
  font-size: 20px;
}
.sb-visualization-list-empty {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  height: 100%;
}
.sb-visualizations-page__details-view {
  display: flex;
  flex-direction: row;
  flex: 1 0 100%;
  overflow: auto;
}
.sb-for-print {
  display: none;
}
@media only print {
  #sb-subbar,
  .sb-toolbar,
  .sb-content-zoom,
  .sb-svg-legend__header__button,
  .sb-toolbar-state__intercom,
  #user-image-top-card,
  .sb-toolbar-state-info-right-item,
  .md-panel-outer-wrapper {
    display: none;
  }
  * {
    overflow: visible !important;
  }
  html {
    overflow: auto;
  }
  html body {
    display: contents;
  }
  .ng-sablono {
    height: auto;
    min-height: auto;
  }
  .sb-for-print {
    display: block;
  }
  .sb-for-print-title {
    width: 100%;
    border-bottom: 1px solid #E1E1E1;
    padding-bottom: 8px;
    color: #858585;
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: center;
  }
  .sb-print-logo {
    width: 50px;
    margin-right: 10px;
  }
  .sb-visualization-page {
    padding: 0 1cm;
  }
  .sb-visualization-page.sb-visualization-page--with-searchable-select {
    margin: unset;
  }
  .sb-visualization-page__controls {
    padding-top: 1cm;
    break-before: page;
    break-inside: avoid;
    position: relative;
    max-height: initial;
    margin: 0;
    width: auto;
  }
  .sb-visualization-page__controls--left {
    display: none;
  }
  .sb-visualizations-page__details-view {
    position: absolute;
    left: 0;
    right: 0;
  }
  .sb-title span {
    display: inline;
  }
  .sb-svg-legend {
    background: transparent;
    box-shadow: none;
  }
  .sb-svg-legend .sb-svg-legend__entry,
  .sb-svg-legend .sb-svg-legend__header span {
    background: transparent;
  }
  .sb-svg-legend__entries {
    max-height: none;
    overflow: hidden;
  }
  .sb-svg-legend__entry {
    max-width: none;
  }
  .sb-searchable-select md-content {
    overflow: hidden !important;
  }
  .sb-visualization-page .sb-svg-container {
    height: 100% !important;
    width: 100% !important;
  }
  .sb-visualization-page .sb-svg-container svg {
    max-width: 100%;
    padding-bottom: 0;
  }
  .sb-svg-container.sb-print-prefer-landscape svg {
    max-height: 170mm;
  }
  .sb-svg-container.sb-print-prefer-portrait svg {
    max-height: 255mm;
  }
  .sb-content-zoom {
    height: auto;
  }
  @page {
    margin: 0;
  }
}
.sb-action-card:focus {
  outline: none;
}
.sb-visualization-card--primary .sb-single-action-card {
  color: #2B2B2B;
}
.sb-visualization-card--secondary .sb-single-action-card {
  color: #2B2B2B;
}
.sb-visualization-tooltip {
  background-color: #FFFFFF;
  border-radius: 3px;
  padding: 10px;
  box-shadow: 0px 0px 10px 0px rgba(0, 0, 0, 0.62);
  border: 1px solid #F4F3F2;
  pointer-events: none;
}
sb-deliverable-row {
  width: 100%;
}
.ellipsed-text-deliverable-row {
  align-self: stretch;
  overflow: hidden;
}
.ellipsed-text-deliverable-row span {
  float: left;
  max-width: 100%;
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
  min-width: 0;
  min-height: 0;
}
.sb-deliverable-row-secondary {
  color: #858585;
  font-family: 'Work Sans', 'Helvetica Neue', sans-serif;
  font-size: 12px;
  font-weight: 400;
}
.sb-deliverable-row-primary {
  color: #565656;
  font-family: 'Work Sans', 'Helvetica Neue', sans-serif;
  font-size: 14px;
  font-weight: 400;
}
.sb-deliverable-row {
  display: flex;
  flex-direction: row;
  align-items: center;
  padding: 8px/2 8px;
  border-bottom: 1px solid #E1E1E1;
  flex-wrap: wrap;
  border-left: 4px solid;
  /*
    &.sb-task-has-claims,
    &.sb-task-has-obstructions{
        background:rgba(255, 185, 0, 0.09);
        .sb-progress b,
        .sb-progress-value{
            background:rgba(255, 185, 0, 0.09);
        }
    }
    &.sb-task-behind{
        background: rgba(255, 229, 229, 0.5);
        .sb-progress b,
        .sb-progress-value{
            background: rgba(255, 229, 229, 0.5);
        }
    }*/
}
.sb-deliverable-row.sb-deliverable-row-no-progress {
  border: 1px solid #CECECE;
  border-bottom: none;
}
.sb-deliverable-row.sb-deliverable-row-no-progress:hover {
  cursor: default;
}
.sb-deliverable-row.sb-deliverable-row-no-progress .sb-workflow {
  max-width: 171px;
}
.sb-deliverable-row:focus {
  outline: none;
}
.sb-deliverable-row:hover {
  cursor: pointer;
}
.sb-deliverable-cell {
  font-size: 14px;
  border-collapse: collapse;
  display: flex;
  flex: 1;
  text-align: left;
  width: 100%;
  min-width: 114px;
  height: 40px;
  align-items: center;
  justify-content: flex-start;
  padding: 2px 8px;
  overflow: hidden;
}
.sb-deliverable-cell.sb-tags {
  justify-content: flex-end;
}
.sb-deliverable-cell-rows {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  overflow: hidden;
}
.sb-deliverable-row .sb-code {
  color: #858585;
  font-family: 'Work Sans', 'Helvetica Neue', sans-serif;
  font-size: 12px;
  font-weight: 400;
  align-self: stretch;
  overflow: hidden;
}
.sb-deliverable-row .sb-code span {
  float: left;
  max-width: 100%;
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
  min-width: 0;
  min-height: 0;
}
.sb-deliverable-row .sb-name {
  color: #565656;
  font-family: 'Work Sans', 'Helvetica Neue', sans-serif;
  font-size: 14px;
  font-weight: 400;
  align-self: stretch;
  overflow: hidden;
}
.sb-deliverable-row .sb-name span {
  float: left;
  max-width: 100%;
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
  min-width: 0;
  min-height: 0;
}
.sb-deliverable-row .sb-issue-warning {
  display: none;
  font-size: 16px;
  padding: 0;
  z-index: 1;
  position: absolute;
  left: 48px;
  bottom: 10px;
  background: #FFFFFF;
}
.sb-deliverable-row .sb-progress {
  justify-content: center;
  align-items: center;
  font-weight: 500;
  max-width: 60px;
  min-width: 60px;
  position: relative;
}
.sb-deliverable-row .sb-progress-value {
  font-size: 11px;
  position: absolute;
  left: 40px;
  bottom: 6px;
  color: #6C6C6C;
  background: #FFFFFF;
  padding-right: 2px;
  z-index: 1;
}
.sb-deliverable-row .sb-progress-icon {
  font-size: 24px;
}
.sb-deliverable-row .sb-dates {
  font-size: 14px;
  font-weight: 400;
  text-align: center;
  color: #858585;
  justify-content: center!important;
  align-items: flex-start;
  max-width: 171px;
}
.sb-deliverable-row .sb-structure {
  color: #858585;
  font-family: 'Work Sans', 'Helvetica Neue', sans-serif;
  font-size: 12px;
  font-weight: 400;
  align-self: stretch;
  overflow: hidden;
}
.sb-deliverable-row .sb-structure span {
  float: left;
  max-width: 100%;
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
  min-width: 0;
  min-height: 0;
}
.sb-deliverable-row .sb-description {
  color: #565656;
  font-family: 'Work Sans', 'Helvetica Neue', sans-serif;
  font-size: 14px;
  font-weight: 400;
  align-self: stretch;
  overflow: hidden;
}
.sb-deliverable-row .sb-description span {
  float: left;
  max-width: 100%;
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
  min-width: 0;
  min-height: 0;
}
.sb-deliverable-row .sb-workflow {
  justify-content: flex-end;
  align-items: center;
  position: relative;
  text-align: right;
  color: #565656;
  font-family: 'Work Sans', 'Helvetica Neue', sans-serif;
  font-size: 14px;
  font-weight: 400;
  /*a{
            text-decoration: none;
            color: @sablono-grey-700;
            &:hover{
                color: @primary-font-color;
            }
        }*/
}
.sb-deliverable-row .sb-inspection {
  white-space: nowrap;
  color: #858585;
  font-family: 'Work Sans', 'Helvetica Neue', sans-serif;
  font-size: 12px;
  font-weight: 400;
}
.sb-deliverable-row.sb-task-done .sb-progress-value,
.sb-deliverable-row.sb-task-not-started .sb-progress-value {
  display: none;
}
.sb-deliverable-row.sb-task-done.sb-task-has-claims .sb-issue-warning,
.sb-deliverable-row.sb-task-not-started.sb-task-has-claims .sb-issue-warning,
.sb-deliverable-row.sb-task-done.sb-task-has-obstructions .sb-issue-warning,
.sb-deliverable-row.sb-task-not-started.sb-task-has-obstructions .sb-issue-warning {
  display: block;
}
.sb-deliverable-row.sb-deliverable-row-mini {
  padding: 4px 2px 4px 0;
}
.sb-deliverable-row.sb-deliverable-row-mini .sb-deliverable-cell {
  padding: 2px 4px;
}
.sb-deliverable-row.sb-deliverable-row-mini .sb-deliverable-cell.sb-dates {
  max-width: 80px;
  min-width: 80px;
}
.sb-deliverable-row.sb-deliverable-row-mini .sb-progress {
  max-width: 40px;
  min-width: 40px;
  width: 40px;
  padding: 0 16px 0 0;
}
.sb-deliverable-row.sb-deliverable-row-mini .sb-progress .sb-progress-value {
  left: 20px;
}
@media screen and (max-width: 960px) {
  .sb-deliverable-row {
    padding: 4px 0 14px;
    position: relative;
  }
  .sb-deliverable-row .sb-tags {
    display: none;
  }
  .sb-deliverable-row .sb-progress b {
    font-size: 14px;
    width: 60px;
  }
  .sb-deliverable-row .sb-dates {
    min-width: 114px;
  }
  .sb-deliverable-row .sb-workflow {
    text-align: left;
    justify-content: flex-start;
    align-items: flex-end;
  }
  .sb-deliverable-cell-rows {
    min-width: 60px;
    align-items: flex-start;
    justify-content: center;
  }
}
.sb-delayed-days-indicator {
  font-size: 11px;
  font-weight: 400;
  padding: 2px 4px 1px;
  border-radius: 4px;
  margin: 1px;
  color: #FFFFFF;
  background: #B33636;
  font-weight: 500;
  white-space: nowrap;
  margin-left: 8px;
}
.sb-delayed-days-indicator.sb-tag-on-time {
  background: #5EC29A;
}
.sb-delayed-days-indicator.sb-tag-behind {
  background: #B33636;
}
.sb-delayed-days-indicator.sb-tag-unscheduled {
  background: #6C6C6C;
}
.sb-delayed-days-indicator.sb-tag-open-claims {
  background: #E87E00;
}
.sb-delayed-days-indicator.sb-tag-open-obstructions {
  background: #FF4141;
}
.sb-delayed-days-indicator.sb-tag-infos {
  background: #365781;
}
.sb-delayed-days-indicator.sb-tag-user-date {
  background: #36B381;
}
.sb-delayed-days-indicator.sb-tag-user-date-conflict {
  background: #BA6500;
}
.sb-at-risk-indicator {
  font-size: 11px;
  font-weight: 400;
  padding: 2px 4px 1px;
  border-radius: 4px;
  margin: 1px;
  color: #FFFFFF;
  background: #E87E00;
  font-weight: 500;
  white-space: nowrap;
  margin-left: 8px;
}
.sb-at-risk-indicator.sb-tag-on-time {
  background: #5EC29A;
}
.sb-at-risk-indicator.sb-tag-behind {
  background: #B33636;
}
.sb-at-risk-indicator.sb-tag-unscheduled {
  background: #6C6C6C;
}
.sb-at-risk-indicator.sb-tag-open-claims {
  background: #E87E00;
}
.sb-at-risk-indicator.sb-tag-open-obstructions {
  background: #FF4141;
}
.sb-at-risk-indicator.sb-tag-infos {
  background: #365781;
}
.sb-at-risk-indicator.sb-tag-user-date {
  background: #36B381;
}
.sb-at-risk-indicator.sb-tag-user-date-conflict {
  background: #BA6500;
}
.sb-pick-project-member-dialog {
  max-width: 600px;
}
.sb-dialog__action-buttons {
  padding: 0 16px 8px;
}
.sb-bulk-state-change-dialog {
  max-width: 600px;
  min-width: 600px;
}
.sb-bulk-state-change-dialog__toolbar {
  flex: 0 0 96px;
}
.sb-bulk-state-change-dialog__content {
  display: flex;
  overflow-y: hidden;
}
.sb-bulk-state-change-dialog__activities-list {
  overflow-y: auto;
  margin-bottom: 8px;
}
.sb-bulk-state-change-dialog__action-buttons {
  padding: 0 16px 8px;
  flex: 0 0 52px;
}
.sb-bulk-state-change-dialog__progress-selector {
  display: flex;
  height: 100px;
  align-items: center;
  justify-content: space-around;
}
.sb-bulk-state-change-dialog__select-type__progress-option {
  opacity: 0.5;
  border: 1px solid;
  transition: all ease-in-out 0.4s;
  font-size: 32px;
  padding: 12px 16px;
  outline-width: 0;
  border-radius: 4px;
  cursor: pointer;
}
.sb-bulk-state-change-dialog__select-type--selected.sb-todo {
  background-color: #B3B3B3;
  color: #2B2B2B;
  opacity: 1;
}
.sb-bulk-state-change-dialog__select-type--selected.sb-in-progress {
  background-color: #61A2F5;
  color: #FFFFFF;
  opacity: 1;
}
.sb-bulk-state-change-dialog__select-type--selected.sb-done {
  background-color: #36B381;
  color: #FFFFFF;
  opacity: 1;
}
.sb-bulk-state-change-dialog__state-select {
  margin: unset;
}
.sb-bulk-state-change-dialog__state-select-options md-select-menu,
.sb-bulk-state-change-dialog__state-select-options md-content {
  max-height: 288px;
}
.sb-bulk-checklist-pdf-export-dialog {
  max-width: 600px;
  min-width: 600px;
}
.sb-bulk-checklist-pdf-export-dialog__toolbar {
  flex: 0 0 96px;
}
.sb-bulk-checklist-pdf-export-dialog__actions {
  flex: 0 0 52px;
}
.sb-dialog md-toolbar .sb-bulk-checklist-pdf-export-dialog__header {
  margin: 16px;
}
.sb-bulk-checklist-pdf-export-dialog__success {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
}
.sb-bulk-checklist-pdf-export-dialog__success-text {
  font-family: 'Work Sans', 'Helvetica Neue', sans-serif;
  font-size: 16px;
  font-weight: 400;
  color: #565656;
}
.sb-dialog .sb-bulk-checklist-pdf-export__empty-state {
  display: flex;
  flex-direction: row;
  min-height: 130px;
  padding: 0 16px;
}
.sb-bulk-checklist-pdf-export__icon-container {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  flex: 0 0 auto;
  width: 120px;
}
.sb-bulk-checklist-pdf-export__text-container {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  flex: 1;
}
.sb-bulk-checklist-pdf-export__text-container .sb-bulk-checklist-pdf-export__empty-state-title,
.sb-bulk-checklist-pdf-export__text-container .sb-bulk-checklist-pdf-export__empty-state-text {
  margin: 4px;
  font-weight: 300;
  color: #6C6C6C;
}
.sb-bulk-checklist-pdf-export__text-container .sb-bulk-checklist-pdf-export__empty-state-title {
  color: #565656;
}
.mdi-file-remove {
  font-size: 80px;
  width: 80px;
  height: 80px;
}
.sb-bulk-checklist-pdf-export-dialog__add-email-btn-icon {
  display: flex;
  justify-content: center;
  border-radius: 50%;
  margin-left: 8px;
  height: 20px;
  width: 20px;
  outline-width: 0;
  font-size: 18px;
  color: #36B381;
}
.sb-bulk-checklist-pdf-export-dialog__add-email-btn-text {
  margin-left: 8px;
}
.sb-bulk-checklist-pdf-export-dialog__add-email-wrapper {
  display: flex;
  flex-direction: row;
  align-items: center;
  border: 0;
  background-color: transparent;
  position: absolute;
  left: 12px;
}
.sb-bulk-checklist-pdf-export-dialog__add-email-wrapper:disabled .sb-bulk-checklist-pdf-export-dialog__add-email-btn-icon {
  color: #B3B3B3;
}
.deliverable_checklists_pdf_export {
  max-width: 600px;
  min-width: 600px;
}
.sb-dialog md-toolbar .deliverable_checklists_pdf_export__header {
  margin: 16px;
}
.sb-bulk-create-note-dialog {
  max-width: 600px;
  min-width: 600px;
}
.sb-bulk-create-note-dialog__toolbar {
  flex: 0 0 96px;
}
.sb-bulk-create-note-dialog__actions {
  flex: 0 0 52px;
}
/**
 * Helper mixin to create a colored badge with text.
 * Positioning needs to be added by the user.
 */
.sb-activity-set-conf-dialog {
  max-width: 600px;
  min-width: 600px;
}
.sb-activity-set-conf-dialog__toolbar {
  min-height: auto;
}
.sb-activity-set-conf-dialog__actions {
  flex: 0 0 52px;
}
.sb-activity-set-conf-dialog__activities {
  display: flex;
  flex-direction: column;
  overflow-y: hidden;
  margin: 0;
}
.sb-activity-set-conf-dialog__activities-list {
  overflow-y: auto;
}
.sb-dialog .sb-activity-set-conf-dialog__options {
  padding: 0;
  margin: 0;
}
.sb-activities-list-selector__text {
  margin-top: 0;
}
.sb-activity-set-conf-dialog__loading {
  display: flex;
  flex-direction: column;
}
.sb-activity-set-conf-dialog__activities {
  display: flex;
  flex-direction: column;
}
.sb-activity-set-conf-dialog__scheduling-info {
  padding-bottom: 16px;
}
.sb-beta-feature-badge:after {
  content: ' BETA ';
  transform: rotate(0deg);
  padding: 6px;
  background: #36B381;
  color: #FFFFFF;
  font-weight: 500;
  font-size: 12px;
  line-height: 12px;
  border-radius: 12px;
  position: absolute;
  right: 64px;
  margin: 10px 0;
}
.sb-activity-set-conf-dialog__team-set-container {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  padding-left: 16px;
  padding-right: 16px;
}
.sb-activity-set-conf-dialog__team-type-select {
  margin: 0 5px;
  padding-left: 10px;
  flex: 1;
  height: 45px;
}
.sb-activity-set-conf-dialog__team-select {
  width: 100%;
  flex: 2;
}
.sb-activity-set-conf-dialog__team-select .sb-team-select__input {
  height: 45px;
}
.sb-activity-set-conf-dialog__team-select .sb-team-select {
  width: 100%;
  text-indent: 8px;
}
.sb-deliverable-set-config-dialog {
  max-width: 600px;
  min-width: 600px;
}
.sb-dialog .sb-deliverable-set-config-dialog__options {
  padding: 0;
  margin: 0;
}
.sb-deliverables-list-header {
  width: calc(100% - 8px);
  margin-top: 8px;
  flex: 0 0 auto;
}
.sb-deliverables-list-header .sb-workflow {
  align-items: center;
}
.sb-deliverables-list-header .sb-has-active-selections:before {
  font-size: 20px;
  position: absolute;
  left: 7px;
  bottom: -2px;
  content: '-';
}
.sb-deliverables-list-header .sb-deliverables-list-header-checkbox {
  position: absolute;
  z-index: 1;
  left: 18px;
  top: 12px;
}
.sb-deliverables-list-header .sb-deliverables-list-header-checkbox.md-checked .md-icon {
  background: #309f73;
}
.sb-deliverables-list-header-row {
  width: calc(100% - 20px);
  max-width: 1400px;
  border-width: 0;
  margin: 0 0 2px 10px;
  padding: 0 8px;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  background: #FBFAFA;
  position: relative;
  box-shadow: 0 0 1px 1px rgba(0, 0, 0, 0.14), 0 0 2px 2px rgba(0, 0, 0, 0.098), 0 0 5px 1px rgba(0, 0, 0, 0.084);
}
.sb-deliverables-list-header-row .sb-deliverable-cell {
  font-weight: 300;
  color: #4C4C4C;
  overflow: visible;
}
.sb-deliverables-list-container {
  position: relative;
  margin: 0 auto;
  background: #F4F3F2;
  overflow: hidden;
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 100%;
  max-width: 1400px;
}
.sb-deliverables-list-container .sb-odata-list-content {
  min-height: calc(100% - 120px);
  height: calc(100% - 120px);
}
.sb-deliverables-list-container .sb-odata-list-content .sb-odata-row-container {
  width: calc(100% - 20px);
  max-width: 1400px;
  margin: 0 0 0 20px;
  background: #FFFFFF;
}
.sb-deliverables-list-container__filter-container {
  flex: 0 0 auto;
}
@media screen and (max-width: 960px) {
  .sb-odata-row-container,
  .sb-deliverables-list-header,
  .sb-deliverables-list-header-row {
    width: 100%;
  }
  .sb-odata-list-repeater {
    height: 100%;
    padding-bottom: 0;
  }
  .sb-odata-list-repeater.sb-deliverables-list-with-filters {
    height: calc(100% - 62px);
    min-height: calc(100% - 62px);
    padding-bottom: 54px;
  }
}
.sb-toolbar-icon .sb-icon-template,
.sb-toolbar-icon .sb-icon-wbs_structure {
  font-size: 20px;
}
.sb-request-for-progress-panel {
  width: 600px;
}
.sb-deliverables-list {
  height: 100%;
  justify-content: center;
  width: calc(100% - 20px);
  display: flex;
  flex-direction: row;
}
.sb-deliverables-list__filter-menu {
  margin-top: 70px;
  position: absolute;
  left: 0;
  top: 0;
}
.sb-deliverables-list-container .sb-odata-list-repeater {
  position: relative;
  left: -5px;
}
.sb-deliverables-empty-state {
  margin: auto;
}
@media screen and (max-width: 1800px) {
  .sb-deliverables-list-container.is-menu-showing {
    margin: 0 auto 0 200px;
  }
}
.sb-area-manager-card {
  border: 1px solid #E1E1E1;
}
.sb-area-manager-card .sb-user-badge-container {
  border: none !important;
}
.sb-area-manager-card__active-switch {
  display: block;
  margin: 8px 0 0;
  width: 50px;
}
.sb-area-manager-card__active-switch .md-container {
  margin-left: 8px;
}
.sb-area-manager-card__menu {
  display: inline-block;
}
.sb-area-manager-card__active-text {
  display: inline-block;
  vertical-align: top;
  font-size: 10px;
  max-width: 50px;
  color: #858585;
}
.sb-area-manager-card__existing-replacement {
  position: relative;
}
.sb-area-manager-card__existing-replacement .md-button {
  padding: 0;
  margin: 0;
}
.sb-area-manager-card__existing-replacement .sb-area-manager-card__menu {
  vertical-align: top;
  overflow-x: hidden;
}
.sb-area-manager-card__menu-item .sb-user-badge-container {
  background: #FFFFFF;
  height: 48px;
}
.sb-area-manager-card__menu-item .sb-user-badge-container .sb-user-badge-icon {
  cursor: pointer;
}
.sb-area-manager-card__menu-item .sb-user-badge-container .sb-user-badge-user-role {
  display: none;
}
.sb-area-manager-card__menu-item .sb-user-badge.replacement {
  outline: none;
  padding: 0;
  width: 100%;
}
.breadcrumb-menu-content .sb-add-new-card .sb-add-new-card-content,
.sb-area-manager-card__menu .sb-add-new-card .sb-add-new-card-content {
  padding-left: 8px;
  border: none;
  background: #FFFFFF;
  color: #858585;
  text-transform: none;
}
.sb-add-new-card--change-forbidden {
  pointer-events: none;
}
.sb-area-manager-card__activate {
  display: inline-block;
  position: relative;
  padding: 0;
  text-align: center;
}
.sb-team-user-list sb-team-area-manager-card .sb-add-new-card-content {
  height: 63px !important;
}
.sb-user-badge.replacement {
  width: 190px;
}
.sb-user-badge.replacement .sb-user-badge-container {
  box-sizing: border-box;
  box-shadow: none;
  height: 100%;
}
.sb-user-badge.replacement .sb-user-badge-user {
  line-height: initial;
  text-align: left;
  text-transform: initial;
  font-weight: 300;
  cursor: pointer !important;
}
.sb-add-user-dialog {
  max-width: 600px;
  width: 600px;
  min-width: 600px;
  transform: all 0.3s ease-in-out;
  min-height: 270px;
}
.sb-add-user-dialog .sb-add-user-dialog-content {
  min-height: 270px;
  display: flex;
  justify-content: space-around;
  margin-bottom: 0;
}
.sb-add-user-dialog .sb-add-user-dialog-loading {
  flex: 1;
}
.sb-add-user-dialog .md-toolbar-tools i {
  font-size: 25px;
  margin-right: 8px;
}
.sb-add-user-dialog .sb-add-user-roles {
  display: flex;
  flex-direction: column;
}
.sb-add-user-dialog .sb-add-user-roles .sb-add-user-roles-container {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  margin: 0 0 16px;
}
.sb-add-user-dialog .sb-add-user-roles em {
  text-align: center;
  font-size: 14px;
  width: 85%;
  margin: 0 auto;
  min-height: 56px;
}
.sb-add-user-dialog .sb-add-user-roles .sb-add-user-role {
  flex-grow: 1;
  justify-content: center;
  display: flex;
}
.sb-add-user-dialog .sb-add-user-roles .sb-add-user-role .sb-add-user-badge {
  width: 50px;
  height: 50px;
  border: 1px solid;
  line-height: 50px;
  text-align: center;
  font-size: 50px / 2;
  cursor: pointer;
  outline-width: 0;
  border-radius: 5px;
}
.sb-add-user-dialog .sb-add-user-roles .sb-add-user-role .sb-add-user-badge.selected {
  padding: 1px;
  color: #FFFFFF;
}
.sb-add-user-dialog-team-option {
  margin: 0 auto;
  width: 100%;
}
.sb-add-user-dialog-select-team {
  padding-bottom: 16px;
}
.sb-add-user-dialog-select-team p {
  margin-top: 0;
}
.sb-add-user-dialog-team-option-container {
  border-left: 5px solid;
  min-height: 45px;
}
.sb-add-user-dialog-team-option-container .md-select-value {
  padding: 8px 8px;
}
.sb-add-user-dialog-team-option-container.md-input-focused .md-select-value > span {
  padding-bottom: 8px;
}
.sb-add-user-dialog-chips {
  margin-bottom: 16px;
}
.sb-add-user-dialog-team-option-select md-option {
  border-left: 5px solid;
  margin: 2px 0;
}
.sb-teams {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  height: 100%;
}
.sb-teams-invited-members {
  width: 800px;
  margin-bottom: 16px;
}
.sb-teams-invited-members .sb-teams-invited-members-cards {
  display: flex;
  flex-wrap: wrap;
  width: 100%;
}
.sb-teams-invited-members .sb-teams-invited-members-cards .sb-teams-invited-members-card {
  width: 250px;
  margin: 0 4px 8px;
  border: 1px solid #B3B3B3;
}
.sb-teams-invited-members .sb-teams-invited-members-cards .sb-teams-invited-members-card .sb-user-badge-user {
  margin-left: 4px;
}
.sb-team-card {
  width: 800px;
  max-width: 100%;
  border-top: 8px solid;
  margin: 16px auto;
}
.sb-team-card .sb-team-card-content {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding-top: 8px;
}
.sb-team-card .sb-team-card-edit {
  color: #2B2B2B;
  background: #F4F3F2;
  cursor: pointer;
  border-radius: 5px;
  margin-left: 4px;
  padding: 4px;
  outline-width: 0;
}
.sb-team-card .sb-team-card-edit:hover {
  background: #E1E1E1;
}
.sb-team-card .sb-team-card-details {
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: flex-end;
  box-shadow: 0 1px #e0e0e0;
  padding: 8px 0;
}
.sb-team-card .sb-team-card-details .sb-team-card-details-title {
  font-size: 20px;
  font-weight: 300;
  margin: 0;
  align-self: flex-start;
}
.sb-team-card .sb-team-card-details .sb-team-card-details-members-number {
  padding-top: 4px;
  align-self: flex-start;
  font-family: 'Work Sans', 'Helvetica Neue', sans-serif;
  font-size: 14px;
  font-weight: 400;
  color: #858585;
}
.sb-team-card .sb-team-card-details .sb-team-card-details-info {
  display: flex;
  flex-direction: row;
  font-size: 12px;
  color: #858585;
}
.sb-team-card .sb-team-card-details .sb-team-card-details-info > div {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  align-items: flex-end;
  min-width: 100px;
  margin-left: 8px;
}
.sb-team-card .sb-team-card-details .sb-team-card-details-info > div.sb-team-card-details-info-block {
  padding-right: 8px;
  border-right: 1px solid #CECECE;
}
.sb-team-card .sb-team-card-details a {
  text-decoration: none;
  color: #6C6C6C;
  padding: 0 0 2px;
}
.sb-team-card .sb-team-card-details a i {
  color: #B3B3B3;
}
.sb-team-card__team-logo {
  display: inline-block;
  width: 64px;
  height: 64px;
  position: relative;
  margin-right: 8px;
}
.sb-team-card__team-logo img {
  max-width: 64px;
  max-height: 64px;
  width: auto;
  height: auto;
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  margin: auto;
}
sb-team-create {
  width: 100%;
  max-width: 800px;
  margin: 16px;
}
.sb-team-card-users {
  width: 100%;
}
.sb-team-user-list {
  width: 100%;
}
.sb-team-user-list sb-team-user-card,
.sb-team-user-list sb-team-user-create,
.sb-team-user-list sb-team-area-manager-card {
  width: 250px;
  margin: 3px;
  height: 68px;
  float: left;
}
.sb-team-user-list sb-team-user-card .sb-user-badge,
.sb-team-user-list sb-team-user-create .sb-user-badge,
.sb-team-user-list sb-team-area-manager-card .sb-user-badge {
  height: 63px;
}
.sb-team-user-list sb-team-user-card .sb-user-badge .sb-user-badge-container,
.sb-team-user-list sb-team-user-create .sb-user-badge .sb-user-badge-container,
.sb-team-user-list sb-team-area-manager-card .sb-user-badge .sb-user-badge-container {
  background: initial !important;
  border: 1px solid #E1E1E1;
}
.sb-team-user-list sb-team-user-card .sb-user-badge .sb-user-badge-user,
.sb-team-user-list sb-team-user-create .sb-user-badge .sb-user-badge-user,
.sb-team-user-list sb-team-area-manager-card .sb-user-badge .sb-user-badge-user {
  text-indent: 4px;
  cursor: default;
}
.sb-team-user-list sb-team-user-card .sb-add-new-card-content,
.sb-team-user-list sb-team-user-create .sb-add-new-card-content,
.sb-team-user-list sb-team-area-manager-card .sb-add-new-card-content {
  line-height: normal;
  padding: 0 8px !important;
  height: 61px;
  margin-top: 0;
  box-sizing: border-box;
}
.sb-team-user-list sb-team-user-card .sb-add-new-card-content span,
.sb-team-user-list sb-team-user-create .sb-add-new-card-content span,
.sb-team-user-list sb-team-area-manager-card .sb-add-new-card-content span {
  text-indent: 4px;
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
  min-width: 0;
  min-height: 0;
}
.sb-team-user-list sb-team-area-manager-card {
  height: 132px;
}
.sb-team-card-users-loading {
  float: left;
  position: relative;
  margin: 3px;
  width: 250px;
  height: 63px;
}
.sb-team-card__info-icon {
  font-size: 16px;
  line-height: 20px;
  text-align: center;
}
.sb-team-card__info-icon:hover {
  color: #2B2B2B;
}
.area-managers-section__title {
  padding-top: 8px;
  clear: both;
  font-size: 16px;
  color: #2B2B2B;
  text-align: left;
  margin: 0 0 16px 0;
}
/* IE only styles */
@media all and (-ms-high-contrast: none), (-ms-high-contrast: active) {
  .sb-team-user-list {
    min-height: 240px;
    overflow-y: auto;
    overflow-x: hidden;
    width: 800px;
    max-width: 800px;
  }
  .sb-team-card {
    max-width: 800px;
  }
}
.sb-team-card__content {
  display: flex;
  flex-direction: column;
}
.sb-team-card-details__team-members,
.sb-team-card-details__commercial-members {
  margin-bottom: 16px;
  width: 100%;
}
.sb-team-card-details__commercial-members {
  margin-top: 16px;
}
.sb-lane__container {
  position: relative;
  background: #FFFFFF;
}
.sb-lane__container .sb-lean-board__deliverable-card,
.sb-lane__container .sb-lane {
  background: #FFFFFF;
}
.sb-lane__container .sb-deliverable-card__mini-actions {
  right: 0;
  top: 10px;
  display: flex;
  flex-direction: row;
}
.sb-lane {
  width: 100%;
  margin-left: 300px;
  position: absolute;
  top: 0;
  height: 100%;
}
.sb-lane__row {
  display: flex;
  flex-direction: row;
  position: relative;
  z-index: 1;
  width: 100%;
}
.sb-lane__bg {
  height: 100%;
  width: 100%;
  display: flex;
  position: absolute;
  z-index: 0;
}
.sb-lane__column-day {
  flex: 1;
  border-right: 1px solid #858585;
  border-bottom: 1px solid #858585;
}
.sb-lane__column-day.sb-lane__column-day--non-working {
  background: #d3d3d3;
}
.sb-lane__column-day.sb-lane__column-day--today {
  background: #8dd4b8;
}
.sb-lane__deliverable__button {
  background-color: transparent;
  border-radius: 4px;
  font-size: 16px;
  border: none;
  outline: none;
}
.sb-lane__deliverable__button:hover {
  background: #CECECE;
}
.sb-lane__deliverable__button i {
  transition: color 0.2s ease-out;
  color: #2B2B2B;
}
.sb-lane__deliverable__button--warning i {
  color: #B33636;
}
.sb-lane md-progress-linear {
  z-index: 10000;
  position: absolute;
}
.sb-lane .md-progress-linear.md-default-theme .md-container,
.sb-lane md-progress-linear .md-container {
  background-color: #86D1B3 !important;
}
.sb-lane md-progress-linear .md-container .md-bar {
  background-color: #36B381 !important;
}
.sb-activity-card--selected .sb-activity-card {
  box-shadow: 0 8px 8px 0 rgba(0, 0, 0, 0.4);
  background: #36B381 !important;
  border-color: #36B381 !important;
  color: #4C4C4C;
}
.sb-activity-card--selected .sb-activity-card .sb-activity-card__info--details {
  color: #4C4C4C;
}
.sb-activity-card--selected .sb-activity-card .sb-activity-card__info--topologicalindex {
  color: #4C4C4C;
}
.sb-activity-card--selected .sb-activity-card .sb-activity-card__info--details {
  color: #4C4C4C;
}
.sb-activity-card {
  margin: 0 4px 8px;
  min-height: 40px;
  height: 40px;
  background: #CECECE;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  position: relative;
  border-width: 2px;
  border-style: solid;
  box-sizing: border-box;
  cursor: pointer;
  outline: none;
  color: #565656;
}
.sb-activity-card.sb-activity-card--overflows--right {
  margin-right: 13px;
}
.sb-activity-card.sb-activity-card--overflows--right:after {
  content: "";
  position: absolute;
  right: -13px;
  top: -2px;
  width: 0;
  height: 0;
  border-left-width: 11px;
  border-color: inherit;
  border-left-style: solid;
  border-bottom: 20px solid transparent;
  border-top: 20px solid transparent;
}
.sb-activity-card.sb-activity-card--overflows--right .sb-activity-card__move-right {
  display: none;
}
.sb-activity-card.sb-activity-card--overflows--left {
  margin-left: 13px;
}
.sb-activity-card.sb-activity-card--overflows--left:before {
  content: "";
  position: absolute;
  left: -13px;
  top: -2px;
  width: 0;
  height: 0;
  border-right-width: 11px;
  border-color: inherit;
  border-right-style: solid;
  border-top: 20px solid transparent;
  border-bottom: 20px solid transparent;
}
.sb-activity-card.sb-activity-card--overflows--left .sb-activity-card__move-left {
  display: none;
}
.sb-lane__row--first .sb-activity-card {
  margin-top: 8px;
}
.sb-activity-card__info {
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  position: absolute;
  max-width: 100%;
}
.sb-activity-card__text-container {
  width: 100%;
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
  min-width: 0;
  min-height: 0;
}
.sb-activity-card__info-name {
  font-size: 14px;
  font-weight: 400;
  padding-bottom: 1px;
}
.sb-activity-card__info-name--mini {
  display: none;
  padding-left: 4px;
}
.sb-activity-card__info--loading {
  height: 35px;
}
.sb-shown_on_hover {
  height: 0;
  overflow: hidden;
  transition: height 0.1s ease-in-out;
}
.sb-shown_on_hover.visible {
  height: 25px;
}
.sb-round_button {
  background: #FBFAFA;
  border-radius: 50%;
  outline-width: 0;
  font-size: 20px;
  color: #6C6C6C;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}
.sb-round_button:hover {
  background: #E1E1E1;
  cursor: pointer;
}
.sb-activity-card__move-right,
.sb-activity-card__move-left {
  height: 0;
  overflow: hidden;
  transition: height 0.1s ease-in-out;
  background: #FBFAFA;
  border-radius: 50%;
  outline-width: 0;
  font-size: 20px;
  color: #6C6C6C;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  position: absolute;
  top: 15px/2;
  width: 0;
}
.sb-activity-card__move-right.visible,
.sb-activity-card__move-left.visible {
  height: 25px;
}
.sb-activity-card__move-right:hover,
.sb-activity-card__move-left:hover {
  background: #E1E1E1;
  cursor: pointer;
}
.sb-activity-card__move-right.visible,
.sb-activity-card__move-left.visible {
  width: 25px;
  border: 1px solid #858585;
}
.sb-activity-card__actions {
  height: 0;
  overflow: hidden;
  transition: height 0.1s ease-in-out;
  position: absolute;
  top: -25px;
  display: flex;
  flex-direction: row;
  left: 0;
}
.sb-activity-card__actions.visible {
  height: 25px;
}
.sb-activity-card__actions.visible .sb-activity-card__actions__button {
  height: 25px;
  width: 25px;
  border: 1px solid #858585;
}
.sb-activity-card__actions__button {
  background: #FBFAFA;
  border-radius: 50%;
  outline-width: 0;
  font-size: 20px;
  color: #6C6C6C;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}
.sb-activity-card__actions__button:hover {
  background: #E1E1E1;
  cursor: pointer;
}
.sb-activity-card__move-right {
  right: -16px;
}
.sb-activity-card__move-right i {
  position: relative;
  top: 1px;
  left: 1px;
}
.sb-activity-card__move-left {
  left: -16px;
}
.sb-activity-card__move-left i {
  position: relative;
  top: 1px;
  right: 1px;
}
.sb-activity-card__info--main {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  width: 100%;
  height: 50%;
}
.sb-activity-card--mini .sb-activity-card__info--main {
  height: 100%;
}
.sb-activity-card__info--details {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  width: 100%;
  max-width: 100%;
  color: #6C6C6C;
  padding-left: 4px;
  font-size: 12px;
  overflow: hidden;
  background-color: inherit;
}
.sb-activity-card__information {
  border-right: 1px solid #B3B3B3;
  padding-right: 4px;
}
.sb-activity-card__information .mdi {
  color: #858585;
}
.sb-activity-card__tag-cloud {
  align-self: center;
}
.sb-activity-card__tag-cloud .sb-tags {
  flex-wrap: nowrap;
}
.sb-activity-card__tag-cloud .sb-tag {
  padding: 0;
  background: none;
  border: none;
  color: inherit;
}
.sb-activity-card__tag-cloud .sb-tag .mdi {
  font-size: 12px;
  color: inherit;
}
.sb-activity-card__info--duration {
  border-right: 1px solid #B3B3B3;
  padding-right: 4px;
  border-right: none;
  background-color: inherit;
}
.sb-activity-card__info--duration .mdi {
  color: #858585;
}
.sb-activity-card__info--topologicalindex {
  font-family: 'Work Sans', 'Helvetica Neue', sans-serif;
  font-size: 12px;
  font-weight: 400;
  border: 1px solid;
  height: 13px;
  line-height: 13px;
  border-radius: 25%;
  padding: 0 2px;
  margin: -1px 4px 0;
}
.sb-activity-card__info--labour {
  border-right: 1px solid #B3B3B3;
  padding-right: 4px;
  border-right: none;
}
.sb-activity-card__info--labour .mdi {
  color: #858585;
}
.sb-activity-card__info--team {
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
  min-width: 0;
  min-height: 0;
}
.sb-activity-card__info--late,
.sb-activity-card__tag-cloud {
  display: flex;
  color: #7D2626;
}
.sb-activity-card__jump-to-monday {
  display: none;
  text-transform: uppercase;
  font-size: 12px;
  position: absolute;
  z-index: 1;
  top: 4px;
  right: 4px;
  padding: 4px 8px;
  background: rgba(255, 255, 255, 0.9);
  cursor: pointer;
  border-radius: 3px;
  outline-width: 0;
  border: 1px solid #858585;
}
.sb-activity-card__jump-to-monday:hover {
  color: #4C4C4C;
  background: #FBFAFA;
  font-weight: 500;
}
.sb-activity-card:hover .sb-activity-card__jump-to-monday {
  display: block;
}
.sb-activity-card__date-diff {
  position: absolute;
  background: #FFFFFF;
  top: -6px;
  right: -7px;
  padding: 0 4px;
  color: #2B2B2B;
  z-index: 1;
}
.sb-activity-card--mini .sb-activity-card {
  height: 20px;
  min-height: 20px;
  margin-top: 0;
  margin-bottom: 4px;
}
.sb-activity-card--mini .sb-lane__row--first .sb-activity-card {
  margin-top: 4px;
}
.sb-activity-card--mini .sb-activity-card__info--details {
  display: none;
}
.sb-activity-card--mini .sb-activity-card__info-name--mini {
  display: block;
}
.sb-activity-card--mini .sb-activity-card--overflows--left:before {
  border-bottom: 10px solid transparent;
  border-top: 10px solid transparent;
}
.sb-activity-card--mini .sb-activity-card--overflows--right:after {
  border-bottom: 10px solid transparent;
  border-top: 10px solid transparent;
}
.sb-activity-card--mini .sb-activity-card__move-right,
.sb-activity-card--mini .sb-activity-card__move-left {
  top: -7px/2;
}
.sb-activity-card--mini .sb-activity-card__text-container {
  line-height: 15px;
}
.sb-activity-card--not-completed {
  box-shadow: 1px 1px 3px 0 rgba(0, 0, 0, 0.3);
}
.sb-activity-card__progress-icon {
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: white;
  border-radius: 25%;
}
.sb-activity-card__progress-icon i {
  font-size: 16px;
}
.sb-lean-board__deliverable-card {
  box-shadow: 2px 0px 0px 0px rgba(0, 0, 0, 0.14), -2px 0px 0px 0px rgba(0, 0, 0, 0.14);
  display: block;
  position: sticky;
  left: 0;
  z-index: 2;
  height: 100%;
  width: 300px;
  display: flex;
  flex-direction: row;
  opacity: 0.9;
  transition: width 0.2s ease-in-out;
  box-sizing: border-box;
  padding-left: 32px;
}
.sb-deliverable-card__details {
  padding: 4px 8px;
  display: flex;
  flex-direction: row;
}
.sb-deliverable-card__details-container {
  display: flex;
  flex-direction: column;
  max-width: inherit;
  width: inherit;
  flex: 1;
  overflow: hidden;
}
.sb-deliverable-card__details-top {
  display: flex;
  flex-direction: row;
  max-width: inherit;
  width: inherit;
  flex: 1;
  justify-content: space-between;
}
.sb-deliverable-card__structure,
.sb-deliverable-card__name {
  font-family: 'Work Sans', 'Helvetica Neue', sans-serif;
  font-size: 12px;
  font-weight: 400;
  padding: 2px 0;
  margin-right: 8px;
  z-index: 1;
  width: inherit;
}
.sb-deliverable-card__code {
  padding: 2px 0;
  word-wrap: break-word;
  overflow-wrap: break-word;
  hyphens: auto;
  white-space: normal;
  font-family: 'Work Sans', 'Helvetica Neue', sans-serif;
  font-size: 14px;
  font-weight: 400;
  word-break: break-all;
}
.sb-deliverable-card__name {
  word-wrap: break-word;
  overflow-wrap: break-word;
  hyphens: auto;
  white-space: normal;
}
.sb-deliverable-card__structure {
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
  min-width: 0;
  min-height: 0;
}
.sb-deliverable-card__select-indicator {
  flex: 0 0 20px;
  align-self: center;
  padding-left: 0;
  background-color: transparent;
  border-radius: 4px;
  font-size: 20px;
  border: none;
  outline: none;
  cursor: initial;
}
.sb-deliverable-card__select-indicator i {
  color: #36B381;
}
.sb-deliverable-card__wrapper {
  width: 100%;
  border-bottom: 1px solid #858585;
}
.sb-lean-board__week-selector {
  height: 25px;
  min-height: 25px;
  width: 63px;
  min-width: 0;
  margin: 0;
  padding: 0;
  line-height: 20px;
  font-size: 10px;
}
.sb-lean-board__week-selector.sb-button-group__button {
  margin: 0;
}
.sb-lean-board__header-row--smaller {
  font-size: 12px;
}
.sb-lean-board__header__week button.sb-lean-board__week-nav-button {
  margin: 0;
  padding: 0;
  min-height: 10px;
  height: 30px;
  border-radius: 0%;
}
.sb-lean-board__header__week button.sb-lean-board__week-nav-button i {
  color: #2B2B2B;
}
.sb-lean-board__header__week button.sb-lean-board__week-nav-button.open-session:hover,
.sb-lean-board__time-range-button.open-session:hover {
  background-color: #86D1B3;
}
.sb-lean-board__header__week button.sb-lean-board__week-nav-button.open-session,
.sb-lean-board__time-range-button.open-session {
  background-color: #36B381;
}
.sb-lean-board__header__week button.sb-lean-board__week-nav-button.open-session i::before,
.open-session .sb-lean-board__time-range-button--text-wrapper {
  background: #86D1B3;
  border-radius: 5%;
}
.sb-lean-board__header__week button.sb-lean-board__week-nav-button.closed-session,
.sb-lean-board__time-range-button.closed-session {
  background-color: #36B381;
}
.sb-lean-board__header__week button.sb-lean-board__week-nav-button.closed-session:hover,
.sb-lean-board__time-range-button.closed-session:hover {
  background: #86D1B3;
}
.sb-lean-board__header__week button.sb-lean-board__week-nav-button.closed-session i::before,
.closed-session .sb-lean-board__time-range-button--text-wrapper {
  background: #86D1B3;
  border-radius: 5%;
}
.sb-lean-board--disabled {
  pointer-events: none;
}
.sb-lean-board__column-day--non-working {
  background: #d3d3d3;
}
.sb-lean-board__column-day--today {
  background: #8dd4b8;
}
.sb-lean-board__time-range-button {
  width: 68%;
  margin: 0;
  padding: 0;
  min-height: 20px;
  height: 30px;
}
.sb-lean-board__time-range-button--text-wrapper {
  height: 80%;
  display: flex;
  justify-content: center;
  align-items: center;
  color: #6C6C6C;
}
.sb-lean-board__header-items-wrapper {
  display: flex;
  flex-direction: row;
  height: 100%;
  border-bottom: solid #858585 1px;
  background-color: #FFFFFF;
  width: inherit;
  margin-left: -1px;
}
.sb-deliverable-card__late-activities {
  width: 400px;
  box-sizing: border-box;
  max-height: calc(calc(100vh - 8px) - (80px + 52px));
  overflow: auto;
}
.sb-deliverable-card__late-activities .sb-activity-card .sb-activity-card__info--team {
  max-width: 65%;
}
.sb-deliverable-card__late-activities .sb-activity-card.sb-activity-card--overflows--left,
.sb-deliverable-card__late-activities .sb-activity-card.sb-activity-card--overflows--right {
  display: none;
}
.sb-deliverable-card__late-activities--loading {
  display: block;
  min-height: 50px;
  height: 50px;
}
.sb-activity-details-panel {
  width: 500px;
  min-height: 240px;
}
.sb-activity-details-panel md-checkbox.md-checked .md-icon:after {
  top: 0;
  left: 5px;
}
.sb-activity-details-panel__header {
  min-height: 30px;
  padding: 16px 8px;
  background-color: #FBFAFA;
  align-items: center;
}
.sb-activity-details-panel__header-text {
  font-size: 20px;
  font-weight: 500;
  margin-left: 8px;
  color: #4C4C4C;
}
.sb-activity-details-panel__call-to-actions {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}
.sb-activity-details-panel__call-to-actions--only-one {
  margin-left: auto !important;
}
.sb-activity-details-panel__row {
  display: flex;
  flex-direction: row;
}
.sb-activity-details-panel__content {
  display: flex;
  flex-direction: column;
  padding: 8px 16px;
}
.sb-activity-details-panel__key,
.sb-activity-details-panel__value {
  font-size: 14px;
  display: block;
  margin: 2px;
}
.sb-activity-details-panel__key {
  color: #6C6C6C;
  text-rendering: auto;
  font-smoothing: antialiased;
  -moz-font-smoothing: antialiased;
  -webkit-font-smoothing: antialiased;
  flex: 1.3;
  align-self: flex-start;
}
.sb-activity-details-panel__value {
  word-break: break-all;
  text-rendering: auto;
  font-smoothing: antialiased;
  -moz-font-smoothing: antialiased;
  -webkit-font-smoothing: antialiased;
  flex: 1.5;
}
.sb-activity-details-panel__last-planned-duration-input-container {
  margin: -4px 0 8px 0;
  padding: 0;
  padding-bottom: 4px;
  height: 21px;
  width: 48%;
}
.sb-activity-details-panel__last-planned-duration-input-container .md-errors-spacer {
  display: none;
}
.sb-activity-details-panel__last-planned-duration-input-container input {
  padding-top: 0;
}
.sb-activity-details-panel__checkbox-container {
  display: flex;
  flex-direction: row;
  flex: 1.5;
  margin-bottom: 4px;
}
.sb-activity-details-panel__date-picker-form {
  flex: 1.5;
  position: relative;
}
.sb-activity-details-panel__date-picker-form .sb-datepicker__not-set-text {
  left: 0;
}
.sb-activity-details-panel__has-changed-this-session {
  position: absolute;
  top: 0;
  left: 140px;
  font-size: 20px;
  color: #36B381;
}
.sb-activity-details-panel__checkbox {
  margin-top: 16px;
}
.sb-activity-details-panel__checkbox-text {
  font-family: 'Work Sans', 'Helvetica Neue', sans-serif;
  font-size: 12px;
  font-weight: 400;
  position: relative;
  top: 10px;
}
.sb-activity-details-panel__tooltip {
  word-wrap: break-word;
  max-width: 300px;
  white-space: initial;
  height: initial;
  line-height: 1.6;
}
.sb-activity-details-panel__tags {
  display: flex;
  flex-direction: column;
}
.sb-activity-details-panel__tag-cloud {
  width: 100%;
  border-right: none;
  padding-right: 0;
}
.sb-activity-details-panel__change-date {
  cursor: pointer;
  margin-left: 8px;
}
.sb-activity-details-panel__change-date:hover {
  color: #2B2B2B;
}
.sb-activity-details-panel__loading {
  min-height: 245px;
}
.sb-activity-details-panel__date-picker {
  height: 21px;
  position: relative;
  top: -2px;
}
.sb-activity-details-panel__date-picker .md-datepicker-input {
  font-weight: 300;
  color: #4C4C4C;
}
.sb-activity-details-panel__date-picker .sb-datepicker--undefined-value .sb-datepicker__not-set-text {
  color: #858585;
  font-family: 'Work Sans', 'Helvetica Neue', sans-serif;
  font-size: 14px;
  font-weight: 400;
}
.sb-activity-details-panel__date-picker .sb-datepicker-container {
  margin: 4px 0;
  padding: 0;
}
.sb-activity-details-panel__date-picker .md-datepicker-button,
.sb-activity-details-panel__date-picker label,
.sb-activity-details-panel__date-picker .md-errors-spacer {
  display: none;
}
.sb-activity-details-panel__date-picker .md-input {
  height: 21px;
}
.sb-activity-details-panel__date-picker .md-datepicker-triangle-button {
  transform: translate(8px, 2px);
}
.sb-activity-details-panel__date-picker .md-datepicker-input-container {
  margin-left: 0;
}
.sb-activity-details-panel__error {
  font-size: 12px;
  color: #B33636;
}
.sb-activity-details-panel__forecasted-date {
  position: relative;
  top: 2px;
}
.sb-activity-details-panel__accept-forecasted-date-button {
  font-size: 12px;
  background-color: #4ABB8E;
  color: #FFFFFF !important;
  min-height: 18px;
  height: 18px;
  line-height: 18px;
  font-size: 10px;
  margin: 0 0 0 8px;
  text-transform: none;
}
.sb-activity-details-panel__accept-forecasted-date-button:hover {
  background-color: #31A174 !important;
}
.sb-activity-details-panel .sb-loading {
  position: inherit;
  top: inherit;
  width: inherit;
  text-align: inherit;
}
.sb-activity-details-panel__further-details {
  outline: none;
  padding: 4px 16px;
  font-size: 14px;
  color: #4ABB8E;
  cursor: pointer;
  font-weight: 400;
}
.sb-activity-details-panel__responsible-team {
  padding-top: 0;
}
.sb-workflow__activity-details-panel-cell--multiple-entries {
  min-width: 0;
}
.sb-workflow__activity-details-panel-cell--multiple-entries ul {
  margin: 0;
  padding: 0;
  overflow: auto;
  list-style-type: none;
  max-height: 58px;
}
.sb-workflow__activity-details-panel-cell--multiple-entries ul li {
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
  min-width: 0;
  min-height: 0;
}
.sb-activity-details-panel__session-loader {
  display: flex;
  align-items: center;
}
.sb-activity-details-panel__plan-button-wrapper {
  display: flex;
}
.sb-activity-details-panel__edit-row {
  min-height: 33px;
}
.sb-exit-running-session-dialog {
  max-width: 800px;
  min-width: 800px;
}
.sb-exit-running-session-dialog .md-button {
  white-space: normal;
  line-height: 15px;
}
.sb-look-ahead-definition__dialog {
  display: block;
  max-width: 470px;
}
.sb-look-ahead-definition__row {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
}
.sb-look-ahead-definition__content {
  color: #2B2B2B;
  font-family: 'Work Sans', 'Helvetica Neue', sans-serif;
  font-size: 14px;
  font-weight: 400;
  display: flex;
  flex-direction: column;
}
md-dialog-content.sb-look-ahead-definition__content {
  padding: 8px 16px;
  margin: 0;
}
.sb-look-ahead-definition__key,
.sb-look-ahead-definition__value {
  text-rendering: auto;
  font-smoothing: antialiased;
  -moz-font-smoothing: antialiased;
  -webkit-font-smoothing: antialiased;
  display: block;
  margin: 2px;
  align-self: center;
}
.sb-look-ahead-definition__key {
  flex: 0 0 190px;
}
.sb-look-ahead-definition__value {
  margin-left: 16px;
  flex: 1 1 0;
}
.sb-look-ahead-definition__last-planned-duration-input-container {
  margin: 0 0 8px 0;
  padding: 0;
  width: 120px;
}
.sb-look-ahead-definition__last-planned-duration-input-container .md-errors-spacer {
  display: none;
}
.sb-look-ahead-definition__checkbox-container {
  margin: 8px 0 8px 0;
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
}
.sb-look-ahead-definition__date-picker-form {
  flex: 0 0 0;
  position: relative;
}
.sb-look-ahead-definition__checkbox {
  margin: 0;
  flex: 0 0 30px;
}
.sb-look-ahead-definition__select {
  margin: 8px 0 8px 0;
}
.sb-look-ahead-definition__date-picker {
  position: relative;
}
.sb-look-ahead-definition__date-picker .md-datepicker-input {
  color: #2B2B2B;
  font-family: 'Work Sans', 'Helvetica Neue', sans-serif;
  font-size: 14px;
  font-weight: 400;
}
.sb-look-ahead-definition__date-picker .sb-datepicker--undefined-value .sb-datepicker__not-set-text {
  color: #858585;
}
.sb-look-ahead-definition__date-picker .sb-datepicker-container {
  margin: 8px 0 8px 4px;
  padding: 0;
}
.sb-look-ahead-definition__date-picker .md-datepicker-button,
.sb-look-ahead-definition__date-picker label,
.sb-look-ahead-definition__date-picker .md-errors-spacer {
  display: none;
}
.sb-look-ahead-definition__date-picker .md-input {
  height: 32px;
}
.sb-look-ahead-definition__date-picker .md-datepicker-triangle-button {
  transform: translate(8px, 2px);
}
.sb-look-ahead-definition__date-picker .md-datepicker-input-container {
  margin-left: 0;
}
.sb-look-ahead-definition__error {
  font-size: 12px;
  color: #B33636;
}
/**
 * Default styling of lean board related elements.
 */
.sb-lean-board__container {
  width: inherit;
}
.sb-page__lean-board .sb-lean-board__header__week {
  background: #36B381;
}
.sb-page__lean-board .sb-filter-sidenav__handle {
  color: #2B2B2B;
  background-color: #36B381;
}
.sb-page__lean-board .sb-lean-board__week-selector.md-button {
  color: #2B2B2B;
  background-color: #36B381;
}
.sb-page__lean-board .sb-lean-board__week-selector.active,
.sb-page__lean-board .sb-lean-board__week-selector.md-button:not([disabled]):hover {
  background-color: #86D1B3 !important;
}
.sb-page__lean-board .sb-deliverable-card__loading .md-container {
  background-color: #86D1B3 !important;
}
.sb-page__lean-board .sb-deliverable-card__loading .md-container .md-bar {
  background-color: #36B381 !important;
}
.sb-page__lean-board--scroll-fix {
  overflow: scroll;
}
/**
 * Active session styling of lean board related elements.
 */
.sb-page__lean-board--open-session .sb-lean-board__header__week {
  background: #36B381;
}
.sb-page__lean-board--open-session .sb-filter-sidenav__handle {
  color: #2B2B2B;
  background-color: #36B381;
}
.sb-page__lean-board--open-session .sb-lean-board__week-selector.md-button {
  color: #2B2B2B;
  background-color: #36B381;
}
.sb-page__lean-board--open-session .sb-lean-board__week-selector.active,
.sb-page__lean-board--open-session .sb-lean-board__week-selector.md-button:not([disabled]):hover {
  background-color: #86D1B3 !important;
}
.sb-page__lean-board--open-session .sb-deliverable-card__loading .md-container {
  background-color: #86D1B3 !important;
}
.sb-page__lean-board--open-session .sb-deliverable-card__loading .md-container .md-bar {
  background-color: #36B381 !important;
}
sb-lean-board {
  height: 100%;
  width: 100%;
  overflow: auto;
}
.sb-lean-board {
  margin-left: -1px;
  position: relative;
  max-width: calc(100% - 300px);
}
.sb-page__lean-board--pagination-showing .sb-lean-board {
  margin-top: 2px;
}
.sb-lean-board__filter {
  position: fixed;
  bottom: 16px;
  right: 40%;
  z-index: 2;
  padding-right: 8px;
  padding-left: 8px;
}
.sb-lean-board__teams-filter,
.sb-lean-board__size-selector {
  display: flex;
  flex-direction: row;
  height: 45px;
  text-align: right;
  position: relative;
}
.sb-lean-board__teams-filter .sb-lean-board__size-select,
.sb-lean-board__size-selector .sb-lean-board__size-select,
.sb-lean-board__teams-filter .sb-team-select,
.sb-lean-board__size-selector .sb-team-select {
  margin: 8px 0;
}
.sb-lean-board__teams-filter .sb-lean-board__size-select span,
.sb-lean-board__size-selector .sb-lean-board__size-select span,
.sb-lean-board__teams-filter .sb-team-select span,
.sb-lean-board__size-selector .sb-team-select span {
  text-align: left;
}
.sb-lean-board__teams-filter .sb-lean-board__size-select,
.sb-lean-board__size-selector .sb-lean-board__size-select {
  margin-right: 4px;
}
.sb-lean-board__teams-filter .sb-team-select,
.sb-lean-board__size-selector .sb-team-select {
  position: relative;
  min-width: initial;
}
.sb-lean-board__teams-filter .label,
.sb-lean-board__size-selector .label {
  margin-right: 8px;
  line-height: 45px;
}
.sb-lean-board__area-manager-filter {
  position: relative;
  top: 8px;
  margin: 0;
  margin-bottom: 4px;
}
.sb-lean-board__pagination-button {
  position: relative;
  min-height: 70px;
}
.sb-lean-board__header-row {
  height: 30px;
  width: inherit;
  z-index: 4;
  padding-right: 10px;
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  position: sticky;
  top: 0;
}
.sb-lean-board__header__week {
  box-shadow: 2px 0px 0px 0px rgba(0, 0, 0, 0.14), -2px 0px 0px 0px rgba(0, 0, 0, 0.14);
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  height: 30px;
  min-width: 268px;
  padding-left: 32px;
  position: sticky;
  left: 0;
  border-bottom: solid #858585 1px;
  z-index: 10000;
}
.sb-lean-board__header-item {
  flex: 1;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  border-right: solid #858585 1px;
}
.sb-next-page-button,
.sb-previous-page-button {
  width: 90vw;
  position: sticky;
  left: 5vw;
  margin-top: 16px;
}
.sb-work-page .sb-filter-sidenav__handle {
  background-color: #2B8F67;
}
.sb-lean-board__download {
  box-shadow: 0 2px 5px 0 rgba(0, 0, 0, 0.26);
  position: absolute;
  right: 90px;
  bottom: 34px;
  height: 20px;
  width: 20px;
  z-index: 10000;
  border-radius: 20px;
  background: #858585;
}
.sb-lean-board__download.md-button:not([disabled]).md-icon-button:hover {
  background: #858585;
}
.sb-lean-board__download-icon {
  position: relative;
  color: #FFFFFF;
  left: -3px;
}
.sb-lean-board__view-switcher {
  font-size: 12px;
}
.sb-lean-board__options-panel-entry {
  height: auto;
}
/**
 * Overall page layout
 */
.sb-inspect-request {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  height: 100%;
  background-color: #F4F3F2;
}
/**
 * Top part of page above list content.
 * Containing explanatory text and action buttons.
 */
.sb-inspect-request__header {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  flex-shrink: 0;
  min-width: 320px;
  max-width: 960px;
  padding: 8px 16px 16px;
}
.sb-inspect-request__header .sb-title {
  margin-left: 10%;
  margin-right: 10%;
  text-align: center;
  margin-bottom: 0;
}
@media (max-width: 960px) {
  .sb-inspect-request__header .sb-title {
    font-size: 1em;
  }
}
.sb-inspect-request__content {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  min-width: 320px;
  max-width: 960px;
  width: 100%;
  height: 100%;
}
@media (max-width: 960px) {
  .sb-inspect-request__content {
    margin: 0;
  }
}
.sb-issue-row {
  display: flex;
  flex-direction: row;
  align-items: center;
  padding: 8px/2 8px;
  border-bottom: 1px solid #858585;
  flex-wrap: wrap;
  border-left: 4px solid;
}
.sb-issue-row:focus {
  outline: none;
}
.sb-issue-row:hover {
  cursor: pointer;
}
.sb-issue-row__status--open {
  border-left-color: #A13131;
}
.sb-issue-row__status--done {
  border-left-color: #2B8F67;
}
.sb-issue-row__type--info {
  border-left-color: #858585;
}
.ellipsed-text-issue-row {
  align-self: stretch;
  overflow: hidden;
}
.ellipsed-text-issue-row span {
  float: left;
  max-width: 100%;
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
  min-width: 0;
  min-height: 0;
}
.sb-issue-row-secondary {
  font-weight: 400;
  color: #858585;
  font-size: 12px;
}
.sb-issue-row-primary {
  color: #565656;
  font-weight: 400;
  font-size: 14px;
}
.sb-issue-row__cell--status-icon {
  font-size: 24px;
  padding: 8px;
}
.sb-issue-row__cell--status-icon .sb-claim {
  color: #E87E00;
}
.sb-issue-row__cell--status-icon .sb-obstruction {
  color: #FF4141;
}
.sb-issue-row__cell--status-icon .sb-info {
  color: #365781;
}
.sb-issue-row__cell--status-icon .sb-issue-closed {
  color: #B3B3B3;
}
.sb-issue-row__cell--text {
  color: #565656;
  font-weight: 400;
  font-size: 14px;
  align-self: stretch;
  overflow: hidden;
}
.sb-issue-row__cell--text span {
  float: left;
  max-width: 100%;
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
  min-width: 0;
  min-height: 0;
}
.sb-issue-row__cell--name {
  font-weight: 400;
  color: #858585;
  font-size: 12px;
  align-self: stretch;
  overflow: hidden;
}
.sb-issue-row__cell--name span {
  float: left;
  max-width: 100%;
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
  min-width: 0;
  min-height: 0;
}
.sb-issue-row__cell--component-name {
  color: #565656;
  font-weight: 400;
  font-size: 14px;
  align-self: stretch;
  overflow: hidden;
}
.sb-issue-row__cell--component-name span {
  float: left;
  max-width: 100%;
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
  min-width: 0;
  min-height: 0;
}
.sb-issue-row__cell--component-description {
  font-weight: 400;
  color: #858585;
  font-size: 12px;
  align-self: stretch;
  overflow: hidden;
}
.sb-issue-row__cell--component-description span {
  float: left;
  max-width: 100%;
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
  min-width: 0;
  min-height: 0;
}
.sb-issue-row__cell--author-and-date {
  display: flex;
  gap: 16px;
  width: 220px;
}
.sb-issue-row__cell--author-and-date__reporter {
  margin-left: 18px;
}
.sb-issue-row__cell--author-and-date__badge {
  width: 30px;
  display: flex;
  align-items: center;
}
.sb-edited-badge-wrapper {
  width: 30px;
  max-width: 30px;
  margin-right: 15px;
  display: flex;
}
@media screen and (max-width: 960px) {
  .sb-issue-row {
    padding-left: 0;
    padding-right: 0;
    position: relative;
  }
}
@media screen and (max-width: 1200px) {
  .sb-issue-row__cell--author-and-date {
    margin-left: 0;
  }
}
.sb-issue-row__menu__trigger {
  margin-right: 6px;
  font-size: 24px;
}
.sb-issue-row__menu__trigger-wrapper {
  width: 30px;
}
.sb-issue-report {
  max-width: 700px;
}
.sb-issue-report__pdf-with-images-choice .sb-choice-second-icon {
  left: unset;
  right: -6px;
  bottom: -6px;
  color: #858585;
  background-color: #FFFFFF;
  border-radius: 0.2em;
}
.sb-issues__section {
  height: 100%;
  justify-content: center;
  width: calc(100% - 20px);
  display: flex;
  flex-direction: row;
}
.sb-issues__filter-menu {
  margin-top: 70px;
  position: absolute;
  left: 0;
  top: 0;
}
.sb-issues__content {
  display: flex;
  flex-direction: column;
  position: relative;
  background: #F4F3F2;
  margin: 0 auto;
  width: 100%;
  height: 100%;
  max-width: 1400px;
}
.sb-issues__list-header {
  width: calc(100% - 8px);
  margin-top: 8px;
  flex: 0 0 auto;
  position: relative;
  left: 8px;
}
.sb-issues__list-header-row {
  width: calc(100% - 20px);
  max-width: 1400px;
  border-width: 0;
  margin: 0 auto 2px;
  padding: 0 8px;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  background: #FBFAFA;
  position: relative;
  box-shadow: 0 0 1px 1px rgba(0, 0, 0, 0.14), 0 0 2px 2px rgba(0, 0, 0, 0.098), 0 0 5px 1px rgba(0, 0, 0, 0.084);
}
.sb-issues__page-odata-list {
  flex: 1;
  display: flex;
  flex-direction: column;
}
.sb-issues__page-odata-list .sb-odata-row-container {
  width: calc(100% - 20px);
  max-width: 1400px;
  margin: 0 0 0 20px;
  background: #FFFFFF;
}
.sb-issue__header-cell {
  font-weight: 300;
  margin-top: 8px;
  color: #4C4C4C;
  overflow: visible;
}
.sb-issue__header-cell--status {
  width: 25px;
  max-width: 25px;
}
.sb-issue__header-cell--responsible-team,
.sb-issue__header-cell--confirming-team {
  cursor: not-allowed;
}
.sb-issue__header-cell--activity {
  cursor: not-allowed;
}
.sb-issue__header__date {
  font-size: 14px;
  font-weight: 300;
  color: #4C4C4C;
  overflow: visible;
}
.sb-issue__header-cell__dates {
  display: flex;
  width: 220px;
}
.sb-issue__header-cell--date {
  max-width: 200px;
  margin-left: 200px;
}
.sb-issue__header-cell--badge {
  width: 30px;
  max-width: 30px;
}
.sb-issue__cell {
  font-size: 14px;
  border-collapse: collapse;
  display: flex;
  flex: 1;
  text-align: left;
  width: 100%;
  height: 48px;
  align-items: center;
  justify-content: flex-start;
  padding: 2px 8px;
  overflow: hidden;
}
.sb-issue-row__cell--with-rows {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  overflow: hidden;
}
.sb-issue__cell--text-and-code {
  flex-basis: 140px;
}
.sb-issues-empty-state {
  margin: auto;
}
@media screen and (max-width: 960px) {
  .sb-odata-row-container,
  .sb-issues__list-header,
  .sb-issues__list-header-row {
    width: 100%;
  }
  .sb-issue-row__cell--with-rows {
    min-width: 60px;
    align-items: flex-start;
    justify-content: center;
  }
}
.sb-issues__content .sb-odata-list-repeater {
  position: relative;
  left: -5px;
}
@media screen and (max-width: 1800px) {
  .sb-issues__content.is-menu-showing {
    margin: 0 auto 0 200px;
  }
}
@media screen and (max-width: 1200px) {
  .sb-issue__header-cell--date {
    margin-left: 0;
  }
}
/**
 * Overall page layout
 */
.sb-inspect-deliverables {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  height: 100%;
  background-color: #F4F3F2;
}
/**
 * Toolbar styling for desktop (tall) and mobile (small).
 */
md-toolbar.sb-inspect-deliverables__toolbar {
  display: flex;
  flex-direction: row;
  justify-content: center;
  min-height: 128px;
  height: 128px;
  max-height: 128px;
  background-color: #36B381;
  color: #FFFFFF;
}
@media (max-width: 960px) {
  md-toolbar.sb-inspect-deliverables__toolbar {
    min-height: 56px;
    height: 56px;
    max-height: 56px;
  }
}
.sb-inspect-deliverables__toolbar-button {
  color: #FFFFFF;
  background-color: #36B381;
}
.sb-inspect-deliverables__toolbar-button:hover {
  background-color: #86D1B3;
}
/**
 * Main content area underneath the toolbar.
 *
 * Reduces the centered main area to 960px.
 */
.sb-inspect-deliverables__content {
  justify-content: center;
  align-items: center;
  min-width: 320px;
  max-width: 960px;
  width: 100%;
  flex: 1;
  display: flex;
  flex-direction: column;
  margin-top: -48px;
  z-index: 3;
  background-color: #F4F3F2;
}
@media (max-width: 960px) {
  .sb-inspect-deliverables__content {
    margin: 0;
  }
}
/**
 * Search input based on auto-complete
 */
.inspect-deliverables__form {
  max-width: 800px;
}
.inspect-deliverables__search-input-container {
  width: 100%;
}
/**
 * add magnify glass in front of search input
 */
.inspect-deliverables__search-input-container md-autocomplete-wrap:before {
  font-family: "Material Design Icons", sans-serif;
  content: "\F349";
  line-height: 1;
  padding: 8px 0 0 8px;
  font-weight: normal;
  font-size: 24px;
  color: #FFFFFF;
}
inspection-row {
  width: 100%;
}
.sb-inspection-row__part {
  padding: 4px;
}
.sb-inspection-row__part--right {
  display: flex;
  flex-direction: column;
  align-items: flex-end;
}
.sb-inspection-row__part--center {
  flex: 1;
  display: flex;
  flex-direction: column;
  padding-right: 8px;
}
.sb-inspection-row__text-primary {
  font-family: 'Work Sans', 'Helvetica Neue', sans-serif;
  font-size: 14px;
  font-weight: 400;
  line-height: 16px;
}
.sb-inspection-row__text-secondary {
  font-family: 'Work Sans', 'Helvetica Neue', sans-serif;
  font-size: 12px;
  font-weight: 300;
  line-height: 14px;
}
.sb-inspection-row {
  width: 100%;
  font-size: 14px;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
}
.sb-inspection-row__statistics {
  line-height: 16px;
}
.sb-timeline-detail__note {
  width: 100%;
}
.sb-timeline-detail__note .sb-issue-row {
  border: none;
}
.sb-timeline-detail__deliverable .sb-deliverable-cell-rows {
  cursor: pointer;
}
.sb-timeline-detail__deliverable .sb-deliverable-row {
  border: none;
  width: 100%;
}
.sb-timeline-detail__loader {
  margin-bottom: 12px;
}
.sb-timeline__odata-list .sb-odata-list-content {
  min-height: calc(100% - 80px);
  height: calc(100% - 80px);
}
.sb-timeline__inspections {
  background: transparent;
  max-width: 380px;
  overflow-y: hidden;
  height: 100%;
}
.sb-timeline__inspections .sb-expand-box {
  left: 0;
}
.sb-timeline__details-content {
  position: absolute;
  top: 0;
  left: 380px;
  right: 0;
  background-color: transparent;
  bottom: 0;
}
.sb-timeline__list-item {
  color: #6C6C6C;
}
.sb-timeline__list-item .sb-user-image {
  background-color: #6C6C6C;
  color: #FBFAFA;
  border: none;
}
.sb-timeline__list-item--active {
  color: #36B381;
}
.sb-timeline__list-item--active .sb-user-image {
  background-color: #36B381;
  color: #FBFAFA;
  border: none;
}
.md-button,
md-button {
  border-radius: 30px;
  font-weight: 400 !important;
  padding: 0 10px 0 10px;
}
.md-button.md-primary {
  background: #36B381;
  color: #FFFFFF;
  border: 1px solid #36B381;
}
.md-button.md-primary:not([disabled]):hover {
  background: #309f73;
}
.md-button.md-primary:focus {
  color: #36B381;
}
.md-button.md-primary[disabled] {
  background: #6C6C6C;
  border: 1px solid #6C6C6C;
  color: #CECECE;
}
.md-button.sb-default {
  color: #36B381;
  border: 1px solid #36B381;
}
.md-button.sb-default:not([disabled]):hover {
  background: #309f73;
  color: #FFFFFF;
}
.md-button.sb-default[disabled] {
  border: 1px solid #6C6C6C !important;
  color: #CECECE !important;
}
.md-button.md-icon-button {
  color: #36B381;
}
.md-button:not([disabled]).md-icon-button:hover {
  color: #FFFFFF;
  background-color: #36B381;
}
.md-button.md-icon-button.md-primary {
  color: #FFFFFF;
}
.md-button.md-icon-button.md-primary i {
  font-size: 22px;
}
.md-button:not([disabled]).md-icon-button.md-primary:hover {
  color: #FFFFFF;
  background-color: #86D1B3;
  border-color: #86D1B3;
}
.sb_button__warning {
  color: #FFFFFF;
  background: #B33636;
}
.sb_button__warning:hover,
.sb_button__warning:not([disabled]):hover {
  background-color: #A13131;
  color: #D99B9B;
}
/**
 * Create a visually grouped set of buttons.
 */
.sb-button-group {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  box-sizing: border-box;
  padding-left: 8px;
  padding-right: 8px;
}
.sb-button-group__button {
  margin: 20px 0;
}
.sb-button-group__button.md-button:not(.active) {
  background-color: transparent;
  color: #36B381;
}
.sb-button-group__button.md-button.active {
  color: #FFFFFF;
}
.sb-button-group__button.md-button:not(.active):hover {
  color: #FFFFFF;
}
.sb-button-group__button.md-button.md-raised:not([disabled]) {
  box-shadow: none;
}
.md-button.sb-button-group__button--left {
  border-top-right-radius: 0;
  border-bottom-right-radius: 0;
}
.md-button.sb-button-group__button--middle {
  border-radius: 0;
}
.md-button.sb-button-group__button--right {
  border-top-left-radius: 0;
  border-bottom-left-radius: 0;
}
.sb-process-activity {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  box-sizing: border-box;
  min-height: 40px;
  position: relative;
  cursor: pointer;
}
.sb-process-activity--selected {
  background-color: #36B381;
  color: white;
}
.sb-process-activity .sb-process-activity__progress_box {
  height: 46px;
  width: 46px;
}
.sb-process-activity .sb-process-activity__progress_box i {
  font-size: 34px;
}
.sb-process-activity__content {
  display: flex;
  width: 100%;
  box-sizing: border-box;
  padding-right: calc(46px / 2 + 8px);
  padding-left: 8px;
}
.sb-process-activity__color-indicator {
  border-left: 3px solid;
}
.sb-process-activity__text-group {
  min-width: 0;
  width: 100%;
  display: flex;
  flex-direction: column;
}
.sb-process-activity__text {
  font-family: 'Work Sans', 'Helvetica Neue', sans-serif;
  font-size: 12px;
  font-weight: 400;
}
.sb-process-activity__progress_box {
  position: absolute;
  right: calc(-46px/2);
  top: calc((40px - 46px)/2);
}
.sb-process-activity__progress_box .sb-progress-option__button {
  background-color: #FFFFFF;
}
.sb-activity-progress__panel {
  background-color: #FFFFFF;
}
.sb-activity-progress__item--disabled {
  color: rgba(0, 0, 0, 0.38);
}
.sb-activity-progress__item--disabled i {
  color: rgba(0, 0, 0, 0.38);
}
.sb-activity-progress__item--disabled .md-button:not([disabled]):hover {
  background-color: rgba(0, 0, 0, 0) !important;
}
.sb-activity-progress__menu-header {
  padding: 0 16px 8px 16px;
  background-color: #FFFFFF;
}
.sb-activity-progress__menu-header .sb-activity-progress__menu-header__title {
  font-family: 'Work Sans', 'Helvetica Neue', sans-serif;
  font-size: 14px;
  font-weight: 500;
}
.sb-activity-progress__menu-header .sb-activity-progress__menu-header__message {
  font-family: 'Work Sans', 'Helvetica Neue', sans-serif;
  font-size: 14px;
  font-weight: 300;
}
.sb-process-deliverable {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  border-right: 1px solid #E1E1E1;
  max-width: 300px;
  width: 300px;
  min-width: 300px;
  padding: 8px;
  box-sizing: border-box;
  height: 64px;
}
.sb-process-deliverable__deliverable-details {
  display: flex;
  flex-direction: column;
  max-width: 250px;
  justify-content: center;
}
.sb-process-deliverable__btn {
  display: flex;
  flex-direction: column;
  outline-width: 0;
  justify-content: center;
  background: transparent;
  border-radius: 4px;
  font-size: 18px;
  border: none;
}
.sb-process-deliverable__btn:hover {
  background: #CECECE;
}
.sb-process-deliverable__btn i {
  color: #36B381;
}
.sb-process-deliverable__action {
  position: relative;
  top: 0;
  right: 0;
}
.sb-secondary-text {
  font-family: 'Work Sans', 'Helvetica Neue', sans-serif;
  font-size: 12px;
  font-weight: 400;
}
.sb-normal-text {
  font-family: 'Work Sans', 'Helvetica Neue', sans-serif;
  font-size: 14px;
  font-weight: 400;
}
.sb-activity-card--small .sb-deliverable-card__inspection {
  display: none;
}
.sb-activity-card--mini .sb-deliverable-card__dates,
.sb-activity-card--mini .sb-deliverable-card__inspection,
.sb-activity-card--mini .sb-deliverable-card__tag-cloud {
  display: none;
}
.sb-master-detail-layout {
  position: relative;
  top: 0;
  bottom: 0;
  right: 0;
  left: 0;
  margin-left: 200px;
  height: 100%;
  background-color: #FFFFFF;
}
.sb-master-detail--empty {
  height: 100%;
  width: 100%;
  min-width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
}
.sb-master-detail__no-data-card {
  background-color: #F4F3F2;
}
.sb-master-detail-layout__master-content {
  position: absolute;
  left: 0;
  top: 0;
  bottom: 0;
  width: 100%;
  min-width: 100%;
  max-width: 100%;
  z-index: 1;
}
.sb-master-detail-layout__master-content--scroll-fix .md-virtual-repeat-scroller {
  margin-right: 40px;
}
.sb-process {
  display: flex;
  flex-direction: row;
  width: 100%;
  border-bottom: 1px solid #E1E1E1;
}
.sb-process__activity-card {
  flex-basis: 10%;
  max-width: 180px;
  min-width: 180px;
  width: 180px;
  margin: 0 16px;
  outline-width: 0;
}
.sb-process__activity-sequence {
  display: flex;
  flex-direction: row;
  align-items: center;
  height: 64px;
  min-height: 64px;
}
.sb-process__empty-activity-sequence {
  padding: 20px;
  font-size: 14px;
}
.sb-activity-filters {
  display: flex;
  flex-direction: row;
  align-items: center;
  min-height: 70px;
  height: 70px;
}
.sb-activity-filters .sb-deliverables-filters-container {
  max-width: none;
}
.sb-activity-filters__chips-display {
  flex-grow: 1;
}
.sb-button-predecessor-successor {
  display: flex;
  align-items: center;
}
.sb-process-container__filter-menu {
  position: fixed;
  top: 112px;
  left: 0;
  bottom: 70px;
  overflow-y: auto;
  overflow-x: hidden;
  transform: translate(0, 70px);
}
.sb-process-viewer__nav {
  position: absolute;
  top: 0;
  bottom: 0;
  z-index: 2;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  width: 40px;
  color: #36B381;
  background-color: rgba(255, 255, 255, 0.8);
  outline: none;
  cursor: pointer;
}
.sb-process-viewer__nav button {
  cursor: pointer;
}
.sb-process-viewer__nav:hover {
  background-color: #CECECE;
}
.sb-process-viewer__nav--left {
  left: 300px;
  box-shadow: 2px 0px 0px 0px rgba(0, 0, 0, 0.14);
}
.sb-process-viewer__nav--right {
  right: 0;
  box-shadow: -2px 0px 0px 0px rgba(0, 0, 0, 0.14);
}
.sb-commercial-header__container,
.sb-commercial-card {
  max-width: 1200px;
  margin: 0 auto 16px;
  overflow: auto;
}
.sb-commercial-header__container {
  overflow-y: hidden;
  overflow-x: auto;
}
.sb-commercial-header {
  min-width: 750px;
}
.sb-commercial-card__cost-group-container {
  padding: 0;
  min-width: 750px;
  max-width: 1200px;
  overflow-y: hidden;
  overflow-x: auto;
}
.sb-commercial-header__body {
  position: relative;
  padding-top: 0;
  padding-bottom: 0;
  min-width: 1100px;
}
.sb-commercial-header__body--loading {
  min-height: 100px;
}
.sb-commercial-header__cost-group {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  height: 52px;
  padding-bottom: 0;
  font-size: 18px;
}
.sb-commercial-header__cost-group-select {
  margin-top: 0;
  min-width: 350px;
  max-width: 350px;
  color: #2B2B2B;
}
.sb-commercial-header__no-cost-groups {
  font-family: 'Work Sans', 'Helvetica Neue', sans-serif;
  font-size: 18px;
  font-weight: 300;
  text-align: left;
  padding: 8px 32px;
  background: #E1E1E1;
  max-width: 290px;
  margin: 8px 0 0;
  display: block;
}
.sb-commercial-header__budget {
  display: flex;
  flex-direction: row;
  align-items: center;
}
.sb-commercial-header__amount {
  height: 38px;
  min-width: 255px;
}
.sb-commercial-header__currency {
  height: 38px;
}
.sb-commercial-header__actions {
  visibility: hidden;
  display: block;
  padding: 0 8px;
}
.sb-commercial-header__actions-container {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  visibility: hidden;
}
.sb-commercial-header__actions-container--visible {
  visibility: visible;
}
.sb-commercial-header__membership-summary {
  display: flex;
  flex-direction: row;
  align-items: center;
}
.sb-commercial-header__membership-summary--details {
  display: flex;
  flex-direction: column;
  font-family: 'Work Sans', 'Helvetica Neue', sans-serif;
  font-size: 12px;
  font-weight: 300;
  padding-right: 8px;
}
.sb-commercial-header__budget-distribution {
  position: relative;
  width: 180px;
  margin: 16px;
  height: 38px;
  text-align: right;
  top: -16px;
}
.sb-commercial-header__budget-distribution .sb-commercial-header__currency-icon {
  transform: translate(-8px, 0);
}
.sb-commercial-header__budget-distribution--invalid {
  margin-top: 8px;
  font-size: 12px;
  white-space: nowrap;
  position: absolute;
  color: #FF4141;
}
.sb-commercial-header__budget-distribution-input.md-input[disabled] {
  color: #4C4C4C;
  max-width: 190px;
  margin-right: 30px;
}
.sb-commercial-header__budget-label {
  font-size: 12px;
  color: #6C6C6C;
  white-space: nowrap;
}
.sb-commercial-header__budget-value {
  font-size: 16px;
  display: block;
  height: 30px;
  line-height: 30px;
}
.sb-commercial-header__cost-group-search {
  width: 320px;
  display: block;
  margin: 0 8px 8px;
  padding: 8px;
  border-radius: 3px;
  border: 1px solid #CECECE;
  box-shadow: 0 1px 1px 0 rgba(0, 0, 0, 0.4);
  font-size: 16px;
}
.sb-commercial-header__budget-count,
.sb-commercial-header__budget-percentage {
  position: relative;
  top: -8px;
  font-size: 10px;
}
.align-label-with-input {
  padding-bottom: 16px;
}
.align-text-right {
  text-align: right;
}
.sb-cost-group {
  display: flex;
  flex-direction: row;
  box-sizing: border-box;
  position: relative;
  min-height: 200px;
}
@media (max-width: 960px) {
  .sb-cost-group {
    flex-wrap: wrap;
  }
}
.sb-cost-group__cost-group-template {
  flex: 1;
  padding: 16px;
  min-width: 1100px;
}
@media (max-width: 960px) {
  .sb-cost-group__cost-group-template {
    flex-basis: 60%;
  }
}
.sb-cost-group__description {
  display: flex;
  justify-content: space-between;
  padding-bottom: 16px;
  color: #2B2B2B;
  font-size: 18px;
  margin-top: 0;
}
.sb-cost-group-stage__weight-input-container {
  margin: 0;
  margin-left: 8px;
  min-width: 60px;
  max-width: 60px;
}
.sb-cost-group-stage__weight-input-container .mdi-percent {
  text-align: left;
}
.sb-cost-group-stage__process-template-name {
  font-family: 'Work Sans', 'Helvetica Neue', sans-serif;
  font-size: 14px;
  font-weight: 500;
  height: 34px;
  line-height: 34px;
  margin-right: auto;
  margin-left: auto;
}
.sb-data-table__body .md-input-message-animation {
  font-size: 10px;
}
.sb-data-table__body .md-errors-spacer {
  display: none;
}
.sb-data-table {
  height: 100%;
  width: 100%;
  box-sizing: border-box;
}
.sb-data-table__header {
  display: flex;
  flex-direction: row;
  box-sizing: border-box;
  min-height: 32px;
  padding: 0;
  font-family: 'Work Sans', 'Helvetica Neue', sans-serif;
  font-size: 12px;
  font-weight: 400;
  line-height: 12px;
  letter-spacing: 0;
}
.sb-data-table__body {
  display: flex;
  flex-direction: column;
}
.sb-data-table__body-row {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  font-family: 'Work Sans', 'Helvetica Neue', sans-serif;
  font-size: 14px;
  font-weight: 300;
  box-sizing: border-box;
  padding: 0;
}
.sb-data-table__body-row--dark {
  background-color: #F4F3F2;
}
.sb-data-table__body-row--process-template {
  background-color: #36B381;
  color: white;
}
.sb-data-table__activity {
  flex: 3;
  font-family: 'Work Sans', 'Helvetica Neue', sans-serif;
  font-size: 12px;
  font-weight: 400;
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.sb-data-table__weight {
  flex: 1.5;
}
.sb-data-table__budget-allocated {
  flex: 1.5;
  text-align: right;
}
.sb-data-table__progress {
  flex: 2;
  text-align: right;
}
.sb-data-table__link {
  text-decoration: underline;
  color: #2B8F67;
  background-color: inherit;
  border: none;
  padding-left: 0;
  padding-right: 0;
  margin-left: -1px;
  margin-right: -1px;
  outline: none;
}
.sb-data-table__link:disabled {
  color: #6C6C6C;
  text-decoration: none;
}
.sb-data-table__link:active {
  font-weight: bold;
}
.sb-data-table__total-spent {
  margin-left: 16px;
  flex: 1;
  text-align: right;
}
.sb-data-table__body-row--small {
  font-size: 10px;
}
.sb-data-table__ungroup-icon {
  font-size: 18px;
  cursor: pointer;
  border-radius: 50%;
  padding: 2px;
  outline-width: 0;
}
.sb-data-table__ungroup-icon:hover {
  background: #E1E1E1;
}
.sb-data-table__ungroup-icon:active {
  background: #858585;
}
.sb-line-breaker {
  white-space: pre-line;
}
.sb-date-select {
  margin: 0;
}
.sb-date-select .sb-date-select__placeholder {
  color: rgba(0, 0, 0, 0.87);
}
.md-select-menu-container .sb-selected-date-range {
  padding: 8px 8px 0 22px;
}
.md-select-menu-container .sb-selected-date-range-label {
  padding-bottom: 4px;
  font-family: 'Work Sans', 'Helvetica Neue', sans-serif;
  font-size: 12px;
  font-weight: 500;
  color: #858585;
  text-transform: uppercase;
}
.md-select-menu-container .sb-selected-date-range-value {
  padding-bottom: 8px;
  font-family: 'Work Sans', 'Helvetica Neue', sans-serif;
  font-size: 14px;
  font-weight: 500;
  color: #565656;
  line-height: 18px;
}
.md-select-menu-container .sb-date-selector-option {
  color: #6C6C6C;
  height: 32px;
  margin: 0;
  padding: 0 22px;
  font-family: 'Work Sans', 'Helvetica Neue', sans-serif;
  font-size: 14px;
  font-weight: 400;
}
.md-select-menu-container .sb-date-selector-option .md-text {
  display: flex;
  width: 100%;
  font-family: 'Work Sans', 'Helvetica Neue', sans-serif;
  font-size: 14px;
  font-weight: 500;
}
.md-select-menu-container md-select-menu md-content md-option[selected="true"]:focus,
.md-select-menu-container md-select-menu md-content md-option[selected]:focus {
  color: #6C6C6C;
}
.md-select-menu-container .sb-option-label {
  line-height: 24px;
  margin-right: auto;
}
.md-select-menu-container .sb-option-icon {
  display: block;
  font-family: 'Work Sans', 'Helvetica Neue', sans-serif;
  font-size: 20px;
  font-weight: 400;
  line-height: 24px;
  margin: 0;
}
.sb-date-picker {
  position: relative;
}
.sb-date-picker__actions {
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
}
.sb-date-picker__calendar {
  padding: 0;
}
.mdi-calendar-alert {
  color: #E87E00;
}
.sb-new-cost-group-dialog__type-row--selected {
  background-color: #36B381;
}
.sb-cost-group-dialog__toolbar-container {
  flex: 0 0 96px;
}
.sb-cost-group-dialog__actions {
  flex: 0 0 52px;
}
.sb-combine_cost_group_activities__content {
  display: flex;
  overflow-y: hidden;
}
.sb-combine_cost_group_activities__activities-list {
  overflow-y: auto;
  margin-bottom: 8px;
}
.sb-combine_cost_group_activities__state-select {
  margin: unset;
}
.sb-cost-group-dialog__membership-autocomplete--managers {
  margin-bottom: 32px;
}
.sb-cost-group-dialog__error-message {
  color: #FF4141;
}
.sb-commercial {
  height: 100%;
}
.sb-cost-group-dialog {
  width: 700px;
}
.sb-cost-group-dialog__toolbar {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
}
.sb-checklist-template-row {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  padding: 8px/2 8px;
  border-bottom: 1px solid #858585;
  flex-wrap: wrap;
}
.sb-checklist-template-row__cell {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  flex: 1;
  font-size: 14px;
  min-width: 114px;
  height: 48px;
  padding: 2px 8px;
  overflow: hidden;
}
.sb-checklist-template-row__cell:focus {
  outline: none;
}
.sb-checklist-template-row__cell--with-rows {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  overflow: hidden;
}
.sb-checklist-template-row__author-and-date {
  flex-grow: 0;
  flex-shrink: 1;
  min-width: 60px;
  overflow: visible;
  padding: 0 15px 0 40px;
}
.sb-checklist-template-row__number-of-items {
  flex-grow: 0;
  flex-shrink: 1;
  min-width: 200px;
  overflow: visible;
  padding-right: 0;
  padding-left: 0px;
  text-align: right;
}
.sb-checklist-template-row__number-of-items .sb-checklist-template-row__text-container {
  width: 100%;
  text-align: center;
}
.sb-checklist-templates-list {
  overflow: scroll;
  height: 100%;
}
.sb-checklist-templates-list__list-header {
  margin-top: 64px;
}
.sb-checklist-templates-list__header-row {
  flex-wrap: wrap;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  width: 90%;
  max-width: 1400px;
  margin: 0 auto 2px;
  box-shadow: 0 0 1px 1px rgba(0, 0, 0, 0.14), 0 0 2px 2px rgba(0, 0, 0, 0.098), 0 0 5px 1px rgba(0, 0, 0, 0.084);
  padding: 8px/2 8px;
  border-bottom: 1px solid #E1E1E1;
  background: #FFFFFF;
}
.sb-checklist-templates-list__header-cell {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  flex: 1;
  font-size: 14px;
  min-width: 114px;
  height: 40px;
  padding: 2px 8px;
  overflow: hidden;
}
.sb-checklist-templates-list__number-of-items,
.sb-checklist-templates-list__date {
  flex-grow: 0;
}
.sb-checklist-templates-list__number-of-items .md-list-item-text,
.sb-checklist-templates-list__date .md-list-item-text,
.sb-checklist-templates-list__number-of-items md-list,
.sb-checklist-templates-list__date md-list {
  width: 100%;
  text-align: center;
}
.sb-checklist-templates-list__number-of-items {
  flex-basis: 180px;
  width: 180px;
}
.sb-checklist-templates-list__date {
  flex-basis: 80px;
  width: 80px;
}
.sb-checklist-templates-list__list-items {
  width: 90%;
  max-width: 1400px;
  margin: 0 auto;
  background: #FFFFFF;
  box-shadow: 0px 2px 0px 0px rgba(0, 0, 0, 0.14), 2px 0px 0px 0px rgba(0, 0, 0, 0.14), -2px 0px 0px 0px rgba(0, 0, 0, 0.14);
}
.sb-checklist-templates-list__list-items:hover {
  cursor: pointer;
}
.sb-checklist-templates-editor__header-container {
  max-width: 1400px;
  width: 90%;
  margin: 16px auto 2px;
}
.sb-checklist-templates-editor__header {
  display: flex;
  padding-bottom: 0;
  flex-direction: column;
}
.sb-checklist-templates-editor__checklist-overview-first-row,
.sb-checklist-templates-editor__checklist-overview-second-row {
  display: flex;
  flex-direction: row;
}
.sb-checklist-templates-editor__number-creator-container {
  display: flex;
  padding-top: 8px;
}
.sb-checklist-templates-editor__creator {
  padding-top: 4px;
}
.sb-checklist-templates-editor__checklist-overview-form {
  flex: 2;
}
.sb-checklist-templates-editor__checklist-name {
  width: calc(100% - 40px);
  font-size: 14px;
  flex-grow: 1;
  font-size: 16px;
}
.sb-checklist-templates-editor__checklist-name .md-errors-spacer {
  display: none;
}
.sb-checklist-templates-editor__checklist-name .sb-checklist-templates-editor__checklist-item-textarea {
  color: #4C4C4C;
  padding-right: 32px;
}
.sb-checklist-templates-editor__checklist-name-label {
  padding-bottom: 4px;
}
.sb-checklist-templates-editor__number-of-items {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  width: 80px;
}
.sb-checklist-templates-editor {
  height: 100%;
  overflow: hidden;
  min-width: 650px;
}
.sb-checklist-templates-editor__checklist-items-container {
  display: block;
  max-width: 1400px;
  width: 90%;
  margin: 16px auto -7px;
  overflow-x: hidden;
  overflow-y: auto;
  max-height: calc(100% - 195px);
}
.sb-checklist-templates-editor__checklist-items {
  display: flex;
  flex-direction: column;
  align-items: center;
  position: relative;
  padding: 0 16px;
  border-bottom: 1px solid #E1E1E1;
}
.sb-checklist-templates-editor__checklist-items:last-child {
  border-bottom: none;
}
.sb-checklist-templates-editor__checklist-item {
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
}
.sb-checklist-templates-editor__is-mandatory-switch {
  font-size: 12px;
  padding-left: 8px;
  padding-right: 8px;
}
.sb-checklist-templates-editor__help-icon {
  font-size: 16px;
  margin-left: 8px;
  color: #2B2B2B;
}
.sb-checklist-templates--work-team-editor__help-icon {
  font-size: 16px;
  margin-left: 8px;
  color: #2B2B2B;
}
.sb-checklist-templates-editor__change-order {
  width: 45px;
  min-width: 45px;
  justify-content: center;
  display: flex;
  flex-direction: column;
}
.md-button.sb-checklist-templates-editor__icon-button {
  font-size: 24px;
  color: #E1E1E1;
  line-height: 18px;
  min-width: auto;
  padding: 0;
  margin: 0;
}
.md-button.sb-checklist-templates-editor__icon-button.sb-checklist-templates-editor__icon-button--move {
  color: #2B2B2B;
}
.md-button.sb-checklist-templates-editor__icon-button {
  width: 45px;
  min-width: 45px;
  margin-left: 8px;
  height: 45px;
  border-radius: 45%;
}
.sb-checklist-templates-editor__icon-button--delete:hover {
  color: #8F2B2B;
  transform: scale(1.1);
}
.sb-checklist-templates-editor__new-item-container {
  overflow-y: hidden;
  padding: 0 0 10px 0;
  margin: 0 0 -10px 0;
  position: relative;
  top: -1px;
}
.sb-checklist-templates-editor__new-item {
  max-width: 1400px;
  width: 90%;
  margin: 0 auto 0;
  position: relative;
  border-top: 1px solid #E1E1E1;
}
.sb-checklist-templates-editor__new-item-content {
  padding: 0 20px;
  overflow: hidden;
}
.sb-checklist-templates-editor__new-item-form {
  position: relative;
  display: flex;
  align-items: center;
}
.sb-checklist-templates-editor__new-item-form .sb-checklist-templates-editor__checklist-item-input {
  top: 5px;
}
.sb-checklist-templates-editor__checklist-item-input {
  width: calc(100% - 40px);
  font-size: 14px;
}
.sb-checklist-templates-editor__checklist-item-input .md-errors-spacer {
  display: none;
}
.sb-checklist-templates-editor__checklist-item-input .sb-checklist-templates-editor__checklist-item-textarea {
  color: #4C4C4C;
  padding-right: 32px;
}
.sb-checklist-templates-editor__checklist-items .sb-checklist-templates-editor__checklist-item-input:not(.md-input-focused) .sb-checklist-templates-editor__checklist-item-textarea {
  border-bottom: none;
}
.sb-checklist-templates-editor__checklist-item-input.md-input-pristine.md-input-focused input {
  border-color: #36B381;
}
.sb-checklist-templates-editor__checklist-item-input.md-input-pristine.md-input-focused label {
  color: #36B381;
}
.sb-checklist-templates-editor__checklist-item-input.md-input-pristine.md-input-focused label:after {
  color: #36B381;
}
.sb-checklist-templates-editor__checklist-item--first-row {
  width: 100%;
  display: flex;
}
.sb-checklist-templates-editor__checklist-item--settings {
  min-width: 290px;
  display: flex;
  flex-direction: column;
  justify-content: center;
}
.sb-checklist-templates-editor__icon-button--wrapper {
  display: flex;
  align-items: center;
}
.sb-checklist-templates-editor__checklist-item--wrapper {
  width: 100%;
  padding-left: 16px;
}
.sb-checklist-templates-editor__checklist-item__require-image {
  display: flex;
  flex-direction: column;
  font-size: 12px;
  min-width: 140px;
}
.sb-checklist-templates-editor__checklist-item__require-image__title {
  color: #858585;
  margin-top: 14px;
}
.sb-checklist-templates-editor__checklist-item__require-image__switch {
  flex: 0 0 40px;
  margin: 0;
}
.sb-create-checklist-template-dialog {
  width: 700px;
}
.sb-dialog .sb-create-checklist-template-dialog__content {
  padding: 16px 16px;
}
.sb-create-checklist-template-dialog__input-container {
  margin: 8px 16px 8px 8px;
}
.sb-create-checklist-template-dialog__input {
  width: 100%;
}
.sb-create-checklist-template-dialog .sb-dialog-header-close {
  color: #6C6C6C;
}
.sb-create-checklist-template-dialog__footer {
  color: #36B381;
}
.sb-calendar {
  width: 95%;
  margin: 8px auto;
}
.sb-calendar-body {
  display: flex;
  flex-direction: row;
}
.sb-calendar-body__day {
  outline: none;
  flex-grow: 1;
  flex-basis: 100%;
}
.sb-calendar-body__day-inner {
  display: flex;
  flex-direction: row;
  padding: 12px 16px;
  cursor: pointer;
  border-radius: 4px;
}
.sb-calendar-body__day-inner:hover {
  background-color: #CECECE;
}
.sb-calendar-body__date {
  display: flex;
  flex-direction: column;
  padding-right: 8px;
  border-right: 1px solid #858585;
}
.sb-calendar-body__day-of-week {
  color: #6C6C6C;
  font-size: 12px;
  font-weight: 500;
  text-transform: uppercase;
  padding-bottom: 4px;
  text-align: center;
}
.sb-calendar-body__day-of-month {
  color: #2B2B2B;
  font-size: 24px;
  font-weight: 500;
  display: inline-block;
  text-align: center;
  line-height: 38px;
  width: 38px;
  height: 38px;
  border-radius: 38px;
}
sb-diary-entry {
  flex-grow: 1;
}
.sb-calendar-body__day--non-working .sb-calendar-body__day-of-week {
  color: #858585;
}
.sb-calendar-body__day--non-working .sb-calendar-body__day-of-month {
  color: #858585;
  background-color: transparent;
}
.sb-calendar-body__day--today .sb-calendar-body__day-of-week {
  color: #6C6C6C;
}
.sb-calendar-body__day--today .sb-calendar-body__day-of-month {
  color: #FFFFFF;
  background-color: #858585;
}
.sb-calendar-body__day--current .sb-calendar-body__day-of-week {
  color: #4ABB8E;
}
.sb-calendar-body__day--current .sb-calendar-body__day-of-month {
  color: #FFFFFF;
  background-color: #4ABB8E;
}
.sb-calendar-header {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
}
.sb-calendar-header__previous,
.sb-calendar-header__next {
  font-size: 20px;
  margin: 6px 0;
  min-width: initial;
}
.sb-calendar-header__today {
  margin: auto 0;
}
.sb-calendar-header__current-month {
  display: inline-block;
  font-weight: 300;
  font-size: 20px;
  margin: 8px;
  padding: 4px;
}
.sb-diary-entry {
  font-family: 'Work Sans', 'Helvetica Neue', sans-serif;
  font-size: 12px;
  font-weight: 400;
  border-radius: 4px;
  margin: 0 8px 8px 8px;
  color: #6C6C6C;
  line-height: 14px;
}
.sb-diary-entry__author,
.sb-diary-entry__finalized {
  display: block;
  margin-bottom: 8px;
}
.sb-diary-entry--is-hidden {
  visibility: hidden;
}
.sb-diary-entry__tag-finalized {
  margin: 0;
}
.sb-site-diary__events-card {
  padding-bottom: 0;
}
.sb-site-diary__events-card-form {
  display: flex;
  flex-direction: row;
}
.sb-site-diary__info-card {
  padding-bottom: 0;
}
.sb-site-diary__info-card-form .md-input-message-animation {
  font-size: 10px;
}
.sb-site-diary__info-card-form .sb-time-picker .md-text {
  font-weight: 400;
}
.sb-site-diary__time-and-weather {
  display: flex;
  flex-direction: row;
}
.sb-site-diary__time-and-weather .mdi-clock-outline {
  position: relative;
  top: 18px;
}
.sb-site-diary__time {
  display: flex;
  flex-direction: row;
  flex: 1 1 auto;
  max-width: auto;
}
.sb-site-diary__weather {
  display: flex;
  flex-direction: row;
  flex-grow: 10;
  position: relative;
  top: 18px;
}
.sb-site-diary__department {
  width: calc(100% - 4px);
  margin: 16px 0 0;
}
.sb-site-diary__input-container {
  flex: 1 1 auto;
  max-width: auto;
  margin: 0;
  position: relative;
  top: 0;
  height: 34px;
}
.sb-site-diary__time-container,
.sb-site-diary__temperature-container {
  flex: 0 90px;
}
.sb-site-diary__time-container .sb-site-diary__input,
.sb-site-diary__temperature-container .sb-site-diary__input {
  text-align: right;
}
.sb-site-diary__info-card-icon {
  font-size: 20px;
  margin-right: 8px;
  color: #4C4C4C;
}
.sb-site-diary__info-card-icon,
.sb-site-diary__info-card-unit {
  line-height: 34px;
  color: #4C4C4C;
}
.sb-site-diary__info-card .mdi-weather-partlycloudy {
  margin-left: 16px;
}
.sb-site-diary__info-card-unit {
  margin-right: 8px;
  flex: 0 0 24px;
}
.sb-site-diary__time-picker {
  height: 83px;
}
.sb-site-diary__message {
  font-size: 10px;
  color: #B33636;
  transform: translate(0, -14px);
}
.sb-site-diary__machines-card {
  padding-bottom: 0;
}
.sb-site-diary__machines-card-form {
  display: flex;
  flex-direction: row;
}
.sb-diary-progress__card-content {
  padding-bottom: 0;
}
.sb-diary-progress__deliverable-container {
  margin: 0 auto;
  display: block;
  cursor: pointer;
  border-bottom: 1px solid #B3B3B3;
}
.sb-diary-progress__deliverable-container:first-child {
  border-top: 1px solid #B3B3B3;
}
.sb-diary-progress__deliverable-container .mdi-chevron-down {
  display: none;
}
.sb-diary-progress__deliverable-container.is-expanded .mdi-chevron-right {
  display: none;
}
.sb-diary-progress__deliverable-container.is-expanded .mdi-chevron-down {
  display: block;
}
.sb-diary-progress__deliverable-overlay {
  min-width: 50px;
  margin: 0;
}
.sb-diary-progress__deliverable {
  display: flex;
  flex-direction: row;
  flex-grow: 1;
  color: #4C4C4C;
  padding: 8px;
  outline: none;
}
.sb-diary-progress__opened-chevron,
.sb-diary-progress__deliverable-structure {
  align-self: center;
}
.sb-diary-progress__opened-chevron {
  font-size: 24px;
}
.sb-diary-progress__deliverable-name-and-code,
.sb-diary-progress__note-name-and-activity {
  display: flex;
  flex-direction: column;
  font-weight: 400;
  flex-grow: 1;
  margin-left: 16px;
}
.sb-diary-progress__deliverable-name-and-code {
  flex-basis: 260px;
}
.sb-diary-progress__note-activity-name {
  font-weight: 300;
}
.sb-diary-progress__deliverable-name {
  font-size: 16px;
}
.sb-diary-progress__deliverable-code,
.sb-diary-progress__deliverable-structure {
  color: #565656;
  font-size: 14px;
  font-weight: 300;
  flex-grow: 1;
}
.sb-diary-progress__list {
  display: block;
  background: #FBFAFA;
  color: #565656;
}
.sb-diary-progress__list-item {
  display: flex;
  align-items: center;
  border-top: 1px solid #B3B3B3;
  padding: 0 16px;
  cursor: initial;
  font-size: 14px;
  position: relative;
}
.sb-diary-progress__list-item .sb-progress-icon {
  font-size: 24px;
  width: 36px;
  text-align: left;
  padding-left: 8px;
}
.sb-diary-progress__list-item .sb-inspection-badge-date {
  display: none;
}
.sb-diary-progress__list-item--left-side {
  display: flex;
  flex-direction: row;
  flex-basis: 100px;
}
.sb-diary-progress__list-item--transition-from-state {
  display: flex;
  flex-direction: row;
  align-items: center;
}
.sb-diary-progress__list-item--transition-from-date {
  color: #2B2B2B;
  background: #9BD9C0;
  font-weight: 400;
  max-height: 11px;
  position: absolute;
  bottom: 4px;
  left: 16px;
}
.sb-diary-progress__issue-status {
  max-width: 44px;
}
.sb-diary-progress__activity-name {
  margin-left: 16px;
  flex-grow: 1;
}
.sb-diary-progress__empty {
  margin: 100px auto;
  color: #B3B3B3;
}
.sb-diary-progress__progress-card-actions {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: stretch;
  align-items: center;
  padding: 8px 0;
}
.sb-diary-progress__progress-card-actions--no-changes-reported {
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  align-items: stretch;
}
.sb-diary-progress__pagination {
  display: flex;
  flex-direction: row;
  align-items: center;
}
.sb-diary-progress__pagination-arrow {
  font-size: 20px;
  color: #2B2B2B;
  cursor: pointer;
  outline: none;
}
.sb-diary-progress__pagination-arrow--disabled {
  color: #E1E1E1;
}
.sb-diary-progress__pagination-arrows-container {
  display: flex;
  flex-direction: row;
  position: relative;
  top: 2px;
}
.sb-diary-progress__pagination-selection {
  position: relative;
  right: -8px;
}
.sb-diary-progress__information {
  max-width: 400px;
  color: #6C6C6C;
  margin-left: 8px;
  font-family: 'Work Sans', 'Helvetica Neue', sans-serif;
  font-size: 14px;
  font-weight: 300;
}
@media screen and (max-width: 1200px) {
  .sb-diary-progress__progress-card-actions {
    display: block;
  }
  .sb-diary-progress__pagination {
    display: block;
  }
  .sb-diary-progress__information {
    margin-left: 0;
    position: relative;
    top: 8px;
    left: 8px;
  }
  .md-button.sb-diary-progress__refresh {
    float: right;
    height: 36px;
    margin: auto;
  }
}
.sb-diary-teams {
  overflow: auto;
}
.sb-diary-teams .md-input:disabled {
  color: initial !important;
}
.site_diary__field-set--teams {
  padding-left: 32px;
}
.sb-diary-teams__team-row {
  display: flex;
  flex-direction: row;
  max-height: 35px;
}
.sb-diary-teams__team-name-and-icon {
  display: flex;
  flex-direction: row;
  align-items: center;
  min-width: 180px;
  max-width: 180px;
  margin-right: 16px;
  max-height: 35px;
}
.sb-diary-teams__team-icon {
  font-family: 'Work Sans', 'Helvetica Neue', sans-serif;
  font-size: 24px;
  font-weight: 300;
  margin-right: 8px;
}
.sb-diary-teams__team-name {
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
  min-width: 0;
  min-height: 0;
  max-width: 160px;
}
.sb-diary-teams__input-container {
  max-width: 80px;
  min-width: 80px;
  margin: 0;
  padding: 0 8px 0 0;
}
.sb-diary-teams__input-container--last {
  position: relative;
}
.sb-diary-teams__input--readonly {
  pointer-events: none;
  font-family: 'Work Sans', 'Helvetica Neue', sans-serif;
  font-size: 16px;
  font-weight: 300;
  border: none !important;
}
.sb-diary-teams__errors {
  font-family: 'Work Sans', 'Helvetica Neue', sans-serif;
  font-size: 12px;
  font-weight: 400;
  display: flex;
  flex-direction: row;
  align-items: center;
  color: #FF5A5A;
  margin-left: 16px;
}
.sb-diary-teams__total-row {
  display: flex;
  flex-direction: row;
  margin-top: 8px;
}
.sb-diary-teams__total-row-label {
  min-width: 180px;
  max-width: 180px;
  position: relative;
  top: 6px;
  margin-right: 16px;
}
.site_diary {
  overflow: auto;
}
.site_diary__field-set {
  border: 0;
}
.site_diary__field-set:disabled {
  pointer-events: none;
}
.site_diary__card_components {
  margin: 16px auto 16px auto;
  width: 60%;
  max-width: 1000px;
}
.site_diary__card_component md-card {
  margin-bottom: 16px;
}
.site_diary__card_component md-card md-card-title-text {
  color: #2B2B2B;
  font-size: 18px;
}
.sb-calendar-chooser__action-buttons {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: stretch;
}
.sb-calendar-header__finalise-entry {
  position: absolute;
  bottom: 2px;
  right: 32px;
}
.sb-site-diary__card-actions {
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  align-items: stretch;
}
.sb-site-diary__textarea {
  flex: 1;
  margin-bottom: 0;
}
@media screen and (max-width: 1200px) {
  .site_diary__card_components {
    width: 100%;
  }
}
.sb-tracker-master-detail-layout__master-content--scroll-fix .md-virtual-repeat-scroller {
  margin-right: 40px;
}
.sb-tracker-process {
  display: flex;
  flex-direction: row;
  width: 100%;
  border-bottom: 1px solid #E1E1E1;
}
.sb-tracker-process__activity-card {
  flex-basis: 10%;
  max-width: 180px;
  min-width: 180px;
  width: 180px;
  margin: 0 16px;
  outline-width: 0;
}
.sb-tracker-process__activity-sequence {
  display: flex;
  flex-direction: row;
  align-items: center;
  height: 64px;
  min-height: 64px;
}
.sb-tracker-process__empty-activity-sequence {
  padding: 20px;
  font-size: 14px;
}
.sb-tracker-activity-filters {
  display: flex;
  flex-direction: row;
  align-items: center;
  min-height: 70px;
  height: 70px;
}
.sb-tracker-activity-filters .sb-tracker-deliverables-filters-container {
  max-width: none;
}
.sb-tracker-activity-filters__chips-display {
  flex-grow: 1;
}
.sb-tracker-button-predecessor-successor {
  display: flex;
  align-items: center;
}
.sb-tracker-process-container__filter-menu {
  position: fixed;
  top: 112px;
  left: 0;
  bottom: 70px;
  overflow-y: auto;
  overflow-x: hidden;
  transform: translate(0, 70px);
}
.sb-tracker__nav {
  position: absolute;
  top: 0;
  bottom: 0;
  z-index: 2;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  width: 40px;
  color: #36B381;
  background-color: rgba(255, 255, 255, 0.8);
  outline: none;
  cursor: pointer;
}
.sb-tracker__nav button {
  cursor: pointer;
}
.sb-tracker__nav:hover {
  background-color: #CECECE;
}
.sb-tracker__legend {
  position: absolute;
  bottom: 8px;
  right: 8px;
  z-index: 2;
}
.sb-tracker__center-vertically {
  margin-top: auto;
  margin-bottom: auto;
}
.sb-freemium-landing__card {
  max-width: 600px;
  margin: auto;
}
.request-access {
  align-items: center;
  display: flex;
  height: 100%;
  margin: 0 auto;
  max-width: 800px;
}
/* yo-module:new_modules_hook */
