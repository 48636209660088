.profile-card-container {
    display: flex;
    flex-direction: column;
    flex-grow: 1;
    background: white;
    border: 1px solid lightgray;
}

.profile-card__actions {
    .flex-row();
    justify-content: space-between;
    align-items: center;
}

.profile-card__language-select {
    .normal-thin-font();
    margin: @margin-small;
}
