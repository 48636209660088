@import "../../../../../../../../styles/global";

#angular-checklist {
    width: 100%;
    height: 100%;
}

.sb-fill-checklist {
    position: absolute;
    right: 0;
    bottom: 0;
    z-index: 1;
    width: 0;
    transition: all 0.3s ease-in-out;
    overflow-x: hidden;
    height: 100%;
    min-height: 100%;
    background-color: @white-background;
    &.animate-from-left {
        top: 0;
        left: 0;
    }
    &.open {
        width: 100%;
        height: 100%;
    }
    .sb-fill-checklist-close {
        z-index: 2;
        position: absolute;
        right: 0;
        font-size: @font-size-larger;
        cursor: pointer;
        border-radius: 50%;
        padding: @padding-small;
        outline-width: 0;
        &:hover {
            background: @sablono-grey-200;
        }
        &:active {
            background: @sablono-grey-500;
        }
    }
}
