.sb-resizeable-flex-box-drag-zone{
  position: absolute;
  top:0;
  right: -7px;
  bottom: 0;
  width: 14px;
  cursor: col-resize;
  user-select: none;
}

[sb-resizeable-flex-box]{
  position: relative;
}