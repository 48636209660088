@error-banner-height: 80px;

.sb-import-progress {
    top:0;
    height: 100%;
    flex-direction: row;
    md-content{
        padding:0;
        overflow-x: hidden;
    }
    sb-table{
        height: 100%;
        top:0;
        font-size: @font-size-small;
    }
    .sb-import-progress-view{
        height:100%;
        min-height: 100%;
        flex:3;
    }
    .sb-import-progress-view-file{
        height:100%;
        min-height: 100%;
        flex:3;
    }

    // We have to overwrite some styles in here because we are
    // displaying the two containers inside another layout.
    // Overwriting styles from sb-upload-preview directive.
    .sb-upload-preview-container{
        .sb-upload-preview-content {
            flex-direction: column;
            flex:2;
            height:100%;
            min-height: 100%;
        }
    }
}

.sb-import-progress__upload-has-failure--message {
    display: flex;
    justify-content: center;
    align-items: center;
    height: @error-banner-height;
    width: 100%;
}

.sb-import-progress__upload-has-failure-message-shown {
    height: calc(100% - @error-banner-height) !important;
    margin-top: @error-banner-height;
}

.sb-import-progress__header-wrapper {
    justify-content: center;
    .flex-row();
}

.sb-import-progress__tutorial-wrapper {
    position: relative;
}

.sb-import-progress__tutorial-icon i {
    font-size: 22px;
    padding: 0;
    position: absolute;
    left: 2px;
    text-shadow: none;
}

.sb-import-progress-view__success-deliverables-button {
    margin-top: @margin-big;
}
