@import "../../../../../../styles/global";
@import "../../../../../../styles/fonts";

@checklist-print-button-size: 66px;

.sb-deliverables-checklists {
    background-color: @sablono-grey-50;
    height: 100%;
    display: block;
}

.sb-deliverables-checklists__title-wrapper {
    .sb-sticky-header;
    width: 100%;
    display: flex;
    align-items: center;
    background-color: @sablono-grey-50;
}

.sb-deliverables-checklists__title {
    margin-left: @margin-normal;
    padding-right: @padding-small;
    font-weight: @font-weight-light;
    color: @sablono-grey-600;
}

.sb-deliverables-checklists__list-container-wrapper {
    margin-top: @margin-normal;
}

.sb-deliverables-checklists__list-container {
    padding-top: 0;
}

.sb-deliverables-checklists__list {
    background-color: @white-background;
    border-bottom: 1px solid @sablono-grey-200;

    &:last-child {
        border-bottom: none;
    }
    &:hover {
        background-color: @sablono-grey-200;
    }

    .md-button:not([disabled]):hover {
        background-color: initial;
    }
}

.sb-deliverables-checklists__item{
    .flex-row;
    outline: none;
    width: 100%;
    padding: 0 @padding-small;
    background-color: @white-background;
}

.sb-deliverables-checklists__item--not-started {
    .sb-deliverables-checklists__item();
    padding: @padding-small;
}

.sb-deliverables-checklists__open {
    align-self: center;
    width: @font-size-icon-large;
    font-size: @font-size-icon-normal;
}

.sb-deliverables-checklists__checklist-name {
    flex: 1 0 0;
}

.sb-deliverables-checklists__attached-to {
    font-size: @font-size-smaller;
}

.sb-deliverables-checklists__activity-name {
    font-size: @font-size-normal;
}


.sb-deliverables-checklists__checklist-name, .sb-deliverables-checklists__activity,
.sb-deliverables-checklists__results, .sb-deliverables-checklists__confirmer,
.sb-deliverables-checklists__trade-person {
    align-self: center;
}

.sb-deliverables-checklists__checklist-name {
    font-size: @font-size-larger;
    color: @sablono-grey-900;
}

.sb-deliverables-checklists__checklist-title {
    padding-top: @padding-small;
    flex-direction: column;
    flex: 1;
}

.sb-deliverables-checklists__activity {
    display: inline;
    color: @sablono-grey-700;
    width: 250px;
    padding-right: @padding-small;
}

.sb-deliverables-checklists__trade-person, .sb-deliverables-checklists__confirmer {
    display: block;
    padding: @padding-small 0;
}

.sb-deliverables-checklists__results {
    display: flex;
    position: relative;
    min-width: 126px;
    padding-top: @padding-small;
    font-size: @font-size-smaller;
    color: @sablono-grey-700;
}

.sb-deliverables-checklists__results--first {
    padding-left: 0;
}

.sb-deliverables-checklists__results.sb-deliverables-checklists__results--draft {
    color: @sablono-grey-400;
}

.sb-deliverables-checklists__results .sb-deliverable-cell {
    display: inline;
}

.sb-deliverables-checklists__results .sb-deliverables-checklists__tag-cloud {
    height: auto;
}

.sb-deliverables-checklists__tag-cloud > .sb-tags {
    flex-direction: column;
}

.sb-deliverables-checklists__checklist-ratio {
    display: flex;
    flex-direction: column;
    align-items: flex-end;
    justify-content: space-evenly;
}

.sb-deliverables-checklists__divider {
    width: 1px;
    background-color: @sablono-grey-200;
    align-self: center;
    height: 54px;
}

.sb-deliverables-checklists__no-data {
    position: relative;
    top: 50%;
    transform: translate(0, -50%);
}

.sb-deliverables-checklists__badge {
    position: absolute;
    .larger-bold-font();
    transform: rotate(-10deg);
    top: 20px;
    right: -2px;
}

.sb-deliverables-checklists__draft-badge {
    .sb-deliverables-checklists__badge();
    color: @sablono-grey-700;
}

.sb-deliverables-checklists__badge--rejected {
    .sb-deliverables-checklists__badge();
    color: @riemi-red-700;
}

.sb-deliverables-checklists__badge--confirmed {
    .sb-deliverables-checklists__badge();
    color: @sablono-green-700;
}

.sb-deliverables-checklist__print-btn.md-fab{
    transition: width 0.2s ease-in-out;
    position: absolute;
    right: 0;
    top: 0;
    width: 0;
    height: @checklist-print-button-size;
    padding: @padding-none;
    margin: @margin-none;
    border-radius: 0;
}

.sb-deliverables-checklists__row {
    width: 100%;
    flex-grow: 1;
    line-height: normal;
    padding: @padding-small;
}

.sb-deliverables-checklists__disabled {
    margin: 24px;
}

.sb-deliverables-checklists__no-checklists {
    margin: 24px;
    text-align: center;
}
