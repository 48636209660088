@sb-diary-progress-icon-width: 44px;
@sb-diary-progress-icon-button-width: 50px;
@sb-diary-progress-empty-state-space: 100px;
@sb-diary-progress-name-and-code-width: 260px;
@sb-diary-progress-information-max-width: 400px;
@sb-diary-progress-list-item-left-side-flex-basis: 100px;
@sb-diary-progress-list-item-transition-from-date-max-height: 11px;
@sb-diary-progress-list-item-transition-from-date-position-bottom: 4px;
@sb-diary-progress-list-item-transition-from-date-position-left: 16px;

.sb-diary-progress__card-content {
    padding-bottom: @padding-none;
}

.sb-diary-progress__deliverable-container {
    margin: 0 auto;
    display: block;
    cursor: pointer;
    border-bottom: 1px solid @sablono-grey-400;

    &:first-child {
        border-top: 1px solid @sablono-grey-400;
    }

    .mdi-chevron-down {
        display: none;
    }

    &.is-expanded {
        .mdi-chevron-right {
            display: none;
        }

        .mdi-chevron-down {
            display: block;
        }
    }
}

.sb-diary-progress__deliverable-overlay {
    min-width: @sb-diary-progress-icon-button-width;
    margin: 0;
}

.sb-diary-progress__deliverable {
    .flex-row();
    flex-grow: 1;
    color: @sablono-grey-800;
    padding: @padding-small;
    outline: none;

}

.sb-diary-progress__opened-chevron, .sb-diary-progress__deliverable-structure {
    align-self: center;
}

.sb-diary-progress__opened-chevron {
    font-size: @font-size-icon-normal;
}

.sb-diary-progress__deliverable-name-and-code, .sb-diary-progress__note-name-and-activity {
    .flex-column();
    font-weight: @font-weight-normal;
    flex-grow: 1;
    margin-left: @margin-normal;
}

.sb-diary-progress__deliverable-name-and-code {
    flex-basis: @sb-diary-progress-name-and-code-width;
}

.sb-diary-progress__note-activity-name {
    font-weight: @font-weight-light;
}

.sb-diary-progress__deliverable-name {
    font-size: @font-size-normal;
}

.sb-diary-progress__deliverable-code, .sb-diary-progress__deliverable-structure {
    color: @sablono-grey-700;
    font-size: @font-size-small;
    font-weight: @font-weight-light;
    flex-grow: 1;
}

.sb-diary-progress__list {
    display: block;
    background: @sablono-grey-50;
    color: @sablono-grey-700;
}

.sb-diary-progress__list-item {
    display: flex;
    align-items: center;
    border-top: 1px solid @sablono-grey-400;
    padding: 0 @padding-normal;
    cursor: initial;
    font-size: @font-size-small;
    position: relative;

    .sb-progress-icon {
        font-size: @font-size-icon-normal;
        width: @sb-diary-progress-icon-width - @padding-small;
        text-align: left;
        padding-left: @padding-small;
    }

    .sb-inspection-badge-date {
        display: none;
    }
}

.sb-diary-progress__list-item--left-side {
    .flex-row();
    flex-basis: @sb-diary-progress-list-item-left-side-flex-basis;
}

.sb-diary-progress__list-item--transition-from-state {
    .flex-row();
    align-items: center;
}

.sb-diary-progress__list-item--transition-from-date {
    color: @primary-font-color;
    background: @sablono-green-50;
    font-weight: @font-weight-normal;
    max-height: @sb-diary-progress-list-item-transition-from-date-max-height;
    position: absolute;
    bottom: @sb-diary-progress-list-item-transition-from-date-position-bottom;
    left: @sb-diary-progress-list-item-transition-from-date-position-left;
}


.sb-diary-progress__issue-status {
    max-width: @sb-diary-progress-icon-width;
}

.sb-diary-progress__activity-name {
    margin-left: @margin-normal;
    flex-grow: 1;
}

.sb-diary-progress__empty {
    margin: @sb-diary-progress-empty-state-space auto;
    color: @sablono-grey-400;
}

.sb-diary-progress__progress-card-actions {
    .flex(row, space-between);
    align-items: center;
    padding: @padding-small @padding-none;
}


.sb-diary-progress__progress-card-actions--no-changes-reported {
    .flex(row, flex-end);
}

.sb-diary-progress__pagination {
    .flex-row();
    align-items: center;
}

.sb-diary-progress__pagination-arrow {
    font-size: @font-size-larger;
    color: @primary-font-color;
    cursor: pointer;
    outline: none;
}

.sb-diary-progress__pagination-arrow--disabled {
    color: @border-color;
}

.sb-diary-progress__pagination-arrows-container {
    .flex-row();
    position: relative;
    top: @margin-tiny;
}

.sb-diary-progress__pagination-selection {
    position: relative;
    right: -@margin-small;
}

.sb-diary-progress__information {
    max-width: @sb-diary-progress-information-max-width;
    color: @sablono-grey-600;
    margin-left: @margin-small;
    .small-thin-font();
}

@media screen and (max-width: @media-lg) {
    .sb-diary-progress__progress-card-actions {
        display: block;
    }
    .sb-diary-progress__pagination {
        display: block;
    }
    .sb-diary-progress__information {
        margin-left: @margin-none;
        position: relative;
        top: @margin-small;
        left: @margin-small;
    }
    .md-button.sb-diary-progress__refresh {
        float: right;
        height: 36px;
        margin: auto;
    }
}
