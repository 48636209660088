@sb-dependency-separator-size: 60px;

.sb-dependency {
    background-color: @sablono-grey-50;
    padding: @padding-small @padding-normal;
}

.sb-dependency__container {
    width: 100%;
    .flex-column;
    justify-content: flex-start;
    float:left;

    &.sb-dependency__container--predecessor{
        margin: @margin-small 0;
    }

    &.sb-dependency__container--successor {
        .sb-dependency__container-title{
            justify-content: flex-end;
        }
    }

    .sb-dependency__container--empty{
        font-size: @font-size-large;
        padding: @padding-normal;
        text-align: center;
    }
}

.sb-dependency__container-title {
    margin: @margin-smaller @margin-small;
    .flex-row-center-start;
    .sb-title {
        margin: 0;
        font-size: @font-size-large;
    }
    .sb-dependency-add{
        font-size: 20px;
        color: @sablono-grey-500;
        margin:0 @margin-normal;
        outline-width: 0;
        cursor: pointer;
        transition: all 0.1s ease-in-out;
        .sb-hover-rotate-90;
        &:hover{
            color: @sablono-green-600;
        }
    }
}

// Button add new dependency
@sb-dependency__row-height: 35px;
.sb-dependency-add-new {
    color: @sablono-grey-400;
    border: 1px dashed;
    cursor: pointer;
    height: @sb-dependency__row-height;
    min-height: @sb-dependency__row-height;
    line-height: @sb-dependency__row-height;
    font-size: @font-size-larger;
    margin: @margin-small;
    text-align: center;
    transition: all 0.2s ease-in-out;
    .mdi{
        transition: all 0.2s ease-in-out;
        transform: rotate(0deg);
        font-size: 22px;
        margin-right: @margin-small;
    }
    transform: rotate(0deg);
    &:hover{
        background: @sablono-grey-50;
        color: @sablono-grey-500;
        border-style: solid;
        .mdi{
            transform: rotate(90deg);
        }
    }
}

.sb-dependency__row {

    display: flex;
    justify-content: flex-start;
    align-items: center;
    border-bottom:1px solid @sablono-grey-300;
    height: 52px;
    min-height: 52px;
    cursor: pointer;

    .sb-progress-icon {
        font-size: @font-size-icon-normal;
        padding: @padding-normal;
    }

    .sb-dependency__description {
        flex-grow: 1;
        .normal-thin-font;
        b{
            .normal-font;
        }
    }
    .sb-dependency-remove{
        padding: @padding-normal;
        font-size: @font-size-icon-normal;
        cursor: pointer;
        outline-width: 0;
        color: @sablono-grey-400;
        .sb-hover-rotate-90;
        &:hover{
            color: @riemi-red-600;

        }
    }
}

.sb-deliverable-separator {

    .flex-row-center;
    flex: 1;
    height: @sb-dependency-separator-size;
    max-height: @sb-dependency-separator-size;
    width: 80%;
    min-height: @sb-dependency-separator-size;
    margin: 0 auto;

    .sb-dependency-current {
        flex: 1;
        .flex-column-center;
    }
    .arrow-from-current,
    .arrow-to-current {
        .flex-row-center;
        .bg {
            width: @sb-dependency-separator-size;
            height: @sb-dependency-separator-size;
            background-size: 80%;
            background-repeat: no-repeat;
            background-position: center center;
            display: block;
        }
    }

    .arrow-from-current {
        .bg {
            background-image: url(../../../../../../images/arrow-from-current.svg);
        }
    }

    .arrow-to-current {
        .bg {
            background-image: url(../../../../../../images/arrow-to-current.svg);
            transform: scaleY(-1); // to flip the image
        }
    }
}


.sb-dependency__scheduling-mode-info {
    margin: 8px;
    cursor: pointer;
}
