@import "../../../../../../../styles/layout";
@import "../../../../../../../styles/global";
@import "../../../../../../../styles/sb-common/number-outlined";

@sb-workflow__cell-label-width: 120px;
@sb-workflow__cell-value-width: 50px;

@sb-workflow-hide-middle: 850px;

@sb-workflow-activity-height: 50px;
@sb-workflow-activity-tags-width: 80px;
@sb-workflow-activity-inspection-width: 60px;
@sb-workflow-activity-data-title-width: 60px;
@sb-workflow-activity-activity-info-width: 388px;
@sb-workflow-activity-team-width: 100px;
@sb-workflow-activity-name-and-status-width: 80px;
@sb-activity-tree-left-offset: 8px;

.sb-progress-option__new-badge {
    margin-left: @margin-small;
    .sb-badge('new!', 10deg, @sablono-green-text-color, @sablono-green-main);
}

.sb-workflow-activity-row {
    display: flex;
    flex-direction: row;
    align-items: center;
    flex-wrap: nowrap;
    align-self: flex-start;
    min-height: @sb-workflow-activity-height;
    height: @sb-workflow-activity-height;
    color: @sablono-grey-600;
    font-weight: @font-weight-normal;
    padding: @padding-smaller;
    position: relative;
    v-accordion {
        width: 100%;
    }
    .sb-workflow-activity-info {
        display: flex;
        align-items: center;
        flex: 1;
        max-width: @sb-workflow-activity-activity-info-width;
        .sb-workflow-index {
            .sb-number-outlined--normal(@sablono-grey-500, @border-solid-thin, @margin-none, @margin-small, @margin-none);
        }
    }
    .sb-user-image {
        margin-left: @margin-small;
    }
    .sb-workflow--violation {
        color: @florange-500;
    }
    .sb-data-title {
        min-width: @sb-workflow-activity-data-title-width;
        font-size: @font-size-smaller;
        font-weight: @font-weight-light;
    }
}

.sb-workflow__name-and-status {
    .flex-row;
    justify-content: center;
    flex: 1 0 @sb-workflow-activity-name-and-status-width;
    padding-right: @padding-small;
}

.sb-workflow__details {
    .flex-row;
    flex: 1 0 0;
}

.sb-workflow__details-inspection {
    padding-left: @padding-normal;
    flex: 0 0 @sb-workflow-activity-inspection-width;
}

.sb-workflow__details-tags {
    flex: 0 0 @sb-workflow-activity-tags-width;
    .flex-row-center();
    line-height: 1em;
    sb-component-tag-cloud {
        text-align: right;
        width: 100%;
    }
}

.sb-workflow__details-team {
    padding-right: @padding-small;
    max-width: @sb-workflow-activity-team-width;
    width: @sb-workflow-activity-team-width;
    flex: 0 0 @sb-workflow-activity-team-width;
    justify-content: center;
    .flex-column();
    &.sb-workflow__details-team--hidden {
        visibility: hidden;
    }
}

.sb-workflow__dates {
    @media (max-width: @sb-workflow-hide-middle){
        display: none;
    }
    flex: 1 0 0;
    .flex-column();
}

.sb-workflow__activity-details-cell.sb-workflow__activity-details-cell--center {
    @media (max-width: @sb-workflow-hide-middle){
        display: none;
    }
}

.sb-workflow__dates-row {
    .flex-row();
}

.sb-workflow__dates-row-title {
    .ellipsed-text();
    font-size: @font-size-smaller;
    font-weight: @font-weight-light;
}

.sb-workflow__cell-label {
    padding-right: @padding-smaller;
    flex: 0 0 @sb-workflow__cell-label-width;
}

.sb-workflow__cell-value {
    flex: 1 0 @sb-workflow__cell-value-width;
}

.sb-workflow-activity-plane__expanded-icon {
    position: absolute;
    top: 50%;
    transform: translate(-@sb-activity-tree-left-offset, -50%);
}

.sb-workflow-activity-plane__expanded-icon.mdi-minus {
    display: none;
}

.sb-workflow-activity-plane__expanded-icon.mdi-plus {
    display: inline-block;
}

v-pane.is-expanded .sb-workflow-activity-pane-header {
    .mdi-plus{
        display: none;
    }

    .mdi-minus {
        display: inline-block;
    }
}
