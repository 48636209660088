.red{
	background-color: red;
}
.activity-container{
    display: inline-block;
    width: 100%;
    &:hover{
        .activity-tag-icon.md-icon-button .mdi{
            opacity: 1;
        }
    }
}
.activities-container{
    display: block;
}
.activity-tag{
    &-icon.md-icon-button .mdi{
        opacity: 0;
        font-size: 15pt!important;
        transition: all 0.2s linear;
        color: @primary-font-color;

    }
    .md-button.md-icon-button{
        height: 42px;
        padding-top: 5px;
    }
}

.sb-dependency-container-empty{
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: flex-start;
    min-height: 40px;
    i {
        padding-right: @padding-small;
    }
}
