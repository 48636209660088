.sb-calendar-selection-container {
    overflow: hidden;
    .flex-row-center;
    //height: 62px;
    .sb-days-container {
        .sb-day-container {
            @day-dimension: 40px;
            //flex: 1;
            padding: 0 16px 12px;
            .flex-column-center;
            &:first-child {
                padding-left: 0;
            }
            &:last-child {
                padding-right: 0;
            }
            .sb-day {
                height: @day-dimension;
                width: @day-dimension;
                .flex-row-center;
                border-radius: 3px;
                background-color: @gray-background;
                outline: none;
                &.sb-selected {
                    background-color: @sablono-green-main;
                    color: @light-text-color;
                    font-weight: @font-weight-normal;
                }
                &.sb-selectable {
                    cursor: pointer;
                }
            }
        }
    }
    .sb-hours-container {
        margin: 10px 0;
        .sb-hours-content {
            flex: 1;
            &:first-child {
                margin-right: 7px;
            }
            &:last-child {
                margin-left: 7px;
            }
            .sb-time-pickers-container {
                display: flex;
                flex: 1;
                sb-time-picker {
                    flex: 1;
                    display: flex;
                }
                .sb-break-line {
                    flex: 0 0 10px;
                    .flex-row-center;
                }
            }
            md-input-container {
                margin: 0;
                width: 100px;
                flex: 1;
                label {
                    display: none;
                }
                md-select[disabled] .md-select-value {
                    color: black;
                }
            }
        }
    }
    .sb-container {
        &.sb-days-container .sb-content {
            margin-top: 7px;
        }
        .sb-small-title {
            margin-left: 5px;
            font-size: 14px;
            color: @sablono-grey-600;
        }
        .sb-content {
            flex: 1;
            display: flex;
            align-items: center;
            justify-content: center;
        }
    }

}
