// Mixins
.flex(@direction, @align-horizontal, @align-vertical: stretch) {
    display: flex;
    flex-direction: @direction;
    justify-content: @align-horizontal;
    align-items: @align-vertical;

}

.flexit(@flexPercent) {
    flex: 1 1 @flexPercent;
    max-width: @flexPercent;
}

.flex-auto {
    flex: 0 0 auto;
}

// Helper classes for layout purposes
.flex-column {
    display: flex;
    flex-direction: column;
}

.flex-row {
    display: flex;
    flex-direction: row;
}

.flex-row-wrap {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
}

.flex-column-center {
    .flex-column;
    justify-content: center;
    align-items: center;
}

.flex-column-center-start {
    .flex-column;
    justify-content: flex-start;
    align-items: center;
}

.flex-row-center {
    .flex-row;
    justify-content: center;
    align-items: center;
}

.flex-row-center-start {
    .flex-row;
    justify-content: flex-start;
    align-items: center;
}

.flex-row-center-end {
    .flex-row;
    justify-content: flex-end;
    align-items: center;
}

// @align-horizontal in flex-direction: column; based layout is align-items
// @align-vertical in flex-direction: row; based layout is justify-content
//
.flex-column2(@align-horizontal: stretch, @align-vertical: flex-start) {
    display: flex;
    flex-direction: column;
    align-items: @align-horizontal;
    justify-content: @align-vertical;
}

// @align-horizontal in flex-direction: row; based layout is justify-content
// @align-vertical in flex-direction: row; based layout is align-items
//
.flex-row2(@align-horizontal: flex-start, @align-vertical: stretch) {
    display: flex;
    flex-direction: row;
    justify-content: @align-horizontal;
    align-items: @align-vertical;
}

.sb-centered-content-area-of(@max-width) {
    max-width: @max-width;
    margin-left: auto;
    margin-right: auto;
}
