@import "../../../../../styles/colorpalette";

.sb-inspections__loader {
    margin-bottom: 12px;
}

.sb-inspect__no-content .sb-no-data-card  {
    margin-top: 8px;
    max-width: calc(100% - 16px);
}

.sb-inspections__content {
    overflow-y: auto;
}

.sb-inspections__list {
    padding: 0;
    color: @sablono-grey-600;
}

.sb-inspections__item {
    .sb-inspection-row__part {
        padding-right: 20px;
    }
}

.sb-inspections__navigation-arrow {
    font-size:30px;
    position: absolute;
    top:10px;
    right:5px;
}
