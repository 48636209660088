@import "../../../../../../styles/layout";

@labour-input-width: 130px;

.sb-activity-form__labour-container {
    .flex-row-center-start;
    width: 100%;
}

.sb-activity-form__input-container {
    display: flex;

}
.sb-activity-form__input {
    min-width: @labour-input-width;
    width: @labour-input-width;
}

.sb-activity-form__labour-unit {
    margin-left: 8px;
    float: right;
}
