@import "../../../../styles/global";
.sb-create-new-items-dialog{
    width: 600px;
    max-width: 80%;
}
.sb-create-new-items-dialog-content{
    min-height:300px;
    position: relative;
    .sb-saving{
        font-size: @font-size-normal;
        position: absolute;
        top:8px;
        right: 16px;
    }
}

.sb-create-new-items-dialog-form{
    .flex-column;
}
