@sb-standard-dialog-width: 800px;
@sb-exit-running-session-dialog-button-line-height: 15px;

.sb-exit-running-session-dialog {
    max-width: @sb-standard-dialog-width;
    min-width: @sb-standard-dialog-width;

    .md-button {
        white-space: normal;
        line-height: @sb-exit-running-session-dialog-button-line-height;
    }
}
