@sb-filter-row-width: 750px;
@sb-filter-max-heigth: 520px;
// Filter box
.sb-filter-box{
    padding: @padding-normal @padding-normal 0;
    text-align: left;
    float: left;
    .flex-column;
    max-height: @sb-filter-max-heigth;
    overflow-y: auto;

    // Visbility toggles
    .filter-advanced{
        max-height: 0;
        height: 0;
    }
    .filter-advanced.sb-filter-box-row{
        padding: 0;
    }

    // if in advanced view
    &.advanced-view{
        .filter-advanced{
            max-height: 70px;
            height: inherit;
            // transition: all 0.3s ease-in;
        }
        .filter-advanced.sb-filter-box-row{
            padding: 2px 0;
        }
    }

    .sb-filter-box-row{
        min-width: @sb-filter-row-width;
        .flex-row-center;
        justify-content: space-between;
        padding: 2px 0;
    }
    .sb-filter-column{
        display: flex;
        flex-direction: column;
    }
    .sb-filter-box-label{
        overflow: hidden;
        flex: 1;
        :first-letter{
            text-transform: capitalize;
        }
    }
    .sb-filter-box-input{
        height: 100%;
        min-height: 100%;
        max-height: 100%;
        overflow: hidden;
        flex:3;
        ::placeholder {
            text-overflow: ellipsis !important;
        }
    }
}

.filter-advanced .sb-filter-box-label{
    max-height: inherit;
    height: inherit;
}

.sb-expand-box{

    .mdi-close{
        position: absolute;
        right:0;
        top: 0;

        cursor: pointer;

        font-size: @font-size-larger;
        color: @sablono-grey-400;
        background-color: @white-background;

        .sb-whiteframe-glow-z1();

        border-radius:50%;
        outline-width: 0;
        &:hover{
            background-color: @sablono-grey-300;
        }
    }
}
