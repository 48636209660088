
.sb-excel-subheader{
    font-size: @font-size-large;
    font-weight: @font-weight-light;
    border-top:1px solid @sablono-grey-300;
    border-bottom:1px solid @sablono-grey-300;
    background: @sablono-grey-50;
    &:nth-child(1){
        border-top-width:0px;
    }
}
.sb-excel-level-select,
.sb-excel-row-select{
    margin: 4px auto;
    padding:@padding-small;
}

.sb-excel-component-label,
.sb-excel-level-label{
    font-size: @font-size-smaller;
    padding:@padding-normal @padding-small 0;
}

.sb-excel-level-remove{
    padding: 0 @padding-small;
    margin-left: 6px;
    margin-right: -6px;
    min-width: 1em;
    height: auto;
}

.row-assign-list-column{
    display: flex;
    flex-direction: column;
    width: 100%;
    &.sortable-ghost{
        visibility: hidden;
    }
    &:nth-last-child(1){
        margin-bottom: @margin-small;
    }
}
.sb-excel-not-assigned{
    color: @sablono-grey-500;
}

.sb-excel-sidenav-settings{
    flex:1;
    z-index: 1;
    height: 100%;
    min-height: 100%;
    border-right:1px solid @sablono-grey-400;
}
.sb-excel {
    top:0;
    height: 100%;
    flex-direction: row;
    md-content{
        padding:0;
        overflow-x: hidden;
    }
    sb-table{
        height: 100%;
        top:0;
        font-size: @font-size-small;
    }
    .sb-excel-view{
        height:100%;
        min-height: 100%;
        flex:3;
    }
    .sb-excel-view-file{
        height:100%;
        min-height: 100%;
        flex:3;
    }
    .sb-excel-level{
        display: flex;
        flex-direction:row;
        align-items: center;
    }
    .sb-excel-settings-container{
        overflow-x: hidden;
        width: 100%;
        height:calc(100% ~"-"@sb-subbar-height ~ "-" @padding-normal);
        position: absolute;
        top:@sb-upload-headers-height;
        left:0;
    }

    // We have to overwrite some styles in here because we are
    // displaying the two contianers inside another layout.
    // Overwriting styles from sb-upload-preview directive.
    .sb-upload-preview-container{
        .sb-upload-preview-content {
            flex-direction: column;
            flex:2;
            height:100%;
            min-height: 100%;
        }
        .sb-upload-preview-structure{
            flex:1;
            border-right:1px solid @sablono-grey-400;
            height: 100%;
            min-height: 100%;
        }
    }
}

.sb-required {
    .sb-excel-component-label{
        color: @riemi-red;
    }
}
