@import "../../../../styles/colorpalette.less";

.registration-form{
    padding-top: @padding-normal;
    md-input-container{
        margin: 8px 0;
        width: 100%;
        float: left;
        padding: 0 @padding-small;
        text-indent: @padding-small;
        box-sizing: border-box;
        &.short-field{
            width:50%;
            @media(max-width: @media-sm){
                width: 100%;
            }
        }
        .sb-register__language-picker {
            margin-bottom: 20px;
        }
    }
    .sb-register-button{
        margin-left:50%;
        width:50%;
    }
    .sb-register-paragraph{
        padding-bottom: 1em;
    }
    .sb-progress-over-button{
        position: absolute;
        bottom: 15px;
        right: 9px;
        overflow: hidden;
    }
    input[type=number]::-webkit-inner-spin-button,
    input[type=number]::-webkit-outer-spin-button {
        -webkit-appearance: none;
        -moz-appearance: none;
        appearance: none;
        margin: 0;
    }
}

.questionnaire__container {
    padding: 0!important;
    width: 100%;
}

.sb-register__title {
    text-align: center;
    margin: 12px 0 24px 0;
}

.sb-account-messages__hs-widget__title,
.sb-account-messages__hs-widget__text {
    margin: 0 0 12px 0;
    padding: 0;
}

.meetings-iframe-container {
    width: 100%;
    height: 605px;
    border: 0;
}

.sb-account-messages {
    color: @light-text-color;
}