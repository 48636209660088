@import "../../../../../styles/global";
@import "../../../../../styles/colorpalette";

.sb-lean-board__deliverable-card{
    .sb-sides-shadow();
    display: block;
    position: sticky;
    left: 0;
    z-index: 2;
    height:100%;
    width: @sb-leanboard-first-column-width;
    .flex-row();
    opacity:0.9;
    transition: width 0.2s ease-in-out;
    box-sizing: border-box;
    padding-left: @padding-big;
}

.sb-deliverable-card__details{
    padding: @padding-smaller @padding-small;
    .flex-row();
}

.sb-deliverable-card__details-container {
    .flex-column();
    max-width: inherit;
    width: inherit;
    flex: 1;
    overflow: hidden;
}

.sb-deliverable-card__details-top {
    .flex-row();
    max-width: inherit;
    width: inherit;
    flex: 1;
    justify-content: space-between;
}

.sb-deliverable-card__structure, .sb-deliverable-card__name {
    .smaller-font();
    padding: @padding-tiny 0;
    margin-right: @padding-small;
    z-index:1;
    width: inherit;
}

.sb-deliverable-card__code {
    padding: @padding-tiny 0;
    .wrapped-text();
    .small-font();
    word-break: break-all;
}

.sb-deliverable-card__name {
    .wrapped-text();
}

.sb-deliverable-card__structure {
    .ellipsed-text();
}

.sb-deliverable-card__select-indicator {
    flex: 0 0 20px;
    align-self: center;
    padding-left: 0;
    background-color: transparent;
    border-radius: 4px;
    font-size: 20px;
    border: none;
    outline: none;
    cursor: initial;
    i {
        color: @sablono-green-main;
    }
}
//
.sb-deliverable-card__wrapper {
    width: 100%;
    border-bottom: 1px solid @leanboard-borders-colors;
}
