.sb-template-group-edit-row {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    align-items: stretch;
    sb-team-select{
        margin-top: @margin-small;
    }
    @media @mq-tablet{
        .flex-column;
    }
}

.sb-template-group-edit-category {
    flex: 1 0;
    min-width: 120px;
}

.sb-template-group-edit-code {
    flex: 1 0;
    min-width: 100px;
}

.sb-template-group-edit-name {
    min-width: 200px;
    flex: 6 0;
}

.sb-template-group-edit-desc {
    flex: 8 0;
    @media @mq-tablet{
        max-height: 200px;
    }
}
.sb-template-group-duration-tooltip-trigger-display {
    display: flex;
    position: absolute;
    top: 10px;
    right: 0;
    cursor: pointer;
    z-index: 100;
}
//Adds space between this input and the one on the left
md-input-container.sb-template-group-edit-duration.sb-template-group-edit-category {
    padding-left: 10px;
    label {
        padding-left: 13px;
    }
}
//Adds space between this input and the one on the left
md-input-container.sb-template-group-edit-work-hours.sb-template-group-edit-category {
    padding-left: 10px;
    label {
        padding-left: 13px;
    }
}
