@import "../../../../../styles/colorpalette";
@import "../../../../../styles/fonts";
@import "../../../../../styles/global";

@filter-menu-width: 200px;
@switch-text-width: 140px;
@md-container-min-width: 34px;

.sb-filter-menu {
    .small-font();
    color: @sablono-grey-700;
    width: @filter-menu-width;
    box-sizing: border-box;
    padding: @padding-normal;
}

.sb-filter-menu__process_activity {
    flex-direction: column;
}

.sb-filter-menu__area-manager-toggle {
    font-size: @font-size-smaller;

    .md-container {
        min-width: @md-container-min-width;
    }

    .md-label span {
        line-height: 15px;
        max-width: @switch-text-width;
        white-space: initial;
        display: inline-block;
    }
}

.sb-filter-menu__page {
    border-top: none;
    padding-top: 0;
}
