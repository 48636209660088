@import "../../../styles/layout";
@import "../../../styles/global";
@import "../../../styles/buttons";

@sb-master-content-width: 100%;
@sb-activity-filters-height: 70px;
@sb-process-activity-card-width: 180px;

@filter-menu-width: 200px;
@sb-process-header-height: 112px;
@filter-menu-margin-top: 70px;

// width of the navigation elements for the process viewer
//
@sb__nav-width: 40px;

////////////////////////////////////////////////////////////
//
// PROCESS VIEWER styles
//////
.sb-tracker-master-detail-layout__master-content--scroll-fix {
    .md-virtual-repeat-scroller {
        margin-right: @sb__nav-width;
    }
}

.sb-tracker-process {
    .flex-row;

    width: 100%;
    border-bottom: 1px solid @border-color;
}

.sb-tracker-process__activity-card {
    flex-basis: 10%;

    max-width: @sb-process-activity-card-width;
    min-width: @sb-process-activity-card-width;
    width: @sb-process-activity-card-width;

    margin: 0 @margin-normal;

    outline-width: 0;
}

.sb-tracker-process__activity-sequence {
    .flex-row;
    align-items: center;
    height: 64px;
    min-height: 64px;
}

.sb-tracker-process__empty-activity-sequence {
    padding: 20px;
    font-size: 14px;
}

.sb-tracker-activity-filters {
    .flex-row;
    align-items: center;
    min-height: @sb-activity-filters-height;
    height: @sb-activity-filters-height;

    .sb-tracker-deliverables-filters-container {
        max-width: none;
    }
}

.sb-tracker-activity-filters__chips-display {
    flex-grow: 1;
}

.sb-tracker-button-predecessor-successor {
    display: flex;
    align-items: center;
}


.sb-tracker-process-container__filter-menu{
    position: fixed;
    top: @sb-process-header-height;
    left: 0;
    bottom: @filter-menu-margin-top;
    overflow-y: auto;
    overflow-x: hidden;
    transform: translate(0, @filter-menu-margin-top);
}

.sb-tracker__nav {
    // position
    position: absolute;
    top: 0;
    bottom: 0;
    z-index: 2;

    // child layout
    .flex-row-center();
    width: @sb__nav-width;

    // appearance
    color: @sablono-green-main;
    background-color: rgba(255,255,255, 0.8);
    outline: none;
    .sb-clickable();
    &:hover{
        background-color: @sablono-grey-300;
    }
}

//.sb-tracker__controls--right {
//    width: 100%;
//    position: relative;
//}

.sb-tracker__legend {
    position: absolute;
    bottom: 8px;
    right: 8px;
    z-index: 2;
}

.sb-tracker__center-vertically {
    margin-top:auto;
    margin-bottom:auto;
}
