.arrow-right(@height, @arrow-depth, @color){
    width: 0;
    height: 0;
    border-top: (@height/2) solid transparent;
    border-left: @arrow-depth solid @color;
    border-bottom: (@height/2) solid transparent;
}

.sb-activity-arrow(@width, @height, @state-width, @task-width, @arrow-depth, @state-color, @task-color){

    text-align: center;
    box-sizing: border-box;
    width: @width;
    float: left;
    display: block;
    outline: none;

    .activity-wrapper, .task, .state {
        position: relative;
        height: @height;
        display: flex;
        width: 90%;
        user-select: none;
        user-focus:  none;
    }

    .task, .state {
        width: 50%;
        flex:1;
        align-items: center;
        justify-content: center;

        &:after {
            content: "";

            position: absolute;
            right: -@arrow-depth;
            top:0;

            .arrow-right(@height, @arrow-depth, @sablono-grey-500);
        }
        &:before {
            content: "";

            position: absolute;
            left: 0;
            top: 0;

            .arrow-right(@height, @arrow-depth, @light-text-color);
        }
        span{
            padding: 0 @arrow-depth;
            text-indent:@arrow-depth;
        }
    }

    .task {
        background-color: @task-color;
        z-index: @z-indexes[background];
        &:after {
            border-left: @arrow-depth solid @task-color;
        }
    }

    .state {
        background-color: @state-color;
        margin-left: 2px;
        &:after {
            border-left: @arrow-depth solid @state-color;
        }
    }
}
