.sb-toolbar-navigator.md-button {
    background-color: transparent;
    border-radius: 0;
    font-size: inherit;
    padding: 0;
    text-transform: none;
}

.sb-toolbar-navigator.md-button:hover {
    background-color: transparent;
}

.sb-toolbar-navigator {
    display: flex;
    height: @main-toolbar-height;
    margin-left: -1 * @padding-normal;
}

.sb-toolbar-navigator-burger-icon {
    color: @light-text-color;
    padding: @padding-normal;
    font-size: 20px;
}

.sb-toolbar-navigator-logo {
    height: 24px;
}

.sb-toolbar-navigator-title {
    .larger-bold-font();
    color: @light-text-color;
    padding-left: @padding-normal;
    padding-right: @padding-normal;
}
