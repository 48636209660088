@import "../../../../../styles/colorpalette";

@sb-list-checkboxes-width: 800px;
@sb-list-checkboxes-height: 300px;
@sb-list-checkboxes-row-height: 32px;

.sb-list-container.delete{
    // change default styling to checkboxes.
    // red style for deletion lists
    .sb-list-checkboxes-row {
        md-checkbox.md-checked{
            color:@riemi-red-600;
            background: @riemi-red-50;
        }
    }
    .sb-list-checkboxes-row md-checkbox.md-checked .md-icon{
        background-color: @riemi-red-400;
    }
    .sb-list-checkboxes-row md-checkbox.md-checked .md-ink-ripple{
        background-color: @riemi-red-100;
    }
    .sb-list-select-all.md-checked{
        .md-icon{
            background: @riemi-red-500;
        }
    }
}
.sb-list-container.select{
    // change default styling to checkboxes.
    // green style for selection lists
    .sb-list-checkboxes-row {
        md-checkbox.md-checked{
            color:@sablono-green-800;
            background: @sablono-green-50;
            .md-icon{
                border-color:@sablono-green-600;
            }
        }
        md-checkbox{
            // Default color is red because the element is been deleted
            color:@riemi-red-500;
            .md-icon{
                border-color:@riemi-red-400;
            }
        }
    }
    .sb-list-checkboxes-row md-checkbox.md-checked .md-icon{
        background-color: @sablono-green-400;
    }
    .sb-list-checkboxes-row md-checkbox.md-checked .md-ink-ripple{
        background-color: @sablono-green-100;
    }
    .sb-list-select-all.md-checked{
        .md-icon{
            background: @sablono-green-500;
        }
    }
}


.sb-list-container{
    margin: @margin-normal auto;
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 100%;
    height: @sb-list-checkboxes-height;
    .sb-list-checkboxes{
        width: @sb-list-checkboxes-width;
        @media(max-width: @sb-list-checkboxes-width){
            width:100%;
        }
        flex:1;
        max-width: 100%;
        overflow-y: auto;
        overflow-x: hidden;
        padding: 4px 0;
        height: 100%;
        background: @sablono-grey-50;
        border:1px solid @sablono-grey-300;
    }
    .sb-list-checkboxes-row{
        max-height:@sb-list-checkboxes-row-height;
        color:@sablono-grey-700;
        font-size: @font-size-small;
         md-checkbox{
             padding: @padding-small @padding-normal;
             margin: 0;
             background: @sablono-grey-50;
             .md-icon{
                 border-color:@sablono-grey-500;
             }

         }
         &:nth-child(even){
             md-checkbox{
                 background: @white-background;
             }
         }
         md-checkbox .md-container{
             left:@padding-small;
         }
     }

    .sb-list-checkboxes-header{
        border: 1px solid @sablono-grey-300;
        background: @sablono-grey-100;
        width: @sb-list-checkboxes-width;
        @media(max-width: @sb-list-checkboxes-width){
            width:100%;
        }
        padding:@padding-small @padding-small @padding-small @padding-big;
        box-sizing: border-box;
        span{
            text-align: center;
            text-transform: uppercase;
            font-size:@font-size-small;
        }
    }
    .sb-list-select-all{
        margin:@margin-small 0 @margin-small @margin-normal;
        width:@sb-list-checkboxes-width;
        max-width: 100%;
        @media(max-width: @sb-list-checkboxes-width){
            width:100%;
        }
        text-align: left;
        line-height: @font-size-large;
        font-size: @font-size-small;
    }

    // This is needed to properly display the list as a table,
    // with any number of fields inside.
    .md-label, .sb-list-checkboxes-header{
        display: table;
        table-layout: fixed;
        width:100%;
        .sb-list-checkbox-content{
            display:table-row;
            span{
                padding:0 @padding-small;
                display:table-cell;
                .ellipsed-text;
                // separator cell can be smaller
                &.mdi{
                    width:@sb-list-checkboxes-row-height;
                }
            }

        }
    }
}
