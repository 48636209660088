@sb-project-card-width: 380px;
@sb-project-card-height: 370px;
@sb-project-card-content-height: 24px;
.sb-project-card {
    position: relative;
    width: @sb-project-card-width;
    max-width: @sb-project-card-width;
    height: @sb-project-card-height;

    .flex-column;
    flex: @sb-project-card-width 1 1;
    align-items: stretch;

    margin:  @margin-small;

    box-sizing: border-box;
    overflow: hidden;
    transition: all 0.2s ease-in-out;

    @media (min-width: 600px) {
        flex: 380px 1 1;
        max-width: 380px;
    }
    &:hover{
        cursor: pointer;
        box-shadow: 0px 4px 5px -2px rgba(0, 0, 0, 0.2), 0px 7px 10px 1px rgba(0, 0, 0, 0.14), 0px 2px 16px 1px rgba(0, 0, 0, 0.12);
        &.sb-add-new-card{
            box-shadow: none;
        }
        .sb-project-card-edit{
            display: block;
        }
    }
    .sb-project-card-edit{
        position: absolute;
        right: 10px;
        top:10px;
        z-index: 1;
        color: @primary-font-color;
        background: rgba(255, 255,255,0.7);
        border-radius: 50%;
        padding: @padding-smaller;
        display: none;
        transition: all 0.1s ease-in-out;
        outline-width: 0;
        .mdi{
            font-size: 24px;
        }
        &:active {
            background: rgba(255, 255,255,0.9) !important;
        }
        &:hover {
            background: rgba(255, 255,255,0.9) !important;
        }
    }
    .sb-project-card-header{
        width: 100%;
        background-size: cover;
        background-position: 50% 50%;
        position: relative;
        flex:5;
        .sb-project-card-header-content{
            display: flex;
            flex-direction:column;
            position: absolute;
            min-height:78px;
            color: @primary-font-color;
            font-size: @font-size-small;
            bottom:0;
            box-sizing: border-box;
            width: 100%;
            padding: @padding-small;
            background: rgba(255,255,255,0.85);
        }
    }
    .sb-project-card-content{
        padding: @padding-small @padding-smaller @padding-smaller;
        color: @primary-font-color;
        font-size:@font-size-small;
        display: flex;
        height: @sb-project-card-content-height;
        min-height:@sb-project-card-content-height;
        flex-direction: column;
        background: @white-background;
        justify-content: space-between;
        .sb-project-card-progress-label{
            font-weight: @font-weight-normal;
            font-size:@font-size-smaller;
        }
    }
    .sb-project-card-infos{
        padding: @padding-small @padding-smaller 0 0;
        display: flex;
        justify-content: space-between;
        font-size:@font-size-smaller;
        position: absolute;
        right: 0;
        top:0;
        .mdi{
            margin-right:4px;
        }
    }
    .sb-project-card-row{
        display: flex;
        flex-direction: row;
        justify-content: space-between;
    }
    .sb-project-card-status{
        flex:1;
        display: flex;
        flex-direction: column;
        justify-content: space-between;
    }
    .sb-project-card-description{
        .ellipsed-text;
        font-size:@font-size-small;
    }
    .sb-project-card-name{
        font-size: @font-size-large;
        font-weight: @font-weight-normal;
        color: @primary-font-color;
        padding-bottom:@padding-smaller;
    }

    .sb-project-card__content {
        width: 100%;
        height: 100%;

        .flex-column();
        align-items: stretch;

        box-sizing: border-box;
        overflow: hidden;
    }

    // overwrite default browser link color and highlighting
    & a {
        color: inherit;
        text-decoration: inherit;
    }
}

.sb-project-demo-card {
    color: @riemi-red-600;
}
