@import '../../components/sbLegend/legend';
@import "../../../../../styles/colorpalette";

@svg-padding: 80px;
@svg-padding-bottom: 180px;
@legend-row-height: 25px;
@element-hover-stroke-width: 4px;
@example-icon-dimension: 15px;

@toolbar-height: 64px;
@subbar-height: 48px;

@height-of-sb-legend-in-activity-done-mode: 165px;
@height-of-sb-legend-in-browse-done-mode: 275px; // unfortunately the height is not equal between browsers.. but it's a fair estimate.
@height-of-sb-legend-collapsed: 34px;

@sb-searchable-select-search-max-width: 300px;
@sb-visualization-page-content-zoom: 300px;

@sb-visualization-page-controls-vertical-whitespace: 72px;

// (RIEMI) applying this 1px in a calc() call fixes the legend layout overflow in IE11
@sb-visualization-page__legend--ie11-height-calc-fix: 1px;

.sb-visualization-page {
    padding: @padding-big;
    height: 100%;
    width: 100%;
    box-sizing: border-box;
    overflow: auto;

    &.sb-visualization-page--with-searchable-select {
        margin-left: calc(@sb-searchable-select-search-max-width + @padding-normal);
    }

    .sb-svg-container {
        height: 100%;
        width: 100%;
        margin: 0 auto;

        svg {
            max-width: 100%;
            height: auto;
            padding-bottom: @padding-big;

            polyline,
            text {
                pointer-events: none; //remove pointer events to elements that can hover a selectable element
            }
        }
    }

    // general hove styling of elements in drawing
    //
    .sb-svg-element:hover {
        stroke-dasharray: 0 !important;
        stroke-opacity: 0.8;
        cursor: pointer;
    }

    // overwrite default style to not destroy other use cases
    //
    .sb-content-zoom {
        bottom: 0;
        right: 0;
        left: auto;
        width: @sb-visualization-page-content-zoom;
        margin: @margin-normal;
    }

    .sb-svg-element--selected {
        stroke: @sablono-green-main !important;
        stroke-width: @element-hover-stroke-width;
        stroke-dasharray: 0 !important;
        stroke-opacity: 0.8;
    }
}

.sb-subbar-mode__bulk-update .sb-subbar-mode .sb-button--disabled.sb-subbar-mode__bulk-update-button,
.sb-subbar-mode__bulk-update .sb-subbar-mode .sb-button--disabled.sb-subbar-mode__bulk-update-button:active {
    color: @sablono-grey-300;
}


.sb-subbar-mode__bulk-update, .sb-subbar-mode__bulk-update .sb-subbar-mode {
    height: @subbar-height;
    width: 100%;
}

.sb-subbar-mode__bulk-update-title {
    padding-left: @padding-normal;
    font-weight: @font-weight-light;
}

.sb-visualization-page__controls {
    position: absolute;
    bottom: 0;

    .flex-column();
    justify-content: flex-end;

    width: @sb-searchable-select-search-max-width;

    box-sizing: border-box;
    margin: @margin-normal;
}

.sb-visualization-page__controls--left {
    top: 0;
    left: 0;
}

.sb-visualization-page__controls--right {
    right: 0;

    max-height: calc(100% - @sb-visualization-page-controls-vertical-whitespace + @sb-visualization-page__legend--ie11-height-calc-fix);
    margin-bottom: @sb-visualization-page-controls-vertical-whitespace;
}

.sb-visualization-page--searchable-select {
    flex: 1;

    width: 100%;
    max-height: 100%;
}

.sb-visualization-page__legend {

    .flex-row();
    align-items: flex-end;
    width: 100%;

    max-height: calc(100% - @sb-visualization-page__legend--ie11-height-calc-fix);

    // overwrite svg legend
    .sb-svg-legend {
        width: 100%;
        height: 100%;
    }
}

.sb-visualization-page__fullscreen-card{
    margin-top: 10px;
}

.sb-visualization-page__stage-selection-header {
    .font(smaller, bold);
    .uppercase();
    color: @primary-font-color;
    margin-left: 24px;
}

.sb-visualization-page__stage-selection-entries {
    .font(small);
    margin-top: @margin-small;
    color: @primary-font-color;
    margin-left: 16px;
}

.sb-visualization-page__stage-selection-entry {
    margin-bottom: @margin-smaller;
}

.sb-visualization-page__stage-selection-entry__list {
    padding-left: @padding-normal;
}
