// TODO MB Nov. 2015: the sb-subbar is currently used globaly!! -> we have to create a proper global toolbar coloring and styling solution


@inner-container-max-width: 1400px;
.sb-selection-toolbar-layout-text{
    position: relative;
    top: 3px;
}
.sb-selection-toolbar-layout {
    justify-content: center;
}

.sb-selection-toolbar-content-layout {
    width: 100%;
    position:relative;
}
.sb-selection-toolbar-layout-right {
    position: absolute;
    top: 0px;
    right: 0px;
    height: 100%;
    padding: 0px;
    display: flex;
    align-items: center;
    .md-menu {
        padding: 0px;
        .md-button {
            height: 40px;
            width: 40px;
            line-height: 40px;
            margin: 0 4px;
            .mdi {
                font-size: 22px;
            }
        }
    }
}
.sb-subbar-mode{
    width:970px;
    position:relative;
    height: auto;
    max-height: @sb-subbar-height;

}

.sb-subbar-mode--wide{
    background: @sablono-green-main;
    width:100%;
    .sb-subbar-mode{
        width:100%;
        max-width: @inner-container-max-width;
        margin: 0 auto;
        padding-left:0;
    }
}

.sb-subbar-mode--full-size {
    width: 100%;
    .sb-subbar-mode{
        width: 100%;
    }
}

.sb-selection-toolbar-layout__button__icon {
    color: @light-text-color;
}
