@dialog-chooser-max-width: 600px;
@dialog-chooser-min-width: 600px;
@dialog-chooser-min-height: 600px;
@dialog-chooser-list-max-height: 400px;
@dialog-chooser-list-min-height: 400px;

@file-list-image-size: 45px;
.sb-dialog.sb-file-chooser-dialog {
    max-width: @dialog-chooser-max-width;
    min-height: @dialog-chooser-min-height;
    min-width: @dialog-chooser-min-width;
    md-dialog-content {
        margin: 0 !important;
        padding: 0 @padding-normal !important;
    }
    .sb-file-chooser-dialog-intro{
        padding:@padding-normal 0 0;
    }
    .sb-file-list{
        order:2;
        border-top: 1px solid @sablono-grey-100;
        padding:0;
        max-height: @dialog-chooser-list-max-height;
        min-height: @dialog-chooser-list-min-height;
        overflow: auto;

    }
    .sb-file-list-item{
        display: flex;
        flex-direction: row;
        background: @white-background;
        color: @sablono-grey-700;
        &:nth-child(even){
            background: @sablono-grey-50;
        }
        &:hover,
        &.selected{
            background: @sablono-green-50;
            cursor: pointer;
            color: @sablono-grey-800;
            small{
                color: @sablono-grey-700;
            }
            img{
                filter:none;
            }
        }
        span{
            .ellipsed-text();
            padding:@padding-small 0 0;
            &:first-child{
                padding: 0;
            }
        }
        small{
            color: @sablono-grey-500;
            margin-right: @margin-small;
        }
        .sb-file-list-info-filename{
            padding-top: @padding-small;
        }
    }
    .sb-file-list-icon{
        min-width:@file-list-image-size;
        max-width:@file-list-image-size;
        display: flex;
        height:100%;
        justify-content:flex-start;
        align-items: center;
        img{
            min-width:32px;
            max-width:32px;
            filter: grayscale(100%);
            transition: all 0.2s;
            &:hover{
                filter:none;
            }
        }
        i{
            // this is needed because sb-icons are not well centered.. to remove when we unify them.
            margin-bottom: 0;
        }
    }
    .sb-file-list-info{
        display: flex;
        flex-direction: column;
        line-height: 18px;
        padding:@padding-small 0;
    }
    .sb-file-list-excel-info{
        padding-top: @padding-small/2;
    }
    md-dialog-actions{
        border-top:1px solid @sablono-grey-100;
    }
}


