@import "components/issue_row";
@import "dialogs/issue_report";

@sb-issue__content-cell-height: 48px;
@sb-issue__content-cell-small-width: 60px;
@sb-issue__content-cell-normal-width: 114px;

@sb-issue-cell-text-and-code-width: 140px;
@sb-issue-cell-date-max-width: 200px;

@sb-issues-list-header-height: 54px;
@sb-issues-page-header-height: 120px;
@sb-issues-list-offset: 20px;
@sb-issues-list-width: calc(100% - @sb-issues-list-offset);
@sb-issues-list-max-width: 1400px;
@filter-menu-width: 200px;


.sb-issues__section {
    height: 100%;
    justify-content: center;
    width: @sb-issues-list-width;
    .flex-row();
}

.sb-issues__filter-menu {
    margin-top: 70px;
    position: absolute;
    left: 0;
    top: 0;
}

.sb-issues__content {
    .flex-column;
    position: relative;
    background: @gray-background;
    margin: 0 auto;
    width: 100%;
    height: 100%;
    max-width: @sb-issues-list-max-width;
}

.sb-issues__list-header {
    // 100% minus scroll width. So we can center the header properly
    width: calc(~"100% - 8px");
    margin-top: @margin-small;
    flex: 0 0 auto;
    position: relative;
    left: 8px;
}

.sb-issues__list-header-row {
    width: @sb-issues-list-width;
    max-width: @sb-issues-list-max-width;
    border-width: 0;
    margin: 0 auto 2px;
    padding: 0 @padding-small;
    .flex-row();
    flex-wrap: wrap;
    background: @sablono-grey-50;
    position: relative;
    .sb-whiteframe-glow-z1;

}

.sb-issues__page-odata-list {

    flex: 1;
    display: flex;
    flex-direction: column;

    .sb-odata-row-container {
        width: @sb-issues-list-width;
        max-width: @sb-issues-list-max-width;
        margin: 0 0 0 20px;
        background: @white-background;
    }
}

.sb-issue__header-cell {
    font-weight: @font-weight-light;
    margin-top: 8px;
    color: @sablono-grey-800;
    overflow: visible;
}

.sb-issue__header-cell--status{
    width: 25px;
    max-width: 25px;
}

.sb-issue__header-cell--responsible-team,
.sb-issue__header-cell--confirming-team {
    cursor: not-allowed;
}

.sb-issue__header-cell--activity {
    cursor: not-allowed;
}

.sb-issue__header__date {
    font-size: @font-size-small;
    font-weight: @font-weight-light;
    color: @sablono-grey-800;
    overflow: visible;
}

.sb-issue__header-cell__dates {
    display: flex;
    width: @sb-issue-author-date;
}

.sb-issue__header-cell--date {
    max-width: @sb-issue-cell-date-max-width;
    margin-left: @sb-issue-cell-date-max-width;
}

.sb-issue__header-cell--badge {
    width: @sb-edited-label-max-width;
    max-width: @sb-edited-label-max-width;
}

.sb-issue__cell {
    font-size: @font-size-small;
    border-collapse: collapse;
    display: flex;
    flex: 1;
    text-align: left;
    width: 100%;
    height: @sb-issue__content-cell-height;
    align-items: center;
    justify-content: flex-start;
    padding: 2px @padding-small;
    overflow: hidden;
}
.sb-issue-row__cell--with-rows {
    .flex-column();
    justify-content: center;
    align-items: flex-start;
    overflow: hidden;
}
.sb-issue__cell--text-and-code {
    flex-basis: @sb-issue-cell-text-and-code-width;
}

.sb-issues-empty-state {
    margin: auto;
}

@media screen and(max-width: @media-md) {
    .sb-odata-row-container,
    .sb-issues__list-header,
    .sb-issues__list-header-row {
        width: 100%;
    }
    .sb-issue-row__cell--with-rows{
        min-width: @sb-issue__content-cell-small-width;
        align-items: flex-start;
        justify-content: center;
    }
}

.sb-issues__content .sb-odata-list-repeater {
    position: relative;
    left: -5px;
}

@media screen and(max-width: @media-xxlg) {
    .sb-issues__content.is-menu-showing {
        margin: 0 auto 0 @filter-menu-width;
    }
}

@media screen and(max-width: @media-lg) {
    .sb-issue__header-cell--date {
        margin-left: 0;
    }
}
