/**
 * Helper mixin to create a colored badge with text.
 * Positioning needs to be added by the user.
 */
.sb-badge(@content, @rotation, @primaryColor, @secondaryColor) {
    content: @content;

    transform: rotate(@rotation);

    padding: 6px;

    background: @secondaryColor;
    color: @primaryColor;

    font-weight: @font-weight-bold;
    font-size: @font-size-smaller;

    line-height: 12px;
    border-radius: 12px; // same as line height for rounded appearance
}
