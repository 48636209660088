@import "../../../../../styles/global.less";

@not-set-text-width: 140px;
@not-set-text-magic-position-left: 4px; // manually adjusted be positioned correctly above the input area of the md-date-picker
@not-set-text-magic-position-bottom: 8px;  // manually adjusted be positioned correctly above the input area of the md-date-picker

.sb-datepicker-checkbox{
    margin-top: 24px;
    margin-left: @margin-big;
    padding-right: @padding-normal;
    &.md-checked .md-icon{
        background: @sablono-green-main;
        border-color: @sablono-green-main;
    }
    .md-icon{
        border-color: @sablono-grey-500;
    }
    &.sb-datepicker-checkbox-range{
        margin: 0 @margin-normal @margin-big;
    }
}
.sb-datepicker-input-group{
    .flex-column;
    > span{
        text-indent: 16px;
        margin-bottom:@margin-small;
    }
    > div{
        .flex-row;
        align-items: flex-start;
    }
    .sb-datepicker{
        .flex-row;
        align-items: flex-start;
        justify-content: flex-start;
        flex:1;
    }
    .sb-datepicker-container{
        min-width: 230px;
        margin-right: @margin-big;
        &.disabled{
            md-datepicker input{
                color: @light-text-color;
            }
            .md-datepicker-calendar-icon{
                color: @sablono-grey-400;
            }
        }
        input{
            font-size: @font-size-normal;
            font-weight: @font-weight-light;
        }
    }
}

.sb-datepicker .md-datepicker-input {
    min-width: @not-set-text-width;
}

.sb-datepicker__not-set-text {
    position: absolute;
    left: @not-set-text-magic-position-left;
    bottom: @not-set-text-magic-position-bottom;
    color: transparent;
}

.sb-datepicker--undefined-value {
    md-datepicker input{
        color: transparent !important;
    }
}
