@import "../../../../styles/global";
@import "../../../../styles/fonts";

// the component root element requires this property
//
inspection-row {
    width: 100%;
}

.sb-inspection-row__part {
    padding: @padding-smaller;
}

.sb-inspection-row__part--right {
    .flex-column();
    align-items: flex-end;
}

.sb-inspection-row__part--center {
    flex:1;
    .flex-column();
    padding-right: @padding-small;
}

.sb-inspection-row__text-primary {
    .small-font();
    line-height: @font-size-normal;
}

.sb-inspection-row__text-secondary {
    .thin-font();
    line-height: @font-size-small;
}

.sb-inspection-row {
    width: 100%;
    font-size:@font-size-small;
    .flex-row-center();
}

.sb-inspection-row__statistics {
    line-height: @font-size-normal;
}
