@calendar-nav-button-margin: 6px;

.sb-calendar-header {
    .flex-row();
    justify-content: flex-start;
    align-items: center;
}

.sb-calendar-header__previous, .sb-calendar-header__next {
    font-size: @font-size-larger;
    margin: @calendar-nav-button-margin 0;
    min-width: initial;
}

.sb-calendar-header__today {
    margin: auto 0;
}

.sb-calendar-header__current-month {
    display: inline-block;
    font-weight: @font-weight-light;
    font-size: @font-size-larger;
    margin: @margin-small;
    padding: @padding-smaller;
}