.sb-create-checklist-template-dialog {
    width: 700px;
}

.sb-dialog .sb-create-checklist-template-dialog__content {
    padding: @padding-normal @padding-normal;
}

.sb-create-checklist-template-dialog__input-container {
    margin: @margin-small @margin-normal @margin-small @margin-small;
}

.sb-create-checklist-template-dialog__input {
    width: 100%;
}

.sb-create-checklist-template-dialog .sb-dialog-header-close {
    color: @sablono-grey-600;
}

.sb-create-checklist-template-dialog__footer {
    color: @sablono-green-500;
}
