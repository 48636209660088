.sb-card{
    overflow-y: auto;
    overflow-x: hidden;
    width: 100%;
    position: relative;
    display: flex;
    flex:1;
    flex-direction: column;
    flex-wrap: nowrap;
    height: 100%;
    &-header{
        flex-shrink: 0;
    }
    md-card-content{
        flex-grow: 1;
        overflow: auto;
        padding:0;
    }
    md-card-footer{
        flex-shrink: 0;
        display: flex;
        flex-direction:column;
    }
}

.sb-card__narrow {
    md-card-header{
        padding-bottom: 0;
    }
    md-card-content{
        padding-top: 0;
    }
}

.sb-card--hover {
    box-shadow: 0 4px 5px -2px rgba(0, 0, 0, 0.2), 0px 7px 10px 1px rgba(0, 0, 0, 0.14), 0px 2px 16px 1px rgba(0, 0, 0, 0.12);
}
