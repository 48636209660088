.sb-select-file__header-wrapper {
    justify-content: center;
    .flex-row();
}

.sb-select-file__tutorial-wrapper {
    position: relative;
}

.sb-select-file__tutorial-icon i {
    font-size: 22px;
    padding: 0;
    position: absolute;
    left: 2px;
    text-shadow: none;
}
