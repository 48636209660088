@import "../issues.less";
@import "../../../../styles/global";

@sb-edited-label-max-width: 30px;
@sb-issue-author-date: 220px;

// Table cells
.sb-issue-row {
    display: flex;
    flex-direction: row;
    align-items: center;
    padding: @padding-small/2 @padding-small;
    border-bottom: 1px solid @sablono-grey;
    flex-wrap: wrap;
    border-left: 4px solid;
    &:focus{
        outline: none;
    }
    &:hover{
        cursor: pointer;
    }
}

.sb-issue-row__status--open{
    border-left-color: @riemi-red-600;
}
.sb-issue-row__status--done{
    border-left-color: @sablono-green-700;
}
.sb-issue-row__type--info {
    border-left-color: @sablono-grey;
}

// Helper classes
// Extends the ellipsed-text mixin adding the align-self:self attribute
.ellipsed-text-issue-row {
    align-self: stretch;
    overflow: hidden;
    span {
        float: left;
        max-width: 100%;
        .ellipsed-text();
    }
}
.sb-issue-row-secondary{
    font-weight: @font-weight-normal;
    color: @sablono-grey-500;
    font-size:@font-size-smaller;
}
.sb-issue-row-primary{
    color: @sablono-grey-700;
    font-weight: @font-weight-normal;
    font-size:@font-size-small;
}
// Styling of the columns

.sb-issue-row__cell--status-icon {
    font-size: @font-size-icon-normal;
    padding: @padding-small;
    .sb-claim {
        color: @note-color-quality-issue;
    }
    .sb-obstruction {
        color: @note-color-obstruction;
    }
    .sb-info {
        color: @note-color-info;
    }
    .sb-issue-closed {
        color: @sablono-grey-400;
    }
}

.sb-issue-row__cell--text {
    .sb-issue-row-primary();
    .ellipsed-text-issue-row();
}
.sb-issue-row__cell--name {
    .sb-issue-row-secondary();
    .ellipsed-text-issue-row();
}
.sb-issue-row__cell--component-name {
    .sb-issue-row-primary();
    .ellipsed-text-issue-row();
}
.sb-issue-row__cell--component-description {
    .sb-issue-row-secondary();
    .ellipsed-text-issue-row();
}

.sb-issue-row__cell--author-and-date {
    display: flex;
    gap: @margin-normal;
    width: @sb-issue-author-date;
}

.sb-issue-row__cell--author-and-date__reporter {
    margin-left: 18px;
}

.sb-issue-row__cell--author-and-date__badge {
    width: @sb-edited-label-max-width;
    display: flex;
    align-items: center;
}

.sb-edited-badge-wrapper{
    width: @sb-edited-label-max-width;
    max-width: @sb-edited-label-max-width;
    margin-right: 15px;
    display: flex;

}

@media screen and(max-width: @media-md){
    .sb-issue-row{
        padding-left: 0;
        padding-right: 0;
        position: relative;
    }
}
@media screen and(max-width: @media-lg){
    .sb-issue-row__cell--author-and-date {
        margin-left: 0;
    }
}

.sb-issue-row__menu__trigger {
    margin-right: 6px;
    font-size: @font-size-icon-normal;
}

.sb-issue-row__menu__trigger-wrapper {
    width: 30px;
}
