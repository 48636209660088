@import "../../../../../styles/sb-common/badge";

@sb-activity-set-conf-dialog-max-width: 600px;
@sb-activity-set-team-select-height: 45px;

.sb-activity-set-conf-dialog {
    max-width: @sb-activity-set-conf-dialog-max-width;
    min-width: @sb-activity-set-conf-dialog-max-width;
}

.sb-activity-set-conf-dialog__toolbar {
    min-height: auto;
}

.sb-activity-set-conf-dialog__actions {
    flex: 0 0 @sb-toolbar-height;
}

.sb-activity-set-conf-dialog__activities {
    .flex-column();
    overflow-y: hidden;
    margin: @margin-none;
}

.sb-activity-set-conf-dialog__activities-list {
    overflow-y: auto;
}

.sb-dialog .sb-activity-set-conf-dialog__options {
    padding: @padding-none;
    margin: @margin-none;
}

.sb-activities-list-selector__text {
    margin-top: @margin-none;
}

.sb-activity-set-conf-dialog__loading {
    .flex-column();
}

.sb-activity-set-conf-dialog__activities {
    .flex-column();
}

.sb-activity-set-conf-dialog__scheduling-info {
    padding-bottom: @padding-normal;
}

.sb-beta-feature-badge:after {
    .sb-badge(' BETA ', 0deg, @sablono-green-text-color, @sablono-green-main);
    position: absolute;
    right: 64px;
    margin: 10px @margin-none;
}

.sb-activity-set-conf-dialog__team-set-container {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    padding-left: @padding-normal;
    padding-right: @padding-normal;
}

.sb-activity-set-conf-dialog__team-type-select {
    margin: 0 5px;
    padding-left: 10px;
    flex: 1;
    height: @sb-activity-set-team-select-height;
}

.sb-activity-set-conf-dialog__team-select {
    width: 100%;
    flex: 2;

    .sb-team-select__input {
        height: @sb-activity-set-team-select-height;
    }

    // fix content size of team select
    //
    .sb-team-select {
        width: 100%;
        text-indent: 8px;
    }
}
