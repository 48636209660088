@import "../../../styles/layout";
@import "../../../styles/global";
@import "../../../styles/buttons";

@import "sbActivityCard/activity_card";
@import "sbDeliverableCard/deliverable_card";

@sb-master-content-width: 100%;
@sb-activity-filters-height: 70px;
@sb-process-activity-card-width: 180px;

@filter-menu-width: 200px;
@sb-process-header-height: 112px;
@filter-menu-margin-top: 70px;

// width of the navigation elements for the process viewer
//
@sb-process-viewer__nav-width: 40px;

////////////////////////////////////////////////////////////
//
// PROCESS VIEWER styles
//////

.sb-master-detail-layout {
    position: relative;
    top: 0;
    bottom: 0;
    right: 0;
    left: 0;
    margin-left: 200px;
    height: 100%;

    background-color: @white-background;
}

.sb-master-detail--empty {
    height: 100%;
    width: 100%;
    min-width: 100%;

    .flex-row-center;
}

.sb-master-detail__no-data-card {
    background-color: @gray-background;
}


.sb-master-detail-layout__master-content {
    position: absolute;
    left: 0;
    top: 0;
    bottom: 0;

    width: @sb-master-content-width;
    min-width: @sb-master-content-width;
    max-width: @sb-master-content-width;

    z-index: @z-indexes[background];
}

.sb-master-detail-layout__master-content--scroll-fix {
    .md-virtual-repeat-scroller {
        margin-right: @sb-process-viewer__nav-width;
    }
}

.sb-process {
    .flex-row;

    width: 100%;
    border-bottom: 1px solid @border-color;
}

.sb-process__activity-card {
    flex-basis: 10%;

    max-width: @sb-process-activity-card-width;
    min-width: @sb-process-activity-card-width;
    width: @sb-process-activity-card-width;

    margin: 0 @margin-normal;

    outline-width: 0;
}

.sb-process__activity-sequence {
    .flex-row;
    align-items: center;
    height: 64px;
    min-height: 64px;
}

.sb-process__empty-activity-sequence {
    padding: 20px;
    font-size: 14px;
}

.sb-activity-filters {
    .flex-row;
    align-items: center;
    min-height: @sb-activity-filters-height;
    height: @sb-activity-filters-height;

    .sb-deliverables-filters-container {
        max-width: none;
    }
}

.sb-activity-filters__chips-display {
    flex-grow: 1;
}

.sb-button-predecessor-successor {
    display: flex;
    align-items: center;
}


.sb-process-container__filter-menu{
    position: fixed;
    top: @sb-process-header-height;
    left: 0;
    bottom: @filter-menu-margin-top;
    overflow-y: auto;
    overflow-x: hidden;
    transform: translate(0, @filter-menu-margin-top);
}

.sb-process-viewer__nav {
    // position
    position: absolute;
    top: 0;
    bottom: 0;
    z-index: 2;

    // child layout
    .flex-row-center();
    width: @sb-process-viewer__nav-width;

    // appearance
    color: @sablono-green-main;
    background-color: rgba(255,255,255, 0.8);
    outline: none;
    .sb-clickable();
    &:hover{
        background-color: @sablono-grey-300;
    }
}
.sb-process-viewer__nav--left {
    left: @sb-process-viewer-deliverable-card-width;
    box-shadow: 2px 0px 0px 0px rgba(0, 0, 0, 0.14);
}
.sb-process-viewer__nav--right {
    right: 0;
    box-shadow: -2px 0px 0px 0px rgba(0, 0, 0, 0.14);
}
