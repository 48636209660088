@activity-detail-padding-left: 82px;

.sb-workflow__activity-details {
    display: block;
    padding: @padding-smaller 0 @padding-smaller @activity-detail-padding-left;
    border-top: 1px solid @sablono-grey-200;
}

.sb-workflow__activity-details-row {
    .flex-row-center-start;
}

.sb-workflow__activity-details-cell {
    .flex-row;
    flex: 1 0 0;
    width: 100%;

    span {
        .ellipsed-text();
        font-weight: @font-weight-normal;
    }

    .sb-workflow__activity-details-label {
        font-size: @font-size-smaller;
        font-weight: @font-weight-light;
    }

    .md-primary {
        background-color: @sablono-green-200;
    }
}

.sb-workflow__activity-details-delete-date {
    font-size: @font-size-smaller;
    color: @sablono-sea-green;
    cursor: pointer;
    outline: none;

    &:hover {
        text-decoration: underline;
    }
}

.sb-workflow__activity-details-cell--multiple-entries {
    ul{
        padding-left: 0;
    }

    ul li {
        .ellipsed-text();
    }
}
