.sb-time-range-selector-dialog{
    max-height: 95%;

    md-dialog-actions{
        border-top:1px solid @sablono-grey-300;
    }
    .sb-activity-tree{
        margin-bottom:@margin-normal;
    }
}
.sb-dialog.sb-time-range-selector-dialog md-dialog-content{
    margin: 0;
}

.sb-time-range-selector-dialog__label {
    font-size: @font-size-smallest;
}

.closed-session {
    .sb-time-range-selector-dialog__md-toolbar{
        background-color: @sablono-green-main;
    }

    .sb-time-range-selector-dialog__button-group button.sb-button-group__button{
        background-color: @sablono-green-main;

        &:hover {
            background-color: @sablono-green-button-hover !important;
        }
    }

    .md-calendar-selected-date {
        .md-calendar-date-selection-indicator {
            background: @sablono-green-main !important;
        }
    }
}

.open-session {
    .sb-time-range-selector-dialog__md-toolbar{
        background-color: @sablono-green-main;
    }

    .sb-time-range-selector-dialog__button-group button.sb-button-group__button{
        background-color: @sablono-green-main;

        &:hover {
            background-color: @sablono-green-button-hover !important;
        }
    }

    .md-calendar-selected-date {
        .md-calendar-date-selection-indicator {
            background: @sablono-green-main !important;
        }
    }
}

.sb-time-range-selector-dialog__md-toolbar,
.sb-dialog-header-close,
.sb-time-range-selector-dialog__button-group button{
    color: @sablono-grey-800 !important;
}

.sb-time-range-selector-dialog {
    .md-calendar-selected-date {
        .md-calendar-date-selection-indicator {
            color: @sablono-grey-800 !important;
        }
    }
}

.sb-time-range-selector-dialog__calendar-form {
    .flex-row();
}

.sb-time-range-selector-dialog__calendar-form--left-calendar,
.sb-time-range-selector-dialog__calendar-form--right-calendar,
.sb-time-range-selector-dialog__additional-options-column {
    padding: @padding-small;
    .flex-column()
}

.sb-time-range-selector-dialog__button-group {
    margin-top: @margin-normal;
}

.sb-time-range-selector-dialog__additional-options-column {
    display: flex;
}

.sb-time-range-selector-dialog__additional-options-column--top,
.sb-time-range-selector-dialog__additional-options-column--bottom {
    .flex-column();
}

.sb-time-range-selector-dialog__additional-options-column--bottom {
    margin-top: @margin-big
}
