@import "../../../../../../styles/global";
@import "../../../../../../styles/colorpalette";

@import "./sbDeliverableDates/sb_deliverable_dates";

.small-font {
    font-size: @font-size-small;
    font-weight: @font-weight-normal;
}

@sb-deliverable-details-small-cell-width: 80px;

@sb-deliverable-details-description-width: 400px;
@sb-deliverable-details-description-height: 50px;

.sb-deliverable-details__responsibilities, .sb-deliverable-details__deliverable-link {
    .flex(row, flex-start, center);
    padding: 3px @padding-small 2px @padding-small;

    .normal-font();
    color: @sablono-grey-600;
}

.sb-deliverable-details__deliverable-type {
    .flex(row, flex-start, center);

    padding: 3px @padding-small 2px @padding-small;

    .normal-font();
    color: @sablono-grey-600;
}
.sb-deliverable-details__cm-deliverable-text {
    .sb-deliverable-details__cm-text();
}

.sb-deliverable-details__cm-label {
    min-width: 170px;
    padding-left: @padding-smaller;
    .smaller-font();
}

.sb-deliverable-details__cm-text {
    .small-font();
}

a.sb-deliverable-details__cm-text {
    text-decoration: underline;
    cursor: pointer;
    color: @primary-font-color;
}

.sb-deliverable-details {
    position: relative;

    .sb-deliverable-details__loading {
        position: absolute;
        top: 0;
        bottom: 0;
        left: 0;
        right: 0;
        background-color: @white-background;
        z-index: 2;
    }

    .sb-deliverable-details-header {
        display: flex;
        padding: @padding-smaller @padding-smaller 0;

        .sb-deliverable-details-header-info {
            padding: 6px 0 6px @padding-small;
            box-sizing: border-box;
            flex: 3;

            @media(max-width: @media-md){
                width: 200px;
            }

            .sb-info-name-code {
                min-width: 40px;
                input,
                textarea,
                span {
                    font-weight: @font-weight-light;
                    font-size: @font-size-large;
                    padding: 0;
                    height: 21px;
                }
            }

            .sb-info-structure {
                color: @sablono-grey-600;
                font-size: @font-size-small;
                .flex-row-center-start;

                span {
                    width: 90%;
                    padding-left: @padding-smaller;
                }

                i {
                    color: @sablono-grey-400;
                    padding: 0 @padding-smaller 3px 0;
                }
            }

            .sb-info-structure-autocomplete {
                width: 93%;

                md-autocomplete,
                md-autocomplete md-autocomplete-wrap {
                    height: 24px;
                }

                md-autocomplete input:not(.md-input) {
                    height: 24px;
                    line-height: 24px;
                    font-size: @font-size-small;
                    font-weight: @font-weight-light;
                    color: @sablono-grey-600;
                    padding: @padding-smaller;
                }

                md-autocomplete button {
                    position: absolute;
                    right: -30px;
                    bottom: -2px;
                    width: 40px;
                    background: linear-gradient(
                        to right,
                        rgba(255, 255, 255, 0) 0%,
                        rgba(255, 255, 255, 1) 26%,
                        rgba(255, 255, 255, 1) 100%
                    );
                    md-icon {
                        right: 0;
                    }
                }
            }
        }

        .sb-info-overview {
            flex: 2;
            justify-content: space-between;
        }

        .sb-info-description {
            flex: 1;
            padding: 3px 0px 0px 0px;
            margin: 0;
            width: @sb-deliverable-details-description-width;
            font-size: @font-size-small;
            height: @sb-deliverable-details-description-height;
            max-height: @sb-deliverable-details-description-height;
            border-bottom: 1px solid @sablono-green-main;
            position: relative;
            overflow: auto;

            @media(max-width: @media-chrome-lg){
                width: 300px;
            }

            @media(max-width: @media-md){
                width: 200px;
            }

            label {
                position: relative;
            }

            textarea {
                position: absolute;
                font-weight: @font-weight-light;
                line-height: 1.3em;
                border: none;

                &.ng-invalid {
                    border-bottom: 2px solid red;
                }

                &[disabled] {
                    border: none;
                    color: @sablono-grey-800;
                    .not-selectable;
                }
            }

            &[disabled] {
                border: none;
            }
        }
    }

    .sb-deliverable-details-saved {
        display: flex;
        justify-content: flex-end;
        align-items: center;
        margin: 0 @margin-normal 0 @margin-normal;

        .sb-saving {
            position: absolute;
            right: 68px;
            top: 15px;
        }

        .sb-toggle-edit-button, .sb-open-in-mobile-button {
            cursor: pointer;
            border-radius: 10px;
            background: @sablono-grey-100;
            width: 40px;
            height: 40px;
            .flex-column-center;
            transition: all 0.2s;

            &:hover {
                .sb-toggle-edit-button-icon, .sb-open-in-mobile-button-icon {
                    color: @sablono-green-button;
                    transform: scale(1.1);
                }
            }

            .sb-toggle-edit-button-icon, .sb-open-in-mobile-button-icon {
                font-size: 24px;
                color: @sablono-green-button;
                &.edit-mode-active {
                    color: @sablono-green-button;
                }
            }
        }
    }

    .sb-toggle-edit-button {
        margin-right: @margin-small;
    }

    .sb-deliverable-details-info-section {
        .flex(row, space-between);

        padding: @padding-small;

        .sb-info-left {
            min-height: 60px;
            .flex-column();
            flex: 1;
            position: relative;
            margin-right: @margin-normal;

            .sb-deliverable-times-last-update {
                position: absolute;
                bottom: 1px;
            }
        }

        .sb-info-right {
            .flex-column();
            flex: 1;
            align-items: flex-end;
            justify-content: center;
            margin-right: @margin-smaller;
        }
    }

    .sb-info-progress {
        display: flex;
        justify-content: center;
        align-items: center;
        position: relative;
        max-width: @sb-deliverable-details-small-cell-width;
        padding: @padding-small;
        font-size: @font-size-smaller;
        font-weight: @font-weight-normal;
        height: 40px;
        .not-selectable;
        .mdi {
            font-size: 40px;
        }
    }

    .sb-info-progress-value {
        position: absolute;
        right: 1px;
        bottom: 8px;
        border-radius: 10px;
        padding: 0 @padding-smaller;
        background: @white-background;
        color: @sablono-light-steel;
        font-size: @font-size-smaller;
        font-weight: @font-weight-bold;
        .not-selectable;
    }
    .sb-data-title {
        min-width: 60px;
        font-size: @font-size-smaller;
        font-weight: @font-weight-light;
        color: @sablono-grey-700;
        .not-selectable;
    }
}
