.sb-toolbar-state__user {
    cursor: pointer;
}

.sb-toolbar-state__icon-button {
    padding: 0;
    background-color: @light-text-color;
    md-icon {
        color: @sablono-dark-steel !important;
    }
}

.sb-toolbar-state__icon-button:hover {
    background-color: @sablono-gravel !important;
}

//.sb-toolbar-state__icon-button.md-accent {
//    background-color: @florange-500;
//    :hover {
//        background-color: @florange-600;
//    }
//}

.sb-toolbar-state__user.sb-role-admin-color {
    .sb-user-image {
        background-color: @role_admin;
        color: contrast(@role_admin, @primary-font-color, @light-text-color);
    }
}
.sb-toolbar-state__user.sb-role-manager-color {
    .sb-user-image {
        background-color: @role_manager;
        color: contrast(@role_manager, @primary-font-color, @light-text-color);
    }
}
.sb-toolbar-state__user.sb-role-controller-color {
    .sb-user-image {
        background-color: @role_controller;
        color: contrast(@role_controller, @primary-font-color, @light-text-color);
    }
}
.sb-toolbar-state__user.sb-role-inspector-color {
    .sb-user-image {
        background-color: @role_inspector;
        color: contrast(@role_inspector, @primary-font-color, @light-text-color);
    }
}
.sb-toolbar-state__user.sb-role-reviewer-color {
    .sb-user-image {
        background-color: @role_reviewer;
        color: contrast(@role_reviewer, @primary-font-color, @light-text-color);
    }
}

.sb-toolbar-state__timeline {
    position: absolute;
    width: 500px;
    right: 0;
}

.sb-inspection-button_container {
    position: relative;
}

.sb-inspection_badge {
    position: absolute;
    top: -8px;
    right: -2px;
    height: 22px;
    width: 22px;

    cursor: pointer;

    background-color: @sablono-seafoam-green;
    color: @sablono-dark-steel;
    border: 1px solid @sablono-dark-steel;
    border-radius: 12px;

    .small-font();
    line-height: 20px;
    text-align: center;
}
