@sb-checkbox-width: 16px;
@sb-checkbox-width-and-padding: @sb-checkbox-width + @padding-small * 2;

.sb-checkbox{
    width: @sb-checkbox-width-and-padding;
    display: flex;
    justify-content: center;
    align-items: center;

    opacity: 0.0;
    transition: all 0.4s ease-in-out;

    i {
        outline: none;
        padding: @padding-small;
        font-size: 18px;
    }

    &.sb-checkbox-show {
        animation-duration: 0.5s;
        animation-name: sb-checkbox-fadeInFromNone;
        opacity: 1;
    }

    .mdi-checkbox-marked{
        color: @sablono-green-700;
    }
}

.sb-checkbox .mdi-checkbox-marked{
    color: darken(@sablono-green-main, 5%);
}

.sb-checkbox-parent-hover:hover{
    .sb-checkbox {
        opacity: 1;
    }
}

.sb-checkbox-layout{
    width: @sb-checkbox-width-and-padding; // equal to an md-icon-button width
}


@keyframes sb-checkbox-fadeInFromNone {
    0% {
        display: none;
        opacity: 0;
    }
    1% {
        display: block;
        opacity: 0;
    }
    100% {
        display: block;
        opacity: 1;
    }
}
