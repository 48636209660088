
@inner-container-max-width: 1400px;

.sb-background-info{
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    height: 100%;
    min-height: 100%;
    display: flex;
    flex-direction:column;
    justify-content: center;
    align-items: center;
    background: @gray-background;
}

.sb-background-info-plain-text {
    max-width : 480px;
    margin-left: @margin-big;
    margin-right: @margin-big;
}

.sb-background-info .sb-trascluded-content{
    display: flex;
    flex-direction: column;
    max-width: @inner-container-max-width;
    width: 96%;
    &.loading{
        margin-bottom: 64px;
    }
}

// Trying to unify info messages
.sb-info-large, .sb-background-info{
    text-align: center;
    color: @sablono-grey-500;
    h1,h2,h3,h4,h5{
        font-weight: @font-weight-light;
        color:@sablono-grey-300;
        margin-left: 10%;
        margin-right: 10%;
    }
    h2{
        color:@sablono-grey-600;
        font-weight:@font-weight-light;
        font-size:32px;
    }
    h3{
        color:@sablono-grey-500;
        font-weight:@font-weight-light;
        font-size:26px;
    }
    h4{
        font-weight:@font-weight-light;
        font-size:@font-size-larger;
        color: @sablono-grey-500;
    }
    p{
        font-size: @font-size-large;
        margin:@margin-normal;
    }
    h2, h3, h4 {
        &.md-primary{
            color: @primary-font-color;
        }
    }
    i { // make the image large -> we used to have this style on import pages.
        font-size: 10 * @font-size-large;
        text-shadow: 0 1px 0 rgba(0, 0, 0, 0.5);
        padding: 2 * @font-size-large;
    }
    button {
        margin-top: @margin-normal;
    }
}
.sb-background-info{
    .sb-background-list{
        display: flex;
        flex-direction: column;
        span{
            font-size: @font-size-large;
            margin-bottom:@margin-small;
        }
    }
    .sb-buttons-container{
        display: flex;
        width: 100%;
        justify-content: center;
        align-items: center;
        margin: @margin-normal 0;
        button{
            margin: 0;
        }
    }
}
