// Dialog
// (change date dialog)

.sb-change-date-dialog{
    width: 600px;
    md-dialog-actions{
        border-top:1px solid @sablono-grey-300;
    }
    .sb-activity-tree{
        margin-bottom:@margin-normal;
    }
}
.sb-dialog.sb-change-date-dialog md-dialog-content{
    margin: 0;
}
.sb-change-date-dialog__content{
    margin: @margin-normal 0!important;
}
.sb-change-date-dialog__activity-list{
    position: relative;
    color: @sablono-grey-700;
    outline-width: 0;
    &.selected{
        font-weight: @font-weight-bold;
        color: @primary-font-color;
    }
}
