.sb-assign-structure-dialog{
    max-width: 600px;
    width:100%;
    min-width: 400px!important;
    md-dialog-actions{
        border-top:1px solid @sablono-grey-300;
    }
    .sb-assign-structure-dialog-selected-info{
        border-top:1px solid @sablono-grey-300;
        order:2;
        margin:@margin-normal 0;
        padding: 0 @padding-normal;
    }
}
