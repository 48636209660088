// Common styling for source icons
.sb-source-icon {
    width: 24px;
    height: 24px;
    opacity: 0.6;
}

.mdi-2x {
    font-size: 28px;
    height: 28px;
    min-height: 28px;
    width: 28px;
    min-width: 28px;
}

md-icon.mdi:before {
    display: block; // fix distortion of image in image button
}

// this class should be refactored but it's used in a lot of places
.toolbar-tools-icon {
    font-size: 1.4em;
    padding-right: 6px;
    vertical-align: bottom;
}
