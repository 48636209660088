@import "../../../styles/layout";
@import "../../../styles/global";

/**
 * Overall page layout
 */
.sb-inspect-request{
    .flex-column-center-start;

    height: 100%;

    background-color: @gray-background;
}

/**
 * Top part of page above list content.
 * Containing explanatory text and action buttons.
 */
.sb-inspect-request__header{
    .flex-column-center;
    flex-shrink: 0;

    min-width: @media-s;
    max-width: @media-md;

    padding: @padding-small @padding-normal @padding-normal;

    .sb-title{
        margin-left: 10%;
        margin-right: 10%;
        text-align: center;
        margin-bottom:0;

        @media(max-width: @media-md) {
            font-size: 1em;
        }
    }
}


.sb-inspect-request__content {
    .flex-column-center;

    min-width: @media-s;
    max-width: @media-md;
    width: 100%;
    height: 100%;

    // Reset margin on medium sized screens (< 960px)
    //
    @media(max-width: @media-md) {
        margin: 0;
    }
}
