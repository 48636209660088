@import "../../../../../styles/fonts";
@import "../../../../../styles/global";

@progress-icon-button-size: 36px;

.sb-progress-option__button.md-icon-button{
    height: @progress-icon-button-size;
    width: @progress-icon-button-size;
    padding: 0;
    line-height: inherit;

    .mdi {
        font-size: 24px;
    }

    &[disabled]{
        background: @sablono-grey-200;
        .sb-bottom-shadow();
    }
}

.sb-progress-option__button--square .sb-progress-option__button{
    background: @gray-background;
    box-shadow: none!important;
    &[disabled]{
        background: none;
    }
    position: relative;
    &.md-icon-button{
        border-radius: 0;
        padding: 0;
    }
    &[disabled]:hover{
        background: none!important;
    }
    &:hover{
        background: @sablono-grey-200!important;
    }
    &:after{
        width: 0;
        height: 0;
        border-bottom: 12px solid @sablono-grey-300;
        border-left: 12px solid transparent;
        position: absolute;
        content: '';
        bottom: 0;
        right: 0;
    }
    &[disabled]:after {
        border: none;
    }
    .mdi{
        font-size:22px;
        &.sb-todo{
            font-size: 20px;
        }
    }
}

.sb-progress-option--cannot-start-icon {
    font-size: 12px;
}

// on an disabled button any icon should be grayed out like the text
//
.sb-progress-options__item {
    .md-button[disabled] {
        i {
            color: @sablono-green-text-color-disabled;
        }
    }
}

.sb-progress-option__menu-header {
    padding: @padding-none @padding-normal @padding-small @padding-normal;
    background-color: @white-background;
    max-width: 500px;
    overflow: visible;

    .sb-progress-option__menu-header__title {
        .small-bold-font();
    }
    .sb-progress-option__menu-header__message {
        .small-thin-font();
    }
}

.sb-workflow-status {
    max-height: none;
}
