sb-inspection-progress-list {
    min-width: 320px;
    max-width: 960px;
    width: 100%;
    flex: 1;
    display: flex;
    flex-direction: column;
}
.sb-inspection-progress-list{
    min-width: @media-s;
    max-width: @media-md;
    width: 100%;
    flex: 1;
    background: @white-background;
}
.sb-inspection-progress-list__deliverable{
    .flex-row-center-start;
    font-size: @font-size-large;
    padding: 0 @padding-normal;
    .sb-clickable;
    line-height: 1.2em;
    outline-width: 0;
}
.sb-inspection-progress-list__item--dirty{
    font-style: italic;
    color: @primary-font-color;
    .sb-inspection-progress-list__deliverable__info{
        color: @primary-font-color;
        text-indent: 8px;
        &:before{
            content: '*';
            position: absolute;
            left:-6px;
        }
    }
}
.sb-inspection-progress-list__activity-list{
    list-style: none;
    padding: 0;
    margin: 0;
}
.sb-inspection-progress-list__deliverable__info{
    .flex-column;
    position: relative;
    align-items: flex-start;
    padding: @padding-small;
    color: @sablono-grey-700;
}

.sb-inspection-progress-list__deliverable__info__code{
    font-size: @font-size-smaller;
}
.sb-inspection-progress-list__deliverable__info__name{
    font-size: @font-size-normal;
    font-weight: @font-weight-normal;
}
.sb-inspection-progress-list__deliverable__info__structure{
    font-size: @font-size-small;
}
.sb-inspection-progress-list__activity{
    .flex-row-center;
    font-size: @font-size-small;
    justify-content: space-between;
    padding: @padding-small;
    width: 90%;
    margin:0 0 0 auto;
    line-height: 1.2em;
}
.sb-inspection-progress-list__activity__row{
    background: @sablono-grey-50;
    &:nth-child(even){
        background: @white-background;
    }
}
.sb-inspection-progress-list__activity__info{
    .flex-column;
}
.sb-inspection-progress-list__row{
    .sb-bottom-shadow;
    outline-width: 0;
}

.sb-inspection-progress-list__progress-change .sb-progress-option__button.md-icon-button{
    // styling changes requested by david jung
    background-color: @sablono-grey-200;
    &:hover {
        background-color: @sablono-grey-300 !important;
    }
    &:after {
        border-bottom-color: @sablono-grey-400;
    }
}
