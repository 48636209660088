@import "../../../../../styles/global.less";

.sb-new-cost-group-dialog__type-row--selected {
    background-color: @sablono-green-main;
}

.sb-cost-group-dialog__toolbar-container {
	flex: 0 0 @sb-expanded-toolbar-height;
}

.sb-cost-group-dialog__actions {
	flex: 0 0 @sb-toolbar-height;
}
