@import '../../../../../styles/global';
@import '../../../../../styles/fonts';
@import '../../../../../styles/layout';
@import '../../../../../styles/colorpalette';
@import '../../../../../styles/sb-common/card';
@import '../../../../../styles/sb-common/tag';

@card-image-height: 180px;

.sb-image-card {
    .flex-column();
    justify-content: flex-end;

    margin: @margin-small;
    padding: @padding-none;

    md-card-content {
        padding: @padding-none @padding-normal;
        cursor: pointer;
        outline: none;
    }
    &:hover, &:focus{
        cursor: pointer;
        background-color: @sablono-grey-100;
    }

    // overwrite default browser link color and highlighting
    & a {
      color: inherit;
      text-decoration: inherit;
    }
}

.sb-image-card__content {
    .flex-column();
    justify-content: space-between;
    flex: 1;
}

.sb-image-card__header {
    .normal-bold-font();
}

.sb-image-card-info {
    .flex-row();
    align-items: center;

    width: 100%;
    float: left;
    box-sizing: border-box;
    overflow: hidden;

    .small-font();
    line-height: 20px;
    color: @primary-font-color;

    .sb-image-card-info-icon {
        float: left;
        padding-right: @padding-smaller;
    }
    .sb-image-card-info-text {
        float: left;
        .ellipsed-text();
    }
}

.sb-image-card__footer {
    .flex-row();
    align-items: center;
    justify-content: space-between;

    max-height: 36px;
    height: 36px;
}

.sb-image-card__delete-action {
    &:hover, &:focus{
        .mdi-delete {
            color: @riemi-red-500;
        }
    }
}

.sb-image-card__source-indicator {
    .sb-tag--normal();

    border-color: @sablono-grey-400;

    text-transform: uppercase;
    white-space: nowrap;
    margin-left: @margin-small;
}

.sb-image-card__photo {
    height: @card-image-height;
    background-size: contain;
    background-repeat: no-repeat;
    background-position: center;
    box-sizing: border-box;
    background-origin: content-box;
    padding-bottom: @padding-normal;
}

