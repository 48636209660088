@sb-edit-badge-width: 25px;
@sb-edit-badge-height: 15px;

.sb-edit__edited-label {
    transform: rotate(-30deg);
    font-size: @font-size-smallest;
    border-radius: 4px;
    border: 1px solid;
    padding: 0 2px;
}
.sb-edit__badge {
    flex: 1;
    display: flex;
    width: @sb-edit-badge-width;
    max-width: @sb-edit-badge-width;
    height:@sb-edit-badge-height;
    justify-content: left;
    align-items: center;
    padding: 0 0 0 @padding-small;
    position: relative;
    top: -1px;
}
.sb-warning-label{
    color: @riemi-red;
}