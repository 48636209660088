@import "../../../../styles/layout.less";
@import "../components/sbWeekCalendar/sbWeekCalendarBody/week_calendar_body";
@import "../components/sbWeekCalendar/sbWeekCalendarHeader/week_calendar_header";
@import "../components/sbDiaryEntry/diary_entry";
@import "../components/sbDiaryEvents/diary_events";
@import "../components/sbDiaryInfo/diary_info";
@import "../components/sbDiaryMachines/diary_machines";
@import "../components/sbDiaryProgress/diary_progress";
@import "../components/sbDiaryTeams/diary_teams";

@sb-site-diary-card-components-width: 60%;
@sb-site-diary-card-components-max-width: 1000px;

.site_diary {
    overflow: auto;
}

.site_diary__field-set {
    border: @border-none;
}

.site_diary__field-set:disabled {
    pointer-events: none;
}

.site_diary__card_components {
    margin: @margin-normal auto @margin-normal auto;
    width: @sb-site-diary-card-components-width;
    max-width: @sb-site-diary-card-components-max-width;
}

.site_diary__card_component md-card {
    margin-bottom: @margin-normal;

    md-card-title-text {
        color: @primary-font-color;
        font-size: @font-size-large;
    }
}

.sb-calendar-chooser__action-buttons {
    .flex(row, space-between);
}

.sb-calendar-header__finalise-entry {
    position: absolute;
    bottom: @margin-tiny;
    right: @margin-big;
}

.sb-site-diary__card-actions {
    .flex(row, flex-end);
}

.sb-site-diary__textarea {
    flex: 1;
    margin-bottom: 0;
}

@media screen and (max-width: @media-lg) {
    .site_diary__card_components {
        width: 100%;
    }
}
