@import "sbWorkflowActivity/workflow_activity";
@import "sbWorkflowGroup/workflow_group";
@import "sbWorkflowActivityDetail/workflow_activity_detail";
@import "sbActivityTree/activity_tree";
@sb-deliverable-workflow-button-size: 20px;
@sb-deliverable-details-summary-height: 40px;

.sb-deliverable-workflow {
    position: relative;
    flex: 1;
    .sb-deliverable-workflow-tree {
        font-size: @font-size-small;
        padding: 0;
        background: @white-background;
        .md-list-item-content {
            flex: 1;
        }
        md-list-item .md-avatar {
            width: @sb-deliverable-workflow-button-size;
            height: @sb-deliverable-workflow-button-size;
            margin-top: -@margin-small;
            margin-right: @margin-small;
        }
    }
    .sb-deliverable-workflow-header {
        .flex-row;
        padding: 0 @padding-normal;
        justify-content: space-between;
        min-height: 60px;
        h3 {
            font-weight: @font-weight-light;
            color: @sablono-grey-600;
        }
    }
    .sb-info-summary-activities,
    .sb-info-summary-issues {
        display: flex;
        flex-direction: row;
        padding: @padding-small 0 0 @padding-normal;
        height: @sb-deliverable-details-summary-height;
        font-size: @font-size-small;
        .mdi {
            font-size: 18px;
        }
        .sb-info-summary-value {
            display: flex;
            flex-direction: column;
            text-align: center;
            justify-content: space-around;
            margin: 0 6px;
        }
    }
    .sb-info-summary-issues {
        box-shadow: 1px 0 @sablono-grey-300;
        padding: 0 @padding-normal 0 0;
    }
    .sb-info-summary-values {
        display: flex;
        flex-direction: row;
        justify-content: flex-end;
    }
    .sb-user-image-by {
        margin-right: 0 !important;
    }
}

.sb-deliverable-workflow-header-wrapper {
    .sb-sticky-header;
    .flex-row;
    justify-content: space-between;
    background-color: @gray-background;

}
