.sb-filter__container{

    md-select .md-select-value{
        border-bottom-color: transparent;
    }

    md-select .md-select-placeholder{
        color: @sablono-grey-600;
        font-weight: @font-weight-normal;
    }
}

.sb-filter__selector{
    margin: 4px 0 @margin-small;
    background: @sablono-grey-100;
    padding:4px;
}
