@import "./directives/sbDependencyContainer/sbDependencyContainer";
@import "./directives/sbEditorTree/sbEditorTree";
@import "./directives/sbGroupActivityRows/sbGroupActivityRows";
@import "./dialogs/sbChangeParentDialog/sbChangeParentDialog";
@import "./dialogs/sbExitEditDialog/sbExitEditDialog";

@sb-template-editor-sidenav-width: 26%;
@sb-template-editor-width: 74%;

.sb-editor-card {
    position: absolute;
    overflow-x: hidden;
    background-color: transparent;
    height: 100%;
    flex-grow: 1;
    width: 100%;

    .info-container {

        .sb-template-activity__checklist-input-container{
            margin-top: 18px;
        }

        md-input-container{
            margin: 6px 0;
        }

        .sb-no-data-card{
            min-width: 100%;
            margin: 0;
        }
        h2.sb-no-data-card-header {
            color: @sablono-green-main!important;
            margin: @margin-small 0px!important;
            font-weight: @font-weight-light!important;

        }
        overflow: auto;
        .flex-column;
        flex: 1;
        background-color: @gray-background;
        padding: 8px;
        margin-bottom: 48px;
        .cards-container {
            flex: 1;
            display: flex;
            justify-content: space-around;
        }
        h2 {
            margin: 8px;
            font-weight: @font-weight-normal;
            color: rgba(0,0,0,0.53);
        }
    }
}

.editor-tree-layout{
    position: absolute;
    left: 0;
    top: 0;
    width: @sb-template-editor-sidenav-width;
    bottom: 0;
    z-index: 1;
    .sb-whiteframe-glow-z1;
}
.sb-templates-editor {
    position: absolute;
    &-breadcrumbs{
        flex-shrink: 0;
        z-index: 3;   ;
        box-shadow: none;
    }
}

.group-and-activity-container {
    .flex-row;
    align-items: flex-start;
    align-content: stretch;
    flex-wrap: wrap;
    sb-group-activity-rows{
        flex: 1 1 500px;
    }
}
.activity-tag{
    display: flex;
    justify-content: space-between;
    align-items: flex-end;
    &-text{
        padding: 4px 10px;
        font-size: @font-size-normal;
        color: rgba(0,0,0,0.53);
        font-weight: @font-weight-normal;
    }
}

.activity-dependency-autocomplete,
.group-item-autocomplete {

    &.md-autocomplete-suggestions li {
        padding-top: 5px;
        padding-bottom: 5px;
        height: auto;
        line-height: 20px;
        white-space: normal;
        border-bottom: 1px solid @border-color;
    }
}
.group-autocomplete{
    md-autocomplete-wrap{
        box-shadow: none!important;
    }
}

.group-item-template-title{
    .flex-row();
}

.activity-dependency-autocomplete {
    li {
        display: flex;
        flex-direction: column;
        .activity-state {
            font-weight: @font-weight-bold;
        }
    }
}

.card {
    display: flex;
    flex-grow: 1;
    background-color: white;
    flex-direction: column;
    align-items: stretch;
}

.examining {
    background-color: red;
}

.current .activities-container {
    .activity {
        cursor: default;
    }
    .task,
    .state {
        &:before {
            border-left-color: @gray-background;
        }
    }
}
.cards-container{
    display:flex;
    .left-card, .right-card{
        padding: 16px;
    }
    .card-with-title{
        flex:1;
    }
    h2{
      margin: 8px;
       text-align: left;
    }
    h2.right{
        text-align: right;
    }
}

.current-container{
    display: flex;
    .arrow-from-current,
    .arrow-to-current{
        flex:1;
        justify-content:center;
        .bg{
            width: 40%;
            height: 104px;
            background-size: 100% 100%;
            background-repeat: no-repeat;
            display: block;
        }

    }
    .arrow-from-current{
        display: flex;
        justify-content: flex-start;
        align-items: flex-end;
        .bg{
            background-image: url(../../../images/arrow-from-current.svg);
        }
    }
    .arrow-to-current{
        display: flex;
        justify-content: flex-end;
        align-items: flex-end;
        .bg{
            background-image: url(../../../images/arrow-to-current.svg);
        }
    }
    h2{
        margin: 0 auto ;
        color: @primary-font-color;
        text-align: center;
    }

    .current-card {
        flex:1.5;
        margin: auto;
        background: @gray-background;
        sb-activity-item{
            cursor: auto;
            left: 0;
            &:hover{
                transform: scale(1)!important;
            }
        }

    }

}

.editor-detail-animation {

    position: absolute;
    top: 0;
    left: @sb-template-editor-sidenav-width;
    bottom: 0;
    right: 0;

    &.ng-leave,
    &.ng-enter {
        animation-duration: 500ms;
        animation-timing-function: ease-in-out;
    }
    &.ng-leave{
        animation-name: zoomOut;
    }
}

.template-view-go-left{
    &.ng-enter {
        animation-name: slideInLeft;
    }
    //&.ng-leave {
    //    animation-name: slideOutRight;
    //}

}
.template-view-go-right{
    &.ng-enter {
        animation-name: slideInRight;
    }
    //&.ng-leave {
    //    animation-name: slideOutLeft;
    //}
}
.template-view-go-bottom{
    &.ng-enter {
        animation-name: slideInUp;
    }
    //&.ng-leave {
    //    animation-name: slideOutUp;
    //}
}
.template-view-go-top{
    &.ng-enter {
        animation-name: slideInDown;
    }
    //&.ng-leave {
    //    animation-name: slideOutDown;
    //}
}
.template-view-go-center{
    &.ng-enter {
        animation-name: zoomIn;
    }
    //&.ng-leave {
    //    animation-name: zoomOut;
    //}
}

.md-select-menu-container.md-active md-select-menu{
    min-width: 170px;
}
.md-select-menu-container {
    z-index: 10011;
}
.template-editor-message{
    white-space: -moz-pre-wrap; /* Firefox */
    white-space: -o-pre-wrap; /* Opera */
    white-space: pre-wrap; /* Chrome */
    word-wrap: break-word; /* IE */
    white-space: nowrap;
    font-size:12pt;
    letter-spacing: .005em;
    font-weight: 400;
}

.template-editor-message-name{
    font-size:20px;
    letter-spacing: .005em;
    font-weight: 400;
    position:absolute;
    left:80px;
    top:12px;
}
