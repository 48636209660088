@import "../../../../../styles/global";

@sb-commercial-header-cost-group-height: 52px;
@sb-commercial-header-cost-group-width: 350px;
@sb-commercial-header-body-height: 100px;
@sb-commercial-header-body-min-width: 1100px;
@sb-commercial-header-input-height: 38px;
@sb-commercial-header-input-width: 190px;
@sb-commercial-header-input-min-width: 255px;
@sb-commercial-header-input-margin: 30px;
@sb-commercial-header-cost-group-search-width: 320px;
@sb-commercial-header-budget-distribution-width: 180px;

.sb-commercial-header__body {
    position: relative;
    padding-top: @padding-none;
    padding-bottom: @padding-none;
    min-width: @sb-commercial-header-body-min-width;
}

.sb-commercial-header__body--loading {
    min-height: @sb-commercial-header-body-height;
}

.sb-commercial-header__cost-group {
    .flex-row();
    justify-content: space-between;
    height: @sb-commercial-header-cost-group-height;
    padding-bottom: @padding-none;

    font-size: @font-size-large;
}

.sb-commercial-header__cost-group-select {
    margin-top: 0;
    min-width: @sb-commercial-header-cost-group-width;
    max-width: @sb-commercial-header-cost-group-width;
    color: @primary-font-color;
}

.sb-commercial-header__no-cost-groups {
    .large-thin-font();
    text-align: left;

    padding: @padding-small @margin-big;
    background: @sablono-grey-200;
    max-width: @sb-commercial-header-cost-group-search-width - @sb-commercial-header-input-margin;
    margin: @margin-small 0 0;
    display: block;
}

.sb-commercial-header__budget {
    .flex-row();
    align-items: center;
}

.sb-commercial-header__amount {
    height: @sb-commercial-header-input-height;
    min-width: @sb-commercial-header-input-min-width;
}

.sb-commercial-header__currency {
    height: @sb-commercial-header-input-height;
}

.sb-commercial-header__actions {
    visibility: hidden;

    display: block;
    padding: 0 @padding-small;
}

.sb-commercial-header__actions-container {
    .flex-row();
    justify-content: space-between;
    align-items: center;
    visibility: hidden;
}

.sb-commercial-header__actions-container--visible {
    visibility: visible;
}

.sb-commercial-header__membership-summary {
    .flex-row();
    align-items: center;
}

.sb-commercial-header__membership-summary--details {
    .flex-column();
    .thin-font();

    padding-right: @padding-small;
}

.sb-commercial-header__budget-distribution {
    position: relative;
    width: @sb-commercial-header-budget-distribution-width;
    margin: @margin-normal;
    height: @sb-commercial-header-input-height;
    text-align: right;
    top: -@margin-normal;

    .sb-commercial-header__currency-icon {
        transform: translate(-@margin-small, 0);
    }
}

.sb-commercial-header__budget-distribution--invalid {
    margin-top: @margin-small;
    font-size: @font-size-smaller;
    white-space: nowrap;
    position: absolute;
    color: @riemi-red-A700;
}

.sb-commercial-header__budget-distribution-input.md-input[disabled]{
    color: @sablono-grey-800;
    max-width: @sb-commercial-header-input-width;
    margin-right: @sb-commercial-header-input-margin;
}

.sb-commercial-header__budget-label {
    font-size: @font-size-smaller;
    color: @sablono-grey-600;
    white-space: nowrap;
}

.sb-commercial-header__budget-value {
    font-size: @font-size-normal;
    display: block;
    height: @sb-commercial-header-input-margin;
    line-height: @sb-commercial-header-input-margin;
}

.sb-commercial-header__cost-group-search {
    width: @sb-commercial-header-cost-group-search-width;
    display: block;
    margin: 0 @margin-small @margin-small;
    padding: @padding-small;
    border-radius: 3px;
    border: 1px solid @sablono-grey-300;
    box-shadow: 0 1px 1px 0 rgba(0, 0, 0, 0.4);
    font-size: @font-size-normal;
}

.sb-commercial-header__budget-count, .sb-commercial-header__budget-percentage {
    position: relative;
    top: -@margin-small;
    font-size: @font-size-smallest;
}
