@sb-workflow-group-name-width: 388px;
@sb-workflow-column-space-large: 100px;
@sb-workflow-column-space-small: 30px;
@sb-workflow-activity-info-width: 60px;
@sb-workflow-completed-activities-width: 108px;
@sb-workflow-activity-group-margin: 20px;
@sb-workflow-activity-group-icon-margin: 27px;

.sb-group__progress-icon {
    font-size: 20px;
    padding: 14px;
}

// needed for column alignment with activity row
.sb-workflow-duration__spacer {
    min-width: @sb-workflow-column-space-large;
    max-width: @sb-workflow-column-space-large;
    width: @sb-workflow-column-space-large;
    flex: 1 0 @sb-workflow-column-space-large;
}

.sb-workflow-total-float-box__spacer {
    min-width: @sb-workflow-column-space-large;
    max-width: @sb-workflow-column-space-large;
    width: @sb-workflow-column-space-large;
}

.sb-workflow-properties-source-icon__spacer {
    min-width: @sb-workflow-column-space-small;
    max-width: @sb-workflow-column-space-small;
    width: @sb-workflow-column-space-small;
    flex: 1 0 @sb-workflow-column-space-small;
}

.sb-workflow-activity-group.sb-workflow-activity-row {
    margin-left: @sb-workflow-activity-group-margin;
    position: relative;

    .sb-workflow__dates, .sb-workflow-activity-info, .sb-data-title, .sb-workflow__completed-activities, .sb-workflow-dates-row-title {
        font-weight: @font-weight-bold;
    }

    .sb-workflow-activity-info {
        .flex-row;
        flex: 1 0 @sb-workflow-activity-info-width;
    }

    .sb-workflow-activity-group__chevron {
        position: absolute;
        transform: translate(-@sb-workflow-activity-group-icon-margin, 0);
    }
}

.sb-workflow__completed-activities {
    width: @sb-workflow-completed-activities-width;
    align-self: center;
}
