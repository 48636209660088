
.sb-info-message{
    padding: @padding-small @padding-normal;
    margin: 0;
    position: relative;
    .flex-row-center-start;
    i{
        font-size:18px;
        margin-right: @margin-normal;
        &.mdi-close{
            position: absolute;
            top:0;
            right: 0;
            cursor: pointer;
            border-radius: 50%;
            padding: 2px;
            margin: 2px;
        }
    }
    span{
        margin-right: @margin-small;
    }

    // Colors
    // INFO and default
    background: @sablono-green-A100;
    i{
        color: @sablono-green-200;
        &.mdi-close:hover{
            background: @sablono-green-100;
        }
    }
    &-warning{
        background: @florange-A100;
        i{
            color:@florange-200;
            &.mdi-close:hover{
                background: @florange-100;
            }
        }
    }
    &-alert{
        background: @riemi-red-A100;
        i{
            color:@riemi-red-500;
            &.mdi-close:hover{
                background: @riemi-red-100;
            }
        }
    }
}

.sb-info-message--clickable {

    &:hover {
        cursor: pointer;
    }
}
