@import "../../../../../../../styles/layout.less";

.sb-checklist-template-row {
    .flex-row-center();
    padding: @padding-small/2 @padding-small;
    border-bottom: 1px solid @sablono-grey;
    flex-wrap: wrap;
}

.sb-checklist-template-row__cell {
    .flex-row-center();
    flex: 1;
    font-size: @font-size-small;
    min-width: @sb-issue__content-cell-normal-width;
    height: @sb-issue__content-cell-height;
    padding: 2px @padding-small;
    overflow: hidden;
    &:focus {
        outline: none;
    }
}

.sb-checklist-template-row__cell--with-rows {
    .flex(column, center, flex-start);
    overflow: hidden;
}

.sb-checklist-template-row__author-and-date {
    flex-grow: 0;
    flex-shrink: 1;
    min-width: 60px;
    overflow: visible;
    padding: 0 15px 0 40px;
}

.sb-checklist-template-row__number-of-items {
    flex-grow: 0;
    flex-shrink: 1;
    min-width: 200px;
    overflow: visible;
    padding-right: 0;
    padding-left: 0px;
    text-align: right;

    .sb-checklist-template-row__text-container {
        width: 100%;
        text-align: center;
    }
}
