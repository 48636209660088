@import "../../../../../styles/global";
@import "../../../../../styles/colorpalette";

@sb-process-template-cell-max-width: 270px;
@sb-process-template-cell-xs-max-width: 105px;

.sb-process-template{

    display: flex;
    width: 100%;
    align-items: center;

    .sb-process-template__icon{
        font-size: @font-size-icon-normal;
        width: 30px;
        padding: 0 @padding-normal 0 @padding-small;
        // needed for tooltips
        transform: translate(0, 0);
    }

    .sb-process-template__text{
        .flex-column;
        flex:1;
        margin-right: @margin-small;
        max-width:@sb-process-template-cell-max-width;
        span,small{
            padding-top: @padding-smaller!important;
            line-height:@font-size-normal;
            white-space: normal!important;
        }
    }

    .sb-process-template__fixed--text{
        align-items: flex-end;
        max-width:@sb-process-template-cell-xs-max-width;
        small{
            margin-right: 0!important;
        }
    }

    .sb-user-image{
        color: @sablono-grey-600;
    }
}

.sb-template-process--selected  {
    background-color: @disabled-text-color;
    .sb-process-template__icon,
    .sb-user-image{
        color: @primary-font-color;
    }
}


