@import "../../../../../styles/global";
@import "../../../../../styles/colorpalette";
@import "../../../../../styles/layout";
@import "../../../../../styles/sb-common/card";

@sb-tile-size: 300px;
@sb-tile-icon-size: 92px;
@sb-tile-icon-top: 58px;
@sb-tile-mdi-icon-size: 155px;
@sb-tile-mdi-icon-top: 20px;
@sb-tile-title-size: 28px;
@sb-tile-bg-color: @white-background;
@sb-tile-bg-hover-color: @sablono-grey-100;
@sb-tile-title-color: @sablono-grey-500;

.sb-tile {
    position: relative;
    flex: @sb-tile-size 1 1;
    max-width: @sb-tile-size;
    height: @sb-tile-size;
    min-height: @sb-tile-size;
    transition: all 0.3s ease-in-out;
    cursor: pointer;
    .not-selectable;

    background: @sb-tile-bg-color;
    color:@sb-tile-title-color;
    &:hover {
        background: @sb-tile-bg-hover-color;
    }

    .sb-tile-content {
        display: flex;
        flex: 1;
        flex-direction: column;
        justify-content: space-between;
        align-items: center;
        align-content: center;
        box-sizing: border-box;
        // ie
        width: 100%;
        min-width: 100%;
    }
    &-just-text {
        .sb-tile-content {
            justify-content: space-around;
        }
    }
    .sb-tile-title,
    h2 {
        max-width: 100%;
        font-size: @sb-tile-title-size;
        font-weight: @font-weight-light;
        padding: @padding-normal 0;
        text-align: center;
        margin: 0;
        color: @sb-tile-title-color;
    }

    .sb-tile-subtitle,
    h3 {
        font-size: @font-size-larger;
        font-weight: @font-weight-light;
        text-align: center;
        margin: 0;
        color: @sb-tile-title-color;

    }

    .sb-tile-text,
    p {
        margin: @margin-small 0;
        font-size: @font-size-normal;
        // ie
        width: 100%;
    }

    .sb-tile-icon,
    i {
        font-size: @sb-tile-icon-size;
        padding: @sb-tile-icon-top 0 0;
        &.mdi {
            font-size: @sb-tile-mdi-icon-size;
            padding-top: @sb-tile-mdi-icon-top;
            color: @sablono-green-main;
        }
    }

    .sb-tile-image,
    img {
        max-width: 100%;
        box-sizing: border-box;
    }

}

.sb-tile--selectable {
    transition: all 0.2s ease-in-out;

    &:hover {
        cursor: pointer;
        .sb-card--hover();
    }

    &:focus {
        outline: none;
    }
}

.sb-tile-container {
    .flex-row-wrap();
    justify-content: center;
    align-items: center;

    background: transparent;
    position: relative;
    width: 100%;
    margin: 0 auto;
}
