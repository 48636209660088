@sb-deliverable-dependencies-separator-size: 60px;

.sb-dependency-manager {
    position: absolute;
    right: 0;
    bottom: 0;
    z-index: 1;
    width: 0;
    transition: all 0.3s ease-in-out;
    overflow-x: hidden;
    height: 100%;
    min-height: 100%;
    &.animate-from-left {
        top: 0;
        left: 0;
    }
    &.open {
        width: 100%;
        height: 100%;
    }
    .sb-dependency-manager-content {
        padding: @padding-normal;
        width: 100%;
        height: 100%;
        box-sizing: border-box;
        background: @white-background;
        .flex-column-center;
        justify-content: flex-start;
    }
    .sb-dependency-manager-close {
        position: absolute;
        top: 0;
        right: 0;
        font-size: @font-size-larger;
        cursor: pointer;
        border-radius: 50%;
        padding: @padding-small;
        outline-width: 0;
        &:hover {
            background: @sablono-grey-200;
        }
        &:active {
            background: @sablono-grey-500;
        }
    }
    h3,
    .sb-dependency-manager-add-list-actions {
        height: 34px;
        margin: 0;
    }
    .sb-dependency-manager-add-list-container {
        flex: 1;
        min-height: @sb-deliverable-dependencies-separator-size;
        height: 90%;
    }
}

.sb-dependency-manager-add-list-container {
    width: 98%;
    margin: @margin-normal auto;

    .sb-odata-list-content {
        background: @white-background;
    }
    .sb-odata-list-repeater {
        border-bottom: 1px solid @sablono-grey-300;
    }
    .sb-odata-row-container {
        width: 100%;
        &:nth-last-child(1) {
            margin-bottom: 0;
        }
    }
    .sb-dependency-manager-add-list {
        overflow: auto;
        width: 100%;
        height: 90%;
        min-height: 90%;
    }
    .sb-dependency-manager__row {
        height: 52px;
        min-height: 52px;
        border: 1px solid @sablono-grey-300;
        border-bottom: none;
        padding: 0 @padding-small 0 @padding-normal;
        box-sizing: border-box;
        .flex-row-center;
        .sb-deliverable-row {
            border: 0;
            width: 85%;
        }
    }
    .sb-deliverable-dependency-action-wrapper {
        width: 15%;
        max-height: 32px;
    }
    .sb-deliverable-dependency-action-has {
        font-size: @font-size-smaller;
        text-transform: uppercase;
        color: @sablono-green-700;
        .flex-column-center;
        align-items: flex-end;
        justify-content: space-around;
        transition: opacity 0.3s ease-in-out;
        opacity: 1;
        &.ng-hide {
            opacity: 0;
        }
    }
    .sb-deliverable-dependency-action-has-not{
        color: @sablono-green-700;
        .flex-column-center;
        align-items: flex-end;
        justify-content: space-around;
        transition: opacity 0.1s ease-in-out 0.3s;
        opacity: 1;
        &.ng-hide {
            opacity: 0;
        }
    }
    .sb-deliverable-dependency-button-add {
        cursor: pointer;
        .mdi {
            font-size: 30px;
        }
        .sb-hover-rotate-90;
    }

}

