.sb-checklist-templates-editor__header-container {
    max-width: @sb-checklist-templates-editor-max-width;
    width: @sb-checklist-templates-editor-width;
    margin: @margin-normal auto @margin-tiny;
}

.sb-checklist-templates-editor__header {
    display: flex;
    padding-bottom: 0;
    flex-direction: column;
}

.sb-checklist-templates-editor__checklist-overview-first-row,
.sb-checklist-templates-editor__checklist-overview-second-row {
    display: flex;
    flex-direction: row;
}

.sb-checklist-templates-editor__number-creator-container{
    display: flex;
    padding-top: @padding-small;
}

.sb-checklist-templates-editor__creator {
    padding-top: @padding-smaller;
}

.sb-checklist-templates-editor__checklist-overview-form {
    flex: 2;
}

.sb-checklist-templates-editor__checklist-name {
    .sb-checklist-templates-editor__checklist-item-input;
    flex-grow: 1;
    font-size: @font-size-normal;
}

.sb-checklist-templates-editor__checklist-name-label {
    padding-bottom: @padding-smaller;
}

.sb-checklist-templates-editor__number-of-items {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    width: 80px;
}
