@import './components/area_manager_card/area_manager_card';
@import "./dialogs/dialogs";


@sb-team-card-max-width: 800px;
@sb-team-card-user-width: 250px;
@sb-team-card-user-height: 63px;
.sb-teams {
    .flex-column-center-start;
    height: 100%;
}

.sb-teams-invited-members {
    width: @sb-team-card-max-width;
    margin-bottom: @margin-normal;

    .sb-teams-invited-members-cards {
        display: flex;
        flex-wrap: wrap;
        width: 100%;

        .sb-teams-invited-members-card {
            width: @sb-team-card-user-width;
            margin: 0 @margin-smaller @margin-small;
            border: 1px solid @sablono-grey-400;

            .sb-user-badge-user {
                margin-left: @margin-smaller;
            }
        }
    }
}

//  Team list
//    Team cards
.sb-team-card {
    width: @sb-team-card-max-width;
    max-width: 100%;
    border-top: 8px solid;
    margin: @margin-normal auto;
    .sb-team-card-content {
        .flex-column-center;
        padding-top: @padding-small;
    }
    .sb-team-card-edit {
        color: @primary-font-color;
        background: @sablono-grey-100;
        cursor: pointer;
        border-radius: 5px;
        margin-left: @margin-smaller;
        padding: @padding-smaller;
        outline-width: 0;
        &:hover {
            background: @sablono-grey-200;
        }
    }
    .sb-team-card-details {
        width: 100%;
        .flex-row;
        justify-content: space-between;
        align-items: flex-end;
        .sb-bottom-shadow;
        padding: @padding-small 0;
        .sb-team-card-details-title {
            .sb-title;
            margin: 0;
            align-self: flex-start;
        }
        .sb-team-card-details-members-number {
            padding-top: @padding-smaller;
            align-self: flex-start;
            .small-font();
            color: @sablono-grey-500;
        }
        .sb-team-card-details-info {
            .flex-row;
            font-size: @font-size-smaller;
            color: @sablono-grey-500;
            > div {
                .flex-column-center();
                align-items: flex-end;
                min-width: 100px;
                margin-left: @margin-small;
                &.sb-team-card-details-info-block {
                    padding-right: @padding-small;
                    border-right: 1px solid @sablono-grey-300;
                }
            }
        }
        a {
            text-decoration: none;
            color: @sablono-grey-600;
            padding: 0 0 2px;
            i {
                color: @sablono-grey-400;
            }
        }
    }

}

@sb-team-card-logo-size: 64px;
.sb-team-card__team-logo {
    display: inline-block;
    width: @sb-team-card-logo-size;
    height: @sb-team-card-logo-size;
    position: relative;
    margin-right: @margin-small;
    img {
        max-width: @sb-team-card-logo-size;
        max-height: @sb-team-card-logo-size;
        width: auto;
        height: auto;
        position: absolute;
        top: 0;
        right: 0;
        bottom: 0;
        left: 0;
        margin: auto;
    }
}

sb-team-create {
    width: 100%;
    max-width: @sb-team-card-max-width;
    margin: @margin-normal;
}

// User lists
.sb-team-card-users {
    width: 100%;
}

.sb-team-user-list {
    width: 100%;
    sb-team-user-card, sb-team-user-create, sb-team-area-manager-card {
        width: @sb-team-card-user-width;
        margin: 3px;
        height: @sb-team-card-user-height + 5px;
        float: left;
        .sb-user-badge {
            height: @sb-team-card-user-height;
            .sb-user-badge-container {
                background: initial !important;
                border: 1px solid @border-color;
            }
            .sb-user-badge-user {
                text-indent: 4px;
                cursor: default;
            }
        }
        .sb-add-new-card-content {
            line-height: normal;
            padding: 0 @padding-small !important;
            height: @sb-team-card-user-height - 2;
            margin-top: 0;
            box-sizing: border-box;
            span {
                text-indent: 4px;
                .ellipsed-text;
            }
        }
    }

    sb-team-area-manager-card {
        height: (@sb-team-card-user-height * 2) + 6px;
    }
}

.sb-team-card-users-loading {
    float: left;
    position: relative;
    margin: 3px;
    width: @sb-team-card-user-width;
    height: @sb-team-card-user-height;
}

.sb-team-card__info-icon {
    font-size:@font-size-normal;
    line-height:20px;
    text-align: center;
    &:hover {
        color: @primary-font-color;
    }
}

.area-managers-section__title {
    padding-top: @padding-small;
    clear: both;
    font-size: @font-size-normal;
    color: @sablono-grey-900;
    text-align: left;
    margin: 0 0 @margin-normal 0;
}

/* IE only styles */
@media all and (-ms-high-contrast: none), (-ms-high-contrast: active) {

    .sb-team-user-list {
        min-height: 240px;
        overflow-y: auto;
        overflow-x: hidden;
        width: @sb-team-card-max-width;
        max-width: @sb-team-card-max-width;

    }

    .sb-team-card {
        max-width: @sb-team-card-max-width;
    }
}

.sb-team-card__content {
    display: flex;
    flex-direction: column;
}

.sb-team-card-details__team-members,
.sb-team-card-details__commercial-members {
    margin-bottom: @margin-normal;
    width: 100%;
}

.sb-team-card-details__commercial-members {
    margin-top: @margin-normal;
}

