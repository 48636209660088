sb-subbar-actions{
    width:100%;
    flex: 1;
    .sb-subbar-content{
        display: flex;
        align-items: center;
        justify-content: flex-end;
        padding: @padding-smaller;
        h1,h2,h3,h4,h5,p{
            margin:0;
            font-size:@font-size-larger;
            font-weight: @font-weight-normal;
            .ellipsed-text();
            span{
                font-weight: @font-weight-light;
            }
        }

    }
}
