@error-banner-height: 80px;

.sb-excel-viewer {
    top: 0;
    height: 100%;
    flex-direction: row;
    md-content {
        padding: 0;
        overflow-x: hidden;
    }
    sb-table {
        height: 100%;
        top: 0;
        font-size: @font-size-small;
    }
    .sb-excel-viewer-view {
        height: 100%;
        min-height: 100%;
        flex: 3;
    }
    .sb-excel-viewer-view-file {
        height: 100%;
        min-height: 100%;
        flex: 3;
    }

    // We have to overwrite some styles in here because we are
    // displaying the two containers inside another layout.
    // Overwriting styles from sb-upload-preview directive.
    .sb-upload-preview-container {
        .sb-upload-preview-content {
            flex-direction: column;
            flex: 2;
            height: 100%;
            min-height: 100%;
        }
    }
}

.sb-excel-viewer__upload-has-failure--message {
    display: flex;
    justify-content: center;
    align-items: center;
    height: @error-banner-height;
    width: 100%;
}

.sb-excel-viewer__upload-has-failure-message-shown {
    height: calc(100% - @error-banner-height) !important;
    margin-top: @error-banner-height;
}

.sb-excel-viewer__header-wrapper {
    justify-content: center;
    .flex-row();
}

.sb-excel-viewer__tutorial-wrapper {
    position: relative;
}

.sb-excel-viewer__tutorial-icon i {
    font-size: 22px;
    padding: 0;
    position: absolute;
    left: 2px;
    text-shadow: none;
}

.sb-excel-viewer__success-deliverables-button {
    margin-top: @margin-big;
}

.sb-excel-viewer__progress-bar {
    position: absolute;
    z-index: 10;
}
