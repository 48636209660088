@sb-filter-autocomplete-checkbox-width: 190px;
.sb-filter-autocomplete {
    display: flex;
    flex-direction: row;
    sb-structure-autocomplete{
        width: 100%;
    }
    md-autocomplete {
        flex: 1;
        margin-bottom: 3px;
        height: 32px;
        width: 100%;
        padding: 4px 0 9px 0;
        background: @white-background;
        input,
        input.ng-invalid{
            border:none!important;
        }
        button{
            margin: @margin-smaller @margin-normal;
        }
        md-autocomplete-wrap {

            box-shadow: none;
            background-color: @sablono-grey-100;
            align-items: flex-end;
            margin-bottom: 2px;
            height: 38px;
            input {
                width: 90%;
                padding: 0 2px 6px;
                height: 33px;
                text-indent:4px;
            }

            &:after{
                font-family:'Material Design Icons';
                content: '\25BC';
                position: absolute;
                bottom:10px;
                right:8px;
                speak: none;
                transform: scaleY(0.5) scaleX(1);
            }
        }

        &[disabled] {
            background: @white-background;
            md-autocomplete-wrap {
                background: @sablono-grey-50;
                padding: 0;
                border-bottom: 1px dashed @sablono-grey-400;
            }
            input {
                color: @sablono-grey-500;
                background: @sablono-grey-50;
                border: none;
                box-shadow: none;
            }
        }
    }
    md-select[disabled] .md-select-value{
        background:@sablono-grey-100;
        height:35px;
        top:4px;
    }
    md-checkbox.md-checked .md-icon {
        background: @sablono-green-main;
    }
    md-checkbox .md-icon {

        border-width: 1px;
    }
    md-checkbox .md-container
    {
        left: -1px;
    }
    .sb-filter-autocomplete-checkbox {
        width: 30%;
        font-size: @font-size-smaller;
        margin-bottom: @margin-normal;
        align-self: flex-end;
        .md-label{
            line-height:20px;
        }
    }

}

.sb-autocomplete-item-multiline {
    md-autocomplete-parent-scope {
        display: flex;
        align-items: flex-start;
        max-height: 100%;
    }
    li {
        line-height: normal;
        display: flex;
        align-items: center;
        margin: @margin-smaller 0;
        height: auto;
        min-height: 48px;
        border-bottom: 1px solid @sablono-grey-50;
    }
}

.sb-autocomplete-item-multiline-text {
    white-space: normal;
    line-height: normal;
    height: auto;
    padding-left: 20px;
    text-indent: -20px;
}
