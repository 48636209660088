@import "../../../../../styles/global";
@import "../../../../../styles/colorpalette";

@sb-late-activities-panel-height: calc(100vh - @margin-small); // 100% of viewports height
@sb-late-activities-panel-width: 400px;
@sb-late-activities-panel-header-height: 80px;
@sb-late-activities-panel-footer-height: 52px;

.sb-deliverable-card__late-activities {
    width: @sb-late-activities-panel-width;
    box-sizing: border-box;

    .sb-scroll-wrapper(@sb-late-activities-panel-height);

    .sb-activity-card {

        .sb-activity-card__info--team {
            max-width: 65%;
        }

        // hide actions for moving activity by date
        //
        &.sb-activity-card--overflows--left,
        &.sb-activity-card--overflows--right {
            display: none;
        }
    }
}

.sb-deliverable-card__late-activities--loading {
    display: block;
    min-height: 50px;
    height: 50px;
}

.sb-scroll-wrapper(@height) {
    max-height: calc(@height - (@sb-late-activities-panel-header-height + @sb-late-activities-panel-footer-height));
    overflow: auto;
}
