[sb-content-fill]{
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    display: flex;
    justify-content: center;
    align-items:flex-start;
    flex-direction:row;
    width: 100%;
    height: 100%;
    margin:0 auto;
    overflow: hidden;
    box-sizing: border-box;
}
