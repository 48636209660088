@sb-dialog-team-min-width: 400px;
@sb-dialog-team-max-width: 600px;
@sb-dialog-team-max-height: 400px;

@file-list-image-size: 45px;
.sb-dialog.sb-edit-or-add-team-dialog {
    max-width: @sb-dialog-team-max-width;
    md-dialog-content {
        margin: 0 !important;
        padding-top: @padding-normal;
    }
    i.mdi{
        margin-right:@margin-small;
    }
    .sb-edit-or-add-team-project-team-name{
        color: @sablono-grey-500;
        border-bottom: 1px dotted;
        width: 80%;
        height: 28px;
        margin-right:@margin-normal;
    }
    .sb-edit-or-add-team-dialog-form{
        .flex-column-center;
        justify-content: flex-start;
        align-items: flex-start;
        .sb-title{
            width: 100%;
            margin: 0 0 @margin-small 0;
            &:nth-child(1){
                margin:0 0 @margin-big 0 ;
            }
        }
        > div{
            width: 100%;
            .flex-row;
        }
        p{
            width: 100%;
            margin: 0 0 @margin-normal 0;
        }
        md-input-container{
            width: 100%;
            padding-right: @padding-small;
            margin: 0;
        }
        .sb-edit-or-add-team-dialog-color-picker{

            max-width: 80px;
            border-radius: 40px;
            input[type=color] {
                border: 0;
                padding: 0;
                cursor: pointer;
            }
        }
    }
    .sb-edit-or-add-team__logo{
        min-height: 80px;
        width: 100%;
        max-width: 100px;
        margin:0 0 0 @margin-normal;
        background-repeat: no-repeat;
        background-size: contain;
        background-position: 100% 50%;
    }
    .sb-edit-or-add-team__upload-logo{
        .flex-row-center-start;
        position: relative;
    }
    .sb-edit-or-add-team__upload-logo--error{
        font-size:@font-size-smaller;
        color:@riemi-red
    }
    md-dialog-actions{
        border-top:1px solid @sablono-grey-100;
    }
    .sb-title{
        color: @sablono-green-900;
        border-bottom: 1px solid @sablono-green-main;
    }
}
