.sb-template-activity-edit-row {
    .flex-row;
    flex-wrap: wrap;
    align-items: flex-end;
    .sb-team-select{
        margin-left: inherit;
    }
}
.sb-template-activity__edit{
    .flex-row;
    flex-wrap: wrap;
}
.sb-template-activity-edit-code {
    flex: 0 0 100px
}

.sb-template-activity-edit-name {
    flex: 1 1;
    @media @mq-tablet{
        flex: 0 0 48%;
    }
}

.sb-template-activity-edit-state {
    flex: 1 1;
    @media @mq-tablet{
        flex: 0 0 48%;
    }
}

.sb-template-activity-edit-desc {
    flex: 1;
    min-width: 320px;
}
.sb-template-activity-color{
    flex:1;
    max-width: 84px;
    margin-left: @margin-small;
}
.sb-template-activity__team-select{
    flex-grow: 1;
    .sb-team-select__input{
        height: auto;
    }
}
.sb-template-activity__checklist-input-container{
    max-width: 100%;
    flex-grow: 2;
}
.sb-template-activity-edit-duration{
    .flexit(7%);
    @media @mq-tablet{
        .flexit(20%);
    }
    @media @mq-mobile{
        .flexit(25%);
    }
}
.sb-template-activity-edit-unit{
    .flexit(20%);
    @media @mq-tablet{
        .flexit(35%);
    }
    @media @mq-mobile{
        .flexit(45%);
    }
}
.sb-template-activity-shrunk-control span.md-select-icon{
    position:absolute;
    right:15px;
    top:5px;
}
.sb-template-activity-tooltip-trigger-display{
    display:flex;
    position:absolute;
    top:8px;
    right:0px;
    cursor:pointer;
}
.sb-template-activity-layout-row-alignment{
    align-items:flex-end;
    height:95px;
}
.sb-template-activity__labour{
    .flex-row-center;
    max-width: 175px;
    @media @mq-tablet{
        max-width: 130px;
    }
}

.sb-template-activity__labour__unit{
    margin-bottom:@margin-normal;
}

.sb-template-activity__checklist-input .md-select-icon {
    text-align: left;
}

.sb-template-activity__confirm-team-tooltip {
    margin-bottom: 12px;
}