@import "../colorpalette";
@import "../fonts";

@sb-tags-font-size: 11px;
@sb-tags-font-weight: 400;

.sb-tag {
    font-size: @sb-tags-font-size;
    font-weight: @sb-tags-font-weight;
    padding: 2px 4px 1px;
    border-radius: 4px;
    margin: 1px;
    color: @light-text-color;

    &.sb-tag-on-time {
        background: @activity-scheduling-on-time;
    }
    &.sb-tag-behind {
        background: @activity-scheduling-behind;
    }
    &.sb-tag-unscheduled {
        background: @activity-scheduling-unscheduled;
    }
    &.sb-tag-open-claims {
        background: @note-color-quality-issue;
    }
    &.sb-tag-open-obstructions {
        background: @note-color-obstruction;
    }
    &.sb-tag-infos {
        background: @note-color-info;
    }
    &.sb-tag-user-date {
        background: @user-date-color;
    }
    &.sb-tag-user-date-conflict {
        background: @user-date-conflict-color;
    }
}

.sb-tag--normal {
    .small-bold-font();
    line-height: 20px;
    padding: 1px 12px;
    border-radius: 12px;
    border: 1px solid;
    margin: 1px;
    color: @sablono-grey-600;
}
