@import "./directives/sbNoteTemplateEditCard/sbNoteTemplateEditCard";
@import "./directives/sbNoteTemplateConfigCard/sbNoteTemplateConfigCard";
@import "./directives/sbNoteTemplateVirtualList/sb_note_template_virtual_list";
@import "./directives/sbDataState/sb_data_state";
@import "./dialogs/createNoteTemplate/createNoteTemplate";

// @TODO: try to make these variables work for all values
@table-indentation: 8px;
@row-padding: @padding-small;

@header-indentation: @row-padding + @table-indentation;

.empty-error {
    font-weight: @font-weight-light;
    text-align: center;
    margin-top: 250px;
}

.md-button.md-fab.md-fab-bottom-right.add-button{
    position: fixed;
}

.sb-note-templates-delete{
    // +4px for alignment with the search icon
    left : @filter-card-width + @margin-big + 4px;
}

.notes-header-container {
    z-index: 5;
    position: absolute;
    left: 0;
    right: 0;
    top: 0;
    width: 100%;
    display: flex;
    flex-grow: 1;
    align-items: center;
    justify-content: center;
    background-color: @gray-background;
    padding: 0 0px;
    .notes-header {
        margin-right: 240px;
        padding-left: @header-indentation;
        padding-right: @header-indentation;
        background-color: @white-background;
        display: flex;
        flex-direction: row;
        margin-top: 0px;
        max-width: @container-width;
        width: 100%;
        div {
            button {
                min-width: 0px;
                margin: 5px 0px;
                width: 100%;
                i {
                    margin-right: 3px;
                }
            }
        }
        md-checkbox {
            margin: auto;
        }
    }
}

@media (max-width: @media-xlg) { // breakpoint where the search bar is not there anymore

    .rows-container .row-container{
        padding-right: 10px;
    }
    .notes-header-container .notes-header{
        margin-right: 10px;
    }

    .sb-note-templates-table {
        .rows-container{
            .row{
                left: 2px;
            }
        }
        .md-virtual-repeat-offsetter{
            width: 100%;
        }
        .notes-header-container{
            z-index: 10;
            position: absolute;
            right: 0;
            .notes-header{
                padding: 0 9px;
            }
        }
    }
}

@media (max-width: @media-sm) {

    .rows-container .row-container{
        padding-left: 0;
        padding-right: 0;
    }
    .notes-header-container .notes-header{
        margin-right: 0;
        margin-left: 0;
    }
    .sb-note-templates-table{
        padding-left: 0;
        padding-right: 0;
    }
}


.note-templates-select-bar {
    @media (max-width: 959px) {
        width: 100%;
        left: 0;
    }
    @media (min-width: 960px) and (max-width: 1050px) {
        width: 82%;
        left: 21%;
    }
    @media (min-width: 1049px) and (max-width: 1179px) {
        width: 82%;
        left: 19%;
    }
    @media (min-width: 1180px) and (max-width: 1279px) {
        width: 98%;
        left: 13%;
    }
    @media (min-width: 1280px) and (max-width: 1349px) {
        width: 100%;
        left: 10%;

    }
    @media (min-width: 1350px) and (max-width: 1389px) {
        width: 97%;
        left: 1%;

    }
    @media (min-width: 1390px) and (max-width: 1440px) {
        width: 97%;
        left: 1%;
    }
}
