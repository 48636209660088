@sb-activity-tree-min-height: 36px;
@sb-activity-tree-group-left-offset: -4px;

.sb-activity-tree{
    padding: 0;
    background: @white-background;
    padding: 0;

    // @12/05/17 by RIEMI: overwrite gantt injected styled to fix separator line issues
    //
    .angular-ui-tree-node,
    .angular-ui-tree-placeholder {
        position: static;
    }
    .angular-ui-tree-nodes{
        position: static;

        .angular-ui-tree-nodes {
            padding-left: 0;
            position: static;
        }
    }
}
hr.sb-activity-tree__separator{
    border-top:1px solid @sablono-grey-300;
    border-bottom: none;
    margin: 0;
    position: absolute;
    width: 100%;
    left:0;
}
.sb-activity-tree__toggle_btn{
    position: absolute;
    top:50%;
    transform: translate(0, -50%);
    left:@sb-activity-tree-group-left-offset;
}
.sb-activity-tree__item{
    min-height: @sb-activity-tree-min-height;
    margin: 0;
    &.selected{
        background: @sablono-green-50;
    }
}
.sb-activity-tree__row{
    color: @sablono-grey-700;
    outline-width: 0;
    &.selected{
        font-weight: @font-weight-bold;
        color: @primary-font-color;
    }
}
.sb-activity-tree__row--default{
    position: relative;
    padding: @padding-smaller 0;
    cursor: pointer;
    .sb-activity-tree__toggle_btn{
        left:-20px;
        top:10px;
    }
}
.angular-ui-tree-nodes{
    list-style: none;
    margin: 0;
    padding: 0;
}
.sb-workflow-activity-pane-content {
    background-color: @sablono-grey-50;
}
