.sb-group--header-text__sorted{
    font-weight: @font-weight-bold;
}

.sb-sort-panel{
    background-color: @sablono-grey-50;
}

.sb-group-item-text{
    font-size: 14px;
}

.sb-sort-group{
    white-space:nowrap;
    width: 100%;
    height: 100%;
    cursor: pointer;
    div[md-menu-origin]{
        width: 100%;
        height: 100%;
        display: flex;
        flex-direction: column;
        justify-content: center;
        outline: none;
    }
}
