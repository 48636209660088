@import "components/inspection_row";
@import "states/timeline_detail";

@time-line-list-width: 380px;
@time-line-header-height: 80px;
@time-line__item--color-default: @sablono-grey-600;
@time-line__item--color-text: @sablono-grey-50;
@time-line__item--color-highlight: @sablono-green-main;

// overall layout
//
.sb-timeline__odata-list .sb-odata-list-content {
    min-height: calc(100% ~"-" @time-line-header-height);
    height:  calc(100% ~"-" @time-line-header-height);
}

.sb-timeline__inspections {
    background: transparent;
    max-width: @time-line-list-width;
    overflow-y: hidden;
    height: 100%;

    .sb-expand-box{
        left: 0;
    }
}

.sb-timeline__details-content{
    position: absolute;
    top: 0;
    left: @time-line-list-width;
    right: 0;
    background-color: transparent;
    bottom: 0;
}

// coloring of the list-items
//
.sb-timeline__list-item {
    color: @time-line__item--color-default;
    .sb-user-image {
        background-color: @time-line__item--color-default;
        color: @time-line__item--color-text;
        border: none;
    }
}
.sb-timeline__list-item--active {
    color: @time-line__item--color-highlight;
    .sb-user-image {
        background-color: @time-line__item--color-highlight;
        color: @time-line__item--color-text;
        border: none;
    }
}
