// ACCORDION STYLES
.filter-accordion {
    display: flex;
    flex-direction: column; // place everything underneath each other

    v-pane {
        transition: transform 0.5s;
        border-bottom: @border-solid-thin @sablono-grey-100;

        i {
            //float: right;
            transition: transform 0.5s;
        }

        &.is-expanded {
            > v-pane-header {
                i {
                    transform: rotate(90deg);
                    opacity: 1;
                }
                .header-option-name {
                    display: none;
                }
            }

            > v-pane-content {
                > div {
                    opacity: 1;
                    transform: translate3d(0, 0, 0);
                }
            }
        }
        // &.is-expanded

        &.is-expanded-add, &.is-expanded-remove {
            > v-pane-content {
                transition: max-height 0.5s;
            }
        }
        // &.is-expanded-add, &.is-expanded-remove
    }
    // v-pane

    v-pane-header {
        padding-right: 15px;
        padding-left: 15px;
        transition: color 0.25s, border-color 0.5s;
        font-size: @font-size-normal;
        color: @sablono-grey-700;
        /*************************/
        .header-option {
            flex-grow: 2;
            font-weight: @font-weight-bold;
            //overflow: visible;
        }
        /*************************/

        .sb-user-image {
            font-size: @font-size-smaller;
            height: 25px;
            width: 25px;
            justify-content: center;
        }

        div {
            display: flex; // important for v-pane-header text to ellipse if @filter-card-width is very small (like 150px)
            justify-content: space-between; // put arrow on the right side
            span {
                .ellipsed-text;
            }
            height: @small-list-height;
            align-items: center;
        }

        &::after, &::before {
            content: '';
            display: block;
            position: absolute;
            top: 50%;
            right: 0;
        }

        &::before {
            transform: rotate(-90deg);
        }

        &:hover {
            color: @sablono-grey-800;
        }
    }
    // v-pane-header

    v-pane-content {
        > div {
            opacity: 0;
            transform: translate3d(0, 30px, 0);
            transition: transform 0.5s, opacity 0.5s;
        }
    }
    // v-pane-content
}

// .filter-accordion
.sb-filter-heading{
    color: @sablono-grey-700;
    font-weight: @font-weight-thin;
    margin: 0 10px;
}
.sb-filter-card {
    font-size: @font-size-smaller;
    align-self: flex-start; // this replaced "display: table", so there is no unnecessary white space on the bottom of the card
    border-top: @border-solid-medium @sablono-green-main;

    md-list {
        padding: @padding-none;
        background-color: @sablono-grey-50;
        md-list-item {
            &:hover {
                background-color: @sablono-grey-50 !important;
            }
        }
    }

    // add color to note type icon if selected
    md-list-item {
        color: @sablono-grey-600;
        &:not(.selected) {
            md-icon {
                color: @sablono-grey-500;
            }
            .sb-user-image, .sb-icon-date-last-week {
                color: @sablono-grey-500;
                border-color: @sablono-grey-500;
            }
        }

    }

    md-list-item {
        min-height: @small-list-height;
        padding-left: 4px;

        // gives selected option a grey background + blue side border
        border-top: @border-solid-thin @sablono-grey-200;
        &.selected {
            border-left: @border-solid-medium @sablono-green-main;
            padding-left: @padding-none;
            background-color: @sablono-grey-50;
            /*************************/
            .md-list-item-inner {
                font-weight: @font-weight-bold;
            }
            /*************************/
        }

        .md-list-item-inner {
            min-height: @small-list-height;

            > md-icon:first-child {
                margin: 6px;
            }

            p {
                .ellipsed-text;
                flex-grow: 2;
            }

            .sb-user-image--mini {
                margin-right: 8px;
                font-size: @font-size-smaller;
                height: 25px;
                width: 25px;

            }
            /*************************/
            .time-filter {
                height: 25px;
                width: 25px;
                font-size: @font-size-smaller;
                margin-top: 1px !important;
            }
            /*************************/
        }
        // makes note type icons grey
        md-icon {
            margin: @margin-none @margin-small;
            font-size: @font-size-large;
            border: @border-none;
            padding-top: 3px;
        }
        // creator icons margin on the right side
        .sb-user-image {
            margin-right: 8px;
            font-size: @font-size-smaller;
            width: 25px;
            height: 25px;
        }

    }
    // md-list-item
}

// .sb-filter-card

.sb-filter-card-scroll-container {
    margin: 0 0 0 @margin-small;
    padding: 0 9px 1px 0;
    overflow-x: hidden;
    overflow-y: auto;
}

.sb-type-header-icon {
    height: 18px;
    width: 18px;
    margin-right: 2px;
}

.sb-duration-header-icon {
    font-size: @font-size-smaller;
    line-height: 22px;
    margin-right: 2px;
}
