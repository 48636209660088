@import "../../../../../styles/global";
@import "../../../../../styles/colorpalette";

.sb-schedule-stats-card {
    position: relative;
}

.sb-card__overlay {
    position: absolute;
    width: 100%;
    z-index: @z-indexes[panels];
}

.sb-components-schedule-stats__header, .sb-components-schedule-stats__template-selector {
    .flex-column;
    width: 100%;
}

.sb-template-bar-chart {
    display: flex;
    .sb-background-info {
        background-color: @white-background;
    }
}

.sb-chart__header-wrapper {
    z-index: @z-indexes[panels];
    justify-content: space-between;
}

.sb-chart__header {
    margin: 0;
    color: @primary-font-color;
    font-size:@font-size-normal;
    font-weight: @font-weight-normal;
    .ellipsed-text();
}

.sb-chart__subheader-label {
    color: @sablono-grey-600;
    .smaller-font();
}

.sb-chart__subheader-text {
    color: @sablono-grey-700;
    .small-font();
}

.sb-chart__header-content--text {
    .ellipsed-text();
}

.sb-template-bar-chart__card {
    max-height: 100vh;
    overflow: auto;
}
