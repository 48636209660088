@import "../../../../../styles/layout";
@import "./sbActivityName/activity_name";
@import "./sbActivityStateName/activity_state_name";
@import "./sbActivityLabour/activity_labour";
@import "./sbActivityDuration/activity_duration";
@import "./sbActivityAnnotation/activity_annotation";
@import "./sbActivityColor/activity_color";

@left-column-width: 200px;

.sb-activity-form {
    .flex-column;
}



.sb-activity-form__grouped-row {
    .flex-row;
}

.sb-form__row--align-end {
    align-items: flex-end;
    flex: 1;
}




.sb-activity-form__name-input {
    min-width: @left-column-width;
}

.sb-activity-form__state-input {
    flex: 5;
}

.sb-activity-form__color-input {
    flex: 2;
}




.sb-activity-form__annotation-input {
    display: flex;
    height: 100%;
}




.sb-activity-form__labour-input {
    display: flex;
    min-width: @left-column-width;
}

.sb-activity-form__team-input {
    display: flex;
    width: 100%;

    // align team input
    //
    margin-bottom: 16px;

    .sb-team-select__input {
        height: 45px;
    }

    // fix content size of team select
    //
    .sb-team-select {
        width: 100%;
        text-indent: 8px;
    }
}




.sb-activity-form__start-date-input {
    min-width: @left-column-width;
    float: right;
}

.sb-activity-form__duration-input {
    width: 100%;
}
