@import "../../../../styles/layout";
@import "../../../../styles/global";

@sb-process-viewer-deliverable-card-width: 300px;
@deliverable-card-height: 64px;
@deliverable-card-width: 250px;
@overlay-button-border-radius: 4px;

.sb-process-deliverable {
    .flex-row;
    justify-content: space-between;
    border-right: 1px solid @border-color;
    max-width: @sb-process-viewer-deliverable-card-width;
    width: @sb-process-viewer-deliverable-card-width;
    min-width: @sb-process-viewer-deliverable-card-width;
    padding: @padding-small;

    box-sizing: border-box;
    height: @deliverable-card-height;
}

.sb-process-deliverable__deliverable-details {
    .flex-column;
    max-width: @deliverable-card-width;
    justify-content: center;
}

.sb-process-deliverable__btn {
    .flex-column;
    outline-width: 0;
    justify-content: center;
    background: transparent;
    border-radius: @overlay-button-border-radius;
    font-size: @font-size-large;
    border: none;
    &:hover {
        background: @sablono-grey-300;
    }
    i {
        color: @sablono-green-main;
    }
}

.sb-process-deliverable__action {
    position: relative;
    top: 0;
    right: 0;
}

.sb-secondary-text {
    .smaller-font;
}

.sb-normal-text {
    .small-font;
}

.sb-activity-card--small {
    .sb-deliverable-card__inspection {
        display: none;
    }
}

.sb-activity-card--mini {
    .sb-deliverable-card__dates, .sb-deliverable-card__inspection, .sb-deliverable-card__tag-cloud {
        display: none;
    }
}
