@import "../../../../../styles/colorpalette";

@sb-inspection-badge-width: 55px;
@sb-inpection-badge-height: 50px;

.sb-inspection-badge{
    flex: 1;
    display: flex;
    width: @sb-inspection-badge-width;
    max-width: @sb-inspection-badge-width;
    height:@sb-inpection-badge-height;
    justify-content: center;
    align-items: center;
    padding: 0 0 0 @padding-small;
    position: relative;
    top: -1px;
}

.sb-inspection-badge-date {
    background-color: @sablono-green-text-color;
    border: 1px solid @sablono-light-steel;
    color: @sablono-light-steel;
    font-weight: @font-weight-normal;
    font-size: 10px;
    padding: 2px 4px;
    border-radius: 3px;
    cursor: default;
    line-height: 1em;
    position: absolute;
    bottom: -1px;
    left: -8px;
}