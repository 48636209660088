@import "../colorpalette";

// Give any toast a white background and dark text
//  https://sablono.atlassian.net/browse/SAB-9152
md-toast.md-default-theme .md-toast-content, md-toast .md-toast-content {
    background-color: @white-background;
    color: @primary-font-color;
}

.sb-toast {
    &.loading {
        padding-right: 5px;
    }
    &-undo {
        .sb-toast-action {
            border-left: 1px solid @sablono-grey-800;
            margin-left: 16px;
            &-button {
                margin-left: 8px !important;
            }
        }
    }
}
