@sb-site-diary-form-line-height: 34px;
@sb-site-diary-time-flex-basis: 90px;
@sb-site-diary-top-offset: 18px;
@sb-site-diary-unit-size: 24px;
@sb-time-picker-height: 83px;

.sb-site-diary__info-card {
    padding-bottom: 0;
}

.sb-site-diary__info-card-form {

    .md-input-message-animation {
        font-size: @font-size-smallest;
    }

    .sb-time-picker .md-text {
        font-weight: @font-weight-normal;
    }
}

.sb-site-diary__time-and-weather {
    .flex-row();

    .mdi-clock-outline {
        position: relative;
        top: @sb-site-diary-top-offset;
    }
}

.sb-site-diary__time {
    .flex-row();
    .flexit(auto);
}

.sb-site-diary__weather {
    .flex-row();
    flex-grow: 10;
    position: relative;
    top: @sb-site-diary-top-offset;
}

.sb-site-diary__department {
    width: calc(100% - @margin-smaller);
    margin: @margin-normal 0 0;
}

.sb-site-diary__input-container {
    .flexit(auto);
    margin: 0;
    position: relative;
    top: 0;
    height: @sb-site-diary-form-line-height;
}

.sb-site-diary__time-container, .sb-site-diary__temperature-container {
    flex: 0 @sb-site-diary-time-flex-basis;

    .sb-site-diary__input {
        text-align: right;
    }
}

.sb-site-diary__info-card-icon {
    font-size: @font-size-larger;
    margin-right: @margin-small;
    color: @sablono-grey-800;
}

.sb-site-diary__info-card-icon, .sb-site-diary__info-card-unit {
    line-height: @sb-site-diary-form-line-height;
    color: @sablono-grey-800;
}

.sb-site-diary__info-card .mdi-weather-partlycloudy {
    margin-left: @margin-normal;
}

.sb-site-diary__info-card-unit {
    margin-right: @margin-small;
    flex: 0 0 @sb-site-diary-unit-size;
}

.sb-site-diary__time-picker {
    height: @sb-time-picker-height;
}

.sb-site-diary__message {
    font-size: 10px;
    color: @riemi-red-500;
    transform: translate(0, -14px);
}
