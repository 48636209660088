@import "../../../../../../styles/colorpalette";

.sb-sidenav {
    background-color: @dark-background;
    color: @light-text-color;
    z-index: 80;

    md-content {
        background-color: @dark-background;
        color: @light-text-color;
    }
}

.sb-sidenav .md-button {
    border-radius: 0;
    margin: 0;
    padding: 0 16px;
    text-align: left;
    width: 100%;
}

.sb-sidenav .md-button.sb-sidenav-list-header {
    margin: 6px 0 12px 0;
}

.sb-sidenav-head-bar {
    cursor: pointer;
    height: @main-toolbar-height;
}

.sb-sidenav-head-bar-image {
    background-size: cover;
    background-position: 50% 50%;
    height: 200px;
}

.sb-sidenav-head-bar-image-project {
    display: flex;
    flex-direction: column;
    height: 100%;
    justify-content: flex-end;
}

.sb-sidenav-head-bar-image-project p {
    background-color: fade(@dark-background, 85);
    font-weight: @font-weight-normal;
    margin: 0;
    padding-bottom: 10px;
    padding-left: @padding-normal;
}

.sb-sidenav-head-bar-image-project p:first-child {
    padding-bottom: 2px;
    padding-top: 10px;
}

.sb-sidenav-project-code,
.sb-sidenav-project-name {
    font-weight: @font-weight-light;
    margin-left: @margin-small;
    .ellipsed-text;
}

.sb-sidenav-project-code {
    font-size: @font-size-small;
}

.sb-sidenav-project-name {
    font-size: @font-size-large;
    margin-top: 3px;
}

.sb-sidenav-progress,
.sb-sidenav-progress .md-mode-determinate,
.sb-sidenav-progress .md-bar {
    height: 6px;
}

.sb-sidenav-list {
    margin: 0;
    padding: 0;
}

.sb-sidenav-list-link {
    display: flex;
    height: 40px;
}

.sb-sidenav-list-link:disabled {
    .sb-sidenav-link-icon {
        color: @sablono-grey-300;
    }
}

.sb-sidenav-link-icon {
    min-width: 25px;
    text-align: left;
}

.sb-sidenav-link-new-badge::after {
    .sb-badge('new!', 10deg, @sablono-green-text-color, @sablono-green-main);

    position: absolute;
    right: 40px;
    top: 10px;
}

.sb-sidenav-link-pro-badge::after {
    .sb-badge('Pro', 10deg, @sablono-green-text-color, @sablono-green-main);

    position: absolute;
    right: 40px;
    top: 10px;
}

.sb-sidenav-link-beta-badge::after {
    .sb-badge('Beta', 10deg, @sablono-green-text-color, @sablono-green-main);

    position: absolute;
    right: 40px;
    top: 10px;
}

.sb-sidenav-link-trial-badge::after {
    .sb-badge('Trial', 10deg, @sablono-green-text-color, @sablono-green-main);

    position: absolute;
    right: 40px;
    top: 10px;
}

.sb-sidenav-list-menu-header,
.sb-sidenav-list-menu-item {
    text-transform: none;
}

.sb-sidenav-list-menu-header span {
    font-weight: @font-weight-bold;
}

.sb-sidenav-list-menu-header > i {
    padding-right: 12px;
}

.sb-sidenav-list-menu-item > i {
    padding-left: 32px;
}

.sb-sidenav-no-project {
    display: flex;
    font-size: 26px;
    padding: 8px 20px 14px 20px;
}

md-backdrop.md-opaque {
    z-index: 70;
}
