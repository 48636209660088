@full-height: 100%;
@full-width: 100%;

@sb-list-item-container-height: 56px;
@sb-list-item-height: 40px;

.fix(@property, @unit) {
        @{property}: @unit;
    max-@{property}: @unit;
    min-@{property}: @unit;
}

.sb-searchable-select {
    .flex-column();
    height: @full-height;
    width: @full-width;
    margin: @margin-none;
}

.sb-selectable-item-container {
    box-sizing: border-box;

    &:hover {
        background-color: @sablono-grey-300;
    }

    &.md-checked {
        background-color: @sablono-grey-300;
    }
}


.sb-selectable-item {
    padding: @padding-small @padding-none;
    margin-left: @margin-small;
    margin-bottom: @margin-none; // overwrite md-radio-button style
}

// toolbar overwrite for color and multiple rows in md-toolbar
//
md-toolbar.sb-searchable-select__toolbar {
    .flex-column();
    background-color: @sablono-green-main;
}

.sb-searchable-select__search {
    .flex-column-center();
}

.sb-searchable-select__search-field {
    width: @full-width;
}

.mdi.sb-close-icon {
    font-size: 18pt;
    vertical-align: initial;
}

.sb-searchable-select__list-item--selected {
    background-color: @sablono-grey-300;
}

.sb-searchable-select__item-header-wrapper {
    width: 100%;
    position: relative;
}

.sb-searchable-select__item-header {
    .font(small, bold);
    .uppercase();
    .wrapped-text();

    color: @primary-font-color;
    width: 70%;
    text-align: center;
    margin-left: auto;
    margin-right: auto;
}

.sb-searchable-select__button {
    position: absolute;
    transform: scale(0.7);
    color: @primary-font-color;
}

.sb-searchable-select__button--place-nicely-next-to-header {
    right: 0;
    top: -14px;
}

.sb-searchable-select__button--place-nicely-in-autocomplete {
    top: 12px;
    right: 50px;
}
