@sb-deliverable-notes-cell-width-small: 30px;
@sb-deliverable-notes-cell-width-medium: 150px;
@sb-deliverable-notes-cell-width-medium-tablet: 100px;
@sb-deliverable-notes-cell-width-date-badge: 65px;
@sb-deliverable-notes-photos-container-height: 160px;
//⬇️ This has to be aligned with vm.attachmentsVisible in the controller
@sb-deliverable-notes-photos-per-row: 6;
.sb-deliverable-notes-row-container {
    font-size: 14px;
    display: flex;
    flex-direction: column;
    flex: 1;
    background: @white-background;
    &.sb-row-is-openable {
        .sb-deliverable-notes-row {
            cursor: pointer;
        }
    }
    .sb-deliverable-notes-row {
        display: flex;
        flex-direction: row;
        align-items: center;
        flex-wrap: nowrap;
        align-self: flex-start;
        width: 100%;
        color: @sablono-grey-600;
        font-weight: @font-weight-normal;
        border-bottom: 1px solid @sablono-grey-200;
        padding: @padding-smaller @padding-normal;
        position: relative;
        outline-width: 0;
        box-sizing: border-box;
        .sb-deliverable-notes-cell {
            display: flex;
            flex-direction: column;
            padding: 0 @padding-small;
            .ellipsed-text();
            > div {
                display: flex;
                flex-direction: row;
            }
            .sb-deliverable-notes-cell-field-name {
                align-self: flex-end;
                padding-bottom: 1px;
                margin-right: @margin-small;
            }
        }
        .sb-deliverable-cell-open-ability {
            flex: 0 0 @sb-deliverable-notes-cell-width-small;
            i {
                transition: 400ms;
            }
        }
        .sb-deliverable-notes-cell-field-name {
            font-size: @font-size-smaller;
            color: @sablono-grey-500;
            display: flex;
        }
        .sb-deliverable-notes-cell-team-value {
            .ellipsed-text();
            font-size: @font-size-smaller;
        }
        > div {
            flex: 1;
            flex-direction: column;
            align-content: center;
            justify-content: center;
        }
        .sb-deliverable-notes-cell-date{
            max-width: @sb-deliverable-notes-cell-width-date-badge;
            padding-left: @padding-small;
            display: flex;
            flex-direction: row;
        }
        .sb-deliverable-notes__edited-badge{
            top: 10px;
            position: relative;
        }
        .sb-deliverable-notes-cell-icon {
            max-width: @sb-deliverable-notes-cell-width-small;
            .sb-claim {
                color: @note-color-quality-issue;
            }
            .sb-obstruction {
                color: @note-color-obstruction;
            }
            .sb-info {
                color: @note-color-info;
            }
            .sb-issue-closed {
                color: @sablono-grey-400;
            }
        }
        .sb-deliverable-notes-text-cell {
            flex: 3;
            word-break: break-word;
            overflow-wrap: anywhere;
            padding:0 @padding-small;
        }
        .sb-deliverable-notes-code-cell {
            flex: 2;
        }
        .sb-deliverable-notes-cell-date {
            .sb-inspection-badge {
                position: relative;
                top: -5px;
            }
        }
        i {
            display: inline-block;
            font-size: @font-size-icon-normal;
        }
    }
    .sb-deliverable-notes-row-container__loading {
        height:200px;
    }
    .sb-deliverable-notes-row-container__photos {
        height: 0;
        opacity: 0;
    }
    &.sb-is-open {
        .sb-deliverable-cell-open-ability i {
            transform: rotateZ(90deg);
        }
        .sb-deliverable-notes-row-container__photos {
            height: auto;
            opacity: 1;
        }
    }

    @media @mq-tablet{
        .sb-deliverable-notes-row{
            padding: 0;
            .sb-deliverable-notes-cell > div{
                flex-direction: column;
                .sb-deliverable-notes-cell-field-name{
                    align-self: flex-start;
                }
            }
            .sb-deliverable-notes-text-cell{
                font-size: @font-size-small;
            }
        }
    }

}

.sb-deliverable-notes__state-change{
    &.not-visible {
        visibility: hidden;
        display: block!important;
    }
}

.sb-deliverable-notes__teams-section {
    display: flex;
    width: 100%;
}

.sb-deliverable-note__row-actions {
    align-items: center;
}
