@import "../../../../../styles/global";

@sb-activity-set-conf-dialog-max-width: 600px;

.sb-deliverable-set-config-dialog {
    max-width: @sb-activity-set-conf-dialog-max-width;
    min-width: @sb-activity-set-conf-dialog-max-width;
}

.sb-dialog .sb-deliverable-set-config-dialog__options {
    padding: @padding-none;
    margin: @margin-none;
}
