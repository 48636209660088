.sb-deliverable-notes {
    md-list, md-list-item{
        padding: 0;
    }
    .md-subheader {
        top: 40px !important; // to not overflow the summary header (important because the element has an element style of top: 0)
    }
    .sb-deliverable-notes-summary {
        .sb-sticky-header;
        z-index: 3; // because the md-subheader is sticky with z-index 2
        background-color: @sablono-grey-50;
        display: flex;
        flex-direction: row;
        padding: 0 @padding-normal;
        justify-content: space-between;
        h3 {
            font-weight: @font-weight-light;
            color: @sablono-grey-600;
            padding-right: @padding-small;
            margin: 0 0 4px 0;
        }
        &.sb-deliverable-notes-no-issues-happy {
            .sb-all-issues-resolved-text {
                display: flex;
                align-items: center;
                h3 {
                    color: @sablono-green-800;
                }
                i {
                    color: @sablono-green-800;
                    margin-left: 5px;
                    font-size: 20px;
                }
            }
        }
    }
    .sb-info-summary-notes {
        display: flex;
        flex-direction: row;
        font-size: @font-size-smaller;
        .sb-info-summary-value {
            display: flex;
            flex-direction: row;
            align-items: center;
            span {
                padding-right: 4px;
            }
            i {
                padding-right: 12px;
            }
        }
        .sb-claim {
            color: @note-color-quality-issue;
        }
        .sb-obstruction {
            color: @note-color-obstruction;
        }
        .sb-info {
            color: @note-color-info;
        }
        .sb-issue-closed {
            color: @sablono-grey-400;
        }
    }
}
.sb-deliverable-notes-no-issues {
    i {
        font-size: 100px;
        padding-bottom: 0;
    }
    h1 {
        margin-top: @padding-normal;
        margin-bottom: 0;
        color: @sablono-grey-500;
    }
    span {
        color: @primary-font-color;
    }
}

.sb-deliverable-notes__header-layout {
    max-height: 55px;
    display: flex;
    flex-grow: 1;
    flex-direction: row;
    align-items: center;
}

.sb-deliverable-notes__header {
    .flex-column();
}

.sb-deliverable-notes__add-issue-btn {
    background: @sablono-green-main;
    border-radius: 50%;
    margin-left: @margin-small;
    height: 20px;
    width: 20px;
    outline-width: 0;
    font-size: 18px;
    color: @sablono-grey-50;
    cursor: pointer;
}


