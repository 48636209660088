@import "../../../../../styles/colorpalette";
@import "../../../../../styles/global";

@sb-activity-card-bg: @sablono-grey-300;
@sb-activity-card-height: 40px;
@sb-activity-card-action-button-size: 25px;
@sb-activity-card-border-width: 2px;
@sb-activity-card-info-topologicalindex-height: 13px;
@sb-activity-card-info-topologicalindex-border-radius: 25%;
@sb-activity-card-progress-icon-margin-top: -1px;
@sb-activity-card-progress-icon-margin-left: -7px;
@mini-mode-height: 20px;

// default inner styling applied to every activity card
//
.sb-activity-card{
    margin: @margin-none @margin-smaller @margin-small;
    min-height: @sb-activity-card-height;
    height: @sb-activity-card-height;
    background: @sb-activity-card-bg;
    .flex-column-center;
    position: relative;
    border-width: @sb-activity-card-border-width;
    border-style: solid;
    box-sizing: border-box;
    @sb-activity-card-overflow-width: 15px;
    cursor: pointer;
    outline: none;
    color: @sablono-grey-700;

    // show left arrow on overflowing activity
    //
    &.sb-activity-card--overflows--right{
        margin-right:@sb-activity-card-overflow-width - 2;
        &:after{
            content: "";
            position: absolute;
            right: -(@sb-activity-card-overflow-width - @sb-activity-card-border-width);
            top:-@sb-activity-card-border-width;
            width: 0;
            height: 0;
            border-left-width: @sb-activity-card-overflow-width - (2 * @sb-activity-card-border-width);
            border-color: inherit;
            border-left-style: solid;
            // important: these two always after the border-left properties
            border-bottom: (@sb-activity-card-height / 2) solid transparent;
            border-top: (@sb-activity-card-height / 2) solid transparent;
        }
        .sb-activity-card__move-right{
            display: none;
        }
    }

    // show right arrow on overflowing activity
    //
    &.sb-activity-card--overflows--left{
        margin-left: @sb-activity-card-overflow-width - 2;
        &:before{
            content: "";
            position: absolute;
            left: -(@sb-activity-card-overflow-width - @sb-activity-card-border-width);
            top:-@sb-activity-card-border-width;
            width: 0;
            height: 0;
            border-right-width: @sb-activity-card-overflow-width - (2 * @sb-activity-card-border-width);
            border-color: inherit;
            border-right-style: solid;
            // important: these two always after the border-right properties
            border-top: (@sb-activity-card-height / 2) solid transparent;
            border-bottom: (@sb-activity-card-height / 2) solid transparent;
        }
        .sb-activity-card__move-left{
            display: none;
        }
    }
}

.sb-lane__row--first .sb-activity-card{
    margin-top: @margin-small;
}

// first row inside card displaying progress status and activity name or state name
//
.sb-activity-card__info{
    @sb-activity-card-details-offset: 8px;
    width: 100%;
    height: 100%;
    .flex-row;
    justify-content: flex-start;

    position: absolute;
    max-width: 100%;
}

.sb-activity-card__text-container{
    width: 100%;
    .ellipsed-text;
}

.sb-activity-card__info-name{
    font-size: @font-size-small;
    font-weight: @font-weight-normal;
    padding-bottom: 1px;
}

.sb-activity-card__info-name--mini {
    display: none;
    padding-left: @padding-smaller;
}

.sb-activity-card__info--loading{
    height: 35px;
}

.sb-shown_on_hover{
    height:0;
    overflow: hidden;
    transition: height 0.1s ease-in-out;
    &.visible{
        height: @sb-activity-card-action-button-size;
    }
}
.sb-round_button{
    background: @sablono-grey-50;
    border-radius: 50%;
    outline-width: 0;
    font-size: 20px;
    color:@sablono-grey-600;
    .flex-column-center;
    &:hover{
        background: @sablono-grey-200;
        cursor: pointer;
    }
}
.sb-activity-card__move-right,
.sb-activity-card__move-left{
    .sb-shown_on_hover;
    .sb-round_button;
    position: absolute;
    top:(@sb-activity-card-height - @sb-activity-card-action-button-size)/2;
    width:0;

    &.visible{
        width: @sb-activity-card-action-button-size;
        border:1px solid @sablono-grey-500;
    }
}
.sb-activity-card__actions{
    .sb-shown_on_hover;
    position: absolute;
    top:-@sb-activity-card-action-button-size;
    .flex-row;
    left: 0;
    &.visible{
        .sb-activity-card__actions__button{
            height: @sb-activity-card-action-button-size;
            width: @sb-activity-card-action-button-size;
            border:1px solid @sablono-grey-500;
        }

    }

}
.sb-activity-card__actions__button{
    .sb-round_button;

}
.sb-activity-card__move-right{
    right: -16px;
    i{
        position: relative;
        top:1px;
        left: 1px;
    }
}
.sb-activity-card__move-left{
    left: -16px;
    i{
        position: relative;
        top:1px;
        right: 1px;
    }
}
.sb-activity-card__info--main{
    .flex-row-center-start();
    width: 100%;
    height: 50%;
}

.sb-activity-card--mini .sb-activity-card__info--main{
    height: 100%;
}

// second row inside card displaying overall duration of activity and
// assigned team with name and color
//
.sb-activity-card__info--details{
    .flex-row;
    justify-content: flex-start;
    width: 100%;
    max-width: 100%;
    color: @sablono-grey-600;
    padding-left: @padding-smaller;
    font-size: @font-size-smaller;
    overflow: hidden;
    background-color: inherit;
}

.sb-activity-card__information{
    border-right:1px solid @sablono-grey-400;
    padding-right:@padding-smaller;
    .mdi{
        color: @sablono-grey-500;
    }
}

.sb-activity-card__tag-cloud{
    align-self: center;

    .sb-tags {
        flex-wrap: nowrap;
    }
    .sb-tag{
        padding: 0;
        background: none;
        border: none;
        color: inherit;
        .mdi{
            font-size:@font-size-smaller;
            color: inherit;
        }
    }
}

.sb-activity-card__info--duration{
    .sb-activity-card__information;
    border-right: none;
    background-color: inherit;
}

.sb-activity-card__info--topologicalindex{
    .smaller-font();
    border: @border-solid-thin;
    height: @sb-activity-card-info-topologicalindex-height;
    line-height: @sb-activity-card-info-topologicalindex-height;
    border-radius: @sb-activity-card-info-topologicalindex-border-radius;
    padding: @padding-none @padding-tiny;
    margin: -1px @margin-smaller 0;
}

.sb-activity-card--mini .sb-activity-card__info--topologicalindex {

}

.sb-activity-card__info--labour{
    .sb-activity-card__information;
    border-right: none;
}
.sb-activity-card__info--team{
    .ellipsed-text;
}

.sb-activity-card__info--late, .sb-activity-card__tag-cloud{
    display: flex;
    color: @riemi-red-800;
}
.sb-activity-card__jump-to-monday{
    display: none;
    text-transform: uppercase;
    font-size: @font-size-smaller;
    position: absolute;
    z-index: @z-indexes[background];
    top:4px;
    right: 4px;
    padding: @padding-smaller @padding-small;
    background: rgba(255,255,255,0.9);
    cursor: pointer;
    border-radius: 3px;
    outline-width: 0;
    border: 1px solid @sablono-grey-500;
    &:hover{
        color: @sablono-grey-800;
        background: @sablono-grey-50;
        font-weight: @font-weight-bold;
    }
}

.sb-activity-card:hover .sb-activity-card__jump-to-monday {
    display: block;
}

.sb-activity-card__date-diff {
    @date-diff-offset-top: -6px;
    @date-diff-offset-right: -7px;

    position: absolute;
    background: @white-background;
    top: @date-diff-offset-top;
    right: @date-diff-offset-right;
    padding: 0 @padding-smaller;
    color: @sablono-grey-900;
    z-index: @z-indexes[background]
}

.sb-activity-card--mini {

    .sb-activity-card {
        .sb-activity-card-height-by(@mini-mode-height);
        margin-top: @margin-none;
        margin-bottom: @margin-smaller;
    }

    .sb-lane__row--first .sb-activity-card{
        margin-top: @margin-smaller;
    }

    .sb-activity-card__info--details {
        display: none;
    }

    .sb-activity-card__info-name--mini {
        display: block;
    }

    .sb-activity-card--overflows--left{
        &:before{
            .sb-activity-card-borders-by(@mini-mode-height);
        }
    }

    .sb-activity-card--overflows--right{
        &:after{
            .sb-activity-card-borders-by(@mini-mode-height);
        }
    }

    .sb-activity-card__move-right,
    .sb-activity-card__move-left{
        top:(@mini-mode-height - 2px - @sb-activity-card-action-button-size)/2;
    }
    .sb-activity-card__text-container {
        line-height: 15px;
    }

}

.sb-activity-card-borders-by(@mode) {
    border-bottom: (@mode / 2) solid transparent;
    border-top: (@mode / 2) solid transparent;
}

.sb-activity-card-height-by(@mode) {
    height: @mode;
    min-height: @mode;
}

.sb-activity-card--not-completed {
    box-shadow: 1px 1px 3px 0 rgba(0,0,0,0.3);
}

.sb-activity-card__progress-icon {
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: white;
    border-radius: @sb-activity-card-info-topologicalindex-border-radius;
    i {
        font-size: @font-size-normal;
    }
}
