@import "../../../../../styles/global";
@import "../../../../../styles/colorpalette";

@sb-menu-filter--number-of-visible-options: 8;
@sb-menu-filter--option-height: 48px;
@sb-menu-filter--search-height: 48px;
@sb-menu-filter--max-height: (@sb-menu-filter--number-of-visible-options * @sb-menu-filter--option-height + @sb-menu-filter--search-height);

.sb-filter-menu md-input-container {
    width: 100%;
}

.sb-menu-filter__container {
    md-select-menu{
        max-height: @sb-menu-filter--max-height;
        md-content{
            max-height: @sb-menu-filter--max-height;
        }
    }
    md-select-menu:not(.md-overflow) md-content {
        padding-top: 0;
    }
}

.sb-menu-filter__search {
    // stick full size position on top of list
    position: sticky;
    top: 0;
    height: 48px;
    // make sure the opt list is behind the search
    z-index: 2;

    border-bottom: 1px solid @sablono-grey-300;
    background-color: @white-background;
    cursor: pointer;
    display: flex;
}
.sb-menu-filter__search-input {
    font-style: italic;

    border: none;
    outline: none;
    height: 100%;
    width: 100%;
    padding: 0;
    // align the item padding with the search
    margin-left: @margin-normal;
}
