.sb-issue-report {
    max-width: 700px;
}
.sb-issue-report__pdf-with-images-choice .sb-choice-second-icon {
    left: unset;
    right: -6px;
    bottom: -6px;
    color: @sablono-grey-500;
    background-color: @white-background;
    border-radius: 0.2em;
}
