.sb-user-image {
    width: 40px;
    height: 40px;
    border-radius: 50%;
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 16px;
    overflow: hidden;
    user-select: none;
    outline: none;
    background-color: transparent;
    color: @sablono-light-steel;
    border-color: @sablono-light-steel;
    border: 1px solid;
}

.sb-user-image--filled{
    .sb-user-image {
        background-color: @sablono-green-main;
        color: @white-background;
        border: none;
    }
}

.sb-user-image--mini {
    .sb-user-image {
        height: 30px;
        width: 30px;
        font-size: 15px;
    }
}
