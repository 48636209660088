.sb-elastic-input-ruler{
    visibility: hidden;
    white-space: normal;
    position: absolute;
    top:0;
    left:0;
    height: 0!important;
    border:1px solid transparent;
}
.sb-elastic-input-container{
    margin: 0;
    padding: 0;
    height:24px;
    // If non editable, make it look normal
    // @todo: check why ng-disabled and disabled are not working
    .md-input.sb-elastic-input.disabled{
        color: @sablono-grey-800;
        pointer-events: none;
        border-bottom: 1px solid transparent;
    }
    .sb-elastic-input{
        .ellipsed-text;
    }
    // If editable, add hover effects
    &.editable{
        .md-errors-spacer{
            min-height: 12px;
        }
        .md-input-message-animation{
            background: rgba(255,255,255,0.7);
            padding-top: 0;
        }
        &:not(.md-input-invalid){
            .sb-elastic-input{
                outline-width: 0;
                box-sizing: border-box;
                border-bottom:1px solid @sablono-green-main;
                margin-bottom:@margin-smaller;
                &:hover{
                    outline:1px dashed @sablono-green-main;
                    border-bottom:1px solid @sablono-green-main;
                    &:focus, &:active{
                        border:none;
                        border-bottom: 1px solid @sablono-green-main;
                    }
                }
                &:focus, &:active{
                    border-bottom: 1px solid @sablono-green-main;
                }
            }
        }
    }

}
