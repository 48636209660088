@import "../../../../../styles/global";
@import "../../../../../styles/colorpalette";
@import "../../../../../styles/layout";
@import "../../../../../styles/sb-common/tag";

@sb-deliverable-content-cell-height: 40px;
@sb-deliverable-content-cell-small-width: 60px;
@sb-deliverable-content-cell-normal-width: 114px;

sb-deliverable-row {
  width: 100%;
}

// Helper classes
// Extends the ellipsed-text mixin adding the align-self:self attribute
.ellipsed-text-deliverable-row {
    align-self: stretch;
    overflow: hidden;
    span {
        float: left;
        max-width: 100%;
        .ellipsed-text();
    }
}
.sb-deliverable-row-secondary{
    color: @sablono-grey-500;
    .smaller-font();
}
.sb-deliverable-row-primary{
    color: @sablono-grey-700;
    .small-font();
}
// Table cells
.sb-deliverable-row {
    display: flex;
    flex-direction: row;
    align-items: center;
    padding: @padding-small/2 @padding-small;
    border-bottom: 1px solid @sablono-grey-200;
    flex-wrap: wrap;
    border-left: 4px solid;
    &.sb-deliverable-row-no-progress{
        border: 1px solid @sablono-grey-300;
        border-bottom: none;
        &:hover{
            cursor: default;
        }
        .sb-workflow{
            max-width:@sb-deliverable-content-cell-normal-width * 1.5;
        }
    }
    &:focus{
        outline: none;
    }
    &:hover{
        cursor: pointer;
    }
    // Bg effect. On hold for now
    /*
    &.sb-task-has-claims,
    &.sb-task-has-obstructions{
        background:rgba(255, 185, 0, 0.09);
        .sb-progress b,
        .sb-progress-value{
            background:rgba(255, 185, 0, 0.09);
        }
    }
    &.sb-task-behind{
        background: rgba(255, 229, 229, 0.5);
        .sb-progress b,
        .sb-progress-value{
            background: rgba(255, 229, 229, 0.5);
        }
    }*/
}
.sb-deliverable-cell {
    font-size: @font-size-small;
    border-collapse: collapse;
    display: flex;
    flex: 1;
    text-align: left;
    width: 100%;
    min-width: @sb-deliverable-content-cell-normal-width;
    height: @sb-deliverable-content-cell-height;
    align-items: center;
    justify-content: flex-start;
    padding: 2px @padding-small;
    overflow: hidden;
    &.sb-tags{
        justify-content: flex-end;
    }
}
.sb-deliverable-cell-rows {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: flex-start;
    overflow: hidden;
}
// Styling of the columns
.sb-deliverable-row{
    .sb-code {
        .sb-deliverable-row-secondary();
        .ellipsed-text-deliverable-row();
    }
    .sb-name {
        .sb-deliverable-row-primary();
        .ellipsed-text-deliverable-row();
    }
    .sb-issue-warning{
        display: none;
        font-size: @font-size-normal;
        padding: 0;
        z-index: 1;
        position: absolute;
        left: 48px;
        bottom: 10px;
        background: @white-background;
    }
    .sb-progress {
        justify-content: center;
        align-items: center;
        font-weight: @font-weight-bold;
        max-width: @sb-deliverable-content-cell-small-width;
        min-width: @sb-deliverable-content-cell-small-width;
        position: relative;
    }
    .sb-progress-value{
        font-size: 11px;
        position: absolute;
        left: 40px;
        bottom: 6px;
        color: @sablono-light-steel;
        background: @white-background;
        padding-right: 2px;
        z-index: 1;
    }
    .sb-progress-icon{
        font-size: @font-size-icon-normal;

    }
    .sb-dates {
        font-size: @font-size-small;
        font-weight: @font-weight-normal;
        text-align: center;
        color: @sablono-grey-500;
        justify-content: center!important;
        align-items: flex-start;
        max-width: @sb-deliverable-content-cell-normal-width * 1.5;
    }
    .sb-structure {
        .sb-deliverable-row-secondary();
        .ellipsed-text-deliverable-row();
    }
    .sb-description {
        .sb-deliverable-row-primary();
        .ellipsed-text-deliverable-row();
    }
    .sb-workflow {
        justify-content: flex-end;
        align-items: center;
        position: relative;
        text-align: right;
        .sb-deliverable-row-primary();
        // Commented till we implement the navigation to the template
        /*a{
            text-decoration: none;
            color: @sablono-grey-700;
            &:hover{
                color: @primary-font-color;
            }
        }*/
    }
    .sb-inspection{
        white-space: nowrap;
        .sb-deliverable-row-secondary();
    }


    // I moved the logic of displaying values here, to avoid rendering issues while evaluating conditions all the time.
    // We already have all the classes needed to toggle the display.
    &.sb-task-done, &.sb-task-not-started{
        .sb-progress-value{
            display: none;
        }
        &.sb-task-has-claims,
        &.sb-task-has-obstructions{
            .sb-issue-warning{
                display:block;
            }
        }
    }


    &.sb-deliverable-row-mini{

        padding: 4px 2px 4px 0;
        .sb-deliverable-cell {
            padding: 2px 4px;
            &.sb-dates{
                max-width: 80px;
                min-width: 80px;
            }
        }
        .sb-progress{
            max-width: 40px;
            min-width: 40px;
            width: 40px;
            padding: 0 @padding-normal 0 0;
            .sb-progress-value{
                left:20px;
            }
            .sb-issue-warning{

            }
        }
    }
}

@media screen and(max-width: @media-md){
    .sb-deliverable-row{
        padding: 4px 0 14px;
        position: relative;
        .sb-tags{
            display: none;
        }
        .sb-progress b{
            font-size: @font-size-small;
            width: @sb-deliverable-content-cell-small-width;
        }
        .sb-dates{
            min-width: @sb-deliverable-content-cell-normal-width;
        }
        .sb-workflow{
            text-align: left;
            justify-content: flex-start;
            align-items: flex-end;
        }
    }
    .sb-deliverable-cell-rows{
        min-width: @sb-deliverable-content-cell-small-width;
        align-items: flex-start;
        justify-content: center;
    }
}

.sb-delayed-days-indicator {
    .sb-tag;
    .sb-tag-behind;

    font-weight: @font-weight-bold;
    white-space: nowrap;
    margin-left: @margin-small;
}

.sb-at-risk-indicator {
    .sb-tag;
    .sb-tag-open-claims;

    font-weight: @font-weight-bold;
    white-space: nowrap;
    margin-left: @margin-small;
}
