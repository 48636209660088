@sb-upload-headers-height: 64px;
.sb-upload-preview-container{
    display: flex;
    // 8px = padding
    height:100%;

    sb-table{
        height: calc(100% ~"-" @sb-upload-headers-height);
        top:64px;
    }
    .sb-upload-preview-structure{
        z-index: 2;
        display: flex;
        flex:35;
        flex-direction: column;
        border-right:1px solid @sablono-grey-400;
        // - toolbar + padding
        height: 100%;
        md-list{
            padding: 0;
        }
    }
    .sb-upload-preview-content{
        display: flex;
        flex-direction: column;
        flex:90;
        // - toolbar + padding
        height: 100%;
        overflow: hidden;
    }

}
.sb-upload-preview-message{
    position: absolute;
    top: 20%;
    left: 10%;
    right: 10%;
    color: @sablono-grey-500;
    font-size: @font-size-larger;
    font-weight: @font-weight-light;
}

.sb-upload-preview-headers{
    color: @sablono-dark-steel;
    height:@sb-upload-headers-height;
    min-height: @sb-upload-headers-height;
    box-sizing: border-box;
    .md-toolbar-tools, &.md-toolbar-tools{
        background: @gray-background;
        border-bottom:1px solid @sablono-grey-400;
    }
    i{
        color:@sablono-light-steel;
    }
    span{
        .ellipsed-text();
        font-size: @font-size-large;
        font-weight:@font-weight-light;
    }
    .not-selectable;
}

.sb-upload-preview-structure-content{
    height:calc(100% ~"-" @sb-upload-headers-height);
    min-height: calc(100% ~"-" @sb-upload-headers-height);
}
