@import "colorpalette";
@import "fonts";
@import "global";

.md-button, md-button {
    border-radius: @primary-button-corner-radius;
    font-weight: @font-weight-normal !important;
    padding: 0 10px 0 10px;
}

// Highlight
.md-button.md-primary {
    background: @sablono-green-main;
    color: @sablono-green-text-color;
    border: 1px solid @sablono-green-main;

    &:not([disabled]):hover {
        background: darken(@sablono-green-main, 5%);
    }

    &:focus {
        color: @sablono-green-main;
    }

    &[disabled] {
        background: @sablono-grey-600;
        border: 1px solid @sablono-grey-600;
        color: @sablono-grey-300;

    }
}

// Lowlight (default)
.md-button.sb-default {
    color: @sablono-green-main;
    border: 1px solid @sablono-green-main;

    &:not([disabled]):hover {
        background: darken(@sablono-green-main, 5%);
        color: @sablono-green-text-color;
    }

    &[disabled] {
        border: 1px solid @sablono-grey-600 !important;
        color: @sablono-grey-300 !important;
    }
}

// custom sablono theme idea -> all icon buttons are green with transparent background
.md-button.md-icon-button {
    color: @sablono-green-main;
}

.md-button:not([disabled]).md-icon-button:hover {
    color: @sablono-green-text-color;
    background-color: @sablono-green-button;
}

.md-button.md-icon-button.md-primary {
    color: @sablono-green-text-color;
    i {
        font-size: 22px;
    }
}
.md-button:not([disabled]).md-icon-button.md-primary:hover {
    color: @sablono-green-text-color;
    background-color: @sablono-green-button-hover;
    border-color: @sablono-green-button-hover;
}

.sb_button__warning {
    color: @light-text-color;
    background: @riemi-red-500;
    &:hover,
    &:not([disabled]):hover {
        background-color: @riemi-red-600;
        color: @riemi-red-50;
    }
}

/**
 * Create a visually grouped set of buttons.
 */
.sb-button-group {
    .flex-row-center();
    box-sizing: border-box;

    padding-left: @padding-small;
    padding-right: @padding-small;
}

@primary-button-corner-radius: 30px;
@button-group-margin-overwrite: 20px 0;

// overwrite md-button margin to bring the grouped button together
//
.sb-button-group__button {
    margin: @button-group-margin-overwrite;

    &.md-button:not(.active) {
        background-color: transparent;
        color: @sablono-green-main;
    }

    &.md-button.active {
        color: @sablono-green-text-color;
    }

    &.md-button:not(.active):hover {
        color: @sablono-green-text-color;
    }

    // remove shadow if raised button is used
    //
    &.md-button.md-raised:not([disabled]) {
        box-shadow: none;
    }
}

.md-button.sb-button-group__button--left {
    border-top-right-radius: 0;
    border-bottom-right-radius: 0;
}

.md-button.sb-button-group__button--middle {
    border-radius: 0;
}

.md-button.sb-button-group__button--right {
    border-top-left-radius: 0;
    border-bottom-left-radius: 0;
}
