@import "../../../../../styles/global";
@import "../../../../../styles/layout";
@import "../../../../../styles/fonts";

@sb-svg-fullscreen-width: 300px;
@toolbar-height: 64px;
@subbar-height: 48px;
@fullscreen-header-height: 48px;
@fullscreen-entry-key-move-right: 20px;

// made to fit the fullscreen correctly in the 2D visualization page (see sketch_visualization.less)
//
@vertical-whitespace: 116px;

@sb-hatched-fullscreen-size: 11px;

.sb-svg-fullscreen {
    .flex-column();

    padding: @padding-none;
    margin: @margin-none;
    min-width: @sb-svg-fullscreen-width;

    background: @sablono-grey-50;

    align-items: center;
    justify-content: center;
}

.sb-svg-fullscreen__button {
    border-radius: 50%;
    padding: @padding-smaller;
    margin: @margin-tiny;
    width: auto !important;
    background: @sablono-grey-200;
    transform: scale(0.75);
    &:hover {
        background: @sablono-grey-300;
    }
}

.sb-svg-fullscreen__entries {
    .flex-column();
    min-width: @sb-svg-fullscreen-width - @margin-big;
    max-height: calc(
        100vh - @toolbar-height - @subbar-height - @fullscreen-header-height - @vertical-whitespace
    );


    overflow-x: hidden;
    overflow-y: auto;
}

.sb-svg-fullscreen__entry {
    .flex-row();
    flex-shrink: 0;
    position: relative;
    max-width: @sb-svg-fullscreen-width - @fullscreen-entry-key-move-right - @padding-big;

    .font(smaller);

    // puts margin on all except the first element
    ~ .sb-svg-fullscreen__entry {
        margin-top: @margin-small;
    }
}

.sb-svg-fullscreen__entry__key,
.sb-svg-fullscreen__entry__icon {
    &::first-letter {
        .uppercase();
    }
}

.sb-svg-fullscreen__buttons-wrapper {
    .flex-row();
}

.sb-svg-fullscreen__entry-text {
    margin-top: auto;
    margin-bottom: auto;
}
