@import '../../../../../styles/global';
@import '../../../../../styles/fonts';
@import '../../../../../styles/layout';
@import '../../../../../styles/colorpalette';

.sb-config-card {
    .flex-column();
    justify-content: space-between;

    color: @sablono-grey-600;

    &:hover, &:focus{
        cursor: pointer;
        background: @sablono-grey-100;
    }

    // overwrite default browser link color and highlighting
    & a {
      color: inherit;
      text-decoration: inherit;
    }
}

.sb-config-card__content {
    padding-bottom: @padding-none;
}

.sb-config-card__header {
    .flex-column-center();
    justify-content: space-around;
    height: 162px;
}

.sb-config-card__header-icon {
    font-size: @font-size-icon-huge;
    color: @sablono-grey-400;
}

.sb-config-card__header-text {
    .small-thin-font();
}

.sb-config-card__title {
    margin: @margin-none;
    padding-top: 12px;
    .large-thin-font();
    color: @primary-font-color;
    .ellipsed-text;
}

.sb-config-card__footer {
    .flex-row();
    align-items: center;
    justify-content: space-between;
}

.sb-config-card__details {
    font-size: @font-size-small;
}

.sb-config-card__delete-action {

    &:hover, &:focus{
        .mdi-delete {
            color: @riemi-red-500;
        }
    }
}
