.sb-combine_cost_group_activities__content {
    display: flex;
    overflow-y: hidden;
}

.sb-combine_cost_group_activities__activities-list {
    overflow-y: auto;
    margin-bottom: 8px;
}

.sb-combine_cost_group_activities__state-select {
    margin: unset;
}
