@import "./../../../styles/global";
@import "./../../../styles/colorpalette";
@import "sbCalendarDaysList/calendar_days_list";
@import "sbSchedulingMode/scheduling_mode";

@sb-project-cards-container-width: 700px;
@sb-project-cards-background-color: @white-background;

.sb-project-settings-cards-container {
    // IE needs this
    width: @sb-project-cards-container-width;
    min-width: @sb-project-cards-container-width;
    max-width: @sb-project-cards-container-width;
    @media(max-width: @sb-project-cards-container-width){
        width: 100%;
        min-width: 100%;
    }
    .sb-project-settings-card-title {
        color: @sablono-grey-600;
        font-weight: @font-weight-light;
        margin-bottom: @margin-smaller;
    }
}

.sb-project-settings-card {
    position: relative;
    background-color: @sb-project-cards-background-color;
    margin: @margin-small 0 @margin-big 0;
    .errors-container {
        .flex-row-center;
        padding-left: @padding-normal;
        font-size: @font-size-small;
        color: @riemi-red;
        font-weight: @font-weight-bold;
        i {
            margin-right: @margin-smaller;
        }
    }
    .md-button.md-accent{
        float: right;
    }
}

.sb-project-settings-card-switch{
    white-space: normal;
    padding:0 @padding-normal;
    display: block;
    line-height: 1.2em;
}
.sb-radio-button-image-container {
     // Avoid the image to be draggable or selectable,
    // and avoid weird orange overlay in firefox
    img {
        border: 1px solid @sablono-grey-300;
        .not-selectable;
    }
    .md-label{
        .flex-row;
        justify-content: space-around;
    }
}

.sb-project-settings-card__header {
    .flex-row;
    align-items: baseline;
}

.sb-project-settings-card__topic-hint {
    color: @sablono-grey-600;
    font-size: @font-size-icon-normal;
    cursor: help;

    margin-left: @margin-small;
}

.sb-project-settings-card__topic-hint-text {
    .thin-font;
}

.sb-project-settings__team_selection {
    display: flex;
}
.sb-project-settings-currency-select{
    width: 165px;
}
