.sb-multi-selection-list{
    .sb-list-checkbox-content{
        display: flex!important;
    }
    span:first-child{
        font-size: @font-size-smaller;
        color: @sablono-grey-600;
        width: 120px;
        min-width: 120px!important;
        align-self:center;
    }
    span{
        white-space: normal!important;
    }
    &.sb-list-container .sb-list-checkboxes-row{
        max-height: none;
    }
}
.sb-multi-selection-list-dialog{
    md-dialog-content{
        overflow-x: hidden!important;
    }
}
