@import "./sbActivitiesListSelectorSearchbar/activities_list_selector_searchbar";

@sb-activities-list-selector-header-width: 92%;
@sb-activites-list-table-margin: 10px;

.sb-activities-list-selector__header {
    .sb-whiteframe-glow-z1;
    .flex-row();
    align-content: center;
    align-items: center;
    margin: @margin-small auto;
    width: @sb-activities-list-selector-header-width;
    padding: @padding-normal;
}

.sb-activities-list__header-checkbox {
    margin: @margin-none;
}

.sb-activities-list__header--clickable {
    cursor: pointer;
    outline: none;
}

.sb-activities-list__header-checkbox--has-active-selections{
    .sb-checkbox-remove-selection-state();
}

.sb-activities-list__activity-name, .sb-activities-list__template-progress-name{
    outline: none;
}

.sb-activities-list-selector__spacer {
    margin: @margin-none @margin-small;
}

.sb-activities-list-selector__activity-team-name {
    .smaller-font();
    font-style: italic;
}

.sb-activities-list-selector__table {
    margin: @sb-activites-list-table-margin;
    margin-bottom: @margin-none;
    position: relative;
    top: -@sb-activites-list-table-margin;
    padding: @padding-none;
    border: 2px solid @border-color;
    border-bottom: none;
    border-top: none;
}

.sb-activities-list-selector__activity-list {
    .flex-row();
    align-items: center;
    position: relative;
    color: @sablono-grey-700;
    outline-width: 0;
    border-bottom: 2px solid @border-color;
    padding: @padding-small;
    margin: @margin-none;
}

md-checkbox:last-of-type.sb-activities-list-selector__checkbox {
    .flex-auto();
    margin: @margin-smaller;
    margin-left: @margin-tiny;

    &.md-checked {
        .md-icon {
            background-color: @sablono-green-main;
        }

    }
}

.sb-activities-list-selector__activity {
    cursor: pointer;
}

.sb-activities-list-selector__activity--selected {
    font-weight: @font-weight-bold;
    color: @sablono-green-main;
}
