@import "../../../../../styles/colorpalette";
@import "../../../../../styles/global";

.sb-choice-group {
    background-color: @white-background;
}

.sb-choice {
    .flex-row;
    height: auto;
    border-bottom: 1px solid @sablono-grey-400;
    padding: @padding-normal 0;
    &:hover {
        background: @sablono-grey-50;
        cursor: pointer;
    }
}
.sb-choice[disabled=disabled]{
    cursor:default;
    opacity: 0.6;
}
.sb-choice__icons{
    position: relative;

    .sb-choice-first-icon {
        font-size: 30px;
        color: @sablono-green-button;

        &.smaller {
            font-size: 30px;
        }

        &.blue {
            color: @sablono-green-button
        }
    }

    .sb-choice-second-icon {
        color: @sablono-green-button;
        font-size: 30px;
        position: absolute;
        bottom: 13px;
        left: 19px;
    }

    .sb-choice-second-icon::before {
        color: @sablono-grey-400;
    }
}
.sb-choice__icon-container,
.sb-choice__loading-container{
    flex: 0 0 auto; //IE
    .flex-column-center;
    width: 90px;
    .mdi-chevron-right {
        align-self: flex-end;
        margin-right: @margin-normal;
        color: @sablono-grey-500;
        font-size: @font-size-icon-normal;
    }
    md-progress-circular{
        align-self: flex-end;
        margin-right: @margin-normal;
    }
}

.sb-choice__image img{
    width: 40px;

}
.sb-choice__text-container {
    flex: 1;
    .flex-row-center-start;
    h3, p {
        margin: @margin-smaller;
        font-weight: @font-weight-light;
        color: @sablono-grey-600;
    }
    h3{
        color: @sablono-grey-700;
    }
    .sb-choice__warning {
        color: @note-color-quality-issue;
    }

    .sb-choice__error {
        color: @note-color-obstruction;
    }
}


