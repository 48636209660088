@import "states/excel/excel";
@import "states/importSelection/import_selection";
@import "states/importUpload/import_upload";
@import "states/importProgress/import_progress";

@import "directives/sbUploadPreview/sb_upload_preview";
@import "directives/sbExcelViewer/sb_excel_viewer";
@import "directives/sbSelectFile/sb_select_file";


// @todo BA: I think this is for animations.. if we are not using ui-views anymore, we can remove it
[ui-view].import.ng-hide-add,
[ui-view].import.ng-hide-remove {
  /* remember to add this */
  display: flex !important;
  opacity: 1;
    background: red;
}

[ui-view].import.ng-hide-remove {
  animation: zoomIn 400ms;
}

[ui-view].import.ng-hide-add {
  animation: zoomOut 400ms;
}

[ui-view].import.ng-hide {
  //opacity:0;
}

[ui-view].import-settings.ng-hide-add,
[ui-view].import-settings.ng-hide-remove {
  /* remember to add this */
  display: flex !important;
  opacity: 1;
}

.import-sidenav.ng-hide-remove {
  animation: zoomIn 400ms;
}

.import-sidenav.ng-hide-add {
  animation: zoomOut 400ms;
}

.import-sidenav.ng-hide {
  //opacity:0;
}
