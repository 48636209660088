.sb-gallery {
    display: flex;
    flex: 1;
    position: relative;
    .not-selectable;
}

.sb-gallery__container {
    display: flex;
    flex: 1;
    overflow: hidden;
}

.sb-gallery__scroll {
    display: flex;
    flex: 1;
}

.sb-gallery__photo {
    width: 100%;
    background-position: center;
    background-size: contain;
    background-repeat: no-repeat;
    .flex-row-center;
}

.sb-gallery__arrows {
    display: flex;
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
}

.sb-gallery__arrow {
    flex: 0 0 50%;
    display: flex;
    align-items: center;
    font-size: 80px;
    color: @light-text-color;
    position: relative;
    outline-width: 0;
    opacity: 0;
    cursor: pointer;

    &.sb-gallery__arrow--active {
        opacity: 1;
    }
}

.sb-gallery__arrow--left {
    justify-content: flex-start;
}
.sb-gallery__arrow--right {
    justify-content: flex-end;
}
