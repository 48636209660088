.sb-timeline-detail__note {
    width: 100%;
    .sb-issue-row{
        border: none;
    }
}

.sb-timeline-detail__deliverable {

    .sb-deliverable-cell-rows {
        cursor: pointer;
    }
    .sb-deliverable-row {
        border: none;
        width: 100%;
    }
}

.sb-timeline-detail__loader {
    margin-bottom: 12px;
}
